import { API_BASE_URL } from '@config';
import { AUTH_ROUTES } from '@shared/config';
import { HttpService } from '@core/services';
import { ISuccess } from '@shared/interfaces';
import { joinUrl } from '@shared/utils';

/**
 * @class SignUpService
 * @description service class for handling SignUp process.
 */
export class SignUpService {
  private static get url() {
    return joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.signupOry);
  }

  private static get createUser_url() {
    return joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.createUser);
  }

  private static get verify_url() {
    return joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.vupdate);
  }

  private static get accept_url() {
    return joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.accept);
  }

  private static get send_verify_url() {
    return joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.vLink);
  }

  private static get send_recovery_url() {
    return joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.recovery);
  }

  private static get delete_smoke_users() {
    return joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.delSmokeUser);
  }

  private static get get_ss_url() {
    return joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.getSS);
  }

  private static get get_bldomains_url() {
    return joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.bldomains);
  }

  /**
   * @function
   * @name signupOry
   * @description service function for SignUp
   * @param {any} signUpDetails
   * @returns {Promise<any>} response as a promise
   */
  public static async signupOry(signUpDetails: any): Promise<ISuccess> {
    const response = await HttpService.post<ISuccess>(joinUrl(this.url), signUpDetails);
    return response;
  }

  public static async createUser(createUserReq: any): Promise<ISuccess> {
    const response = await HttpService.post<ISuccess>(joinUrl(this.createUser_url), createUserReq, {withCredentials: true});
    return response;
  }

  public static async updateVerified(vDetails: any): Promise<ISuccess> {
    const response = await HttpService.post<ISuccess>(joinUrl(this.verify_url), vDetails);
    return response;
  }

  public static async updateAccepted(aDetails: any): Promise<ISuccess> {
    const response = await HttpService.post<ISuccess>(joinUrl(this.accept_url), aDetails);
    return response;
  }

  public static async sendVerifyLink(vDetails: any): Promise<ISuccess> {
    const response = await HttpService.post<ISuccess>(joinUrl(this.send_verify_url), vDetails, {withCredentials: true});
    return response;
  }

  public static async sendRecoveryLink(req: any): Promise<ISuccess> {
    const response = await HttpService.post<ISuccess>(joinUrl(this.send_recovery_url), req, {withCredentials: true});
    return response;
  }


    /**
     * @function
     * @name delSmokeUser
     * @description service function to delete smoke users
     * @param {any} signUpDetails
     * @returns {Promise<any>} response as a promise
     */
     public static async delSmokeUser(): Promise<ISuccess> {
      const response = await HttpService.delete<ISuccess>(joinUrl(this.delete_smoke_users), null);
      return response;
    }

  public static async getSS(): Promise<Boolean> {
    const response = await HttpService.get<Boolean>(joinUrl(this.get_ss_url), null);
    return response;
  }

  public static async getBLDomains() {
    const response = await HttpService.get<string[]>(joinUrl(this.get_bldomains_url), null);
    return response;
  }
  
}
