import { AxiosRequestConfig } from 'axios';
import { ORG_HEADER_NAME } from '@shared/config';
import Vue from 'vue';

/**
  * @class HttpService
  * @description
  * A wrapper class over axios HTTP interface.
  */
  export class HttpService {

    /**
       * @function get
       * @description launch a get request
       *
       * @param  {string} url
       * @param  {config} AxiosRequestConfig
       * @return {T} Promise of type T
       */
    public static get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, config)
          .then(response => resolve(response.data))
          .catch(error => reject(error));
      });
    }

    /**
       * @function post
       * @description launch a post request with given payload
       *
       * @param  {string} url
       * @param {any} data payload for post request
       * @param  {config} AxiosRequestConfig
       * @return {T} Promise of type T
       */
    public static post<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
      //console.log('post')
      return new Promise((resolve, reject) => {
        Vue.axios.post(url, data, config )
          .then(response => resolve(response.data))
          .catch(error => reject(error));
      });
    }

      /**
       * @function delete
       * @description launch a delete request with given payload
       *
       * @param  {string} url
       * @param {any} data payload for post request
       * @return {T} Promise of type T
       */
      public static delete<T>(url: string, data: any): Promise<T> {
        return new Promise((resolve, reject) => {
          Vue.axios.delete(url, data)
            .then(response => resolve(response.data))
            .catch(error => reject(error));
        });
      }
  }
