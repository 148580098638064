// import cfg from '../../public/config.json'
import { ConfigClass } from './config';
// console.log('app.config')
//----- do not use these env variables-----//
// const API_BASE_URL = process.env.VUE_APP_API_URL;
// const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;
// const API_GRAPHQL = process.env.VUE_APP_API_GRAPHQL;
// const API_ORY_URL = process.env.VUE_APP_ORY_URL;
// const API_WS_URL = process.env.VUE_APP_WS_URL;
// const SYNCSERVICE_URL = process.env.VUE_APP_SYNCSERVICE_URL;

// const API_BASE_URL = cfg.API_URL+'/v1/api';
// const SENTRY_DSN = cfg.API_URL+'/sentry';
// const API_GRAPHQL = cfg.API_URL+'/graphql';
// const API_ORY_URL = cfg.ORY_URL;
// const API_WS_URL = cfg.API_URL;

// import fetch from 'sync-fetch';

let API_BASE_URL = ''
let SENTRY_DSN = ''
let API_GRAPHQL = ''
let API_ORY_URL = ''
let API_WS_URL = ''
let SYNCSERVICE_URL = ''
let CB_SITE_NAME = ''
// let IMGKIT_URL = ''
// let IMGKIT_PUB = ''

// const cfg = fetch(process.env.BASE_URL + "config.json").json()

// console.log(cfg)
// API_BASE_URL = cfg.API_URL+'/v1/api';
// SENTRY_DSN = cfg.API_URL+'/sentry';
// API_GRAPHQL = cfg.API_URL+'/graphql';
// API_ORY_URL = cfg.ORY_URL;
// API_WS_URL = cfg.API_URL;

var request = new XMLHttpRequest();
request.open('GET', process.env.BASE_URL + "config.json", false);
request.send(null);

if (request.status === 200) {
  //console.log(request.responseText);
  const cfg = JSON.parse(request.responseText)
  API_BASE_URL = cfg.API_URL+'/v1/api';
  SENTRY_DSN = cfg.API_URL+'/sentry';
  API_GRAPHQL = cfg.API_URL+'/graphql';
  API_ORY_URL = cfg.ORY_URL;
  API_WS_URL = cfg.API_URL;
  CB_SITE_NAME = cfg.CB_SITE_NAME;
  // IMGKIT_URL = cfg.IMGKIT_URL;
  // IMGKIT_PUB = cfg.IMGKIT_PUB;

}else{
  alert('Unable to load App. Please try later')
}


// fetch(process.env.BASE_URL + "config.json")
// .then((response) => response.json())
// .then((cfg) => {
//   // ConfigClass.API_URL = cfg.API_URL+'/v1/api'
//   // ConfigClass.ORY_URL = cfg.ORY_URL
//   console.log(cfg)
//   API_BASE_URL = cfg.API_URL+'/v1/api';
//   SENTRY_DSN = cfg.API_URL+'/sentry';
//   API_GRAPHQL = cfg.API_URL+'/graphql';
//   API_ORY_URL = cfg.ORY_URL;
//   API_WS_URL = cfg.API_URL;
//   SYNCSERVICE_URL = cfg.API_URL+ '/sync/';
//   console.log(API_GRAPHQL)
// })
// .catch(err => console.log('config file not found'))


// async function fetchConfig() {
//   try {
//     const res = await ( await fetch(process.env.BASE_URL + "config.json") ).json();
//     console.log(res)
//     return res
//   } catch(e) { console.log(e); }
// }

// (async function () {
//   const cfg = await fetchConfig();
//   console.log(cfg)
//   API_BASE_URL = cfg.API_URL+'/v1/api';
//   SENTRY_DSN = cfg.API_URL+'/sentry';
//   API_GRAPHQL = cfg.API_URL+'/graphql';
//   API_ORY_URL = cfg.ORY_URL;
//   API_WS_URL = cfg.API_URL;
//   SYNCSERVICE_URL = cfg.API_URL+ '/sync/';
//   console.log(API_GRAPHQL)
// })()

// while (!API_BASE_URL) {
//   console.log(API_BASE_URL)
// }

export {
  API_BASE_URL,
  SENTRY_DSN,
  API_GRAPHQL,
  API_ORY_URL,
  API_WS_URL,
  CB_SITE_NAME,
  // IMGKIT_URL,
  // IMGKIT_PUB
};
