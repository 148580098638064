import gql from 'graphql-tag';

export const APPLICATION_INSIGHTS = gql`
  query getAppsForKeyword(
    $keyword: String!
    $startDate: String!
    $endDate: String!
    $location: String!
    $language: String!
    $device: String!
  ) {
    getApplications: BiddingApps(
      inputs: { keyword: $keyword, language: $language, location: $location, device: $device, startDate: $startDate, endDate: $endDate }
    ) {
      keyword
      datetime
      apps {
        application_id
        application_details {
          icon
          title
          genres
        }
      }
    }
  }
`;

export const KEYWORD_INFO = gql`
  query getInfoForKeyword(
    $keyword: String!
    $location: String!
    $language: String!
    $device: String!
    $startDate: String!
    $endDate: String!
  ) {
    getKeywordInfo: KeywordStat(
      inputs: { keyword: $keyword, language: $language, location: $location, device: $device, startDate: $startDate, endDate: $endDate }
    ) {
      keyword
      content
    }
  }
`;

export const KEYWORD_RANKINGS = gql`
  query getRankingsForKeyword(
    $keyword: String!
    $startDate: String!
    $endDate: String!
    $appIds: [Float!]!
    $location: String!
    $language: String!
    $device: String!
  ) {
    getKeywordRankings: KeywordRankings(
      inputs: {
        keyword: $keyword
        language: $language
        location: $location
        device: $device
        startDate: $startDate
        endDate: $endDate
        applications: $appIds
      }
    ) {
      keyword
      appid
      rank
    }
  }
`;

export const APP_KEYWORDS = gql`
  query getKeywordsForApp(
    $appIds: [Float!]!
    $location: String!
    $language: String!
    $device: String!
    $startDate: String!
    $endDate: String!
  ) {
    getAppKeywords: AppKeywords(
      inputs: { appId: $appIds, language: $language, location: $location, device: $device, startDate: $startDate, endDate: $endDate }
    ) {
      appid
      count
    }
  }
`;

export const APP_KEYWORDS_FULL = gql`
  query getKeywordsForAppFull(
    $appIds: [Float!]!
    $location: String!
    $language: String!
    $device: String!
    $startDate: String!
    $endDate: String!
  ) {
    getAppKeywordsFULL: AppKeywords(
      inputs: { appId: $appIds, language: $language, location: $location, device: $device, startDate: $startDate, endDate: $endDate }
    ) {
      appid
      count
      keywords {
        ranking
        keyword
      }
    }
  }
`;

export const SEARCH_BY_APP = gql`
  query getSearchByApp(
    $appId: Float!
    $title: String!
    $icon: String!
    $location: String!
    $language: String!
    $device: String!
    $startDate: String!
    $endDate: String!
    $currPage: Float!
    $itemsPerPage: Float!
    $opId: Float!
    $clientId: String!
    $CountPref: Float!
  ) {
    searchByApp: searchByApp(
      inputs: {
        appId: $appId
        title: $title
        icon: $icon
        opId: $opId
        clientId: $clientId
        language: $language
        location: $location
        device: $device
        startDate: $startDate
        endDate: $endDate
        currPage: $currPage
        itemsPerPage: $itemsPerPage
        CountPref: $CountPref
      }
    ) {
      details
      keywords {
        name
        popularity
        totalApps
        organicRank
      }
      currentPage
      nextPage
      prevPage
      count
      fromDB
    }
  }
`;

export const GET_RECOM_LIST = gql`
  query getRecommendationList(
    $appId: Float!
    $location: String!
    $language: String!
    $device: String!
    $startDate: String!
    $endDate: String!
    $currPage: Float!
    $itemsPerPage: Float!
    $opId: Float!
    $clientId: String!
  ) {
    getRecommendationList: getRecommendationList(
      inputs: {
        appId: $appId
        opId: $opId
        clientId: $clientId
        language: $language
        location: $location
        device: $device
        startDate: $startDate
        endDate: $endDate
        currPage: $currPage
        itemsPerPage: $itemsPerPage
      }
    ) {
      details
      keywords {
        name
        popularity
        totalApps
        totalDownloads
      }
      currentPage
      nextPage
      prevPage
      count
      fromDB
    }
  }
`;

export const SEARCH_BY_KEYWORD = gql`
  query getSearchByKeyword(
    $keyword: String!
    $location: String!
    $language: String!
    $device: String!
    $startDate: String!
    $endDate: String!
    $currPage: Float!
    $itemsPerPage: Float!
    $opId: Float!
    $clientId: String!
  ) {
    searchByKeyword: searchByKeyword(
      inputs: {
        keyword: $keyword
        opId: $opId
        clientId: $clientId
        language: $language
        location: $location
        device: $device
        startDate: $startDate
        endDate: $endDate
        currPage: $currPage
        itemsPerPage: $itemsPerPage
      }
    ) {
      details
      apps {
        appDetails
        organicRank
        category
        noOfKeywords
        appId
      }
      fromDB
    }
  }
`;

export const APP_METADATA = gql`
  query getMetadataForApp(
    $appId: Float!
    $location: String!
    $language: String!
    $device: String!
    $startDate: String!
    $endDate: String!
  ) {
    getAppMetadata: AppMetaData(
      inputs: { appId: $appId, language: $language, location: $location, device: $device, startDate: $startDate, endDate: $endDate }
    ) {
      content {
        icon
        title
        rating
        genres
        developer {
          name
        }
      }
    }
  }
`;

//charge bee queries

export const CB_Customers = gql`
  query getCBCustomers($id: String!) {
    getAllCBCustomers: getCBCustomer(id: $id) {
      list {
        customer
      }
    }
  }
`;

export const CB_Subs = gql`
  query getCBSubs($custId: String!) {
    getSubsForCust: getCBSubs(custId: $custId) {
      list {
        FirstName
        LastName
        Email
        CompanyName
        BillingAddress
        TeamID
        NetTermDays
        CreatedOn
        UpdatedOn
        ActivatedOn
        TrailStart
        TrailEnd
        Status
        IsDeleted
        BillingPeriod
        BillingPeriodUnit
        Currency
        ExchangeRate
        SubscriptionItems
        Invoices
        NextBillingAt
      }
    }
  }
`;

export const CB_ChangeSub = gql`
  query changeSubPlan($subId: String!) {
    changePlanForSub: changeSubForCust(subId: $subId) {
      subscription
    }
  }
`;

export const CB_SubOnboard = gql`
  query createSubPlan(
    $plan_id: String!
    $invoice_immediately: Boolean!
    $first_name: String!
    $last_name: String!
    $customer_id: String!
    $email: String!
  ) {
    createPlanForSub: CreateCBSubPlan(
      plan_id: $plan_id
      invoice_immediately: $invoice_immediately
      first_name: $first_name
      last_name: $last_name
      customer_id: $customer_id
      email: $email
    ) {
      subscription
      customer
    }
  }
`;

export const CB_CREATE_CUSTOMER = gql`
  query CreateCBCustomer($cust_id: String!, $first_name: String!, $last_name: String!, $email: String!, $company: String!) {
    CreateCBCustomer(cust_id: $cust_id, first_name: $first_name, last_name: $last_name, email: $email, company: $company) {
      customer
    }
  }
`;

export const CHANGE_TERM_END = gql`
  query changeTermEndPlan($sub_id: String!, $termEndDate: String!) {
    changeTermEndPlan(sub_id: $sub_id, termEndDate: $termEndDate) {
      UpdatedOn
      TrailEnd
      TeamID
    }
  }
`;

export const APP_OVERVIEW_ALONE = gql`
  query getOverviewAloneForApp(
    $period: Float!
    $gender: String!
    $location: String!
    $device: String!
    $currdate: String!
    $prevdate: String!
    $appId: Float!
    $change: String!
  ) {
    currentOverviewAlone: getOverviewAlone(
      inputs: { period: $period, gender: $gender, location: $location, device: $device, date: $currdate, adamId: $appId, change: $change }
    ) {
      grandTotal
    }

    previousOverviewAlone: getOverviewAlone(
      inputs: { period: $period, gender: $gender, location: $location, device: $device, date: $prevdate, adamId: $appId, change: $change }
    ) {
      grandTotal
    }
  }
`;

export const APP_OVERVIEW_MAP = gql`
  query getOverviewMapForApp(
    $period: Float!
    $gender: String!
    $location: String!
    $device: String!
    $currdate: String!
    $appId: Float!
    $change: String!
  ) {
    getMapOverview(
      inputs: { period: $period, gender: $gender, location: $location, device: $device, date: $currdate, adamId: $appId, change: $change }
    ) {
      grandTotal
    }
  }
`;

export const APP_OVERVIEW = gql`
  query getOverviewForApp(
    $period: Float!
    $location: String!
    $device: String!
    $currdate: String!
    $prevdate: String!
    $appId: Float!
    $change: String!
  ) {
    currentOverview: getOverview(
      inputs: { period: $period, gender: "MF", location: $location, device: $device, date: $currdate, adamId: $appId, change: $change }
    ) {
      grandTotal
    }

    previousOverview: getOverview(
      inputs: { period: $period, gender: "MF", location: $location, device: $device, date: $prevdate, adamId: $appId, change: $change }
    ) {
      grandTotal
    }
  }
`;

export const APP_OVERVIEW_DEVICE = gql`
  query getDeviceOverviewForApp(
    $period: Float!
    $location: String!
    $device: String!
    $currdate: String!
    $prevdate: String!
    $appId: Float!
    $change: String!
  ) {
    currentOverview: getDeviceOverview(
      inputs: { period: $period, gender: "PH", location: $location, device: $device, date: $currdate, adamId: $appId, change: $change }
    ) {
      grandTotal
    }

    previousOverview: getDeviceOverview(
      inputs: { period: $period, gender: "PH", location: $location, device: $device, date: $prevdate, adamId: $appId, change: $change }
    ) {
      grandTotal
    }
  }
`;

export const OWN_APPS = gql`
  query getOwnApps($from: String!, $at: Float! = 1) {
    getAllOwnApps: getOwnAppsFromDB(from: $from, at: $at) {
      adamId
      appName
      attrExist
      attrType
    }
  }
`;

export const TOP_ADS = gql`
  query getTopAds($param: String!, $appId: Float!, $period: Float!, $goalId: String) {
    getTopAds: getTopAdGroups(appId: $appId, param: $param, period: $period, goalId: $goalId) {
      id
      name
      localSpend
      impressions
      taps
      goalEventCount
      currency
    }
  }
`;

export const TOP_KEYS = gql`
  query getTopKeys($param: String!, $appId: Float!, $period: Float!, $goalId: String) {
    getTopKeys: getTopKeywords(appId: $appId, param: $param, period: $period, goalId: $goalId) {
      id
      name
      localSpend
      impressions
      taps
      goalEventCount
      currency
    }
  }
`;

export const ALL_CARDS = gql`
  query getAllCards($custId: String!, $limit: Float!, $type: String!) {
    getAllCards: getCBCustCard(custId: $custId, limit: $limit, type: $type) {
      list {
        payment_source
      }
      next_offset
    }
  }
`;

export const ADD_CARD = gql`
  query addCard($custId: String!, $number: String!, $cvv: Float!, $year: Float!, $month: Float!, $primary: Boolean!) {
    addCard: addCBCustCard(
      inputs: {
        customer_id: $custId
        replace_primary_payment_source: $primary
        number: $number
        cvv: $cvv
        expiry_year: $year
        expiry_month: $month
      }
    ) {
      code
      successMessage
    }
  }
`;

export const UPDATE_CARD = gql`
  query updateCard($cardId: String!, $year: Float!, $month: Float!) {
    updateCard: updateCBCustCard(inputs: { cardId: $cardId, expiry_year: $year, expiry_month: $month }) {
      customer
      payment_source
    }
  }
`;

export const DEL_CARD = gql`
  query deleteCard($cardId: String!) {
    deleteCard: deleteCBCustCard(cardId: $cardId) {
      customer
      payment_source
    }
  }
`;

export const ALL_TRANS = gql`
  query allTrans($transId: String!, $custId: String!, $limit: Float!, $offset: String!) {
    allTrans: getCBTransaction(transId: $transId, custId: $custId, limit: $limit, offset: $offset) {
      list {
        transaction
      }
    }
  }
`;

export const ALL_INVOICES = gql`
  query getAllInvoices($custId: String!, $limit: Float!) {
    getAllInvoices: getCBCustInvoices(custId: $custId, limit: $limit) {
      list {
        invoice
      }
    }
  }
`;

export const MANUAL_SPEND_CHECK = gql`
  query spendCheckManual($invoice: String!) {
    spendCheckManual(invoice: $invoice) {
      successMessage
    }
  }
`;

export const DL_INVOICE = gql`
  query dlInvoice($invoice_id: String!, $disposition_type: String!) {
    dlInvoice: getCBCustInvoicePDF(invoice_id: $invoice_id, disposition_type: $disposition_type) {
      download
    }
  }
`;

export const KEYWORD_RECOMENDS = gql`
  query KeywordRecomends($appid: Float!, $country: String!, $term: String!) {
    KeywordRecomends: KeywordRecomends(inputs: { appid: $appid, country: $country, term: $term }) {
      content {
        volume
        keyword
      }
      country
      date
      datetime
      term
    }
  }
`;

export const SEARCH_FOR_APPS = gql`
  query searchForApps($term: String!, $location: String!, $language: String!, $device: String!) {
    searchForApps: searchForApps(inputs: { term: $term, language: $language, location: $location, device: $device, num: 100 }) {
      title
      id
      icon
    }
  }
`;

export const SEARCH_FOR_COMPETES = gql`
  query getCompetitors(
    $appId: Float!
    $location: String!
    $language: String!
    $device: String!
    $clientId: String!
    $appIcon: String!
    $appName: String!
  ) {
    getCompetitors: getCompetitors(
      inputs: {
        appId: $appId
        language: $language
        location: $location
        device: $device
        clientId: $clientId
        appIcon: $appIcon
        appName: $appName
      }
    ) {
      title
      id
      icon
      version
      rating
      genres
      price
    }
  }
`;

export const GET_CATEGORY_TOP = gql`
  query getCategoryTop(
    $catId: Float!
    $location: String!
    $language: String!
    $device: String!
    $type: String!
    $now: Float!
    $clientId: String!
  ) {
    getCategoryTop: getCategoryTop(
      inputs: { catId: $catId, type: $type, now: $now, language: $language, location: $location, device: $device, clientId: $clientId }
    ) {
      title
      id
      icon
      rating
      genres
      price
      rating_count
      power
      in_apps
    }
  }
`;

export const UPDATE_CHECKLIST = gql`
  query updateCL($userId: String!, $clfield: CheckListFields!, $time: Float!) {
    updateCL: updateCL(req: { clfield: $clfield, userId: $userId, time: $time }) {
      successMessage
      code
    }
  }
`;

export const GET_CHECKLIST = gql`
  query getUserCL($userId: String!) {
    getUserCL: getUserCL(userId: $userId) {
      createCampaign
      createAdgroup
      addKeys
      addCS
      saIntegration
      slackIntegration
      overviewPage
      kaiApp
      kaiKeyword
      kaiRecom
      kaiTop
      kaiComp
      # teamManagement
      addMember
      automationRules
      createRule
      # checkLogs
      adsManager
      # optimization
      onboarding
      # buyPlan
      scheduledReports
      campGen
      createMmpGoals
      mmpIntegration
    }
  }
`;

export const CHECK_APP_ATTR = gql`
  query getUserCL($appId: Float!) {
    checkAppAttr: checkAppAttr(appId: $appId) {
      successMessage
      code
      content
    }
  }
`;

export const SAVE_KA = gql`
  query kochavaAttributionSave($apple_app_id: Float!, $app_guid: String!, $api_key: String!, $hydrate_date: String!, $type: String!) {
    kochavaAttributionSave: kochavaAttributionSave(
      inputs: { api_key: $api_key, app_guid: $app_guid, apple_app_id: $apple_app_id, hydrate_date: $hydrate_date, type: $type }
    ) {
      successMessage
      code
    }
  }
`;

export const DELETE_KA = gql`
  query deleteKA($appId: Float!) {
    deleteKA: kochavaAttributionDelete(appId: $appId) {
      successMessage
      code
    }
  }
`;

export const GET_DATA_REQUESTS = gql`
  query getDataRequests($at: Float!) {
    getDataRequests: getDataRequests(at: $at) {
      completed
      appId
      keyword
      startTime
      endTime
      currentTime
      currentCount
      totalCount
      missedCount
      location
      device
      language
      clientId
      opId
      appName
      _id
      icon
      progress
      catId
      catType
      CountPref
    }
  }
`;

export const DELETE_DR = gql`
  query deleteDataRequest($dr_id: String!) {
    deleteDataRequest: deleteDataRequest(dr_id: $dr_id) {
      successMessage
      code
    }
  }
`;

export const SEND_SLACK_MSG = gql`
  query sendSlackMsg($change: Float!, $intId: String!) {
    sendSlackMsg: sendSlackMsg(change: $change, intId: $intId) {
      successMessage
      code
    }
  }
`;

export const RE_INVITE_CA = gql`
  query reInviteCA($userId: String!, $email: String!, $change: Float!) {
    reInviteCA: reInviteCA(change: $change, userId: $userId, email: $email) {
      successMessage
      code
    }
  }
`;

export const TOGGLE_AUTOMATION_V2 = gql`
  query toggleAutomationV2($teamId: String!, $at: Float!) {
    toggleAutomationV2: toggleAutomationV2(at: $at, teamId: $teamId) {
      successMessage
      code
    }
  }
`;

export const SBA_REPORT = gql`
  query searchByAppReport($appId: Float!, $location: String!, $language: String!, $device: String!) {
    searchByAppReport: searchByAppReport(inputs: { appId: $appId, language: $language, location: $location, device: $device }) {
      successMessage
      data
    }
  }
`;

export const CB_MIGRATE = gql`
  query migrateCBSubs($custId: String!) {
    migrateCBSubs: migrateCBSubs(custId: $custId) {
      successMessage
      isSuccess
    }
  }
`;

export const CB_TEAMID_MIGRATE = gql`
  query migrateSpecificCBSubs($TeamIDs: [String!]!) {
    migrateCBSubs: migrateSpecificCBSubs(TeamIDs: $TeamIDs) {
      successMessage
      isSuccess
    }
  }
`;

export const CB_TEAMID_DELETE = gql`
  query deleteCBSubs($ObjIDs: [String!]!) {
    migrateCBSubs: deleteCBSubs(ObjIDs: $ObjIDs) {
      successMessage
      isSuccess
    }
  }
`;

export const CB_TEAMID_REMOVE = gql`
  query removeCBSubs($ObjIDs: [String!]!) {
    migrateCBSubs: removeCBSubs(ObjIDs: $ObjIDs) {
      successMessage
      isSuccess
    }
  }
`;
