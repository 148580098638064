import { SESSION_HEADER_NAME, TEAM_HEADER_NAME, TOKEN_HEADER_NAME, USER_HEADER_NAME } from '@shared/config'

import { API_GRAPHQL } from '@config'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { LSS } from '@core/services'
import { decodeToken } from '@shared/utils/commom.util'
// HTTP connection to the API
const httpLink = new HttpLink({
  // You should use an absolute URL here
  uri: API_GRAPHQL
});

const authLink = new ApolloLink((operation, forward) => {
  //console.log(operation.operationName)
  const decoded = decodeToken(LSS.token)
  operation.setContext((settings: any) => ({ headers: {
    // TODO any auth props go here
    // [USER_HEADER_NAME]: decoded.UserId, // appending userId
    // [TEAM_HEADER_NAME]: decoded.TeamID,
    [SESSION_HEADER_NAME]: LSS.sessId,
    [TOKEN_HEADER_NAME]: LSS.token,
    ...settings.headers
  }}));
  return forward(operation);
});

const combined = ApolloLink.from([authLink, httpLink])

// Cache implementation
const cache = new InMemoryCache({
  addTypename: false
})

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: combined,
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache'
    }
  }
});
