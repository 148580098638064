//Russian
export const ru = {
    pages: {
      overview: 'Обзор',
      adsManager: 'Менеджер по рекламе',
      kai: 'Анализ ключевых слов',
      keyOpt: 'Оптимизация ключевых слов',
      teamMgmt: 'Команда',
      integrations: 'Интеграции',
      automationRule: 'Автоматизация',
      campaignGenerator: 'Генератор кампании',
      goals: 'Цели',
      appSettings: 'Настройки приложения',
      kar: 'Запросы на анализ ключевых слов',
      manualCampaign:'Добавить ручную кампанию',
    automatedCampaign:'Добавить автоматизированную кампанию',
    },
    menu: {
      settings: 'Настройки',
      signOut: 'Выход',
      manageSubscription: "Управление подпиской",
      orgs: 'Организации'
    },
    buttons: {
      syncData: 'Синхронизировать последние данные',
      createCampaign: 'Создать кампанию',
      filter: 'Фильтр',
      reset: 'Сброс настроек',
      apply: 'Применять'
    },
    labels: {
      charts: 'Диаграммы',
      selectDs: 'Выберите наборы данных',
      totalSummary: 'Итоговая сводка',
      spend: 'Проводить',
      search: 'Поиск',
      searchByKeySearchMsg: 'Введите текст и нажмите ввод',
      competeSearchMsg : 'Выберите или найдите приложение',
      selectCategoryMsg: 'Выбрать категорию',
      selectCategoryType: 'Выберите тип',
      matchType: 'Тип соответствия',
      filterItem: 'Введите здесь, чтобы отфильтровать элементы ниже',
      cusType: 'Типы клиентов',     //labels both
      createRule: 'Создать правило для',
      fromSelected: 'Из выбранного',
    mmYY: 'ММ / ГГ'   ,
    enterKey: 'Введите ключевые слова'   ,
    selectLevel: 'Выбрать уровень',
    org: 'Организация (ы)',
    app: 'Приложения)',
    camp: 'Кампания (и)',
    adGroup: 'Группа (ы) объявлений',
    key: 'Ключевое слово (а)',
    searchTerm: 'Поисковые запросы',
    yourApps: 'Your Apps',
    suggestedApps: 'Suggested Apps',

    },
    dropdowns: {
      noData: 'Данные недоступны',
      daily: 'ЕЖЕДНЕВНО',
      weekly: 'ЕЖЕНЕДЕЛЬНО',
      monthly: 'ЕЖЕМЕСЯЧНО'
    },
    tabs: {
      accounts: 'учетные записи',
      apps: 'Программы',
      campaigns: 'Кампании',
      adgroups: 'Группы объявлений',
      keywords: 'Ключевые слова',
      creativeSets: 'Творческие наборы',
      searchTerms: 'Поисковые запросы',
      negKeywords: 'Минус-слова',
      ad: 'Объявление'
    },
    summary: {
      taps: 'Краны',
      impressions: 'Впечатления',
      ttr: 'TTR',
      newDownloads: 'Новые загрузки',
      reDownloads: 'Повторные загрузки',
      installs: 'Устанавливает',
    },
    accountHeaders: {
      account: 'Счет',
      spend: 'Проводить',
      averageCpa: 'Средняя цена за конверсию',
      averageCpt: 'Средняя цена за клик',
      impressions: 'Впечатления',
      taps: 'Краны',
      installs: 'Устанавливает',
      ttr: 'TTR',
      conversionRate: 'Коэффициент конверсии',
      newDownloads: 'Новые загрузки',
      reDownloads: 'Повторные загрузки'
    },
    appsHeaders: {
      appName: 'Заявление',
      spend: 'Проводить',
      averageCpa: 'Средняя цена за конверсию',
      averageCpt: 'Средняя цена за клик',
      impressions: 'Впечатления',
      taps: 'Краны',
      installs: 'Устанавливает',
      ttr: 'TTR',
      conversionRate: 'Коэффициент конверсии',
      attrInstall: 'Attr Установить',
      attrInstallRate: 'Скорость установки Attr',
      cpi: 'CPI',
      goals: 'Цели',
      costPerGoal: 'Стоимость за цель',
      goalRate: 'Целевая ставка',
      revenuePerGoal: 'Доход на цель',
      goalsRevenue: 'Доход от целей',
      goalsRoas: 'Цели ROAS',
      newDownloads: 'Новые загрузки',
      reDownloads: 'Повторные загрузки',
      account: 'Счет',
      categoryRank: 'Категория Ранг',
      appId: 'ID приложения',
      totalCamp: 'Всего кампаний',
      goalAMPU: 'Цель АМПУ'
    },
    campaignHeaders: {
      campaign: 'Кампания',
      campaignStatus: 'Статус кампании',
      spend: 'Проводить',
      averageCpa: 'Средняя цена за конверсию',
      averageCpt: 'Средняя цена за клик',
      impressions: 'Впечатления',
      taps: 'Краны',
      installs: 'Устанавливает',
      newDownloads: 'Новые загрузки',
      reDownloads: 'Повторные загрузки',
      ttr: 'TTR',
      conversionRate: 'Коэффициент конверсии',
      attrInstallRate: 'Скорость установки Attr',
      cpi: 'CPI',
      goals: 'Цели',
      costPerGoal: 'Стоимость за цель',
      goalRate: 'Целевая ставка',
      revenuePerGoal: 'Доход на цель',
      goalsRoas: 'Цели ROAS',
      storefronts: 'Витрины',
      account: 'Счет',
      displayStatus: 'Состояние дисплея',
      appName: 'Имя приложения',
      appId: 'ID приложения',
      campaignId: 'Идентификатор кампании',
      delete: 'Удалить',
      totalBudget: 'Общий бюджет',
      dailyCap: 'Дневной лимит',
      supplySource:'Тип'
    },
    campaignDraftHeaders: {
      campaignName: 'Название кампании',
      countries: 'Страны',
      budgetAmount: 'Сумма бюджета',
      resume: 'Резюме',
    },
    adGroupHeaders: {
      adgroup: 'Группа объявлений',
      adgroupStatus: 'Статус группы объявлений',
      spend: 'Проводить',
      averageCpa: 'Средняя цена за конверсию',
      averageCpt: 'Средняя цена за клик',
      impressions: 'Впечатления',
      taps: 'Краны',
      installs: 'Устанавливает',
      newDownloads: 'Новые загрузки',
      reDownloads: 'Повторные загрузки',
      ttr: 'TTR',
      conversionRate: 'Коэффициент конверсии',
      attrInstallRate: 'Скорость установки Attr',
      cpi: 'CPI',
      goals: 'Цели',
      costPerGoal: 'Стоимость за цель',
      goalRate: 'Целевая ставка',
      revenuePerGoal: 'Доход на цель',
      goalsRoas: 'Цели ROAS',
      storefronts: 'Витрины',
      campaignStatus: 'Статус кампании',
      displayStatus: 'Состояние дисплея',
      account: 'Счет',
      appId: 'ID приложения',
      campaignId: 'Идентификатор кампании',
      adgroupId: 'ID группы объявлений'
    },
    keyHeaders: {
      keyword: 'Ключевое слово',
      keywordStatus: 'Статус ключевого слова',
      bidAmount: 'Сумма ставки',
      spend: 'Проводить',
      averageCpa: 'Средняя цена за конверсию',
      averageCpt: 'Средняя цена за клик',
      impressions: 'Впечатления',
      taps: 'Краны',
      installs: 'Устанавливает',
      newDownloads: 'Новые загрузки',
      reDownloads: 'Повторные загрузки',
      ttr: 'TTR',
      conversionRate: 'Коэффициент конверсии',
      attrInstallRate: 'Скорость установки Attr',
      cpi: 'CPI',
      goals: 'Цели',
      costPerGoal: 'Стоимость за цель',
      goalRate: 'Целевая ставка',
      revenuePerGoal: 'Доход на цель',
      goalsRevenue: 'Доход от целей',
      storefronts: 'Витрины',
      campaignStatus: 'Статус кампании',
      adgroupStatus: 'Статус группы объявлений',
      organicRank: 'Органический рейтинг',
      appName: 'Имя приложения',
      difficultyScore: 'Оценка сложности',
      popularity: 'Популярность',
      keywordId: 'Идентификатор ключевого слова',
      appId: 'ID приложения',
      campaignId: 'Идентификатор кампании',
      adgroupId: 'ID группы объявлений',
      suggestedMinBid: 'Рекомендуемая минимальная ставка ASA',
      suggestedMaxBid: 'Рекомендуемая максимальная ставка ASA',
      bidStrength: 'Сила ставки',
      latOff: 'LAT Off Установки',
      latOn: 'LAT при установке',
      matchtype: 'Тип соответствия',
    },
    adsHeaders: {
      ads: 'Объявление',
      creativeType: 'Тип объявления',
      creativeSetStatus: 'Статус',
      spend: 'Проводить',
      averageCpa: 'Средняя цена за конверсию',
      averageCpt: 'Средняя цена за клик',
      impressions: 'Впечатления',
      taps: 'Краны',
      installs: 'Устанавливает',
      newDownloads: 'Новые загрузки',
      reDownloads: 'Повторные загрузки',
      ttr: 'TTR',
      conversionRate: 'Коэффициент конверсии',
      attrInstallRate: 'Скорость установки Attr',
      cpi: 'CPI',
      goals: 'Цели',
      costPerGoal: 'Стоимость за цель',
      goalRate: 'Целевая ставка',
      revenuePerGoal: 'Доход на цель',
      goalsRevenue: 'Доход от целей',
      storefronts: 'Витрины',
      campaignStatus: 'Статус кампании',
      adgroupStatus: 'Статус группы объявлений',
      account: 'Счет',
      appId: 'ID приложения',
      campaignId: 'Идентификатор кампании',
      adgroupId: 'ID группы объявлений',
      action:'Действие',
      delete: 'Удалить'
    },
    creativeHeaders: {
      creativeSet: 'Творческий набор',
      creativeSetStatus: 'Статус',
      spend: 'Проводить',
      averageCpa: 'Средняя цена за конверсию',
      averageCpt: 'Средняя цена за клик',
      impressions: 'Впечатления',
      taps: 'Краны',
      installs: 'Устанавливает',
      newDownloads: 'Новые загрузки',
      reDownloads: 'Повторные загрузки',
      ttr: 'TTR',
      conversionRate: 'Коэффициент конверсии',
      attrInstallRate: 'Скорость установки Attr',
      cpi: 'CPI',
      goals: 'Цели',
      costPerGoal: 'Стоимость за цель',
      goalRate: 'Целевая ставка',
      revenuePerGoal: 'Доход на цель',
      goalsRevenue: 'Доход от целей',
      storefronts: 'Витрины',
      campaignStatus: 'Статус кампании',
      adgroupStatus: 'Статус группы объявлений',
      account: 'Счет',
      appId: 'ID приложения',
      campaignId: 'Идентификатор кампании',
      adgroupId: 'ID группы объявлений'
    },
    searchTermsHeader: {
      searchTerm: 'Текст поискового запроса',
      spend: 'Проводить',
      averageCpa: 'Средняя цена за конверсию',
      averageCpt: 'Средняя цена за клик',
      impressions: 'Впечатления',
      taps: 'Краны',
      installs: 'Устанавливает',
      ttr: 'TTR',
      conversionRate: 'Коэффициент конверсии',
      newDownloads: 'Новые загрузки',
      reDownloads: 'Повторные загрузки',
      storefronts: 'Витрины',
      campaignStatus: 'Статус кампании',
      adgroupStatus: 'Статус группы объявлений',
      keyword: 'Ключевое слово',
      keywordId: 'Идентификатор ключевого слова',
      appId: 'ID приложения',
      campaignId: 'Идентификатор кампании',
      adgroupId: 'ID группы объявлений'
    },
    negativeHeaders: {
      keyword: 'Ключевое слово',
      matchType: 'Тип соответствия',
      status: 'Статус',
      adgroupId: 'ID группы объявлений',
      campaignId: 'Идентификатор кампании',
      deleted: 'Удалено',
      keywordId: 'Идентификатор ключевого слова',
      modificationTime: 'Время модификации'
    },
    filter: {
      account: 'Выберите учетную запись',
      app: 'Выберите приложение',
      goal: 'Выберите цель',
      campaign: 'Выберите кампанию',
      adgroup: 'Выберите группу объявлений',
      ad: "Выберите объявление"
    },
    subTabs: {
      all:'Все',
      live: 'Жить',
      paused: 'Приостановлено',
      deleted: 'Удалено',
      draft: 'Черновой вариант',
      campaigns: 'Кампании',
      adgroups: 'Группы объявлений',
      keywords: 'Ключевые слова',
      creativeSet: 'Творческий набор',
      negKey: 'Минус-слова'
    },
    popups: {
      add: 'Добавлять',
      integration: 'Интеграция',
      adgroup: 'Группа объявлений',
      keywords: 'Ключевые слова',
      creativeSet: 'Творческий набор',
      negKey: 'Минус-слова',
      campaigns: 'Кампании',
    },

    //Team Management Page
    teamMgmt: {
      manageUsers: 'Управлять участниками',
      invite: 'Приглашайте членов команды и управляйте ими',
      add: 'Добавить участников',
      first: 'Имя',
      last: 'Фамилия',
      phone: 'Телефон',
      email: 'Электронное письмо',
      job: 'Название работы',
      allMembers: 'Все участники',
      processing: 'Обработка...',
      ok: 'ОК',
      inviteStatus: 'Статус приглашения',
      isDisabled: 'Выключен',
      company: 'название компании'
    },

    //Add Integration Page
    integration: {
      assPart: 'Ассоциированные партнеры',
      apple: 'Apple Search Ads',
      slack: 'Slack',
      itunes: 'Itunes',
      tenjin: 'Tenjin',
      kochava: 'Kochava',
      adjust: 'Adjust',
      appsFlyer: 'AppsFlyer',
      singular: 'Singular',
      communication:'Коммуникации',
      mmp:'Партнеры по мобильным измерениям',
      completeInt:'Завершенные интеграции',
      receivedEventType:'Полученные типы событий',
      waitForEvents:'Ожидание событий',
      integrateWith:'Интеграция с',
      intStep:'Шаги интеграции',
      integrate:'Интегрировать',
      followIntSteps:'Следуйте этим шагам интеграции',
      //kochava steps
      kochavaStep1:'1. Получите доступ к своим приложениям Kochava по адресу https://go.kochava.com/apps.',
      kochavaStep2:'2. Выберите «Конфигурация партнера» в контекстном меню вашего приложения.',
      kochavaStep3:'3. Нажмите «Добавить конфигурацию» на странице «Конфигурация партнера».',
      kochavaStep4:'4. Выберите Search Ads Maven — iOS в меню Media Partner и нажмите «Перейти».',
      kochavaStep5:'5. Выберите «Создать» в контекстном меню каждого события, которое вы хотите синдицировать в Search Ads Maven.',
      kochavaStep6:'6. Используйте КЛЮЧ API и идентификатор App Store, указанные здесь, для каждого создания обратной передачи.',
      kochavaStep7:'7. Подождите не менее 24 часов, прежде чем события будут распознаны Search Ads Maven. Обратите внимание, что распознаются только события, связанные с кампаниями Apple Search Ads.',
      kochavaStep8:'8. Создайте цель для каждого события, распознаваемого Search Ads Maven. После создания целей данные о событиях MMP, такие как количество и доход, будут доступны на панели инструментов.',

      //branchSteps
      branchStep:'2. Используйте следующий ключ API и идентификатор App Store на панели управления Branch.',

      //customSteps
      customStep:'2. Используйте следующий ключ API и идентификатор App Store в своей обратной передаче.',

      apiKey:'ключ API',
      appStoreId:'Магазин приложений ID',
      distinctEventType:'различные типы событий, связанные с ASA, были доставлены MMP для этого приложения. Эти типы событий можно использовать при создании цели.',
      noDistinctEventType:'События, связанные с Apple Search Ads, еще не получены. Если события не были получены в течение 24 часов, дважды проверьте эти шаги по интеграции и убедитесь, что запущены активные кампании Apple Search Ads, которые инициируют доставку одного или нескольких событий с помощью MMP.',
      dEventType:'Отдельные типы событий',
      noEventFound:'События не найдены',
      close:'Закрывать',
      note:'ПРИМЕЧАНИЕ. Приложения, уже интегрированные с другой MMP, не будут отображаться в этом списке.',
      intDeletionInProgress:"В настоящее время выполняется удаление интеграции, и мы сообщим вам о его завершении."

    },

    //Keyword Auction Insights Page
    kai: {
      addedKeywords: 'Добавлены ключевые слова',
      total: 'Общее',
      cancel: 'Отмена',
      runAds: 'Запуск рекламы',
      ok: 'ОК',
      totalPaidKey: 'Всего оплаченных ключевых слов',
      popularity: 'Популярность',
      numOfApps: 'Количество приложений',
      chance: 'Шанс',
      appsRunAds: 'Приложения с рекламой',
      maxPop: 'Максимальная популярность для вас',
      keyword: 'Ключевое слово',
      clear: 'ясно',
      clrAllFilter: 'Очистить все фильтры',
      keywords: 'Ключевые слова',
      organicRank: 'Органический рейтинг',
      totalApps: 'Всего приложений',
      app: 'Приложение',
      catName: 'Название категории',
      searchByApp: 'Поиск по приложению',
      searchByKey: 'Поиск по ключевым словам',
      addAllKey: 'Добавить все ключевые слова',
      country: 'Страна',
      lang: 'Язык',
      searchForCompetes: 'Предлагаемые конкуренты',
      searchAdsRecommends: 'Рекомендации по поисковой рекламе',
      categoryTop: 'Категория Лучшие приложения',
      compRating: 'Жанры',
      compVersion: 'Версия',
      compPrice: 'Цена',
      compGenres: 'Жанры',
      monthlyDownloads: 'Ежемесячные загрузки',
      showDataText: 'Нет данных для отображения',
      free: 'Бесплатно',
      paid: 'Оплаченный',
      quickAddCriteria: 'Критерии быстрого добавления',
      tooltip: {
        keyText: "Заголовок ключевого слова",
        organicRank: "Органический рейтинг относится к результатам поиска в поисковой системе, на которые не может повлиять платная реклама.",
        popularity: "Оценка популярности ключевого слова. Более высокий рейтинг поиска означает больший объем поиска по ключевому слову.",
        totalApps: "Общее количество приложений, использующих это ключевое слово",
        appDetails: "Имя приложения",
        organicRankApp: "Органический рейтинг относится к результатам поиска в поисковой системе, на которые не может повлиять платная реклама.",
        category: "Категории, к которым принадлежит приложение",
        rating: "Рейтинг приложения",
        version: "Версия приложения",
        price: "Цена приложения",
        genres: "Жанр приложения",
        chance: "Chance Score - это процентная оценка, основанная на оценке ваших шансов попасть в первую десятку по определенному ключевому слову в конкретной категории вашего приложения.",
        appsRunning: "Общее количество приложений, оплачивающих ключевое слово"
      }
    },

    //Overview Page
    overview: {
      selectGoalFirstSelectApp:'Чтобы выбрать цель, сначала выберите приложение в раскрывающемся списке слева.',
      count:'Количество целевых событий',
      exportCSV:'Экспорт CSV',
      period: 'период',
      topKeywords: '10 самых популярных ключевых слов по',
      key: 'Ключевое слово',
      spend: 'Проводить',
      impressions: 'Впечатления',
      taps: 'Краны',
      goToKey: 'Перейти ко всем ключевым словам',
      topAdgroup: '10 самых популярных групп объявлений по',
      adgroup: 'Группа объявлений',
      goToAdgroup: 'Перейти ко всем группам объявлений',
      gender: 'Пол',
      device: 'Устройство',
      searchApp: 'Приложение для поиска',
      lastDay: 'Последний день',
      last7Days: 'Последние 7 дней',
      last30Days: 'Последние 30 дней',
      ttr: 'Скорость сквозного монтажа (TTR)',
      installs: 'Устанавливает',
      avgCPA: 'Средняя цена за конверсию',
      avgCPT: 'Средняя цена за клик',
      conversionRate: 'Коэффициент конверсии (CR)',
      latOnInstalls: 'LAT при установке',
      latOffInstalls: 'LAT Off Установки',
      reDownloads: 'Повторные загрузки',
      newDownloads: 'Новые загрузки',
      all: 'Все',
      tooltip: 'Увеличение или уменьшение с течением времени в процентах по сравнению с предыдущим периодом на основе выбранного диапазона дат.',
      selectPer: 'Выберите период',
      prevPeriod: 'Пред. Период',
      currPeriod: 'Текущий период',

      GOLS: 'Цели',
      CPRG: 'Стоимость цели',
      RPRG: 'Доход на цель',
      GREV: 'Цели Доход',
      GRAS: 'Цели ROAS',
      GETC: 'Цели Количество событий',
    },

    checklist: {
      title: 'Контрольный список',
      integrations: 'Интеграции',
      searchads: 'Поисковая реклама Apple',
      slack: 'Добавить слабую интеграцию',
      kai: 'Ознакомьтесь с инструментами анализа ключевых слов',
      competitorMsg: 'Посмотрите, как работают ваши конкуренты.',
      searchKeywords: 'См. ключевые слова для приложения.',
      seeApps: 'См. Приложения для ставок по ключевому слову.',
      topCharts: 'См. топ-чарты категорий.',
      campaign: 'Кампания',
      initCampaign: 'Начать кампанию.',
      createAdgroup: 'Создать группу объявлений.',
      addKeywords: 'Добавить ключевые слова.',
      addCreativeSet: 'Добавить творческий набор.',
      goLive: 'В прямом эфире.',
      reviewMetrics: 'Проверить успех кампании.',
      automate: 'Автоматизируйте свою кампанию.',
      mmpIntegration: 'Интеграция с MMP',
      createMmpGoals: 'Создание целей MMP',
      campGen:'Генератор кампаний',
      scheduledReports:'Запланированные отчеты'
    },


    //Keyword Optimization Page
   keyOpt: {
   keyBid: 'Оптимизация ключевых слов',
   keyToolTip: 'Вы можете оптимизировать размер ставки для ключевых слов, которые не входят в диапазон предлагаемой минимальной суммы ставки ASA и предлагаемой максимальной суммы ставки ASA.',
   optAll: 'Оптимизировать все',
   opt: 'Оптимизировать',
   optBidAmt: 'Оптимизировать сумму ставки',
   key: 'Ключевое слово',
   optBidForAll: 'Оптимизировать размер ставки для всех ключевых слов',
   campaign: 'Кампания',
   adgroup: 'Группа объявлений',
   bidAmt: 'Сумма ставки',
   asaMinBid: 'Рекомендуемая минимальная ставка ASA',
   asaMaxBid: 'Рекомендуемая максимальная ставка ASA',
   action: 'Действие',
   noData: "У Apple пока нет предложений."
   },

   //ARRules Page
   aRules: {
   createAR: 'Создать правило автоматизации',
   view: 'Вид',
   edit: 'Редактировать',
   delete: 'Удалить',
   disable: 'Запрещать',
   enable: 'Давать возможность',
   name: 'Имя',
   createdAt: 'Создано на',
   status: 'Статус',
   completed: 'Завершено в',
   actionFreq: 'Частота действия',
   rules: 'Правила',
   logs: 'Журналы',
   createdFor: 'Создан для',
   chckFreq: 'Проверить частоту',
   actions: 'Действия',
   enableDisable: 'Включить выключить',
   sno: 'S.No'
   },

   //Create Campaign
   //CampaignView
   createCampaign: {
   createCamp: 'Создать кампанию',
   h2: 'Выберите приложение, чтобы начать создание кампании',
   para: 'В кампаниях есть бюджеты и группы объявлений, посвященные общей теме или стратегии продвижения вашего приложения.',
   select: 'Выберите, где будут показываться объявления',
   searchResults: 'результаты поиска',
   srData: 'В кампаниях есть бюджеты и группы объявлений, посвященные общей теме или стратегии продвижения вашего приложения.',
   searchTab: 'Вкладка поиска',
   stData: 'Продвигайте свое приложение в верхней части списка предлагаемых приложений, когда пользователи переходят на вкладку «Поиск».',
   todayTab: 'Вкладка «Сегодня»',
   todayData:'Обращайтесь к пользователям, когда они впервые заходят в магазин приложений, чтобы найти приложения. Ваше объявление будет видно на первой странице.',

    productPageTab: 'Страницы продукта',
    productPageData: 'Обращайтесь к пользователям, когда они просматривают приложения. Ваше объявление будет вверху. Вы также можете указать пользователей, которые прокрутили до конца соответствующие страницы продуктов.',
   cancel: 'Отмена',
   continue: 'Продолжать',

   //Search Results and Search Tab Campaign
   countAndReg: 'Страны и регионы',
   adgroupSettings: 'Настройки группы объявлений',
   searchMatch: 'Поиск совпадения',
   key: 'Ключевое слово',
   audience: 'Аудитория',
   crSets: 'Творческие наборы',
   chooseCampGroup: 'Выберите группу кампании',
   chooseApp: 'Выберите приложение',
   next: 'Следующий',
   countPara: 'Ваше приложение будет продвигаться в App Store в подходящих странах и регионах, которые вы выберете из списка.',
   chooseCount: 'Выберите одну или несколько стран или регионов',
   campName: 'Название кампании',
   budget: 'Бюджет',
   dailyCap: 'Дневной лимит (необязательно)',
   adgroupPara: 'Группы объявлений содержат цели ценообразования и настройки, которые определяют, кому показывать вашу рекламу.',
   adgroupName: 'Название группы объявлений',
   devices: 'Устройства',
   startTime: 'Время начала',
   adScheduling: 'Планирование размещения рекламы',
   endTime: 'Время окончания',
   dayParting: 'Время суток (необязательно)',
   ok: 'OK',
   defaultMax: 'Макс. Ставка CPT по умолчанию',
   cpaGoal: 'Целевая цена за конверсию (необязательно)',
   saveDraft: 'Сохранить как черновик',
   appleSearchMatch: 'Apple Search Match',
   searchMatchPara: 'Apple Search Match - это самый простой способ запустить вашу рекламу. Поисковая реклама автоматически подберет вашу рекламу тем пользователям, которые ищут приложения, подобные вашему.',
   searchMatchPara1: 'Автоматически сопоставлять мое объявление с релевантными поисковыми запросами',
   keywords: 'Ключевые слова',
   keyPara: 'Ключевые слова - это релевантные слова или термины, которые клиенты могут использовать при поиске приложения, подобного вашему. Ключевые слова позволяют поисковикам быстро находить ваше приложение, что способствует увеличению количества его установок.',
   upload: 'Загрузить',
   suppFormat: 'поддерживаемые форматы: Excel, CSV',
   keyManually: 'Или добавьте ключевые слова вручную',
   targKey: 'Ключевые слова таргетинга',
   clear: 'ясно',
   adgroupNegKey: 'Минус-слова группы объявлений',
   optional: 'По желанию',
   negKey: 'Минус-слова',
   audPara: 'Уточните аудиторию для своей группы объявлений с помощью дополнительных дополнительных функций.',
   custType: 'Тип клиента',
   demo: 'Демография',
   gender: 'Пол',
   location: 'Место расположения (Поиск)',
   crSetPara: 'Объявления создаются автоматически с использованием метаданных, снимков экрана и превью приложений, представленных на странице продукта в App Store. Они также появляются в обычных результатах поиска в App Store. Показанные здесь объявления являются примерами объявлений по умолчанию.',
   crOptional: 'Набор объявлений (необязательно)',
   noCrSet: 'Приложение не соответствует минимальному набору объявлений.',
   campCreated: 'Кампания создана',
   campCreatedPara: 'Теперь вы можете просматривать свои кампании на панели управления поисковой рекламой.',
   close: 'Закрывать',
   matchType: 'Тип соответствия',
   amount: 'Количество',
   currency: 'Валюта',
   allUsers: 'Все пользователи',
   newUsers: 'Новые пользователи',
   appUsers: 'Пользователи других моих приложений',
   existingUsers: 'Существующие пользователи',
   all: 'Все',
   male: 'Мужчина',
   female: 'женский',
   selectApp: 'Приложение для поиска',
   chooseCamp: 'Группа Кампании',
   countries: 'Страны',
   ageRange: 'Возрастной диапазон',
   selectDev: 'Выберите устройства',
   adsOpt: 'Объявление (необязательно)',
   default: 'По умолчанию',
   defaultAdPara: 'Использует активы со страницы продукта в магазине приложений по умолчанию',
   custom: 'Обычай',
   customPara: 'Использует активы из App Store Connect по умолчанию и перенаправляет пользователей на эту страницу, когда они нажимают на объявление',
   pagesInfo: 'Доступны пользовательские страницы продукта',
   createAdPara: 'Нажмите кнопку «Создать объявление», чтобы выбрать один из вариантов пользовательской страницы продукта и просмотреть предварительный просмотр вашего объявления.',
   createAdBtn: 'Создать объявление',
   createAds: 'Создать рекламу',
   selectCustomPara: 'Выберите пользовательскую страницу продукта, чтобы использовать ее ресурсы для создания рекламы. Когда пользователи нажимают на ваше объявление в результатах поиска, они переходят на выбранную вами страницу',
   adName: 'Название объявления',
   search: 'Поиск',
   available: 'Доступный',
   sortBy: 'Сортировать по',
   save: 'Сохранять',
   customAds: 'Пользовательские объявления',
   edit: 'Редактировать',
   previous: 'Предыдущий'
   },

   //Change Password
  changePass: {
    backSett: 'Вернуться к настройкам',
    chngPass: 'Пароль установки',
    newPass: 'Новый пароль',
    confirmPass: 'Подтвердить Пароль',
    submit: 'Представлять на рассмотрение',
    h4: 'Операционная система для маркетологов Кочавы ™',
    para:
      'Операционная система маркетологов (m / OS) легко интегрирует многоканальные маркетинговые решения для рекламодателей и издателей на одной операционной платформе.',
    ok: 'OK',
  },

  //Forgot Password
  forgotPass: {
    forPass: 'Забыл пароль',
    typeEmail: 'Введите адрес электронной почты',
    emailAdd: 'Адрес электронной почты',
    submit: 'Представлять на рассмотрение',
    chckEmail: 'Пожалуйста, проверьте свою электронную почту',
    para: 'Чтобы установить новый пароль, проверьте свою электронную почту',
    close: 'Закрывать',
    alreadyAcc: 'Уже есть аккаунт?',
    signIn: 'Войти',
  },

  //Onboard
  onboard: {
    welcome: 'Добро пожаловать в SearchAds Maven',
    personal: 'Зарегистрироваться',
    start: 'Начинать',
    integration: 'Интеграция',
    next: 'Следующий',
    trial: 'Начать пробную версию',
    scheduleDemo: 'Или запланируйте демонстрацию у нас и узнайте вместе',
    addInt: 'Добавьте интеграцию.',
    signInApple:
      'Войдите в систему с Apple, чтобы добавить интеграцию с Apple Search Ads. Пожалуйста, обрати внимание:',
    step1:
      '1. Для предоставления доступа к учетной записи Apple Search Ads требуются права администратора.',
    step2:
      '2. Чтобы управлять своими кампаниями из SearchAdsNinja, выберите опцию «Чтение и запись» перед предоставлением доступа.',
    skip: 'Пропускать',
  },

  //AddAdgroup
  addAdgroup: {
    addAd: 'Добавить группу объявлений',
    para:
      'Вы еще не выбрали свою кампанию. Вы можете выбрать его из поля ниже.',
    camGroup: 'Группа Кампании',
    campaign: 'Кампания',
    adgroupSett: 'Настройки группы объявлений',
    para1:
      'Группы объявлений содержат цели ценообразования и настройки, которые определяют, кому показывать вашу рекламу.',
    adgroupName: 'Название группы объявлений',
    devices: 'Устройства',
    adScheduling: 'Планирование размещения рекламы',
    startTime: 'Время начала',
    endTime: 'Время окончания',
    dayparting: 'Время суток (необязательно)',
    defaultMax: 'Макс. Ставка CPT по умолчанию',
    cptTool:
      'Это максимальная сумма, которую вы готовы заплатить за нажатие на свое объявление. Эта ставка будет применяться ко всем ключевым словам в этой группе объявлений, если вы не примените индивидуальную ставку для ключевого слова.',
    cpaGoal: 'Целевая цена за конверсию (необязательно)',
    cpaTool:
      'Средняя сумма, которую вы платите за каждую загрузку. Он рассчитывается путем деления общей суммы на количество загрузок.',
    searchMatch: 'Поиск совпадения',
    searchTool:
      'Поисковое соответствие - это самый простой способ запустить вашу рекламу. Поисковая реклама автоматически подберет вашу рекламу тем пользователям, которые ищут приложения, подобные вашему.',
    searchPara: 'Автоматически сопоставлять мое объявление с релевантными поисковыми запросами',
    key: 'Ключевые слова',
    keyPara:
      'Ключевые слова - это релевантные слова или термины, которые клиенты могут использовать при поиске приложения, подобного вашему. Ключевые слова позволяют поисковикам быстро находить ваше приложение, что способствует увеличению количества его установок.',
    inputCpt: 'Введите максимальную ставку CPT по умолчанию, чтобы активировать раздел ключевых слов',
    addKeyPara:
      'Добавьте ключевые слова, относящиеся к вашему приложению и жанру. Мы поможем, поделившись дополнительными рекомендациями.',
    upload: 'Загрузить',
    addKeyManually: 'Или добавьте ключевые слова вручную',
    negKey: '"Минус-слова" группы объявлений (необязательно)',
    supp: 'поддерживаемые форматы: Excel, CSV',
    demAge: 'Демография Возраст, Пол',
    demPara: "Настройте таргетинг объявления на возраст или пол пользователя.",
    ageRange: 'Возрастной диапазон',
    cancel: 'Отмена',
    apply: 'Применять',
  },

  //Edit Adgroup
  editAdgroups: {
    edit: 'Изменить группу объявлений',
    adgroup: 'Группа объявлений',
    advance: 'Дополнительные настройки',
    viewEdit: 'Просмотр и редактирование',
    general: 'Общий',
    settings: 'Настройки',
    schedule: 'Расписание',
    custPara: 'Выберите клиентов приложения, которых вы хотите привлечь',
    selectAge: 'Выберите возрастной диапазон',
    save: 'Сохранить',
  },

  //Create AR
  createAR: {
    rule:"Правило",
    addRule: 'Добавить правило',
    selectDateRange:'Выберите диапазон дат',
    createAutoRule: 'Создать правило автоматизации',
    editAutoRule: 'Изменить правило автоматизации',
    choose: 'Выбирать',
    chooseAutoRule: 'Выберите уровень автоматизации и элементы.',
    conditions: 'Условия',
    actions: 'Действия',
    settings: 'Настройки',
    condition: 'Условие',
    addCondition: 'Добавить условие',
    andCondition:
      'Выберите, должны ли совпадать все вышеуказанные условия или должно соответствовать только одно из них.',
    checkCond: 'Проверить условия для',
    action: 'Действие',
    selectAll: 'Выбрать все',
    enterEmail: 'Пожалуйста, введите правильные идентификаторы электронной почты',
    addTo: 'Добавить в',
    selectAdgroups: 'Выберите Группа объявлений',
    selectCampaigns: 'Выберите кампании',
    cptBid: 'Ставка CPT',
    customBid: 'Специальная ставка',
    matchType: 'Тип соответствия',
    addAction: 'Добавить действие',
    chckFreq: 'Проверить частоту',
    ruleName: 'Имя правила',
    cancel: 'Отмена',
    step2: 'Шаг 2',
    step3: 'Шаг 3',
    andOrTypesText1: 'Все должны совпадать',
    andOrTypesText2: 'Только один должен соответствовать',
    addToModelListNegText1: 'Все выбранные группы объявлений',
    addToModelListNegText2: 'Все группы объявлений выбранных кампаний)',
    addToModelListNegText3: 'Все выбранные кампании',
    matchTypesText1: 'Широкое соответствие',
    matchTypesText2: 'Точное совпадение',
    cptBidItemsText1: 'Установить на заказ',
    cptBidItemsText2: 'Ставка по умолчанию для группы объявлений',
    actionFreqsText1: 'Ежечасно',
    actionFreqsText2: 'Каждые 6 часов',
    actionFreqsText3: 'Каждые 12 часовКаждые 12 часов',
    actionFreqsText4: 'Каждый день',
    actionFreqsText5: 'Пользовательские часы',
    limitActionFreqsText1: 'Каждые 2 часа',
    limitActionFreqsText2: 'Каждые 3 часа',
    limitActionFreqsText3: 'Каждые 2 дня',
    limitActionFreqsText4: 'Каждые 3 дня',
    limitActionFreqsText5: 'Каждую неделю',
    mon: 'понедельник',
    tue: 'понедельник',
    wed: 'среда',
    thu: 'Четверг',
    fri: 'Пятница',
    sat: 'Суббота',
    sun: 'Воскресенье',
    ciTimeRangesText1: 'Последний час',
    ciTimeRangesText2: 'Последний день',
    ciTimeRangesText3: 'Последние 7 дней',
    ciTimeRangesText4: 'Последние 30 дней',
    ciTimeRangesText5: 'Прошлый месяц',
    ciTimeRangesText6: 'Пользовательский час',
    ciTimeRangesText7: 'Диапазон дат',
    allConOperationText1: 'Больше чем',
    allConOperationText2: 'Меньше, чем',
    allConOperationText3: 'Диапазон',
    allConOperationText4: 'Не в зоне',
    amount: 'Сумма $',
    budget: '% Процент бюджета',
    incBy: 'Увеличить на',
    decBy: 'Уменьшить на',
    setToCPA: 'Установить на среднюю цену за конверсию',
    setToCPT: 'Установить на среднюю цену за клик',
    value: '$ Значение',
    change: '% Изменять',
    between: 'Между',
    equalTo: 'Равно',
    appsSelected:'Выбранные приложения',
    campaignsSelected:'Кампании выбраны',
    adgroupsSelected:'Выбраны группы объявлений',
    KeywordsSelected:'Выбраны ключевые слова',
    selectField:'Выберите поле',
    selectOne:'Выберите один',
    pleaseSelect:'Пожалуйста выберите',
    pleaseEnteraValue:'Пожалуйста, введите значение',
    fromValue:'От стоимости',
    toValue:'Ценить, оценивать',
    mustBeLesserThanToValue:'Должно быть меньше значения To',
    mustBeHigherThanFromValue:'Должно быть выше, чем значение',
    cannotBeZero:'Не может быть 0',
    ruleAlreadyExist:'Название правила уже существует',
    emailNotification:'Уведомление по электронной почте',
    slackNotification: 'Слабое уведомление',
    pause:'останавливаться',
    resume:'возобновлять',
    changeDailyCap:'Изменить дневной лимит',
    changeBid:'Изменить ставку',
    changeBudget:'Изменить бюджет',
    addAsNegativeKeyword:'Добавить как минус-слово',
    createActionFor: 'Создать действие для',
    selectAction:'Выберите действие',
    selectEmail:'Выберите электронную почту',
    addOtherEmail:'Добавить другой адрес электронной почты',
    enterAnotherEmail:'Добавить другой адрес электронной почты',
    giveAName:'Назовите это правило',
    searchLogs: 'Фильтровать журналы по правилу',
    details: 'Подробности',
    PENDING: 'В ОЖИДАНИИ',
    SUCCESS: 'УСПЕХ',
    FAILED: 'НЕ УДАЛОСЬ',
    CREATED: 'СОЗДАННЫЙ',
    DELETED: 'УДАЛЕНО',
    ENABLED: 'ВКЛЮЧЕНО',
    DISABLED: 'ИНВАЛИД',
    REACTIVATED: 'ПОВТОРНО АКТИВИРОВАН',
    UPDATED: 'ОБНОВЛЕНО',
    CONDITIONMATCHED : 'УСЛОВИЕ СООТВЕТСТВУЕТ',
    CONDITIONNOTMATCHED:'УСЛОВИЕ НЕ СООТВЕТСТВУЕТ',
    runIndefinite:'Работать бесконечно',
    indefiniteNote:'ПРИМЕЧАНИЕ. Если выбрано это правило автоматизации, оно будет продолжать применяться с указанным интервалом и останется в силе независимо от того, сколько раз оно было запущено.',
    sureToSave:'Вы уверены, что хотите сохранить правило автоматизации?',
    update:'Обновлять',
    selectChannel:'Выберите канал',
    noDataAvailable:'Данные недоступны',
    sureToDelete:'Вы хотите удалить правило автоматизации?',
  },

  //select Account AR
  selectAccountAR: {
    chooseAcc: 'Выберите Аккаунт',
    cancel: 'Отмена',
    apply: 'Применять',
    clearAll: 'Очистить все',
    accounts: 'учетные записи',
    accountSelected: 'Аккаунт (ы) выбран',
  },

  //select Apps AR
  selectAppsAR: {
    chooseApp: 'Выберите приложение',
    cancel: 'Отмена',
    apply: 'Применять',
    apps: 'Программы',
    appSelected: 'Выбрано приложение (я)',
    clearAll: 'Очистить все',
  },

  //select Keywords AR
  selectKeywordsAR: {
    keepOneAdGroup: 'Чтобы продолжить, оставьте выбранной только одну группу объявлений.',
    close: 'Закрывать',
    chooseKeywords: 'Выберите ключевые слова',
    cancel: 'Отмена',
    apply: 'Применять',
    campaigns: 'Кампании',
    expandAll: 'Расширить все',
    collapseAll: 'Свернуть все',
    adGroups: 'Группы объявлений',
    keywords: 'Ключевые слова',
    keywordSelected: 'выбрано ключевое слово (а)',
    clearAll: 'Очистить все',
    excludePausedKeywords:'Исключить ключевые слова паузы',
  },

  //campaign
  //AddCreativeSet
  addCreativeSet: {
    addCreative: 'Добавить набор объявлений',
    setup: 'Настраивать',
    para:
      'Выберите имя, которое описывает тему вашего творческого набора. Выберите язык снимков экрана и превью, которые вы хотите добавить, на странице продукта в App Store.',
    createSetName: 'Название набора объявлений ',
    localLang: 'Язык локализации',
    assetSelection: 'Выбор актива',
    assetSelectionPararaph1:
      'Наборы объявлений состоят из снимков экрана и предварительных просмотров приложений, которые отображаются на странице продукта в App Store.',
    assetSelectionPararaph2: 'Для каждого размера дисплея iPhone',
    assetSelectionPararaph3:
      'выберите хотя бы один пейзаж или три портрета.',
    assetSelectionPararaph4: 'Для каждого размера дисплея iPad',
    assetSelectionPararaph5:
      'выберите хотя бы один альбомный или два портретных объекта.',
    title1: 'iPhone/iPod',
    screenshotsPara1: 'Пожалуйста, выберите не менее',
    screenshotsPara2: 'один пейзаж',
    screenshotsPara3: 'или же',
    screenshotsPara4: 'два портрета',
    screenshotsPara5: 'скриншоты!',
    screenshotsPara6: 'Пожалуйста, выберите не менее',
    screenshotsPara7: 'один пейзаж',
    screenshotsPara8: 'или же',
    screenshotsPara9: 'три портрета',
    screenshotsPara10: 'скриншоты!',
    cancel: 'Отмена',
    save: 'Сохранить',
  },
  strategy:{
    descriptionValid:"Требуется описание",
    alphaNumeric:"Разрешены только буквенно-цифровые символы.",
    textMax:"Описание не должно превышать 250 символов."
  },
  //DeleteStrategy
  deleteStrategy: {
    deleteCamp: 'Удалить стратегию',
    para: 'Вы уверены, что хотите удалить статус?',
    cancel: 'Отмена',
    delete: 'Удалить',
  },
  //DeleteCampaign
  deleteCampaign: {
    deleteCamp: 'Удалить кампанию',
    para: 'Вы уверены, что хотите удалить кампанию?',
    cancel: 'Отмена',
    delete: 'Удалить',
  },

  //EditCampaign
  editCampaign: {
    editCampWizard: 'Мастер редактирования кампании',
    campName: 'Название кампании',
    app: 'Приложение',
    countriesOrRegionsPara: 'Выберите одну или несколько стран или те же регионыВыберите одну или несколько стран или те же регионы',
    budget: 'Бюджет',
    dailyCap: 'Дневной лимит',
    cancel: 'Отмена',
    save: 'Сохранить',
  },

  //SelectCampaign
  selectCamp: {
    chooseCamp: 'Выберите кампанию',
    noSelectedCamp: 'Кампания не выбрана',
    campaigns: 'Кампании',
    expandAll: 'Расширить все',
    collapseAll: 'Свернуть все',
    selectedCampaigns: 'Избранные кампании',
    clearAll: 'очистить все',
    cancel: 'Отмена',
    apply: 'Применять',
  },

  //chargebee
  //EditCard
  editCard: {
    editCreditCard: 'Изменить кредитную карту',
    cardNumber: 'Номер карты',
    expiryDate: 'Дата истечения срока',
    cvv: 'CVV',
    saveCard: 'Сохранить карту',
    updateExpiryPara: '* Вы можете обновить только дату истечения срока действия',
  },

  //creativeset
  //EditCreativeSet
  editCreativeSet: {
    editCreative: 'Изменить набор объявлений',
    easywebinar: 'EasyWebinar',
    softobiz: 'Softobiz Technologies Private Limited',
    setup: 'Настраивать',
    editCreativePara1:
      'Выберите имя, которое описывает тему вашего творческого набора. Выберите язык снимков экрана и превью, которые вы хотите добавить, на странице продукта в App Store.',
    creativeSetName: 'Название набора объявлений',
    localLang: 'Язык локализации',
    assetSelection: 'Выбор актива',
    assetSelectionPararaph1:
      'Наборы объявлений состоят из снимков экрана и предварительных просмотров приложений, которые отображаются на странице продукта в App Store.',
    assetSelectionPararaph2: 'Для каждого размера дисплея iPhone',
    assetSelectionPararaph3:
      'выберите хотя бы один пейзаж или же три портрета активов.select at least один пейзаж или же три портрета assets.',
    assetSelectionPararaph4: 'Для каждого размера дисплея iPad',
    assetSelectionPararaph5:
      'выберите хотя бы один альбомный или два портретных объекта.',
    viewDevice: 'Просмотр размеров дисплея устройства',
    title1: 'iPhone/iPod',
    ipad: 'iPad',
    display: 'Отображать',
    default: 'По умолчанию',
    cancel: 'Отмена',
    save: 'Сохранить',
  },

  addIntegration: {
    invalid: 'Неверная интеграция, удалите и добавьте еще раз.'
  },

  navbar: {
    syncProgress: 'Идет синхронизация данных.'
  },

  messages: {
    onHold: 'кампания приостановлена',
    reasons: 'причины'
  },

  //integration
  //DeleteIntegration
  deleteIntegration: {
    deleteInt: 'Удалить интеграцию',
    cardText: 'Вы уверены, что хотите удалить интеграцию?',
    cancel: 'Отмена',
    delete: 'Удалить',
  },

  //keywords
  //AddKeys
  addKeys: {
    addKeywords: 'Добавить ключевые слова',
    keywordSuggestionsFor: 'Предлагаемые ключевые слова для',
    on: 'на',
    addedKeywords: 'Добавлены ключевые слова',
    total: 'Общее',
    add: 'Добавлять',
    cancel: 'Отмена',
    apply: 'Применять',
    matchExact: 'ТОЧНЫЙ',
    matchBroad: 'ШИРОКИЙ',
    searchHere: 'Поищи здесь',
  },

  //AddKeysKai
  addKeysKai: {
    addKeywords: 'Добавить ключевые слова',
    selectTarget: 'Выбрать цель',
    h4Camp: 'Кампании:',
    selected: 'Выбрано',
    change: 'Изменять',
    h4Adgroups: 'Группы объявлений:',
    maxCPTBid: 'Макс. Ставка CPT:',
    keywordSuggestionsFor: 'Предлагаемые ключевые слова для',
    on: 'на',
    recommendedKeywords: 'Рекомендуемые ключевые слова',
    addedKeywords: 'Добавлены ключевые слова',
    add: 'Добавлять',
    total: 'Общее',
    cancel: 'Отмена',
    apply: 'Применять',
    ok: 'ОК',
  },

  //AddKeywords
  addKeywords: {
    h6AddKeywords: 'Добавить ключевые слова',
    selectTarget: 'Выбрать цель',
    h4Camp: 'Кампании:',
    selected: 'Выбрано',
    change: 'Изменять',
    h4Adgroups: 'Группы объявлений:',
    maxCPTBid: 'Макс. Ставка CPT:',
    keywordSuggestionsFor: 'Предлагаемые ключевые слова для',
    on: 'на',
    recommendedKeywords: 'Рекомендуемые ключевые слова',
    addedKeywords: 'Добавлены ключевые слова',
    add: 'Добавлять',
    total: 'Общее',
    cancel: 'Отмена',
    apply: 'Применять',
    ok: 'ОК',
  },

  //AddnKeys
  addnKeys: {
    addNegKeywords: 'Добавить минус-слова',
    add: 'Добавлять',
    total: 'Общее',
    cancel: 'Отмена',
    save: 'Сохранить',
  },

  //EditBidAmount
  editBidAmount: {
    bidAmount: 'Сумма ставки',
  },

  //SelectAdGroup
  selectAdGroup: {
    snackbar: 'Чтобы продолжить, оставьте выбранной только одну группу объявлений.Please keep only one Ad-Group selected to continue.',
    close: 'Закрывать',
    chooseAdGroup: 'Выберите группу объявлений',
    cancel: 'Отмена',
    apply: 'Применять',
    campaigns : 'Кампании',
    expandAll: 'Расширить все',
    collapseAll: 'Свернуть все',
    adGroups : 'Группы объявлений',
    adGroupSelected : 'Группа объявлений (ы) выбраны',
    clearAll : 'Очистить все'
  },

  //negativeKeywords
  //AddNegativeKeywords
  addNegativeKeywords : {
    addNegKeywords : 'Добавить минус-слова',
    selectTarget : '1. Выбрать цель',
    addNegKeyword : 'Добавить минус-слово',
    paragraph1 : 'Минус-слова можно добавлять в кампании или группы объявлений. Пожалуйста, выберите один из них в поле ниже.',
    paragraph2 : 'В какую кампанию вы хотите загрузить ключевые слова?',
    selectCampaign : 'Выберите кампанию',
    paragraph3 : 'В какую группу объявлений вы хотите загрузить ключевые слова?',
    selectAdgroup : 'Выберите группу объявлений',
    cancel: 'Отмена',
    easywebinar : 'EasyWebinar',
    softobiz : 'Softobiz Technologies Private Limited',
    campaigns : 'Кампании:',
    selected : 'Выбрано',
    change: 'Изменять',
    paragraph4 : ' Минус-слова группы объявлений применяются только к этой группе объявлений. Если вы хотите, чтобы минус-слова применялись ко всем группам объявлений, установите их на уровне кампании.',
    paragraph5 : ' Подумайте о том, чтобы дать кампаниям время для запуска, прежде чем анализировать эффективность рекламы, чтобы узнать, какие ключевые слова могут вам не подойти. Добавление слишком большого количества минус-слов может ограничить показ вашей рекламы.',
    addedKeywords : 'Добавлены ключевые слова',
    add: 'Добавлять',
    total: 'Общее',
    save: 'Сохранить',
  },

  //oAuth
  //SigninSlackBtn
  signinSlackBtn : {
    addSlackChannel : 'Добавить канал Slack',
    sno: 'S.No'
  },

  //DecisionAlert
   decisionAlert : {
cancel : 'Отмена',
ok : 'ОК',
confirm:'Подтверждать'
  },

  //HelloWorld
  helloWorld : {
welcome : 'Добро пожаловать в Vuetify',
para1 : 'Для помощи и сотрудничества с другими разработчиками Vuetify,',
para2 : 'пожалуйста, присоединяйтесь к нашему онлайн',
discord : 'Сообщество Discord',
h2 : "Что дальше?",
impLinks : 'Важные ссылки',
ecosystem : 'Экосистема',
  },

  //InfoAlert
  infoAlert : {
    ok : 'ОК',
  },

  successMsg: {
    campaignUpdate: 'Кампания обновлена.',
    campaignMovedMsg: 'Статус изменен. Кампания перемещена на другую вкладку',
    campaignMoveFailMsg: "Не удалось изменить статус кампании.",
    adgroupUpdateFailMsg: "Не удалось обновить статус группы объявлений.",
    addgroupUpdatedMsg: 'Статус изменен. Группа объявлений перемещена на другую вкладку',
    keywordMovedMsg: 'Статус изменен. Ключевое слово перемещено на другую вкладку',
    keywordMoveFailMsg: "Не удалось обновить статус ключевого слова.",
    creativeAddedMsg: 'Набор объявлений добавлен.',
    campaignSavedMsg: 'Кампания сохранена как черновик.',
    campaignSaveFailMsg: 'В настоящий момент невозможно сохранить черновик кампании. Пожалуйста, попробуйте еще раз!',
    keyAddedMsg: 'Ключевые слова добавлены.',
    signinFailMsg: 'Не удалось войти. Пожалуйста, попробуйте еще раз!',
    deleteMsg: 'Успешно удалено',
    deleteFailMsg: 'Невозможно удалить. Пожалуйста, попробуйте еще раз!',
    dataUpdateMsg : 'Данные обновлены',
    dataUpdateFailMsg: 'Ошибка при обновлении данных',
    AutoDeleteMsg: 'Правило автоматизации удалено успешно',
    requestFailMsg: 'Невозможно обработать ваш запрос. Пожалуйста, попробуйте позднее',
    AutoRuleDisSuccessMsg: 'Правило автоматизации включено успешно',
    AutoRuleEnableMsg: 'Правило автоматизации включено успешно',
    SCAutoRuleDisSuccessMsg: 'Скриптовое правило успешно отключено',
    SCAutoRuleEnableMsg: 'Скриптовое правило успешно включено',
    bidChangeSuccessMsg: 'Сумма ставки успешно изменена.',
    wentWrongMsg: 'Что-то пошло не так.',
    integrateSuccessMsg : 'Интеграция успешно добавлена',
    userCreateMsg: 'Пользователь успешно создан',
    emailExistMsg: 'адрес электронной почты уже существует',
    errorCreateUser: 'Ошибка при создании пользователя',
    inviteFailMsg: 'Ошибка при отправке пригласительной ссылки на электронную почту',
    failTryLaterMsg: 'Что-то не так. Пожалуйста, попробуйте позднее',
    adgroupAddedMsg: 'Группа объявлений добавлена.',
    adgroupUpdatedMsg: 'Группа объявлений обновлена.',
    ruleUpdatedMsg: 'Правило успешно обновлено',
    ruleUpdateFailMsg: 'Не удалось обновить правило. Пожалуйста, попробуйте позднее',
    rCreatedMsg: 'Правило успешно создано',
    rCreationFailMsg: 'Не удалось создать правило. Пожалуйста, попробуйте позднее',
    assetDataFailMsg: 'Не удалось получить сведения об объекте. Пожалуйста, попробуйте еще раз!',
    deleteCampFailMsg: 'Ошибка при удалении кампании',
    CampDeletedMsg: 'Кампания удалена',
    creativeUpdated: 'Набор объявлений обновлен.',
    keyAdded: 'Ключевые слова добавлены.',
    negaKeyAddedMsg: 'Минус-слова добавлены в группы объявлений.',
    negKeyAddCamp: 'В кампанию добавлены минус-слова.',
    errorDeleteKey: 'Ошибка при удалении ключевого слова',
    keyDeleteS: 'Ключевое слово удалено. Ключевое слово перемещено в удаленные ключевые слова',
    syncStarted: 'Синхронизация началась.',
    firstSyncStarted:'Интеграция Apple Search Ads завершена!<br> <br> <p style="text-align: justify; color: inherit;">Подождите несколько минут, пока данные ASA синхронизируются в первый раз. Ваши приложения и данные ASA будут заполнены, как только завершится эта первая синхронизация. Следите за ходом синхронизации в правом верхнем углу страницы.<p>',
    integrationWarning:'Перед интеграцией с этой MMP требуется синхронизированная интеграция с Apple Search Ads. Добавьте интеграцию с Apple Search Ads выше и дождитесь завершения первой синхронизации перед интеграцией с этой MMP.',
    AdDeletedMsg: 'Объявление удалено',
    adUpdate: 'Объявление обновлено',
    deleteAdFailMsg: 'Ошибка при удалении объявления',
    deleteStrategyFailMsg: 'Ошибка при удалении стратегии',
    StrategyDeletedMsg: 'Стратегия удалена',
  },

  createCampaignRules: {
    selectCampGrp: 'Пожалуйста, выберите группу кампании!',
    selectApp: 'Пожалуйста, выберите приложение!',
    selectCountry: 'Пожалуйста, выберите страну!',
    campRequired: 'Требуется название кампании!',
    campNameSize: 'Название вашей кампании должно содержать менее 200 символов',
    adGroupSize: 'Название группы объявлений должно содержать менее 200 символов',
    keywordsize: 'Ключевые слова не могут быть длиннее 80 символов',
    budgetRequired: 'Требуется сумма бюджета!',
    validBudget: 'Пожалуйста, введите действующий бюджет!',
    groupRequired: 'Требуется название группы объявлений!',
    defMaxCptRequired: 'Требуется максимальная ставка CPT по умолчанию!',
    validCptBid: 'Пожалуйста, введите действительную ставку CPT!',
    validDailyCap: 'Пожалуйста, введите действительное дневное ограничение!',
    validCpaGoal: 'Пожалуйста, введите действительную цель CPA!',
    selectDevices: 'Пожалуйста, выберите устройства!',
    fNamereq: 'Имя обязательно',
    fNameGrt: 'Имя не должно превышать 12 символов.',
    textMax: 'Не должно быть больше 50 символов',
    textMin: 'Должно быть не менее 2 символов',
    fNameValid: 'Пожалуйста, введите действительное имя!',
    lNameGrt: 'Фамилия не должна превышать 12 символов.',
    lNameValid: 'Пожалуйста, введите правильное имя!',
    phoneReq: 'Телефон обязателен',
    cmpniNameReq: 'Требуется название компании',
    jobTitleReq: 'Должность обязательна',
    emailReq: 'Электронная почта обязательна',
    emailValid: 'Электронная почта должна быть действительной',
    selectCamp: 'Выберите кампанию',
    cardNoReq: 'Номер карты требуется',
    cardNo16Digit: 'Номер карты должен быть числовым и состоять из 16 цифр.',
    expiryMonthReq: 'Требуется месяц и год истечения срока действия',
    expiryMMYY: 'Срок годности должен быть ММ / ГГГГ - напр. 07/2023',
    invalidExpiry: 'Недействительный месяц истечения срока действия',
    invalidMonth: 'Недействительный год истечения срока действия',
    cvvReq: 'Карточка CVV обязательна',
    cvvNum3Digit: 'CVV карты должен состоять из трех цифр.',
    specialCharNotAllowed:'Спецсимволы не допустимы',
    onlySinglespaceAllowed:'Допускается только одиночный пробел',
    invalidPhoneFormat:'Недопустимый формат номера телефона'
  },

  settings: {
    profileInfo: 'информация профиля',
    updateProf: 'Обновить профиль',
    payMethod: 'Способ оплаты',
    addCredit: 'Добавить кредитную карту',
    cardNo: 'Номер карты',
    invoiceHistory: 'История счетов',
    invoiceId: 'Invoice_Id',
    dueDate: 'Срок оплаты',
    paidAt: 'Paid_At',
    recure: 'Повторяющийся',
    total: 'Общий',
    status: 'Положение дел',
    active: 'Действие',
    cardId: 'Card_Id',
    type: 'Тип',
    expiryMonth: 'Месяц истечения срока',
    expiry: 'Год истечения',
    action: 'Действие',
    addCard: 'Добавить карту',
    settings: 'Настройки',
    accountSetting: 'Настройки аккаунта',
    payment: 'Оплата',
    primaryPayment: 'Сделать эту карту основным источником оплаты',
    successTitle: "Успех!!!",
    successMsg:"Изменение платежной информации успешно обновлено",
    NoSuccessTitle: "Никаких изменений не обнаружено!!!",
    NoSuccessMsg:"Никаких изменений в существующей платежной информации не обнаружено."

  },

  dataRequest: {
    yourDataRequest: 'Ваши запросы данных',
    remain: 'Оставшийся',
    complete: 'Завершенный',
    Fetching: 'Fetching',
    Fetched: 'Fetched',
    appForKeyword: ' Apps for keyword - ',
    keywordsForApp: ' Keywords for App - ',
    appsForCategory: ' top Apps for category - ',
    competitorsForApp: ' suggested competitors for app - ',

  },

  users: {
    manageComp: 'Управляйте компаниями',
    comp: 'Компании',
    addDisManageComp: 'Добавлять, отключать, управлять всеми компаниями',
    createCompAdmin: 'Создать нового администратора компании',
    enterEmail: 'Введите адрес электронной почты',
    allCompAdmin: 'Все компании Администратор',
    rowPerPages:'Ключевые слова'
  },

  manageSubscription: {
    trialPeriod: "Вы находитесь в пробном периоде",
    trialPeriodExpire: "Срок действия вашей пробной версии истекает",
    updateCard: "Обновите свою карту",
    subscriptionDetail: "Сведения о подписке",
    p1: "Следующее выставление счетов",
    p2: "Управляйте платежным адресом, историей платежей и другими данными здесь",
    planListing: 'Список планов',
    furtherPlan: 'Выберите план, чтобы продолжить',
    generalPlan: 'ОБЩАЯ',
    month: 'месяц',
    tryIt: 'Пробуй сколько хочешь',
    createCampaign: 'Создать кампанию',
    keyAnalysis: 'Анализ ключевых слов',
    automationRule: 'Правила автоматизации',
    keyOpt: 'Оптимизация ключевых слов',
  },

  billingDetails: {
    billInfo: 'информация об оплате',
    compName: 'Название организации',
    addLine: 'Адресная строка',
    city: 'Город',
    zip: 'Почтовый индекс',
    vat: 'НДС Количество',
    updateBillAdd: 'Обновить платежный адрес'
  },

  dateRangePresets: {
    today: 'Сегодня',
    yesterday: 'Вчера',
    weekToDate: 'Неделя до даты',
    lastWeek:'На прошлой неделе',
    lastSevenDays:'Последние 7 дней',
    monthToDate:'С начала месяца по сегодняшний день',
    lastMonth:'Прошлый месяц',
    lastThirtyDays:'Последние 30 дней'
  },

  chartHeaders: {
    accounts: 'учетные записи',
    apps: 'Программы',
    campaign: 'Кампании',
    adgroup: 'Группы объявлений',
    keyword: 'Ключевые слова',
    creativeset: 'Творческие наборы',
    searchterm: 'Поисковые запросы',
  },

  campaignGenerator: {
    decisionMessage: 'Вы уверены, что хотите удалить шаблон?',
    addKeyPlaceholder: 'Введите текст, разделенный запятыми, и нажмите клавишу ввода или нажмите кнопку «Добавить».',
    showDraft: 'Показать черновик',
    pageHeading : 'Генератор кампаний',
    createCampaignHeading: 'Автоматическая настройка кампании',
    Segmentation: 'Сегментация',
    Categories: 'Категории',
    Brand: 'Бренд',
    Category: 'Категория',
    Discovery: 'Открытие',
    Competitor: 'Конкурент',
    Custom: 'Пользовательский',
    Criteria: 'Критерии',
    pleaseSelectCriteria: 'Выберите критерии',
    pleaseEnterValue: 'Пожалуйста, введите значение',
    Value: 'Значение',
    To: 'Кому',
    From: 'От',
    FromTo10: 'Пожалуйста, введите значение, отличающееся от первого значения более чем на 10',
    customSlugHeading: 'Пользовательский ярлык кампании:',
    customSlugPH: 'Введите имя слага',
    customSlugAdd: 'Добавить',
    customSlugDup: 'Повторяющееся имя слага',
    SegmentationRules: 'Правила сегментации',
    SegmentationDesc: 'В кампаниях есть бюджеты и группы объявлений, ориентированные на общую тему или стратегию продвижения вашего приложения.',
    age: 'Возраст',
    geo: 'Местоположение',
    selectCustomerType: 'Пожалуйста, выберите тип клиента',
    selectGender: 'Пожалуйста, выберите пол',
    selectAge: 'Пожалуйста, выберите возраст',
    keyManually: 'Добавить ключевые слова вручную',
    Summary: 'Сводка',
    SummaryText: 'Проверить сводку по кампаниям и группам объявлений',
    TotalCampaigns: 'Всего кампаний',
    TotalAdgroups: 'Всего групп объявлений',
    CampaignTitle: 'Название кампании',
    keysReady: 'ключевые слова готовы',
    custom: 'пользовательский',
    keysFetch: 'Выбор ключевых слов для',
    Campaignsarecreated: 'Кампании созданы',
    Creatingcampaigns: 'Создание кампаний',
    Viewallitems: 'Просмотреть все элементы',
    Adgroupsarecreated: 'Группы объявлений созданы',
    Creatingadgroups: 'Создание групп объявлений',
    Keywordsarecreated: 'Ключевые слова созданы',
    Creatingkeywords: 'Создание ключевых слов',
    ready: 'Готово',
    CreatedBy: 'Создано',
    CreatedOn: 'Создано',
    Campaigngenerationdetails: 'Сведения о создании кампании',
    footerProps: {
      itemsPerPageText: 'Строки на страницу',
      itemsPerPageAllText: 'Все',
      showFirstLastPage: true,
    },
    generateCampaigns: 'Создание кампаний',
    setDefaultValues: 'Установить значения по умолчанию',
    setDefaultValuesSub: 'Укажите настройки бюджета по умолчанию для целевых местоположений.',
    nokeyPara: 'Для кампаний категорий, открытий или конкурентов ввод ключевых слов не требуется, так как ключевые слова будут автоматически сгенерированы с использованием нашей технологии анализа ключевых слов.',
    waitingToSync: 'Ожидание синхронизации',
    campSyncHour:'Кампании будут синхронизированы с Apple в течение часа.',
    //syncInProgress: 'Выполняется синхронизация',
    syncInProgress: 'синхронизации',
    syncInProgressFewMin: 'Синхронизация выполняется и должна быть завершена в течение нескольких минут.',
    syncComplete: 'Синхронизация завершена',
    allSyncCompleted: 'Все кампании успешно синхронизированы.',
    campNameUniq: 'Пожалуйста, введите уникальные названия кампаний',
    campNamExists: 'Кампания с таким названием уже существует',
    adNameUniq: 'Пожалуйста, введите уникальные названия групп объявлений',
    saveAsDraft: 'Сохранить как черновик',
    draftHeading: 'Проект шаблона кампании',
    draftSearch: 'Поиск',
    headerOrgName: 'Название организации',
    headerAppName: 'Имя приложения',
    headerResume: 'Продолжить'
  },

  appSetting: {
    manage: 'Управлять',
    setDefaultValues: 'Установить значения по умолчанию',
    edit: 'Редактировать',
    update:'Обновлять',
    noRecords: 'Совпадающих записей не найдено',
    searchByCountry:'Поиск по стране'
  },

  goals: {
    selectGoal: 'Выберите цель',
    selectEventType: 'Выберите тип события',
    selectGoalRule: 'Пожалуйста, выберите тип цели',
    selectEventRule: 'Пожалуйста, выберите тип события',
    goalNameRule: 'Пожалуйста, введите название цели',
    manageGoals: 'Управление целями',
    goalDesc: 'Вы можете управлять своими целями отсюда',
    createGoal: 'Создать цель',
    editGoal: 'Изменить цель',
    chooseGoal: 'Выберите тип цели',
    chooseEvent: 'Выберите события',
    goalName: 'Название цели',
    close: 'Закрывать',
    update: 'Обновлять',
    create: 'Создавать',
    name: 'Имя',
    app: 'Приложение',
    appId: 'Идентификатор приложения',
    type: "Тип",
    events: "События",
    createdOn: "Создано на",
    createdBy: "Сделано",
    action: "Действие",
    revenue: "Доход",
    purchase: "Покупка",
    subscribe: "Подписывайся",
    userEngagement: "Вовлечение пользователей",
    levelComplete: "Уровень пройден",
    regComp: "Регистрация завершена",
    tutComp: "Учебник завершен",
    startTrial: "Начать пробный период",
    appLaunch: "Запуск приложения",
    userAction: "Действия пользователя",
    addToCart: "Добавить в корзину",
    addToWishlist: "Добавить в список желаний",
    checkoutStart: "Начало оформления заказа"
  },

  sr: {
    pageHeading: 'Запланированные отчеты',
    createScheduleReport: 'Создать запланированный отчет',
    srDesc: 'Отчеты по расписанию позволяют отправлять отчеты по почте или в резерв в определенное время и день',
    createSR: 'Запланировать новый отчет',
    for: 'Отчет по',
    type: 'Повторить',
    dataDays: 'Данные за последние',
    reportNameExists: 'Имя уже существует!',
    enterReportName: 'Введите имя',
    repeatMode: 'Тип повтора',
    pleaseSelectFor: 'Выберите уровень отчета',
    pleaseSelectType: 'Пожалуйста, выберите тип повтора',
    pleaseSelectDay: 'Пожалуйста, выберите дни',
    pleaseSelectTime: 'Пожалуйста, выберите время',
    noRepeat: 'Не повторять',
    custom: 'Пользовательский',
    daily: 'Ежедневно',
    weekly: 'Еженедельно',
    monthly: 'Ежемесячно',
    selectOrg: 'Пожалуйста, выберите организацию',
    selectGoal: 'Выберите цель',
    goalsSelected: 'Выбраны цели',
    orgsSelected: 'Выбраны организации',
    scheduleReport: 'запланированный отчет?',
    scheduleReports:'Расписание отчетов',
    reportOf:'Доклад',
    selectTime:'Выберите время',
    downloadReport:'Скачать отчет',
    addMore:'Добавить больше',
    scheduleReport1:'Отчет по расписанию',
    selectSlackChannel:'Выберите слабый канал',
    emailNoSpaces:'Электронная почта не может содержать пробелы',
    emailWarning:'Пожалуйста, выберите хотя бы один адрес электронной почты или введите хотя бы один внешний идентификатор электронной почты ниже',
    errorDuringSave:'Ошибка при сохранении запланированного отчета',
    scheduleSuccess:'Запланировано успешно',
    scheduleSaveWarning:'Вы уверены, что хотите запланировать этот отчет?'
  },
  //Delete Ad
  deleteAds: {
    deleteAd: 'Удалить объявление',
    para: 'Вы уверены, что хотите удалить объявление?',
    cancel: 'Отмена',
    delete: 'Удалить',
    ok:'ХОРОШО'
  },
   //EditAds
   editAd: {
    editAdWizard: 'Мастер редактирования объявлений',
    adName: 'Название объявления',
    cancel: 'Отмена',
    save: 'Сохранять'
  },
  createAdRules: {
    adRequired: 'Требуется название объявления!',
    adNameSize: 'Название вашего объявления должно содержать менее 200 символов.'
},
  common : {
    save: 'Сохранить',
    cancel: 'Отмена',
    noDataText: 'Нет данных для отображения',
    days: 'День(и)',
    time: 'Время',
    na: 'NA',
    goal: 'Цель(и)',
    deleteConfirmText: 'Вы уверены, что хотите удалить это ',
    createConfirmText: 'Приступить к созданию этого нового ',
    updateConfirmText: 'Вы уверены, что обновите это ',
    dataTableFooterProps: {
      itemsPerPageText: 'Строки на страницу',
      itemsPerPageAllText: 'Все',
      showFirstLastPage: true,
    },
  },

  welcomeOnboard: {
    yes: 'да',
    no: 'нет',
    continue: 'Продолжать',
    welcome: 'ДОБРО ПОЖАЛОВАТЬ В ПОИСКОВУЮ ОБЪЯВЛЕНИЕ MAVEN',
    description: 'Добро пожаловать в поиск Ads Maven. Начнем с настройки вашей интеграции. вам понадобится ваш Apple ID, и если ваш ASA использует двухфакторную аутентификацию, вам понадобится соответствующее устройство.',
    getStart: 'Начать',
    setupLater: 'Перейти к приложению и настроить позже',
    setup: 'Настраивать',
    createIntegration: 'Создать интеграцию',
    createIntDescription: 'Введите свой Apple ID, чтобы создать двунаправленную синхронизацию между Search Ads Maven и Apple Search Ads. Если у вас есть партнер MMP, эти интеграции также можно настроить здесь. Давайте начнем с импорта ваших данных Apple Search Ads».',
    enterAppleId: 'ИНТЕГРАЦИЯ ВАШЕГО APPLE ID',
    success: 'Успех',
    stepper: {
      title: 'Идет синхронизация!',
      descreption1: 'Мы импортируем ваши исторические данные из Apple Search Ads. В зависимости от объема данных, это может занять несколько часов. Вы можете проверить индикатор выполнения в правом верхнем углу в любое время во время этой синхронизации, а после завершения синхронизации посетите страницу Интеграции.',   
      descreption2: 'А пока давайте познакомимся с платформой.',
    },
    stepperTwo: {      
      title: 'Интеграция с MMP',
      descreption:'MMP — это сторонние инструменты измерения, которые позволяют вам измерять и сравнивать эффективность ваших рекламных расходов по множеству различных маркетинговых каналов и партнеров на основе данных о взаимодействии приложений с полной воронкой. Самая большая функция MMP, которая поможет нам здесь, — это анализ и оптимизация производительности нашего ASA с учетом событий, которые происходят после того, как клиент покидает магазин приложений и начинает использовать приложение.',
      successfullIntegrations:'Успешные интеграции',
      receivedTypes: 'Полученные типы событий'
      },
    startGuide: 'Руководство по началу работы',
    syncMsg: {
      your: 'Ваш',
      syncWasLess: 'синхронизация была меньше',
      moreTime: 'назад. Пожалуйста, подождите больше времени перед повторной синхронизацией.'
    },
    goToApplication: 'Перейти к приложению',
    overview: {
      title: 'Добро пожаловать в обзор',
      description: 'Здесь вы найдете сводную информацию об эффективности вашей текущей кампании, связанные с ней показатели и многое другое. Вы можете настроить это представление в соответствии с вашими потребностями.',
      popupSecond: {
        title: 'Благодарим за знакомство с Search Ads Maven.',
        description: 'Если у вас есть какие-либо вопросы или проблемы с интеграцией, обратитесь в службу поддержки в любое время по адресу' 
      }
    },
    adsManager: {
      title: 'Добро пожаловать в Менеджер рекламы',
      description: 'Здесь вы можете просматривать детальную аналитику и отчеты по вашим приложениям, кампаниям, группам объявлений, ключевым словам и многому другому. Кроме того, создавайте кампании вручную, чтобы исключить догадки из процесса.  В разделе Ads Manager также есть возможность создавать кампании, группы объявлений, ключевые слова и минус-слова.'
    },
    keywordAnalysis: {
      title: ' Добро пожаловать в Анализ ключевых слов',
      description: 'Здесь вы можете оценить общую эффективность ключевых слов вашей кампании и увидеть предполагаемых конкурентов для вашего приложения, а также завоевать ключевые слова вашего конкурента. Вы также можете быстро добавить дополнительные ключевые слова в свои кампании.'
    },
    keywordOptimization:{
      title: 'Добро пожаловать в Оптимизацию ключевых слов',
      description: 'Здесь вы можете быстро и эффективно оптимизировать свои ставки для ключевых слов, которые не входят в диапазон рекомендуемых ASA минимальных и максимальных ставок. Найдите целевые показатели на основе событий вашего приложения, включенных в рекомендации ASA.'
    },
    integrations: {
      title: 'Добро пожаловать в интеграцию',
      description: 'Здесь вы можете добавить интеграции ASA, определить сторонние интеграции MMP и настроить каналы связи.'
    },
    automation: {
      title: 'Добро пожаловать в Автоматизацию ',
      description: 'Здесь вы можете создавать правила автоматизации для своих организаций, приложений, кампаний, групп объявлений и ключевых слов.'
    },
    goals: {
      title: ' Добро пожаловать в Цели',
      description: 'Здесь вы можете определить, как сторонние MMP-события используются для создания целей кампании на основе доходов, вовлеченности и действий пользователей.'
    },
    campaignGenerator: {
      title: 'Добро пожаловать в генератор кампаний',
      description: 'Здесь вы можете быстро создавать кампании вручную или использовать наш автоматический генератор кампаний, чтобы избавить процесс от догадок.'
    },
    strategicView: {
      title: 'Добро пожаловать в стратегический обзор',
      description: 'Преодолейте сложность Apple Search Ads с помощью стратегий Search Ads Maven. Группируйте кампании, группы объявлений и ключевые слова в упрощенные объекты, основанные на автоматизации и четких этапах. Создавайте, реализуйте и измеряйте успех — все в ваших руках. Упрощайте, оптимизируйте, побеждайте.'
    },
    thirdParty: {
      popupOne: {
        title: 'Вы хотели бы интегрировать данные о событиях третьих лиц? ',
        description: ''
      },
      popupTwo: {
        title: '',
        description: 'Данные сторонних событий нельзя импортировать до тех пор, пока синхронизация ASA не будет завершена. Пожалуйста, вернитесь на эту страницу, когда ваша синхронизация достигнет 100%'
      },
      popupThree: {
        title: '',
        description: 'Для заполнения вашей информации ASA в Search Ads Maven потребуется некоторое время, поэтому давайте пока познакомимся с платформой.'
      },    
    }
  }
}

