import { CORID_HEADER_NAME, SESSION_HEADER_NAME, TEAM_HEADER_NAME, TOKEN_HEADER_NAME, USER_HEADER_NAME } from '@shared/config'
import Vue from 'vue'
import { LSS } from '@core/services'
import axios from 'axios';
import { decodeToken } from '@shared/utils/commom.util'
import { v4 as uuidv4 } from 'uuid';

export default function setupInterceptor(): void {
  const decoded = decodeToken(LSS.token)
  axios.interceptors.request.use((config) => {
    const UserID = decoded.UserId;
    const TeamID = decoded.TeamID;
    const Token = LSS.token;
    const SessID = LSS.sessId;
    const CorrID = uuidv4();

    // if(/upload\.imagekit\.io/gi.test(config.url)) return config;

    if (UserID) {
      config.headers = { [USER_HEADER_NAME]: UserID, ...config.headers };
    }

    if (TeamID) {
      config.headers = { [TEAM_HEADER_NAME]: TeamID, ...config.headers };
    }

    if (Token) {
      config.headers = { [TOKEN_HEADER_NAME]: Token, ...config.headers };
    }

    if (SessID) {
      config.headers = { [SESSION_HEADER_NAME]: SessID, ...config.headers };
    }
    config.headers = { [CORID_HEADER_NAME]: CorrID, ...config.headers };
    return config;
  });
  Vue.axios.interceptors.response.use(function (response: any) {
      // Any status code within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
  }, function (error: any) {
      // Any status codes outside the range of 2xx cause this function to trigger
      // Do something with response error
      if(error.response.status == 401){
        alert('Invalid Session. Try login again')
        LSS.clear();
        window.location.href = Vue.prototype.$out_url
        return Promise.reject(error);
      }
  });
}
