<template>
  <div class="text-center">
    <v-dialog v-model="DecisionAlert" persistent width="500">
      <v-card class="d-flex align-center justify-center flex-column w-100">
        <v-card-title
          class="d-flex justify-space-between align-center w-100 primary white--text px-6 py-3 w-100"
        >
          <h5>{{$t('decisionAlert.confirm')}} </h5>
          <v-icon class="white--text" @click="emitDecisionAlertCancelClicked"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text class="d-flex align-start px-6 py-8 pb-6 w-100">
          <v-icon
            class="font-32 mr-3 "
            color="primary"
            v-if="DecisionAlertIcon === 1"
            >mdi-delete-circle</v-icon
          >
          <v-icon
            class="mr-3 font-32 primary--text"
            color=" "
            v-if="DecisionAlertIcon === 2"
            >mdi-clipboard-check</v-icon
          >

            <v-icon
            class="mr-3 font-32 primary--text "
            color=" "
            small
            v-if="DecisionAlertIcon === 3"
            >mdi-checkbox-marked-circle-outline</v-icon
          >
          <div class="d-flex flex-column w-100">
          <v-card-title class="text-subtitle-1 pa-0">
            {{ DecisionAlertTitle }}
          </v-card-title>
          <v-card-text class=" text-subtitle-1 pa-0 d-flex w-100 flex-column">
           <span> {{ DecisionAlertMessage }} </span>
          <div v-if="DecisionAlertData.from=='adsmanager-toggle'" class="d-flex align-center checkbox-outer  w-100 px-6 justify-start">
          <v-checkbox
              v-model="checkbox"
              label=""
            ></v-checkbox>
            <v-card-text class=" text-subtitle-2 pa-0 greybase--text ml-2 mt-3">
              click here to hide the confirmation dialog next time.
            </v-card-text>
         </div>

          </v-card-text>
          
          </div>
        </v-card-text>

       


        <v-card-actions class="d-flex justify-end align-center w-100 pb-6  px-6">
          <v-btn
            v-if="!onlyMessage"
            width="100px"
            color=" "
            @click="emitDecisionAlertCancelClicked"
          >
            {{ $t('decisionAlert.cancel') }}
          </v-btn>
          <v-btn
            width="60px"
            color="primary"
            @click="emitDecisionAlertOkClicked"
          >
            {{ $t('decisionAlert.ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'DecisionAlert',
  data() {
    return {
      checkbox:false,
      emitMsgOk: 'clicked Ok',
      emitMsgCancel: 'clicked Cancel',
    };
  },
  props: {
    onlyMessage: {
      type: Boolean,
      default: false
    },
    DecisionAlert: {
      type: Boolean,
      default: false,
    },
    DecisionAlertTitle: {
      type: String,
      default: 'Decision...',
    },
    DecisionAlertIcon: {
      type: Number,
      default: 0,
    },
    DecisionAlertMessage: {
      type: String,
      default: 'Are you sure ?',
    },
    DecisionAlertData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    DecisionAlertData: function (data) {
      //console.log(data)
    },
  },
  methods: {
    emitDecisionAlertOkClicked() {
      //console.log('emitDecisionAlertOkClicked');
      if(this.checkbox) this.$emit('emitDecisionAlertOkClicked', {...this.DecisionAlertData, 'checkbox':this.checkbox});
      else this.$emit('emitDecisionAlertOkClicked', this.DecisionAlertData);
    },
    emitDecisionAlertCancelClicked() {
      //console.log('emitDecisionAlertCancelClicked');
      this.$emit('emitDecisionAlertCancelClicked', this.emitMsgCancel);
    },
  },
});
</script>

<style scoped>

</style>
