import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

const options = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#365CA8', //  extra light blue
        primarybase: '#2c416f', //  base blue
        primarydark: '#213155', //  dark blue
        primarylight: '#18438f', //  light blue
        accent:'#18438f',

        secondary: '#f7a284', // peach
        secondarylight: '#ffdbcf', // extra light peach
        secondarybase: '#ffb59b', // light peach
        secondarydark: '#DD6742', // dark peach

        error: '#B51C15', // red
        info: '#56BED7', // blue
        success: '#449168', // green
        warning: '#FDB212', // yellow

        grey:"#535D60", // grey
        greydark:"#1e2122", //dark grey
        greyxdark:"#6f7c7f", //dark grey
        greybase:"#8c9191", //base grey
        greylight:"#b1b4b3", //light grey
        greyxlight:"#d5d6d6", //extra light grey
        greyxxlight:"#F0F0F0", //extra light grey
      },
    },
  },
})

export default new Vuetify(options) 


