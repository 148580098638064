import { Base64 } from 'js-base64';

export function decodeToken(token: string): any {
  try {
    const decodedDetails = JSON.parse(Base64.decode(token.split('.')[1]))
      .userDetails;
    _decodeCompanyNameToOriginal(decodedDetails);
    return decodedDetails;
  } catch (e) {
    return {};
  }
}

/* 
  INFO ℹ️:
  - companyName is encoded with base64 ajust to support chinese characters, 
  because decoding of chinese character is not supported out of the box in JWt.
  - So we encode compnay name in backend and create jwt and send it in frontend,
  which will have to do extra decoding of companyname whihc is done below.

  TODO 📋: 
  - Can we encode the whole payload with Base64 that will support any chinese charactes present in the payload and decode it here twice. Check if this
  works..
*/
function _decodeCompanyNameToOriginal(decodedDetails) {
  if (!decodedDetails) return;
  /* decode to original in companyName property */
  decodedDetails.CompanyName = Base64.decode(decodedDetails.CompanyName);

  /* decode to original in teams property */
  if (decodedDetails.teams && decodedDetails.teams.length !== 0) {
    decodedDetails.teams.forEach((team) => {
      team.Company = Base64.decode(team.Company);
    });
  }
}
