import urljoin from 'url-join';

/**
 * @function
 * @name joinUrl
 * @description joins url segments into single string
 * @param {...string[]}segments segments of a url
 * @returns {string} joined url.
 */
export function joinUrl(...segments: string[]): string {
  return urljoin(segments);
}

/**
 * @function
 * @name replaceParamsWithValues
 * @description replaces params with values in a parameterized string.
 * @note number of parameters in the string must be equal to values passed in the same order.
 * @param paramString parameterized string
 * @param values values to replace params with
 */
export function replaceParamsWithValues(paramString: string, ...values: any[]): string {
  if (values.length !== (paramString.match(/:/g) || []).length) {
    return paramString;
  }

  const segments = paramString.split('/');
  return segments.map(segment => /^:/gi.test(segment) ? values.shift() : segment).join('/');
}
