/** App Constants */
export const SAM_CLIENT_VERSION =  process.env.VUE_APP_SAM_VERSION;

/** KAI */
export const KEYWORDS_PER_TRY = 600;

/** Budget Check */
export const KOCHAVA_BUDGET = 1
export const KOCHAVA_COMPANY = 'Kochava'

