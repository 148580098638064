

















































































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { HttpService, LSS } from '@core/services';
import { API_WS_URL, CB_SITE_NAME, SAM_CLIENT_VERSION } from '@config';
import { decodeToken } from '@shared/utils/commom.util';
import DataRequests from './DataRequests.vue';
import { LSS } from '../core/services';
import { LoginService } from '@services/login.service';
import { bus } from '../main';
import { BusEvents, Roles } from '../utils/enums';
import { updateCLF, getOwnApps } from '../utils/util';
import getSymbolFromCurrency from 'currency-symbol-map'


import {
  TRIGGER_MANUAL_SYNC,
  INTEGRATIONS_INFO,
  CURRENCY_INFO
} from '../utils/apollo-queries';
import { ChargebeeService } from '@services/chargebee.service';
import { SubscriptionPermissions } from '@shared/enums';
import PermissionPopup from './PermissionPopup.vue';
import SyncPopup from '../components/SyncPoup.vue';
import GuidedTourWizard from './on-boarding/GuidedTourWizard.vue';
import { logActivity } from 'src/utils/sam-activity-log';
import { Base64 } from 'js-base64';
export default Vue.extend({
  name: 'navbar',
  components: {
    GuidedTourWizard,
    DataRequests,
    PermissionPopup,
    SyncPopup
  },
  data() {
    return {
      //activity logs
      logType: 'activity',
      logTypefailure: 'failure', 
      syncType:'ASA Sync',
      asatestRunFailedMessage:'Sync fail',
      appleAdsCompleteMessage:'Integration complete',
      featuredName:'ASA Integration',
      featuredPath:'',
      campaignsCount:0,
      adgroupsCount:0,
      keywordscount:0,
      preventAccess: false,
      demoMode: false,
      currency: '',
      notAccessPages : ["/overview","/ads-manager","/arrules"],
      loggedInAs: null,
      applyBoxShadow:false,
      applyBoxShadowonlogo:false,
      permissionMetadata: {},
      ASASyncMetadata: {},
      profilePic: '',
      firstSyncCD: null,
      dailySyncCD: null,
      bfrInfo: {},
      asaIntegrationPreset: true,
      errorBanner: false,
      infoBanner: false,
      orgs: [],
      selectedOrg: 'Org',
      isSelected: true,
      existDR: false,
      triggerDR: null,
      drProgress: 0,
      syncing: false,
      mode: '',
      lastSyncAt: null,
      syncProgress: 0,
      isNavbarExpanded: false,
      drawer: true,
      menu: false,
      mini: false,
      name: 'Ninja',
      locales: [
        { flag: 'us', text: 'EN', localeCode: 'en' },
        { flag: 'de', text: 'DE', localeCode: 'de' },
        { flag: 'es', text: 'ES', localeCode: 'es' },
        { flag: 'kr', text: 'KO', localeCode: 'ko' },
        { flag: 'cn', text: 'ZH', localeCode: 'zh' },
        { flag: 'jp', text: 'JA', localeCode: 'ja' },
        { flag: 'fr', text: 'FR', localeCode: 'fr' },
        { flag: 'ru', text: 'RU', localeCode: 'ru' },
      ],
      selectedLocale: { flag: 'us', text: 'EN', localeCode: 'en' },
      fullscreenLoader: null,
      TeamID: null,
      rotateIcon: true,
      SubscriptionPermissionsEnums: SubscriptionPermissions,
    };
  },
  watch: {
    errorBanner(to, from) {
      this.setbannerView();
    },
    infoBanner() {
      this.setbannerView();
    },
    async $route(to, from) {
      Vue.prototype.$sckt.on('connect', () => {
        Vue.prototype.$sckt.emit('syncprogress:check', {
          teamId: decodeToken(LSS.token).TeamID,
          CorrID: uuidv4(),
        });
      });

      Vue.prototype.$sckt.on('syncprogress:event', async  (progressEvent) => {
        this.syncing = !progressEvent.done;
        this.mode = progressEvent.mode;
        LSS.activeSync = !progressEvent.done;
        if (progressEvent?.bfrInfo) {
          this.bfrInfo = progressEvent.bfrInfo;
          this.firstSyncCD = progressEvent.bfrInfo.firstSyncCD;
          this.dailySyncCD = progressEvent.bfrInfo.dailySyncCD;
        }

        if (progressEvent.lastSyncAt) {
          this.lastSyncAt = progressEvent.lastSyncAt;
          if (!LSS.firstSyncDone) LSS.firstSyncDone = true;
        }
        if (progressEvent.error) {
          if (progressEvent.errCode === 'INVALID_INTEGRATION')
            this.errorBanner = true;
          // Log the sync failure here
          await logActivity(this.logTypefailure, this.asatestRunFailedMessage, this.syncType, '', { "detail": 'INVALID INTEGRATION' });
        } else {
          this.errorBanner = false;
          this.syncProgress = progressEvent.progress;

          if (
            Number(this.syncProgress) > 0 &&
            Number(this.syncProgress) < 100
          ) {
            if (LSS.isRefetchRequired != true) LSS.isRefetchRequired = true;
          }
          if (
            Number(this.syncProgress) === 100 &&
            LSS.isRefetchRequired == true
          ) {
            bus.$emit(BusEvents.DATA_PULLED);
            // Log the sync completion here
            let logAsaData = {
              "apps":LSS.ownApps.length,
              "campaigns":this.campaignsCount,
              "adgroups":this.adgroupsCount,
              "keywords":this.keywordsCount,
            };
          }
          if (
            Number(this.syncProgress) === 100 
          ) {
            LSS.ctoFlag = false
          }
        }
      });

      if (Vue.prototype.$sckt.connected) {
        Vue.prototype.$sckt.emit('syncprogress:check', {
          teamId: decodeToken(LSS.token).TeamID,
          CorrID: uuidv4(),
        });
      }

      this.setbannerView();

      if (LSS.adminToken) {
        this.loggedInAs = decodeToken(LSS.token).CompanyName;
        this.infoBanner = true;
      } else {
        this.loggedInAs = null;
      }
    },
    async isNavbar() {
      this.integrationsInfo();
      this.getCurrencyInfo();
    },
    '$route.query.integrationAlreadyPresent': {
      handler(to, from) {
        if (to) {
          this.pullData();
        }
      },
      deep: true,
    },
    '$route.query.integrated': {
      async  handler(to, from) {
        if (to && Vue.prototype.$sckt) {
          this.$notify.success({
            title: '',
            msg: this.$t('successMsg.firstSyncStarted'),
            timeout: 15000,
          });
          // console.log('syncprogress:event-navbar-emitting')
          Vue.prototype.$sckt.emit('syncprogress:check', {
            teamId: decodeToken(LSS.token).TeamID,
            CorrID: uuidv4(),
          });
         // When Apple Search Ads integration completes
        await logActivity(this.logType, this.appleAdsCompleteMessage, this.featuredName, this.featuredPath, {});
        //end 
        }
      },
      deep: true,
    },
    mini(val) {
      bus.$emit(BusEvents.NAV_TOGGLE, val);

      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return (this.mini = true);
        case 'sm':
          return (this.mini = true);
        case 'md':
          return (this.mini = true);
      }
    },

    menu(data) {
      if (data == true) {
        this.rotateIcon = false;
      } else if (data == false) {
        this.rotateIcon = true;
      }
    },
  },
  methods: {
    getCurrencyIcon(value) {
      if(value !== undefined || value !== null) {
        return (getSymbolFromCurrency(value) == undefined) ? '' : getSymbolFromCurrency(value)
      }
      return '';
    }, 
    async returnToAdmin() {
      LSS.token = LSS.adminToken;
      LSS.adminToken = '';
      if (!this.$route.fullPath.includes('users')) {
        await this.$router.push('/users');
      }
      window.location.reload();
    },
    updateScroll(){

      this.scrollPosition = window.scrollY
      if(this.scrollPosition<50){
            this.applyBoxShadow = false;
      }
      else{
        this.applyBoxShadow = true;
      }
    },

     updateScrollonlogo(){

      this.scrollPosition = window.scrollY
      if(this.scrollPosition<50){
            this.applyBoxShadowonlogo = false;
      }
      else{
        this.applyBoxShadowonlogo = true;
      }
    },
    getIconUrl(itemPath: String) {
      let url = '';

      switch (itemPath) {
        //Overview

        case '/overview':
          url = require('../assets/images/overview.svg');

          break;

        //Ads Manager

        case '/ads-manager':
          url = require('../assets/images/adsmr.svg');

          break;

        //Keyword Analysis

        case '/keyword-auction-insights':
          url = require('../assets/images/kai.svg');

          break;

        //keyword optimization

        case '/keyword-optimization':
          url = require('../assets/images/keyopt.svg');

          break;

        //Integration

        case '/integrations':
          url = require('../assets/images/integration.svg');

          break;

        //Automation Rules

        case '/arrules':
          url = require('../assets/images/rule.svg');

          break;

        //Goals

        case '/goals':
          url = require('../assets/images/goal.svg');

          break;
        
        //strategies
        case '/strategies':
          url = require('../assets/images/strategies.svg');

          break;


        //create campagin
          //   case '/campaign-view':
          // url = require('../assets/images/Campaigns-icon.png');

          // break;

        //Campaign Generator

        case '/campaign-generator':
          url = require('../assets/images/campgen.svg');

          break;

        //Team Management

        case '/team-management':
          url = require('../assets/images/teams.svg');

          break;

        //Companies

        case '/users':
          url = require('../assets/images/company.svg');

          break;

        case '/searchads-manage':
          url = require('../assets/images/activity-log.svg');

          break;

        //Default Icon

        default:
          url = require('../assets/images/small-logo.png');

          break;
      }

      return url;
    },

    openPermissionPopup() {
      this.permissionMetadata = {
        date: moment.now(),
      };
    },
    openSyncMsgPopup(item) {
        if (this.notAccessPages.includes(item.path) && !this.demoMode) {
          let statusMsg = "";
          if (!this.asaIntegrationPreset) {
            if (item.path === "/integrations") {
              statusMsg = "Please wait for the Apple Search Ads sync to complete before adding an MMP integration";
            } else {
              statusMsg = "This page requires an Apple Search Ads integration. Please integrate with Apple Search Ads on the Integrations page in your account settings";
            }
          } else if (this.syncing && this.mode === 'FIRST') {
            statusMsg = "Please wait for the Apple Search Ads sync to complete before accessing this view";
          }
          if (statusMsg !== "") {
            this.ASASyncMetadata = {
              date: moment.now(),
              status: statusMsg
            };
            this.preventAccess = true;
            return;
          }
        }     
      this.preventAccess = false;
    
    },
    async integrationsInfo() {
      try {
        if(Object.keys(decodeToken(LSS.token)).length > 0) {
          const result = await this.$apollo.query({
            query: INTEGRATIONS_INFO
          });
          const info = JSON.parse(result.data.getIntegrationInfo.info);
          const firstSyncDone = result.data.getIntegrationInfo.firstSyncDone;
          LSS.firstSyncDone = firstSyncDone;
          this.asaIntegrationPreset = info.APPLE_SEARCH_ADS;
          if(this.asaIntegrationPreset != undefined && this.asaIntegrationPreset != null) LSS.asaInt = this.asaIntegrationPreset
          else if(result.data.getIntegrationInfo.demoMode) LSS.asaInt = true
          else LSS.asaInt = false
          this.demoMode = result.data.getIntegrationInfo.demoMode;
        }
      } catch (error) {
        this.asaIntegrationPreset = true;
      }
    },
    async getCurrencyInfo() {
      try {
        if(Object.keys(decodeToken(LSS.token)).length > 0) {
            const result = await this.$apollo.query({
              query: CURRENCY_INFO
            });
            if(result && result.data.getCurrencyInfo.currencies) {
              this.currency = result.data.getCurrencyInfo.currencies + '' + this.getCurrencyIcon(result.data.getCurrencyInfo.currencies);
            }
        }
      } catch (error) {
        
      }
    },
    setbannerView() {
      setTimeout(() => {
        const dwb = document.querySelector('.dashboard-with-banner');
        const pwb = document.querySelector('.page-header-with-banner');
        if (this.errorBanner || this.infoBanner) {
          dwb?.style?.setProperty('margin-top', '33px', 'important');
          pwb?.style?.setProperty('margin-top', '33px', 'important');
        } else {
          dwb?.style?.setProperty('margin-top', '0px', 'important');
          pwb?.style?.setProperty('margin-top', '0px', 'important');
        }
      }, 0);
    },
    clearDraftId() {
      LSS.clearOther();
    },
    version() {
      return SAM_CLIENT_VERSION;
    },
    openDataRequests() {
      this.triggerDR = {
        now: Date.now(),
        items: null,
      };
    },
    switchLocale(locale) {
      this.selectedLocale = locale;
      this.$root.$i18n.locale = locale.localeCode;
      localStorage.setItem('locale', locale.localeCode);
      this.$root.$emit('locale-changed', locale);
    },
    async pullData() {
      this.syncing = true;
      LSS.activeSync = true;
      this.syncProgress = 0;
      try {
        const result = await this.$apollo.query({
          query: TRIGGER_MANUAL_SYNC,
        });

        if (result.data.invokeManualSync.successMessage) {
          this.$notify.success({
            title: '',
            msg: this.$t('successMsg.syncStarted'),
          });

          if (result.data.invokeManualSync.data) {
            const syncId = JSON.parse(result.data.invokeManualSync.data).syncId;
            this.campaignsCount = JSON.parse(result.data.invokeManualSync.data).campaignsCount;
            this.adgroupsCount = JSON.parse(result.data.invokeManualSync.data).adgroupsCount;
            this.keywordsCount = JSON.parse(result.data.invokeManualSync.data).keywordsCount;
            Vue.prototype.$sckt.emit('syncprogress:check', {
              teamId: decodeToken(LSS.token).TeamID,
              syncId,
              CorrID: uuidv4(),
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    menuClick(path) {
      if(path.includes("support")){
        window.location.href = 'https://support.searchadsmaven.com/?utm_source=marketing_site_menu';
      }
      else{
        this.$router.push({ path: path });
      }
      this.menu = false;
    },
    async getUserDetails(UserID, item) {
      this.fullscreenLoader = this.$loading.show();
      const token = await LoginService.getUserDetails({
        UserID: UserID,
        SessId: null,
        switchTo: {
          TeamId: item.TeamId,
          Role: item.Role,
          Company: item.Company,
        },
      }).catch((err) => {
        console.log(err);
        alert('Server did not respond');
        this.fullscreenLoader.hide();
        return;
      });
      const user = decodeToken(token);
      LSS.token = token;
      this.$root.$emit('userNameFetched', user);
      this.fullscreenLoader.hide();
      this.$root.$emit('org-changed', true);
      this.updateOrgs();
      LSS.company = item.Company;
      this.selectedOrg = item.Company;
      return user;
    },
    async switchOrg(item) {
      if (decodeToken(LSS.token).TeamID == item.TeamId) return;
      await this.getUserDetails(decodeToken(LSS.token).UserId, item);
      if (!this.$route.fullPath.includes('overview')) {
        await this.$router.push('/overview');
      }
      window.location.reload();
    },
    async updateOrgs() {
      if (LSS.token) {
        if (
          decodeToken(LSS.token).teams &&
          decodeToken(LSS.token).teams.length != 0
        ) {
          this.orgs = decodeToken(LSS.token).teams;
        } else {
          this.orgs = [
            {
              IsDisabled: false,
              CreatedOn: new Date(),
              Company: decodeToken(LSS.token).CompanyName,
              Role: decodeToken(LSS.token).Role,
              TeamId: decodeToken(LSS.token).TeamID,
              IsReadOnly: false,
            },
          ];
        }
        this.setCompany(decodeToken(LSS.token).CompanyName);
      }
    },
    setCompany(company) {
      LSS.company = company;
      this.selectedOrg = company;
    },
    createPortalSession() {
      return ChargebeeService.createPortalSession();
    },

    /* fetchChartDatasetPresets(){
      this.$apollo
          .query({
            query: FETCH_CHART_PRESETS,
          })
          .then((info) => {
           // console.log(info.data.fetchChartPresets);
            LSS.chartDatasets = info.data.fetchChartPresets;
          })
          .catch((error) => {
            console.log(error);
          });
    },

    fetchTableDatasetPresets(){
      this.$apollo
          .query({
            query: FETCH_TABLE_PRESETS,
          })
          .then((info) => {
           LSS.datatableDatasets = info.data.fetchTablePresets
          })
          .catch((error) => {
            console.log(error);
          });
    } */
  },

  computed: {
    morethan24months:{
      cache:false,
      get(){
        if(this.mode == 'FIRST' && LSS.ctoFlag){
          return true
        }
        return false
      }
    },
    isInSetup() {
      return this.$route.name == 'Setup';
    },
    permissions: {
      cache: false,
      get() {
        if (decodeToken(LSS.token)) {
          if (decodeToken(LSS.token).Role == 'SUPERADMIN') {
            return [];
          } else {
            return decodeToken(LSS.token).permissions
              ? decodeToken(LSS.token).permissions
              : [];
          }
        }
      },
    },
    isSuperAdmin: {
      cache: false,
      get() {
        if (
          (decodeToken(LSS.token) &&
          decodeToken(LSS.token).Role == Roles.SUPERADMIN) || 
          (decodeToken(LSS.adminToken) &&
          decodeToken(LSS.adminToken).Role == Roles.SUPERADMIN)
        ) {
          return true;
        }
        return false;
      },
    },
    lastSyncAtLocal: {
      cache: false,
      get() {
        return moment(this.lastSyncAt).format('MMM Do YYYY, h:mma');
      },
    },
    syncFromNow: {
      cache: false,
      get() {
        return moment(this.lastSyncAt).fromNow() 
      }
    },
    isNavbar: {
      cache: false,
      get() {
        return this.$route.name &&
        ![
          'WelcomeMessage',
          'Login',
          'onboard-fork',
          'onboard',
          'signin',
          'signup',
          'logout',
          'verify',
          'forgot-password',
          'change-password',
          'sa-integration',
          'slack-integration',
        ].includes(this.$route.name);
      },
    },
    items: {
      cache: false,
      get() {
        const items = [
          {
            title: this.$t('pages.overview'),
            icon: 'mdi-view-dashboard',
            path: '/overview',
            tooltip: this.$t('pages.overview'),
            guard: SubscriptionPermissions.OVERVIEW,
          },
          {
            title: this.$t('pages.adsManager'),
            icon: 'mdi-view-dashboard',
            path: '/ads-manager',
            tooltip: this.$t('pages.adsManager'),
            guard: SubscriptionPermissions.ADSMANAGER,
          },
          {
            title: this.$t('pages.kai'),
            icon: 'mdi-keyboard',
            path: '/keyword-auction-insights',
            tooltip: this.$t('pages.kai'),
            guard: SubscriptionPermissions.KEYWORDANALYSIS,
          },
          {
            title: this.$t('pages.keyOpt'),
            icon: 'mdi-pencil-box-multiple',
            path: '/keyword-optimization',
            tooltip: this.$t('pages.keyOpt'),
            guard: SubscriptionPermissions.KEYWORDOPTIMIZATION,
          },
          // {
          //   title: this.$t('pages.integrations'),
          //   icon: 'mdi-link-box-variant',
          //   path: '/integrations',
          //   tooltip: this.$t('pages.integrations'),
          //   guard: SubscriptionPermissions.ASAINTEGRATION,
          // },
          {
            title: this.$t('pages.automationRule'),
            icon: 'mdi-ballot',
            path: '/arrules',
            tooltip: this.$t('pages.automationRule'),
            guard: SubscriptionPermissions.AUTOMATIONRULES,
          },
          // {
          //   title: this.$t('pages.automationRule')+"1",
          //   icon: 'mdi-ballot',
          //   path: '/arrules1',
          //   tooltip: this.$t('pages.automationRule'),
          // },
          {
            title: this.$t('pages.goals'),
            icon: 'mdi-checkbox-multiple-marked',
            path: '/goals',
            tooltip: this.$t('pages.goals'),
            guard: SubscriptionPermissions.GOALS,
          },
          {
            title: this.$t('pages.strategies'),
            icon: 'mdi-checkbox-multiple-marked',
            path: '/strategies',
            tooltip: this.$t('pages.strategies'),
            guard: SubscriptionPermissions.STRATEGIES,
          },
          //create campagin
          //   {
          //   title: this.$t('Campagin'),
          //   icon: ' mdi-bullhorn',
          //   path: '/campaign-view',
          //   tooltip: this.$t('Create campagin'),
          // },
          //TODO - Language
          // {
          //   title: this.$t('pages.campaignGenerator'),
          //   icon: ' mdi-bullhorn',
          //   path: '/campaign-generator',
          //   tooltip: this.$t('pages.campaignGenerator'),
          //   guard: SubscriptionPermissions.CAMPAIGNGENERATOR,
          // },
        ];

        if (decodeToken(LSS.token)) {
          if (decodeToken(LSS.token).Role == 'ADMIN') {
            items.push(...this.adminItems);
          }
        }

        if (decodeToken(LSS.token)) {
          if (decodeToken(LSS.token).Role == 'SUPERADMIN') {
            items.push(...this.adminItems);
            items.push(...this.superAdminItems);
          }
        }

        return items;
      },
      set() {
        const s = 5;
      },
    },
    adminItems: {
      cache: false,
      get() {
        return [
          // {
          //   title: this.$t('pages.teamMgmt'),
          //   icon: 'mdi-account-group',
          //   path: '/team-management',
          //   tooltip: this.$t('pages.teamMgmt'),
          //   guard: SubscriptionPermissions.TEAM,
          // },
        ];
      },
    },
    superAdminItems: {
      cache: false,
      get() {
        return [
          {
            title: 'Companies',
            icon: 'mdi-account-multiple-plus',
            path: '/users',
            tooltip: 'Companies',
          },
          {
            title: 'SA Panel',
            icon: 'mdi-account-multiple-plus',
            path: '/searchads-manage',
            tooltip: 'SA Panel',
          },
        ];
      },
    },
    orgitems: {
      cache: false,
      get() {
        return [
          {
            title: this.$t('menu.orgs'),
            icon: 'mdi-account-group',
            active: true,
            items: this.orgs,
          },
        ];
      },
    },
    menuitems: {
      cache: false,
      get() {
        const items = [
          {
            title: this.$t('menu.settings'),
            icon: 'mdi-account',
            path: '/settings',
          },
          {
            title: this.$t('pages.integrations'),
            icon: 'mdi-link-box-variant',
            path: '/integrations',
            tooltip: this.$t('pages.integrations'),
            guard: SubscriptionPermissions.ASAINTEGRATION,
          },

           {
            title: this.$t('pages.support'),
            icon: 'mdi-help-circle-outline',
            tooltip: this.$t('pages.support'),
            path:'https://support.searchadsmaven.com/?utm_source=marketing_site_menu',
            

          },

           {
            title: this.$t('menu.signOut'),
            icon: 'mdi-logout',
            path: '/logout',
          },
        ];

        if (decodeToken(LSS.token)) {
          if (decodeToken(LSS.token).Role == 'ADMIN') {
            items.splice(0, 0, ...this.adminMenuItems);
          }
        }
        if (decodeToken(LSS.token)) {
          if (decodeToken(LSS.token).Role == 'SUPERADMIN') {
          items.splice(0, 0, ...this.superAdminMenuItems);

          }
        }

        return items;
      },
    },

    adminMenuItems: {
      cache: false,
      get() {
        return [
          // {
          //   title: this.$t('menu.manageSubscription'),
          //   icon: 'mdi-account-edit',
          //   path: '/manage-subscription',
          // },
           {
            title: this.$t('pages.teamMgmt'),
            icon: 'mdi-account-group',
            path: '/team-management',
            tooltip: this.$t('pages.teamMgmt'),
            guard: SubscriptionPermissions.TEAM,
          },
        ];
      },
    },
    superAdminMenuItems: {
      cache: false,
      get() {
        return [
          {
            title: this.$t('pages.teamMgmt'),
            icon: 'mdi-account-group',
            path: '/team-management',
            tooltip: this.$t('pages.teamMgmt'),
            guard: SubscriptionPermissions.TEAM,
          },
        ];
      },
    },
  },
  
  async mounted() {
    moment.relativeTimeThreshold('h', 60);
    
   window.addEventListener('scroll', this.updateScroll);
    if (!LSS.asaInt) LSS.asaInt = false;
    else {
      await getOwnApps('navbar', this);
    }
    if(!LSS.firstSyncDone) LSS.firstSyncDone = false
    this.integrationsInfo().then(async () => {
        if(!LSS.asaInt) LSS.asaInt = false
        else{
          await getOwnApps('navbar', this)
        }
      }
    );
    this.getCurrencyInfo()

    this.TeamID = decodeToken(LSS.token).TeamID;
    this.$root.$emit('get-dr', 'nav');
    if (decodeToken(LSS.token)) {
      //console.log(decodeToken(LSS.token).picture);
      const fname = decodeToken(LSS.token).FirstName || 'S';
      const lname = decodeToken(LSS.token).LastName || 'N';
      this.name = fname[0]?.toUpperCase() + lname[0]?.toUpperCase();
    }
    if (decodeToken(LSS.token)) {
      /* const details = decodeToken(LSS.token);
      if(details?.picture?.filePath) {
        this.profilePic = details?.picture?.filePath;
      } else {
        const fname = details?.FirstName || 'S';
        const lname = details?.LastName || 'N';
        this.name = fname[0]?.toUpperCase() + lname[0]?.toUpperCase();
      } */
      if (decodeToken(LSS.token).Role == 'ADMIN') {
        this.items = [
          {
            title: this.$t('pages.overview'),
            icon: 'mdi-apps',
            path: '/overview',
            tooltip: this.$t('pages.overview'),
            guard: SubscriptionPermissions.OVERVIEW,
          },
          {
            title: this.$t('pages.adsManager'),
            icon: 'mdi-view-dashboard',
            path: '/ads-manager',
            tooltip: this.$t('pages.adsManager'),
            guard: SubscriptionPermissions.ADSMANAGER,
          },
          {
            title: this.$t('pages.kai'),
            icon: 'mdi-eye',
            path: '/keyword-auction-insights',
            tooltip: this.$t('pages.kai'),
            guard: SubscriptionPermissions.KEYWORDANALYSIS,
          },
          {
            title: this.$t('pages.keyOpt'),
            icon: 'mdi-pencil-box-multiple',
            path: '/keyword-optimization',
            tooltip: this.$t('pages.keyOpt'),
            guard: SubscriptionPermissions.KEYWORDOPTIMIZATION,
          },
          {
            title: this.$t('pages.teamMgmt'),
            icon: 'mdi-account-group',
            path: '/team-management',
            tooltip: this.$t('pages.teamMgmt'),
            guard: SubscriptionPermissions.TEAM,
          },
          {
            title: this.$t('pages.integrations'),
            icon: 'mdi-link-box-variant',
            path: '/integrations',
            tooltip: this.$t('pages.integrations'),
            guard: SubscriptionPermissions.ASAINTEGRATION,
          },
          {
            title: this.$t('pages.automationRule'),
            icon: 'mdi-ballot',
            path: '/arrules',
            tooltip: this.$t('pages.automationRule'),
            guard: SubscriptionPermissions.AUTOMATIONRULES,
          },
          {
            title: 'Goals',
            icon: 'mdi-checkbox-multiple-marked',
            path: '/goals',
            tooltip: 'Goals',
            guard: SubscriptionPermissions.GOALS,
          },
          {
            title: 'Strategies',
            icon: 'mdi-checkbox-multiple-marked',
            path: '/strategies',
            tooltip: 'Strategies',
            guard: SubscriptionPermissions.STRATEGIES,
          },
          //TODO - Language
          {
            title: 'Automated Campaign Configuration',
            icon: ' mdi-bullhorn',
            path: '/campaign-generator',
            tooltip: 'Automated Campaign Configuration',
            guard: SubscriptionPermissions.CAMPAIGNGENERATOR,
          },
        ];
      }
    }

    this.$root.$on('userDetailsUpdated', (userDetails) => {
      /* if(userDetails?.picture?.filePath) {
        this.profilePic = userDetails?.picture?.filePath;
      } else {
        this.profilePic = '';
        const fname = userDetails?.FirstName || 'S';
        const lname = userDetails?.LastName || 'N';
        this.name = fname[0]?.toUpperCase() + lname[0]?.toUpperCase();
      } */
      const fname = userDetails.FirstName || 'S';
      const lname = userDetails.LastName || 'N';
      this.name = fname[0]?.toUpperCase() + lname[0]?.toUpperCase();
      this.updateOrgs();
    });
    this.$root.$on('asaInt-fetched', (a) => {
      this.asaIntegrationPreset = a;
    });
    this.$root.$on('userNameFetched', (userDetails) => {
      /* if(userDetails?.picture?.filePath) {
        this.profilePic = userDetails?.picture?.filePath;
      } else {
        this.profilePic = '';
        const fname = userDetails?.FirstName || 'S';
        const lname = userDetails?.LastName || 'N';
        this.name = fname[0]?.toUpperCase() + lname[0]?.toUpperCase();
      } */
      const fname = userDetails.FirstName || 'S';
      const lname = userDetails.LastName || 'N';
      this.name = fname[0]?.toUpperCase() + lname[0]?.toUpperCase();
      this.updateOrgs();
    });
    this.$root.$on('new-ds-progress', (p) => {
      this.drProgress = p;
    });
    if (localStorage.getItem('locale')) {
      for (const locale of this.locales) {
        if (locale.localeCode == localStorage.getItem('locale')) {
          this.selectedLocale = locale;
        }
      }
    }
    this.updateOrgs();

    //// Create Chargebee Session
    const el = document.createElement('script');
    el.onload = () => {
      var chargebeeInstance = Chargebee.init({
        site: CB_SITE_NAME,
      });

      chargebeeInstance.setPortalSession(async () => {
        // we have used axios for performing http requests
        // Hit your end point that returns portal session object as response
        // This sample end point will call the below api
        // https://apidocs.chargebee.com/docs/api/portal_sessions#create_a_portal_session
        const portalSession = await this.createPortalSession();
        return portalSession;
      });
    };

    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
    document.body.appendChild(el);

    ////

    // switch (this.$vuetify.breakpoint.name) {
    //   case 'xs':
    //     return (this.mini = true);
    //   case 'sm':
    //     return (this.mini = true);
    //   case 'md':
    //     return (this.mini = true);
    // }

    /* this.fetchChartDatasetPresets();
    this.fetchTableDatasetPresets(); */
    Vue.prototype.$sckt.on('delInt:process', async (testRunEvent) => {
      
      if(testRunEvent.message && testRunEvent.message == 'success') {
        // this.$root.$emit('ASA-Int-Del', this.integrationDetailsForDeletion);
        this.$notify.success('Integration deleted successfully');
        await getOwnApps('asadel', this)
        this.$root.$emit('asaIntegrationDeleted', '')
      }
    });
  },
  beforeDestroy(){
    window.removeEventListener('scroll', this.updateScroll);
    this.$root.$off('userNameFetched');
    this.$root.$off('userDetailsUpdated');
    this.$root.$off('asaInt-fetched');
    this.$root.$off('new-ds-progress');
  }
});
