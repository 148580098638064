// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import * as _ from 'lodash';
import { LSS } from '@core/services';
import { decodeToken } from '@shared/utils/commom.util';
import { apolloClient } from '../apollo/apollo-client';
import { CheckListFields } from '@shared/enums/shared.enums';
import { LoginService } from '@services/login.service';
import { UPDATE_CHECKLIST } from './apptweakqueries';
import { Text } from '@codemirror/state';
import { javascriptLanguage } from '@codemirror/lang-javascript';
import Vue from 'vue';
import { OWN_APPS, APP_METADATA } from '../utils/apptweakqueries';

export class DocInput {
  doc;
  length;
  cursorPos;
  string;
  cursor;
  constructor(doc, length = doc.length) {
    this.doc = doc;
    this.length = length;
    this.cursorPos = 0;
    this.string = '';
    this.cursor = doc.iter();
  }
  syncTo(pos) {
    this.string = this.cursor.next(pos - this.cursorPos).value;
    this.cursorPos = pos + this.string.length;
    return this.cursorPos - this.string.length;
  }
  chunk(pos) {
    this.syncTo(pos);
    return this.string;
  }

  get lineChunks() {
    return true;
  }

  read(from, to) {
    const stringStart = this.cursorPos - this.string.length;
    if (from < stringStart || to >= this.cursorPos) return this.doc.sliceString(from, to);
    else return this.string.slice(from - stringStart, to - stringStart);
  }
}

export function parse(code: string) {
  const nodes = [];
  const parseTree = javascriptLanguage.parser.parse(new DocInput(Text.of([code])));

  parseTree.cursor().iterate((node) => {
    nodes.push(_.cloneDeep(node));
  });

  return nodes;
}

export function decodeTokenF(token: string): any {
  try {
    return JSON.parse(atob(token.split('.')[1])).userDetails;
  } catch (e) {
    return {};
  }
}

export const updateCLF = (param: string, that: Vue): any => {
  try {
    if (LSS.token && decodeToken(LSS.token).checkList) {
      if (decodeToken(LSS.token).checkList[param]) {
        return;
      }
    } else {
      return;
    }
    apolloClient
      .query({
        query: UPDATE_CHECKLIST,
        variables: {
          userId: decodeToken(LSS.token).UserId,
          clfield: CheckListFields[param],
          time: Date.now(),
        },
      })
      .then(async (res) => {
        if (res.data.updateCL) {
          if (res.data.updateCL.code == 1) {
            if (LSS.adminToken) return;
            await getUserDetails(decodeToken(LSS.token).UserId, that);
          }
        }
      })
      .catch((error) => {});
  } catch (e) {
    return;
  }
};

async function getUserDetails(UserID, that) {
  const token = await LoginService.getUserDetails({
    UserID: UserID,
    SessId: null,
  });
  const user = decodeToken(token);
  LSS.token = token;
  that.$root.$emit('userNameFetched', user);
  return user;
}

export async function getOwnApps(from: string, that: Vue) {
  if (Object.keys(decodeToken(LSS.token)).length > 0) {
    await apolloClient
      .query({
        query: OWN_APPS,
        variables: {
          from: from,
          at: Date.now(),
        },
      })
      .then((res) => {
        const apps = res.data.getAllOwnApps;
        appListing(apps, that);
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

async function appListing(apps, that) {
  for (const app of apps) {
    await apolloClient
      .query({
        query: APP_METADATA,
        variables: {
          appId: app.adamId,
          location: 'us',
          language: 'en',
          device: 'iphone',
          startDate: Date.now().toString(),
          endDate: new Date().toISOString().substr(0, 10),
        },
      })
      .then((data) => {
        if (data.data.getAppMetadata == null || data.data.getAppMetadata == undefined) {
          return;
        }
        for (let i = 0; i < apps.length; i++) {
          if (apps[i].adamId == app.adamId) {
            apps[i].appIcon = data.data.getAppMetadata.content.icon;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  LSS.ownApps = [];
  LSS.ownApps = apps?.filter((e) => e.adamId != 0);
  that.$root.$emit('apps-refreshed', true);
}

export function numberFormat(number: number, onlyRound?: boolean): string {
  if (!number) return 0;
  if (onlyRound) return Number(number);
  return Number(number).toLocaleString('en-US');
}

export function numberDecimal(number: number, onlyRound?: boolean): string {
  if (!number) {
    // return 0 ;
    return Number(0).toFixed(2);
  }

  if (onlyRound) return Number(number);

  return Number(number).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function isReadOnly() {
  if (LSS.token && decodeToken(LSS.token).IsReadOnly) {
    return true;
  } else {
    return false;
  }
}
