
















































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { HttpService, LSS } from '@core/services';
import { CB_SITE_NAME, SAM_CLIENT_VERSION } from '@config';
import { decodeToken } from '@shared/utils/commom.util';
import { LSS } from '../core/services';
import { LoginService } from '@services/login.service';
import { bus } from '../main';
import { BusEvents } from '../utils/enums';
import { TRIGGER_MANUAL_SYNC, SWITCH_TEAM } from '../utils/apollo-queries';
import { error } from 'console';
import { ChargebeeService } from '@services/chargebee.service';
import GuidedTourWizard from './on-boarding/GuidedTourWizard.vue';

export default Vue.extend({
  name: 'navbar',
  components: {
    GuidedTourWizard
  },
  data() {
    return {
      loggedInAs: null,
      profilePic: '',
      errorBanner: false,
      infoBanner: false,
      orgs: [],
      selectedOrg: 'Org',
      isSelected: true,
      existDR: false,
      triggerDR: null,
      drProgress: 0,
      syncing: false,
      syncProgress: 0,
      isNavbarExpanded: false,
      drawer: true,
      menu: false,
      mini: false,
      name: 'Ninja',
      locales: [
        { flag: 'us', text: 'EN', localeCode: 'en' },
        { flag: 'de', text: 'DE', localeCode: 'de' },
        { flag: 'es', text: 'ES', localeCode: 'es' },
        { flag: 'kr', text: 'KO', localeCode: 'ko' },
        { flag: 'cn', text: 'ZH', localeCode: 'zh' },
        { flag: 'jp', text: 'JA', localeCode: 'ja' },
        { flag: 'fr', text: 'FR', localeCode: 'fr' },
      ],
      selectedLocale: { flag: 'us', text: 'EN', localeCode: 'en' },
      fullscreenLoader : null,
      TeamID: null
    };
  },
  watch: {
    errorBanner(to, from) {
      this.setbannerView();
    },
    infoBanner() {
      this.setbannerView();
    },
    async $route(to, from) {
      Vue.prototype.$sckt.on('connect', () => {

            Vue.prototype.$sckt.emit('syncprogress:check', {
              teamId: decodeToken(LSS.token).TeamID,
              CorrID: uuidv4()
            });
          });

          Vue.prototype.$sckt.on('syncprogress:event', (progressEvent) => {
            // console.log('syncprogress:event-navbar', progressEvent)
            this.syncing = !progressEvent.done;

            if(progressEvent.lastSyncAt) this.lastSyncAt = progressEvent.lastSyncAt

            if (progressEvent.error) {
              if (progressEvent.errCode === 'INVALID_INTEGRATION')
                this.errorBanner = true;
            } else {
              this.errorBanner = false;
              this.syncProgress = progressEvent.progress;

              if (Number(this.syncProgress) === 100) {
                  bus.$emit(BusEvents.DATA_PULLED);
                }
            }
        });

        if(Vue.prototype.$sckt.connected) {
          Vue.prototype.$sckt.emit('syncprogress:check', {
            teamId: decodeToken(LSS.token).TeamID,
            CorrID: uuidv4()
          });
        }
      this.setbannerView();

      if (LSS.adminToken) {
        this.loggedInAs = decodeToken(LSS.token);
        this.infoBanner = true;
      } else {
        this.loggedInAs = null;
      }
    },
    '$route.query.integrationAlreadyPresent': {
      handler(to, from) {
        if(to) {
          this.pullData()
        }
      },
      deep: true
    },
    '$route.query.integrated': {
      handler(to, from) {
        if(to && Vue.prototype.$sckt) {
          // console.log('syncprogress:event-nav-emitting')
          Vue.prototype.$sckt.emit('syncprogress:check', { teamId: decodeToken(LSS.token).TeamID, CorrID: uuidv4() });
        }
      },
      deep: true
    },
    mini(val) {
      bus.$emit(BusEvents.NAV_TOGGLE, val)
    }
  },
  methods: {
    async returnToAdmin() {
      LSS.token = LSS.adminToken;
      LSS.adminToken = '';
      if (!this.$route.fullPath.includes('overview')) {
        await this.$router.push('/overview');
      }
      window.location.reload();
    },
    setbannerView() {
      setTimeout(() => {
        const dwb = document.querySelector('.dashboard-with-banner');
        const pwb = document.querySelector('.page-header-with-banner');
        if(this.errorBanner || this.infoBanner) {
          dwb?.style?.setProperty('margin-top', '33px', 'important');
          pwb?.style?.setProperty('margin-top', '33px', 'important');
        } else {
          dwb?.style?.setProperty('margin-top', '0px', 'important');
          pwb?.style?.setProperty('margin-top', '0px', 'important');
        }
      }, 0);
    },
    clearDraftId() {
      LSS.clearOther();
    },
    version() {
      return SAM_CLIENT_VERSION;
    },
    switchLocale(locale) {
      this.selectedLocale = locale;
      this.$root.$i18n.locale = locale.localeCode;
      localStorage.setItem('locale', locale.localeCode);
      this.$root.$emit('locale-changed', locale)
    },
    async pullData() {
      this.syncing = true;
      this.syncProgress = 0;
      try {
        const result = await this.$apollo.query({
          query: TRIGGER_MANUAL_SYNC
        });

        if(result.data.invokeManualSync.successMessage) {
          this.$notify.success(this.$t('successMsg.syncStarted'));

          if(result.data.invokeManualSync.data) {
            const syncId = JSON.parse(result.data.invokeManualSync.data).syncId;

            Vue.prototype.$sckt.emit('syncprogress:check', { teamId: decodeToken(LSS.token).TeamID, syncId, CorrID: uuidv4() });
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    menuClick(path) {
      this.menu = false;
      this.$router.push({ path: path });
    },
    async getUserDetails(UserID, item) {
      this.fullscreenLoader = this.$loading.show();
      const token = await LoginService.getUserDetails({
        UserID: UserID,
        SessId: null,
        switchTo: {
          TeamId : item.TeamId,
          Role: item.Role,
          Company: item.Company,
        }
      }).catch(err => {
        console.log(err)
        alert('Server did not respond')
        this.fullscreenLoader.hide();
        return
      });
      const user = decodeToken(token);
      LSS.token = token
      this.$root.$emit('userNameFetched', user);
      this.fullscreenLoader.hide();
      this.$root.$emit('org-changed', true)
      this.updateOrgs()
      LSS.company = item.Company
      this.selectedOrg = item.Company
      return user;
    },
    async switchOrg(item){
      if(decodeToken(LSS.token).TeamID == item.TeamId) return
      await this.getUserDetails(decodeToken(LSS.token).UserId, item);
      if(!this.$route.fullPath.includes("overview"))
      {
        await this.$router.push('/overview')
      }
      window.location.reload();
    },
    async updateOrgs(){
      if(LSS.token){
        if(decodeToken(LSS.token).teams && (decodeToken(LSS.token).teams).length != 0){
          this.orgs = decodeToken(LSS.token).teams
        }else{
          this.orgs = [{
            IsDisabled:false,
            CreatedOn:new Date(),
            Company: decodeToken(LSS.token).CompanyName,
            Role: decodeToken(LSS.token).Role,
            TeamId: decodeToken(LSS.token).TeamID,
            IsReadOnly: false,
          }]
        }
        this.setCompany(decodeToken(LSS.token).CompanyName)
      }
    },
    setCompany(company){
      LSS.company = company
      this.selectedOrg = company
    },

    createPortalSession() {
      return ChargebeeService.createPortalSession();
    },
  },

  computed: {
    isNavbar: {
      cache: false,
      get() {
        return this.$route.name && ![
        'WelcomeMessage',
        'Login',
        'onboard-fork',
        'onboard',
        'signin',
        'signup',
        'logout',
        'verify',
        'forgot-password',
        'change-password',
        'sa-integration',
      ].includes(this.$route.name);
      }
    },
    isInSetup() {
      return this.$route.name == 'Setup';
    },
    items: {
      cache: false,
      get() {
        const items = [
          {
            title: this.$t('pages.overview'),
            icon: 'mdi-apps',
            path: '/overview',
            tooltip: this.$t('pages.overview'),
          },
          {
            title: this.$t('pages.adsManager'),
            icon: 'mdi-view-dashboard',
            path: '/ads-manager',
            tooltip: this.$t('pages.adsManager'),
          },
          {
            title: this.$t('pages.kai'),
            icon: 'mdi-keyboard',
            path: '/keyword-auction-insights',
            tooltip: this.$t('pages.kai'),
          },
          {
            title: this.$t('pages.keyOpt'),
            icon: 'mdi-pencil-box-multiple',
            path: '/keyword-optimization',
            tooltip: this.$t('pages.keyOpt'),
          },
          {
            title: this.$t('pages.integrations'),
            icon: 'mdi-link-box-variant',
            path: '/integrations',
            tooltip: this.$t('pages.integrations'),
          },
          {
            title: this.$t('pages.automationRule'),
            icon: 'mdi-ballot',
            path: '/arrules',
            tooltip: this.$t('pages.automationRule'),
          },
          //TODO - Language
          // {
          //   title: 'App Settings',
          //   icon: 'mdi-table-cog',
          //   path: '/app-settings',
          //   tooltip: 'App Settings',
          // },
        ];

        if (decodeToken(LSS.token)) {
          if (decodeToken(LSS.token).Role == 'ADMIN') {
            items.push(...this.adminItems);
          }
        }

        if (decodeToken(LSS.token)) {
          if (decodeToken(LSS.token).Role == 'SUPERADMIN') {
            items.push(...this.adminItems);
            items.push(...this.superAdminItems);
          }
        }

        return items;
      },
      set() {
        const s = 5;
      },
    },
    adminItems: {
      cache: false,
      get() {
        return [
          {
            title: this.$t('pages.teamMgmt'),
            icon: 'mdi-account-group',
            path: '/team-management',
            tooltip: this.$t('pages.teamMgmt'),
          },
        ];
      },
    },
    superAdminItems: {
      cache: false,
      get() {
        return [
          {
            title: 'Users',
            icon: 'mdi-account-multiple-plus',
            path: '/users',
            tooltip: 'Companies',
          },
        ];
      },
    },
    orgitems: {
      cache: false,
      get() {
        return [
          {
            title: 'Orgs',
            icon: 'mdi-account-group',
            active: true,
            items: this.orgs
          },
        ]
      }
    },
    menuitems: {
      cache: false,
      get() {
        return [

          {
            title: this.$t('menu.settings'),
            icon: 'mdi-account',
            path: '/settings',
          },
          {
            title: this.$t('menu.signOut'),
            icon: 'mdi-logout',
            path: '/logout',
          },
        ];
      },
    },

    // mini() {
    //   switch (this.$vuetify.breakpoint.name) {
    //     case 'xs': return true
    //     case 'sm': return true
    //     case 'md': return true
    //     case 'lg': return false
    //     case 'xl': return false
    //   }
    // },

  },
  async mounted() {
    this.TeamID = decodeToken(LSS.token).TeamID;

    /* if (decodeToken(LSS.token)) {
      const details = decodeToken(LSS.token);
      if(details?.picture?.filePath) {
        this.profilePic = details?.picture?.filePath;
      } else {
        const fname = details?.FirstName || 'S';
        const lname = details?.LastName || 'N';
        this.name = fname[0]?.toUpperCase() + lname[0]?.toUpperCase();
      }
    } */
    if (decodeToken(LSS.token)) {
      const fname = decodeToken(LSS.token).FirstName || 'S';
      const lname = decodeToken(LSS.token).LastName || 'N';
      this.name = fname[0]?.toUpperCase() + lname[0]?.toUpperCase();
      this.profilePic = decodeToken(LSS.token).picture || '';
    }
    if (decodeToken(LSS.token)) {
      if (decodeToken(LSS.token).Role == 'ADMIN') {
        this.items = [
          {
            title: this.$t('pages.overview'),
            icon: 'mdi-apps',
            path: '/overview',
            tooltip: this.$t('pages.overview'),
          },
          {
            title: this.$t('pages.adsManager'),
            icon: 'mdi-view-dashboard',
            path: '/ads-manager',
            tooltip: this.$t('pages.adsManager'),
          },
          {
            title: this.$t('pages.kai'),
            icon: 'mdi-eye',
            path: '/keyword-auction-insights',
            tooltip: this.$t('pages.kai'),
          },
          {
            title: this.$t('pages.keyOpt'),
            icon: 'mdi-pencil-box-multiple',
            path: '/keyword-optimization',
            tooltip: this.$t('pages.keyOpt'),
          },
          {
            title: this.$t('pages.teamMgmt'),
            icon: 'mdi-account-group',
            path: '/team-management',
            tooltip: this.$t('pages.teamMgmt'),
          },
          {
            title: this.$t('pages.integrations'),
            icon: 'mdi-link-box-variant',
            path: '/integrations',
            tooltip: this.$t('pages.integrations'),
          },
          {
            title: this.$t('pages.automationRule'),
            icon: 'mdi-ballot',
            path: '/arrules',
            tooltip: this.$t('pages.automationRule'),
          },
          //TODO - Language
          // {
          //   title: 'App Settings',
          //   icon: 'mdi-table-cog',
          //   path: '/app-settings',
          //   tooltip: 'App Settings',
          // },
        ];
      }
    }

    this.$root.$on('userDetailsUpdated', (userDetails) => {
      /* if(userDetails?.picture?.filePath) {
        this.profilePic = userDetails?.picture?.filePath;
      } else {
        const fname = userDetails?.FirstName || 'S';
        const lname = userDetails?.LastName || 'N';
        this.name = fname[0]?.toUpperCase() + lname[0]?.toUpperCase();
      } */
      const fname = userDetails.FirstName || 'S';
      const lname = userDetails.LastName || 'N';
      this.name = fname[0]?.toUpperCase() + lname[0]?.toUpperCase();
      this.updateOrgs()
    });
    this.$root.$on('userNameFetched', (userDetails) => {
      /* if(userDetails?.picture?.filePath) {
        this.profilePic = userDetails?.picture?.filePath;
      } else {
        this.profilePic = '';
        const fname = userDetails?.FirstName || 'S';
        const lname = userDetails?.LastName || 'N';
        this.name = fname[0]?.toUpperCase() + lname[0]?.toUpperCase();
      } */
      const fname = userDetails.FirstName || 'S';
      const lname = userDetails.LastName || 'N';
      this.name = fname[0]?.toUpperCase() + lname[0]?.toUpperCase();
      this.updateOrgs()
    });
    if (localStorage.getItem('locale')) {
      for (const locale of this.locales) {
        if (locale.localeCode == localStorage.getItem('locale')) {
          this.selectedLocale = locale;
        }
      }
    }
    this.updateOrgs()

    //// Create Chargebee Session
    const el = document.createElement('script');
    el.onload = () => {
      var chargebeeInstance = Chargebee.init({
        site: CB_SITE_NAME,
      });

      chargebeeInstance.setPortalSession(async () => {
        // we have used axios for performing http requests
        // Hit your end point that returns portal session object as response
        // This sample end point will call the below api
        // https://apidocs.chargebee.com/docs/api/portal_sessions#create_a_portal_session
        const portalSession = await this.createPortalSession();
        return portalSession;
      });
    };

    el.setAttribute('src', 'https://js.chargebee.com/v2/chargebee.js');
    document.body.appendChild(el);

    ////
    }

}
);
