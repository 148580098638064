import { IAdGroup, ICampaignReq, ISuccess } from '@shared/interfaces';
import { joinUrl, replaceParamsWithValues } from '@shared/utils';

import { API_BASE_URL } from '@config';
import { CAMPAIGN_ROUTES, INTEGRATION_ROUTES } from '@shared/config';
import { HttpService } from '@core/services';

/**
 * @class IntegrationService
 * @description service class for handling integration related operations.
 */
export class IntegrationService {
  private static get url() {
    return joinUrl(API_BASE_URL as string, INTEGRATION_ROUTES.noVBase);
  }

  /**
   * @function
   * @name getIntegration
   * @description launches a get request to get all the integrations linked with the account
   * @param userID
   * @returns {Promise<ISuccess>} success response.
   */
  public static async getIntegration(userID: string): Promise<ISuccess> {
    const response = await HttpService.get<ISuccess>(joinUrl(this.url, userID));
    return response;
  }

  public static async deleteIntegration(identifier: string): Promise<ISuccess> {
    const response = await HttpService.delete<ISuccess>(joinUrl(this.url),identifier );
    return response;
  }

  /**
   * @function
   * @name getASAIntegrationsState
   * @description launches a get request to get integrations states such as invalid, or incorrect permissions
   * @param userID
   * @returns {Promise<ISuccess>} success response.
   */
  public static async getASAIntegrationsState(): Promise<any[]> {
    const response = await HttpService.get<any[]>(joinUrl(this.url, INTEGRATION_ROUTES.getIntegrationsState));
    return response;
  }
}
