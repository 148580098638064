export enum AdsManagerTabs {
  STRATEGIES = 'strategies_tab',
  ACCOUNTS = 'accounts_tab',
  APPS = 'apps_tab',
  CAMPAIGNS = 'campaigns_tab',
  AD_GROUPS = 'ad_groups_tab',
  KEYWORDS = 'keywords_tab',
  CREATIVE_SETS = 'creative_sets_tab',
  SEARCH_TERMS = 'search_terms_tab',
  NEG_KEYWORDS = 'neg_keywords_tab',
  PRODUCT_PAGES = 'product_page_tab',
}

export enum AdsManagerFilters {
  ACCOUNT = 'account_filter',
  APPS = 'apps_filter',
  CAMPAIGN = 'campaign_filter',
  ADGROUP = 'adgroup_filter',
  KEYWORD = 'keyword_filter',
  AD = 'ad_filter',
}

export enum BusEvents {
  DATA_PULLED = 'DATA_PULLED',
  NAV_TOGGLE = 'NAV_TOGGLE',
  PIC_CHANGED = 'PIC_CHANGED',
}

/**
 * enum for AppDownloaderCriteria https://developer.apple.com/documentation/apple_search_ads/appdownloadercriteria
 */

export enum CustomerTypes {
  ALL = 0,
  NEW = 1,
  OTHER_APPS = 2,
  EXISITING = 3,
}

export enum Genders {
  ALL = 0,
  MALE = 'M',
  FEMALE = 'F',
}

export enum CampaignCreationSteps {
  APP_SELECTION = 1,
  COUNTRIES_REGIONS,
  AD_GROUP,
  SEARCH_MATCH,
  KEYWORDS,
  AUDIENCE,
  CREATIVE_SETS,
}

export enum MaxCPTBidOptions {
  ADGROUP_DEFAULT = 1,
  CUSTOM = 2,
}

export enum AdGroupSelectorContext {
  KEYWORD = 'keyword',
  CREATIVESET = 'creativeset',
}

export enum Roles {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  STAFF = 'STAFF',
}

//enum for Mobile Measurement partners
export enum MMP {
  KOCHAVA_NAME = 'Kochava',
  KOCHAVA_ID = 'KOCHAVA',
  BRANCH_NAME = 'branch',
  BRANCH_ID = 'BRANCH',
  CUSTOM_NAME = 'custom',
  CUSTOM_ID = 'CUSTOM',
  ADJUST_ID = 'ADJUST',
  ADJUST_NAME = 'Adjust',
  APPSFLYER_ID = 'APPSFLYER',
  APPSFLYER_NAME = 'AppsFlyer',
  SINGULAR_ID = 'SINGULAR',
  SINGULAR_NAME = 'Singular'
}

export enum CriteriaOptions {
  GREATER_THAN = 'GREATER_THAN',
  LESS_THAN = 'LESS_THAN',
  IN_RANGE = 'IN_RANGE',
  EQUALS = 'EQUALS',
}

export enum AutomateCampaignType {
  BRAND = 'Brand',
  CATEGORY = 'Category',
  DISCOVERY = 'Discovery',
  COMPETITOR = 'Competitor',
  CUSTOM = 'Custom',
}
