//Spanish
export const es = {
    pages: {
        overview: 'Descripción general',
        adsManager: 'Administrador de anuncios',
        kai: 'Análisis de palabras clave',
        keyOpt: 'Optimización de palabras clave',
        teamMgmt: 'Equipo',
        integrations: 'Integraciones',
        automationRule: 'Automatización',
        campaignGenerator: 'Generador de campañas',
        goals: 'Goles',
        appSettings: 'Configuración de la aplicación',
        kar: 'Solicitudes de análisis de palabras clave',
        manualCampaign:'Agregar campaña manual',
        automatedCampaign:'Agregar campaña automatizada',
    },
    menu: {
        settings: 'Ajustes',
        signOut: 'Desconectar',
        manageSubscription: 'Administrar suscripción',
        orgs: 'Organizaciones'
    },
    buttons: {
        syncData: 'Sincronizar los datos más recientes',
        createCampaign: 'Crear campaña',
        filter: 'Filtrar',
        reset: 'Reiniciar',
        apply: 'Solicitar'
    },
    labels: {
        charts: 'Gráficos',
        selectDs: 'Seleccionar conjuntos de datos',
        totalSummary: 'Resumen total',
        spend: 'Gastar',
        search: 'Buscar',
        searchByKeySearchMsg: 'Escribe un texto y presiona enter',
        competeSearchMsg : 'Seleccionar o buscar una aplicación',
        selectCategoryMsg: 'Seleccione una categoría',
        selectCategoryType: 'Seleccione tipo',
        matchType: 'Tipo de concordancia',
        filterItem: 'Escriba aquí para filtrar los siguientes elementos',
        cusType: 'Tipos de clientes',
        createRule: 'Crear regla para',
        fromSelected: 'De seleccionado',
    mmYY: 'MM / AA'   ,
    enterKey: 'Ingrese palabras claves' ,
    selectLevel: 'Selecciona el nivel',
    org: 'Organización (es)',
    app: 'Aplicación (es)',
    camp: 'Campaña (s)',
    adGroup: 'Grupo (s) de anuncios',
    key: 'Palabra (s) clave',
    searchTerm: 'Término (s) de búsqueda',
    yourApps: 'Tus Aplicaciones',
    suggestedApps: 'Aplicaciones sugeridas',
    },
    dropdowns: {
        noData: 'Datos no disponibles',
        daily: 'DIARIA',
        weekly: 'SEMANAL',
        monthly: 'MENSUAL'
    },
    tabs: {
        accounts: 'Cuentas',
        apps: 'Aplicaciones',
        campaigns: 'Campañas',
        adgroups: 'Grupos de anuncios',
        keywords: 'Palabras clave',
        creativeSets: 'Conjuntos creativos',
        searchTerms: 'Términos de búsqueda',
        negKeywords: 'Palabras clave negativas',
        ad: 'Anuncio'
    },
    summary: {
        taps: 'Grifos',
        impressions: 'Impresiones',
        ttr: 'Tasa de derivación',
        newDownloads: 'Nuevas descargas',
        reDownloads: 'Re-descargas',
        installs: 'Instala',
    },
    accountHeaders: {
        account: 'Cuenta',
        spend: 'Gastar',
        averageCpa: 'Promedio CPA',
        averageCpt: 'Promedio CPT',
        impressions: 'Impresiones',
        taps: 'Grifos',
        installs: 'Instala',
        ttr: 'Tasa de derivación',
        conversionRate: 'Tasa de conversión',
        newDownloads: 'Nuevas descargas',
        reDownloads: 'Re-descargas'
    },
    appsHeaders: {
        appName: 'Solicitud',
        spend: 'Gastar',
        averageCpa: 'Promedio CPA',
        averageCpt: 'Promedio CPT',
        impressions: 'Impresiones',
        taps: 'Grifos',
        installs: 'Instala',
        ttr: 'Tasa de derivación',
        conversionRate: 'Tasa de conversión',
        attrInstall: 'Instalación de atributos',
        attrInstallRate: 'Tasa de instalación de atributos',
        cpi: 'IPC',
        goals: 'Metas',
        costPerGoal: 'Costo por objetivo',
        goalRate: 'Tasa de objetivo',
        revenuePerGoal: 'Ingresos por objetivo',
        goalsRevenue: 'Ingresos de objetivos',
        goalsRoas: 'ROAS de objetivos',
        newDownloads: 'Nuevas descargas',
        reDownloads: 'Re-descargas',
        account: 'Cuenta',
        categoryRank: 'Rango de categoría',
        appId: 'ID de aplicación',
        totalCamp: 'Campañas totales',
        goalAMPU: 'Objetivo AMPU'
    },
    campaignHeaders: {
        campaign: 'Campaña',
        campaignStatus: 'Estado de la campaña',
        spend: 'Gastar',
        averageCpa: 'Promedio CPA',
        averageCpt: 'Promedio CPT',
        impressions: 'Impresiones',
        taps: 'Grifos',
        installs: 'Instala',
        newDownloads: 'Nuevas descargas',
        reDownloads: 'Re-descargas',
        ttr: 'Tasa de derivación',
        conversionRate: 'Tasa de conversión',
        attrInstallRate: 'Tasa de instalación de atributos',
        cpi: 'IPC',
        goals: 'Metas',
        costPerGoal: 'Costo por objetivo',
        goalRate: 'Tasa de objetivo',
        revenuePerGoal: 'Ingresos por objetivo',
        goalsRoas: 'ROAS de objetivos',
        storefronts: 'Escaparates',
        account: 'Cuenta',
        displayStatus: 'Estado de la pantalla',
        appName: 'Nombre de la aplicación',
        appId: 'ID de aplicación',
        campaignId: 'ID de campaña',
        delete: 'Borrar',
        totalBudget: 'Presupuesto total',
        dailyCap: 'Límite diario',
        supplySource:'Escribe'
    },
    campaignDraftHeaders: {
        campaignName: 'Nombre de campaña',
        countries: 'Países',
        budgetAmount: 'Cantidad de presupuesto',
        resume: 'Reanudar',
    },
    adGroupHeaders: {
        adgroup: 'Grupo de anuncios',
        adgroupStatus: 'Estado del Grupo de anuncios',
        spend: 'Gastar',
        averageCpa: 'Promedio CPA',
        averageCpt: 'Promedio CPT',
        impressions: 'Impresiones',
        taps: 'Grifos',
        installs: 'Instala',
        newDownloads: 'Nuevas descargas',
        reDownloads: 'Re-descargas',
        ttr: 'Tasa de derivación',
        conversionRate: 'Tasa de conversión',
        attrInstallRate: 'Tasa de instalación de atributos',
        cpi: 'IPC',
        goals: 'Metas',
        costPerGoal: 'Costo por objetivo',
        goalRate: 'Tasa de objetivo',
        revenuePerGoal: 'Ingresos por objetivo',
        goalsRoas: 'ROAS de objetivos',
        storefronts: 'Escaparates',
        campaignStatus: 'Estado de la campaña',
        displayStatus: 'Estado de la pantalla',
        account: 'Cuenta',
        appId: 'ID de aplicación',
        campaignId: 'ID de campaña',
        adgroupId: 'ID de Grupo de anuncios'
    },
    keyHeaders: {
        keyword: 'Palabra clave',
        keywordStatus: 'Estado de la palabra clave',
        bidAmount: 'Importe de la oferta',
        spend: 'Gastar',
        averageCpa: 'Promedio CPA',
        averageCpt: 'Promedio CPT',
        impressions: 'Impresiones',
        taps: 'Grifos',
        installs: 'Instala',
        newDownloads: 'Nuevas descargas',
        reDownloads: 'Re-descargas',
        ttr: 'Tasa de derivación',
        conversionRate: 'Tasa de conversión',
        attrInstallRate: 'Tasa de instalación de atributos',
        cpi: 'IPC',
        goals: 'Metas',
        costPerGoal: 'Costo por objetivo',
        goalRate: 'Tasa de objetivo',
        revenuePerGoal: 'Ingresos por objetivo',
        goalsRevenue: 'Ingresos de objetivos',
        storefronts: 'Escaparates',
        campaignStatus: 'Estado de la campaña',
        adgroupStatus: 'Estado del Grupo de anuncios',
        organicRank: 'Rango orgánico',
        appName: 'Nombre de la aplicación',
        difficultyScore: 'Puntuación de dificultad',
        popularity: 'Popularidad',
        keywordId: 'ID de palabra clave',
        appId: 'ID de aplicación',
        campaignId: 'ID de campaña',
        adgroupId: 'ID de Grupo de anuncios',
        suggestedMinBid: 'ASA Oferta mínima sugerida',
        suggestedMaxBid: 'ASA Oferta máxima sugerida',
        bidStrength: 'Fuerza de oferta',
        latOff: 'LAT Off-Installationen',
        latOn: 'LAT en instalaciones',
        matchType: 'Tipo de concordancia',
    },
    adsHeaders: {
      ads: 'Anuncio',
      creativeType: 'Tipo de creatividad',
      creativeSetStatus: 'Estado',
      spend: 'Gastar',
      averageCpa: 'Promedio CPA',
      averageCpt: 'Promedio CPT',
      impressions: 'Impresiones',
      taps: 'Grifos',
      installs: 'Instala',
      newDownloads: 'Nuevas descargas',
      reDownloads: 'Re-descargas',
      ttr: 'Tasa de derivación',
      conversionRate: 'Tasa de conversión',
      attrInstallRate: 'Tasa de instalación de atributos',
      cpi: 'IPC',
      goals: 'Metas',
      costPerGoal: 'Costo por objetivo',
      goalRate: 'Tasa de objetivo',
      revenuePerGoal: 'Ingresos por objetivo',
      goalsRevenue: 'Ingresos de objetivos',
      storefronts: 'Escaparates',
      campaignStatus: 'Estado de la campaña',
      adgroupStatus: 'Estado del Grupo de anuncios',
      account: 'Cuenta',
      appId: 'ID de aplicación',
      campaignId: 'ID de campaña',
      adgroupId: 'ID de Grupo de anuncios',
      action:'Acción',
      delete: 'Borrar'
  },
    creativeHeaders: {
        creativeSet: 'Conjunto creativo',
        creativeSetStatus: 'Estado',
        spend: 'Gastar',
        averageCpa: 'Promedio CPA',
        averageCpt: 'Promedio CPT',
        impressions: 'Impresiones',
        taps: 'Grifos',
        installs: 'Instala',
        newDownloads: 'Nuevas descargas',
        reDownloads: 'Re-descargas',
        ttr: 'Tasa de derivación',
        conversionRate: 'Tasa de conversión',
        attrInstallRate: 'Tasa de instalación de atributos',
        cpi: 'IPC',
        goals: 'Metas',
        costPerGoal: 'Costo por objetivo',
        goalRate: 'Tasa de objetivo',
        revenuePerGoal: 'Ingresos por objetivo',
        goalsRevenue: 'Ingresos de objetivos',
        storefronts: 'Escaparates',
        campaignStatus: 'Estado de la campaña',
        adgroupStatus: 'Estado del Grupo de anuncios',
        account: 'Cuenta',
        appId: 'ID de aplicación',
        campaignId: 'ID de campaña',
        adgroupId: 'ID de Grupo de anuncios',
    },
    searchTermsHeader: {
        searchTerm: 'Texto del término de búsqueda',
        spend: 'Gastar',
        averageCpa: 'Promedio CPA',
        averageCpt: 'Promedio CPT',
        impressions: 'Impresiones',
        taps: 'Grifos',
        installs: 'Instala',
        newDownloads: 'Nuevas descargas',
        reDownloads: 'Re-descargas',
        ttr: 'Tasa de derivación',
        conversionRate: 'Tasa de conversión',
        storefronts: 'Escaparates',
        campaignStatus: 'Estado de la campaña',
        adgroupStatus: 'Estado del Grupo de anuncios',
        keyword: 'Palabra clave',
        keywordId: 'ID de palabra clave',
        appId: 'ID de aplicación',
        campaignId: 'ID de campaña',
        adgroupId: 'ID de Grupo de anuncios',
    },
    negativeHeaders: {
        keyword: 'Palabra clave',
        matchType: 'Tipo de concordancia',
        status: 'Estado',
        adgroupId: 'ID de Grupo de anuncios',
        campaignId: 'ID de campaña',
        deleted: 'Eliminada',
        keywordId: 'ID de palabra clave',
        modificationTime: 'Hora de modificación'
    },
    filter: {
        account: 'Seleccionar cuenta',
        app: 'Seleccionar aplicación',
        goal: 'Seleccionar objetivo',
        campaign: 'Seleccionar campaña',
        adgroup: 'Seleccionar Grupo de anuncios',
        ad: 'Seleccionar anuncio'
    },
    subTabs: {
      all:'Todos',
        live: 'Vivir',
        paused: 'Pausada',
        deleted: 'Eliminada',
        draft: 'Sequía',
        campaigns: 'Campañas',
        adgroups: 'Grupos de anuncios',
        keywords: 'Palabras clave',
        creativeSet: 'Conjunto creativo',
        negKey: 'Palabras clave negativas'
    },
    popups: {
        add: 'Agregar',
        integration: 'Integración',
        adgroup: 'Grupo de anuncios',
        keywords: 'Palabras clave',
        creativeSet: 'Conjunto creativo',
        negKey: 'Palabras clave negativas',
        campaigns:'Campañas'
    },

    //Team Management Page
    teamMgmt: {
        manageUsers: 'Administrar miembros',
        invite: 'Invitar y administrar miembros del equipo',
        add: 'Añadir miembro',
        first: 'Primer nombre',
        last: 'Apellido',
        phone: 'Teléfono',
        email: 'Correo electrónico',
        job: 'Título profesional',
        allMembers: 'Todas las miembros',
        processing: 'Procesando...',
        ok: 'OK',
        inviteStatus: 'Estado de la invitación',
        isDisabled: 'Está desactivado',
        company: 'nombre de empresa'
    },

    //Add Integration Page
    integration: {
        assPart: 'Socios asociados',
        apple: 'Anuncios de búsqueda de Apple',
        slack: 'Slack',
        itunes: 'Itunes',
        tenjin: 'Tenjin',
        kochava: 'Kochava',
        adjust: 'Adjust',
        appsFlyer: 'AppsFlyer',
        singular: 'Singular',
        communication:'Comunicaciones',
        mmp:'Socios de medición móvil',
        completeInt:'Integraciones completadas',
        receivedEventType:'Tipos de eventos recibidos',
        waitForEvents:'Esperando Eventos',
        integrateWith:'Integrar con',
        intStep:'Pasos de integración',
        integrate:'Integrar',
        followIntSteps:'Sigue estos pasos de integración',
        //kochava steps
        kochavaStep1:'1. acceda a sus aplicaciones de Kochava en https://go.kochava.com/apps.',
        kochavaStep2:'2. seleccione Configuración de socios en el menú contextual de su aplicación.',
        kochavaStep3:'3. haga clic en Agregar una configuración en la página Configuración de socios.',
        kochavaStep4:'4. Elija Search Ads Maven - iOS en el menú Media Partner y haga clic en Ir.',
        kochavaStep5:'5. elija Crear en el menú contextual de cada evento que desee sindicar en Search Ads Maven.',
        kochavaStep6:'6. utilice la CLAVE API y el ID de la tienda de aplicaciones proporcionados aquí para cada creación de devolución de datos.',
        kochavaStep7:'7. Espere al menos 24 horas para que Search Ads Maven reconozca los eventos. Tenga en cuenta que solo se reconocen los eventos atribuidos a las campañas de Apple Search Ads.',
        kochavaStep8:'8. cree un objetivo para cada evento reconocido por Search Ads Maven. Una vez que se hayan creado los objetivos, los datos de eventos de MMP, como los recuentos y los ingresos, estarán disponibles en el panel.',

        //branchSteps
        branchStep:'1. Utilice la siguiente clave de API y el ID de la tienda de aplicaciones en el panel de control de su sucursal.',

        //customSteps
        customStep:'1. Utilice la siguiente clave de API y el ID de la tienda de aplicaciones en su devolución de datos.',

        apiKey:'Clave API',
        appStoreId:'Tienda de aplicaciones ID',
        distinctEventType:'Un MMP ha entregado distintos tipos de eventos atribuidos a ASA para esta aplicación. Estos tipos de eventos se pueden utilizar en la creación de objetivos.',
        noDistinctEventType:'Aún no se han recibido eventos atribuidos a Apple Search Ads. Si los eventos no se han recibido después de 24 horas, verifique dos veces estos pasos de integración y asegúrese de que se estén ejecutando campañas activas de Apple Search Ads que activarían uno o más eventos para ser entregados por el MMP.',
        dEventType:'Tipos de eventos distintos',
        noEventFound:'No se encontraron eventos',
        close:'Cerca',
        note:'NOTA: Las aplicaciones ya integradas con otro MMP no aparecerán en esta lista.',
        intDeletionInProgress:"La eliminación de la integración está actualmente en curso y le notificaremos cuando finalice."

    },

    //Keyword Auction Insights Page
    kai: {
        addedKeywords: 'Palabras clave agregadas',
        total: 'Total',
        cancel: 'Cancelar',
        runAds: 'Ejecutar anuncios',
        ok: 'OK',
        totalPaidKey: 'Palabras clave pagas totales',
        popularity: 'Popularidad',
        numOfApps: 'Número de aplicaciones',
        chance: 'Oportunidad',
        appsRunAds: 'Aplicaciones que ejecutan anuncios',
        maxPop: 'Máxima popularidad para el tuyo',
        keyword: 'Palabra clave',
        clear: 'Clara',
        clrAllFilter: 'Borrar todo el filtro',
        keywords: 'Palabras clave',
        organicRank: 'Rango orgánico',
        totalApps: 'Aplicaciones totales',
        app: 'Aplicación',
        catName: 'nombre de la categoría',
        searchByApp: 'Buscar por aplicación',
        searchByKey: 'buscar por palabra clave',
        addAllKey: 'Agregar todas las palabras clave',
        country: 'País',
        lang: 'Idioma',
        searchForCompetes: 'Competidores sugeridos',
        searchAdsRecommends: 'Recomendaciones de anuncios de búsqueda',
        categoryTop: 'Aplicaciones principales de la categoría',
        compRating: 'Clasificación',
        compVersion: 'Versión',
        compPrice: 'Precio',
        compGenres: 'Géneros',
        monthlyDownloads: 'Descargas mensuales',
        showDataText: 'No hay datos para mostrar',
        free: 'Gratis',
        paid: 'Pagada',
        quickAddCriteria: 'Criterios de adición rápida',
        tooltip: {
          keyText: "Título de palabra clave",
          organicRank: "El rango orgánico se refiere a los resultados de búsqueda de un motor de búsqueda que no pueden verse influenciados por la publicidad pagada.",
          popularity: "La puntuación de popularidad de la palabra clave de la palabra clave. Una puntuación de búsqueda más alta significa un volumen de búsqueda más alto en la palabra clave.",
          totalApps: "Número total de aplicaciones que utilizan esta palabra clave",
          appDetails: "Nombre de la aplicación",
          organicRankApp: "El rango orgánico se refiere a los resultados de búsqueda de un motor de búsqueda que no pueden verse influenciados por la publicidad pagada.",
          category: "Las categorías a las que pertenece la aplicación",
          rating: "Calificación de la aplicación",
          version: "Versión de la aplicación",
          price: "Precio de la aplicación",
          genres: "Género de la aplicación",
          chance: "La puntuación de probabilidad es una puntuación porcentual basada en la estimación de sus posibilidades de estar entre los diez primeros para una palabra clave en particular en la categoría específica de su aplicación.",
          appsRunning: "Número total de aplicaciones que pagan por la palabra clave"
        }
    },

    //Overview Page
    overview: {
        selectGoalFirstSelectApp:' Para seleccionar un objetivo, primero seleccione una aplicación en el menú desplegable de la izquierda.',
        count:'Recuento de eventos objetivo',
        exportCSV:'Exportar CSV',
        period: 'período',
        topKeywords: '10 palabras clave principales por',
        key: 'Palabra clave',
        spend: 'Gastar',
        impressions: 'Impresiones',
        taps: 'Grifos',
        goToKey: 'Ir a Todas las palabras clave',
        topAdgroup: 'Los 10 Grupos de anuncios principales por',
        adgroup: 'Grupo de anuncios',
        goToAdgroup: 'Ir a todos los Grupos de anuncios',
        gender: 'Género',
        device: 'Dispositivo',
        searchApp: 'Aplicación de búsqueda',
        lastDay: 'Último día',
        last7Days: 'Los últimos 7 días',
        last30Days: 'Últimos 30 días',
        ttr: 'Tasa de derivación',
        installs: 'Instala',
        avgCPA: 'Promedio CPA',
        avgCPT: 'Promedio CPT',
        conversionRate: 'Tasa de conversión',
        latOnInstalls: 'LAT en instalaciones',
        latOffInstalls: 'LAT Off Instalaciones',
        reDownloads: 'Re-descargas',
        newDownloads: 'Nuevas descargas',
        all: 'Todas',
        tooltip: 'El aumento o la disminución a lo largo del tiempo como un porcentaje del período anterior en función del rango de fechas seleccionado.',
        selectPer: 'Seleccionar período',
        prevPeriod: 'Período anterior',
        currPeriod: 'Periodo actual',

        GOLS: 'Objetivos',
        CPRG: 'Costo por objetivo',
        RPRG: 'Ingresos por objetivo',
        GREV: 'Ingresos de objetivos',
        GRAS: 'Objetivos ROAS',
        GETC: 'Objetivos Recuento de eventos',
    },

    checklist: {
      title: 'Lista de Verificación',
      integrations: 'Integraciones',
      searchads: 'Anuncios de búsqueda de Apple',
      slack: 'Agregar integración de Slack',
      kai: 'Familiarícese con las herramientas de análisis de palabras clave',
      competitorMsg: 'Vea cómo les está yendo a sus competidores.',
      searchKeywords: 'Ver palabras clave para una aplicación.',
      seeApps: 'Ver aplicaciones de ofertas para una palabra clave.',
      topCharts: 'Ver gráficos principales de categoría.',
      campaign: 'Campaña',
      initCampaign: 'Iniciar una campaña.',
      createAdgroup: 'Crear grupo de anuncios.',
      addKeywords: 'Añadir palabras clave.',
      addCreativeSet: 'Agregar conjunto creativo.',
      goLive: 'Ir a vivir.',
      reviewMetrics: 'Revisar el éxito de la campaña.',
      automate: 'Automatice su campaña.',
      mmpIntegration: 'Integración MMP',
      createMmpGoals: 'Crear objetivos de MMP',
      campGen:'Generador de campañas',
      scheduledReports:'Informes programados'
    },


    //Keyword Optimization Page
 keyOpt: {
    keyBid: 'Optimización de palabras clave',
    keyToolTip: 'Puede optimizar el monto de la oferta de las palabras clave que no están en el rango del monto de la oferta mínima sugerida por ASA y el monto de la oferta máxima sugerida por el ASA.',
    optAll: 'Optimizar todo',
    opt: 'Optimizar',
    optBidAmt: 'Optimizar el monto de la oferta',
    key: 'Palabra clave',
    optBidForAll: 'Optimizar la cantidad de oferta de todas las palabras clave',
    campaign: 'Campaña',
    adgroup: 'Grupo de anuncios',
    bidAmt: 'Importe de la oferta',
    asaMinBid: 'Oferta mínima sugerida por ASA',
    asaMaxBid: 'Oferta máxima sugerida de ASA',
    action: 'Acción',
    noData: 'Apple no tiene ninguna sugerencia en este momento.'
    },

    //ARRules Page
    aRules: {
    createAR: 'Crear regla de automatización',
    view: 'Vista',
    edit: 'Editar',
    delete: 'Borrar',
    disable: 'Desactivar',
    enable: 'Permitir',
    name: 'Nombre',
    createdAt: 'Creado en',
    status: 'Estado',
    completed: 'Completado en',
    actionFreq: 'Frecuencia de acción',
    rules: 'Reglas',
    logs: 'Registros',
    createdFor: 'Creado para',
    chckFreq: 'Verificar frecuencia',
    actions: 'Comportamiento',
    enableDisable: 'Habilitar deshabilitar',
    sno: 'S.No'
    },

    //Create Campaign
    //CampaignView
    createCampaign: {
    createCamp: 'Crear campaña',
    h2: 'Elija una aplicación para comenzar a crear una campaña',
    para: 'Las campañas tienen presupuestos y Grupos de anuncios que se enfocan en un tema o estrategia común para promocionar su aplicación.',
    select: 'Seleccione dónde se publicarán los anuncios',
    searchResults: 'Resultados de la búsqueda',
    srData: 'Las campañas tienen presupuestos y Grupos de anuncios que se enfocan en un tema o estrategia común para promocionar su aplicación.',
    searchTab: 'Pestaña de búsqueda',
    stData: 'Promocione su aplicación en la parte superior de la lista de aplicaciones sugeridas cuando los usuarios visiten la pestaña Buscar.',
    todayTab: 'Pestaña Hoy',
    todayData:'Llegue a los usuarios cuando vengan por primera vez a la tienda de aplicaciones para descubrir aplicaciones. Su anuncio aparecerá destacado en la primera página.',

    productPageTab: 'Páginas de productos',
    productPageData:'Llegue a los usuarios cuando estén navegando por las aplicaciones. Su anuncio aparecerá en la parte superior. También puede incluir en la lista a los usuarios que se han desplazado hasta la parte inferior de las páginas de productos relevantes.',
 
    cancel: 'Cancelar',
    continue: 'Continuar',

    //Search Results and Search Tab Campaign
    countAndReg: 'Paises y Regiones',
    adgroupSettings: 'Configuración del Grupo de anuncios',
    searchMatch: 'Coincidencia de búsqueda',
    key: 'Palabra clave',
    audience: 'Audiencia',
    crSets: 'Conjuntos creativos',
    chooseCampGroup: 'Elija un Grupo de campaña',
    chooseApp: 'Elija una aplicación',
    next: 'Próximo',
    countPara: 'Su aplicación se promocionará en la App Store en los países y regiones elegibles que elija de la lista.',
    chooseCount: 'Elija uno o más países o regiones',
    campName: 'Nombre de campaña',
    budget: 'Presupuesto',
    dailyCap: 'Límite diario (opcional)',
    adgroupPara: 'Los Grupos de anuncios contienen objetivos y configuraciones de precios que determinan a quién mostrar su anuncio.',
    adgroupName: 'Nombre del Grupo de anuncios',
    devices: 'Dispositivos',
    startTime: 'Hora de inicio',
    adScheduling: 'Programación de anuncios',
    endTime: 'Hora de finalización',
    dayParting: 'Partición del día (opcional)',
    ok: 'OK',
    defaultMax: 'Oferta de CPT máx. Predeterminada',
    cpaGoal: 'Objetivo de CPA (opcional)',
    saveDraft: 'Guardar como borrador',
    appleSearchMatch: 'Coincidencia de búsqueda de Apple',
    searchMatchPara: 'Apple Search Match es la forma más sencilla de poner en marcha sus anuncios. Los anuncios de búsqueda harán coincidir automáticamente su anuncio con los usuarios que buscan aplicaciones como la suya.',
    searchMatchPara1: 'Hacer coincidir automáticamente mi anuncio con búsquedas relevantes',
    keywords: 'Palabras clave',
    keyPara: 'Las palabras clave son palabras o términos relevantes que los clientes pueden usar cuando buscan una aplicación como la suya. Las palabras clave permiten a los usuarios encontrar su aplicación rápidamente, lo que ayuda a impulsar las instalaciones de la aplicación.',
    upload: 'Subir',
    suppFormat: 'formatos compatibles: Excel, CSV',
    keyManually: 'O agregue palabras clave manualmente',
    targKey: 'Orientación por palabras clave',
    clear: 'Claro',
    adgroupNegKey: 'Palabras clave negativas del Grupo de anuncios',
    optional: 'Opcional',
    negKey: 'Palabras clave negativas',
    audPara: ' Defina mejor la audiencia de su Grupo de anuncios mediante funciones avanzadas opcionales.',
    custType: 'tipo de cliente',
    demo: 'Demografía',
    gender: 'Género',
    location: 'Localización (Buscar)',
    crSetPara: 'Los anuncios se crean automáticamente utilizando los metadatos, las capturas de pantalla y las vistas previas de la aplicación que se proporcionan en la página del producto de la App Store. Estos también aparecen en los resultados de búsqueda orgánicos en la App Store. Los anuncios que se muestran aquí son ejemplos de anuncios predeterminados.',
    crOptional: 'Conjunto creativo (opcional)',
    noCrSet: 'La aplicación no cumple con el requisito de Anuncio mínimo',
    campCreated: 'Campaña creada',
    campCreatedPara: 'Ahora, puede ver sus campañas en el panel de anuncios de búsqueda.',
    close: 'Cerca',
    matchType: 'Tipo de concordancia',
    amount: 'Monto',
    currency: 'Divisa',
    allUsers: 'Todos los usuarios',
    newUsers: 'Nuevos usuarios',
    appUsers: 'Usuarios de mis otras aplicaciones',
    existingUsers: 'Usuarios existentes',
    all: 'Todas',
    male: 'Masculino',
    female: 'Mujer',
    chooseCamp: 'Grupo de campaña',
    selectApp: 'Aplicación de búsqueda',
    countries: 'Países',
    ageRange: 'Rango de edad',
    selectDev: 'Seleccionar dispositivos',
    adsOpt: 'Anuncio (Opcional)',
    default: 'Defecto',
    defaultAdPara: 'Utiliza recursos de la página de productos predeterminada de la tienda de aplicaciones.',
    custom: 'Disfraz',
    customPara: 'Utiliza activos de su App Store Connect predeterminada y lleva a los usuarios a esa página cuando tocan el anuncio.',
    pagesInfo: 'Las páginas de productos personalizados están disponibles',
    createAdPara: 'Haga clic en el botón Crear anuncio para seleccionar entre las opciones de la página de su producto personalizado y ver una vista previa de su anuncio.',
    createAdBtn: 'Crear anuncio',
    createAds: 'Crear anuncios',
    selectCustomPara: 'Seleccione una página de producto personalizada para que sus activos se utilicen para crear su anuncio. Cuando los usuarios toquen su anuncio en los resultados de búsqueda, serán llevados a la página que seleccione.',
    adName: 'Nombre del anuncio',
    search: 'Búsqueda',
    available: 'Disponible',
    sortBy: 'Ordenar por',
    save: 'Ahorrar',
    customAds: 'Anuncios personalizados',
    edit: 'Editar',
    previous: 'Previa'
    },


  //Change Password
  changePass: {
    backSett: 'Volver a la configuración',
    chngPass: 'Configurar contraseña',
    newPass: 'Nueva contraseña',
    confirmPass: 'confirmar Contraseña',
    submit: 'Entregar',
    h4: 'El sistema operativo Kochava Marketers ™',
    para:
      'Marketers Operating System (m / OS) integra a la perfección soluciones de marketing omnicanal para anunciantes y editores en una plataforma operativa.',
    ok: 'OK',
  },

  //Forgot Password
  forgotPass: {
    forPass: 'Has olvidado tu contraseñaHas olvidado tu contraseña',
    typeEmail: 'Escriba la dirección de correo electrónico',
    emailAdd: 'Dirección de correo electrónico',
    submit: 'Entregar',
    chckEmail: 'Por favor revise su correo electrónico',
    para: 'Para establecer una nueva contraseña, consulte su correo electrónico',
    close: 'Cerrar',
    alreadyAcc: '¿Ya tienes una cuenta?',
    signIn: 'Registrarse',
  },

  //Onboard
  onboard: {
    welcome: 'Bienvenido a SearchAds Maven',
    personal: 'Inscribirse',
    start: 'Comienzo',
    integration: 'Integración',
    next: 'Próximo',
    trial: 'Comience su prueba',
    scheduleDemo: 'O programe una demostración con nosotros y descubra juntos',
    addInt: 'Agrega una integración.',
    signInApple:
      'Inicie sesión con Apple para agregar una integración de Apple Search Ads. Tenga en cuenta:',
    step1:
      '1. Es necesario tener derechos de administrador para otorgar acceso a la cuenta de Apple Search Ads.',
    step2:
      '2. Para administrar sus campañas desde SearchAdsNinja, seleccione la opción Leer y escribir antes de otorgar acceso.',
    skip: 'Saltar',
  },

  //AddAdgroup
  addAdgroup: {
    addAd: 'Agregar Grupo de anuncios',
    para:
      'Aún no ha seleccionado su campaña. Puede elegirlo en el cuadro de abajo.',
    camGroup: 'Grupo de campaña',
    campaign: 'Campaña',
    adgroupSett: 'Configuración del Grupo de anunciosConfiguración del Grupo de anuncios',
    para1:
      'Los Grupos de anuncios contienen objetivos y configuraciones de precios que determinan a quién mostrar su anuncio.',
    adgroupName: 'Nombre del Grupo de anuncios',
    devices: 'Dispositivos',
    adScheduling: 'Programación de anuncios',
    startTime: 'Hora de inicio',
    endTime: 'Hora de finalización',
    dayparting: 'Partición del día (opcional)',
    defaultMax: 'Oferta de CPT máx. Predeterminada',
    cptTool:
      'Esta es la cantidad máxima que está dispuesto a pagar por un toque en su anuncio. Esta oferta se aplicará a todas las palabras clave de este Grupo de anuncios, a menos que aplique una oferta de palabra clave individual.',
    cpaGoal: 'Objetivo de CPA (opcional)',
    cpaTool:
      'El promedio que ha pagado por cada descarga. Esto se calcula dividiendo el total gastado por el número de descargas.',
    searchMatch: 'Coincidencia de búsqueda',
    searchTool:
      'Search Match es la forma más sencilla de poner en marcha sus anuncios. Los anuncios de búsqueda harán coincidir automáticamente su anuncio con los usuarios que buscan aplicaciones como la suya.',
    searchPara: 'Hacer coincidir automáticamente mi anuncio con búsquedas relevantes',
    key: 'Palabras clave',
    keyPara:
      'Las palabras clave son palabras o términos relevantes que los clientes pueden usar cuando buscan una aplicación como la suya. Las palabras clave permiten a los usuarios encontrar su aplicación rápidamente, lo que ayuda a impulsar las instalaciones de la aplicación.',
    inputCpt: 'Ingrese la oferta de CPT máxima predeterminada para activar la sección de palabras clave',
    addKeyPara:
      'Agregue palabras clave relevantes para su aplicación y género. Te ayudaremos compartiendo recomendaciones adicionales.',
    upload: 'Subir',
    addKeyManually: 'O agregue palabras clave manualmente',
    negKey: 'Palabras clave negativas del Grupo de anuncios (opcional)',
    supp: 'formatos compatibles: Excel, CSV',
    demAge: 'Edad demográfica, género',
    demPara: "Oriente su anuncio según la edad o el sexo de su usuario",
    ageRange: 'Rango de edad',
    cancel: 'Cancelar',
    apply: 'Solicitar',
  },

  //Edit Adgroup
  editAdgroups: {
    edit: 'Editar Grupo de anuncios',
    adgroup: 'Grupo de anuncios',
    advance: 'Configuraciones avanzadas',
    viewEdit: 'Ver y editar',
    general: 'General',
    settings: 'Ajustes',
    schedule: 'Calendario',
    custPara: 'Seleccione los clientes de la aplicación a los que desea llegar',
    selectAge: 'Seleccionar rango de edad',
    save: 'Salvar',
  },

  //Create AR
  createAR: {
    rule:"Regla",
    addRule: 'Agregar regla',
    selectDateRange:'Seleccionar rango de fechas',
    createAutoRule: 'Crear regla de automatización',
    editAutoRule: 'Editar regla de automatización',
    choose: 'Escoger',
    chooseAutoRule: 'Elija el nivel de automatización y los elementos.',
    conditions: 'Condiciones',
    actions: 'Comportamiento',
    settings: 'Ajustes',
    condition: 'Condición',
    addCondition: 'Agregar condición',
    andCondition:
      'Elija si todas las condiciones anteriores deben coincidir o solo una de ellas debe coincidir',
    checkCond: 'Consultar condiciones para',
    action: 'Acción',
    selectAll: 'Seleccionar todo',
    enterEmail: 'Ingrese los ID de correo electrónico adecuados',
    addTo: 'Añadir',
    selectAdgroups: 'Seleccionar Grupos de anuncios',
    selectCampaigns: 'Seleccionar campañas',
    cptBid: 'Oferta de CPT',
    customBid: 'Oferta personalizada',
    matchType: 'Tipo de concordancia',
    addAction: 'Agregar acción',
    chckFreq: 'Verificar frecuencia',
    ruleName: 'Nombre de la regla',
    cancel: 'Cancelar',
    step2: 'Paso 2',
    step3: 'Paso 3',
    andOrTypesText1: 'Todo debe coincidir',
    andOrTypesText2: 'Solo uno debe coincidir',
    addToModelListNegText1: 'Todos los Grupos de anuncios seleccionados',
    addToModelListNegText2: 'Todos los Grupos de anuncios de las campañas seleccionadas)',
    addToModelListNegText3: 'Todas las campañas seleccionadas',
    matchTypesText1: 'Coincidencia amplia',
    matchTypesText2: 'Coincidencia exacta',
    cptBidItemsText1: 'Establecer como personalizado',
    cptBidItemsText2: 'Oferta predeterminada del Grupo de anuncios',
    actionFreqsText1: 'Cada hora',
    actionFreqsText2: 'Cada 6 horas',
    actionFreqsText3: 'Cada 12 horas',
    actionFreqsText4: 'Todos los días',
    actionFreqsText5: 'Horas personalizadas',
    limitActionFreqsText1: 'Cada 2 horas',
    limitActionFreqsText2: 'Cada 3 horas',
    limitActionFreqsText3: 'Cada 2 dias',
    limitActionFreqsText4: 'Cada 3 dias',
    limitActionFreqsText5: 'Cada semana',
    mon: 'lunes',
    tue: 'martes',
    wed: 'miércoles',
    thu: 'jueves',
    fri: 'viernes',
    sat: 'sábado',
    sun: 'domingo',
    ciTimeRangesText1: 'Última 1 hora',
    ciTimeRangesText2: 'Último 1 día',
    ciTimeRangesText3: 'Los últimos 7 días',
    ciTimeRangesText4: 'Últimos 30 días',
    ciTimeRangesText5: 'El mes pasado',
    ciTimeRangesText6: 'Hora personalizada',
    ciTimeRangesText7: 'Rango de fechas',
    allConOperationText1: 'Mas grande que',
    allConOperationText2: 'Menos que',
    allConOperationText3: 'Abarcar',
    allConOperationText4: 'Fuera de rango',
    amount: '$ Cantidad',
    budget: '% Porcentaje del presupuesto',
    incBy: 'Aumentado por',
    decBy: 'Disminuir en',
    setToCPA: 'Establecer en CPA medio',
    setToCPT: 'Establecer en CPT promedio',
    value: '$ Valor',
    change: '% Cambiar',
    between: 'Entre',
    equalTo: 'Igual a',
    appsSelected:'Aplicaciones seleccionadas',
    campaignsSelected:'Campañas seleccionadas',
    adgroupsSelected:'Grupos de anuncios seleccionados',
    KeywordsSelected:'Palabras clave seleccionadas',
    selectField:'Seleccionar campo',
    selectOne:'Seleccione uno',
    pleaseSelect:'Seleccione',
    pleaseEnteraValue:'porfavor introduzca un valor',
    fromValue:'Desde valor',
    toValue:'Valorar',
    mustBeLesserThanToValue:'Ne peut pas être 0',
    mustBeHigherThanFromValue:'Debe ser mayor que del valor',
    cannotBeZero:'Debe ser mayor que del valor',
    ruleAlreadyExist:'El nombre de la regla ya existe',
    emailNotification:'Notificación de correo electrónico',
    slackNotification: 'Notificación de holgura',
    pause:'Pausa',
    resume:'Reanudar',
    changeDailyCap:'Cambiar límite diario',
    changeBid:'Cambiar oferta',
    changeBudget:'Cambiar presupuesto',
    addAsNegativeKeyword:'Agregar como palabra clave negativa',
    createActionFor: 'Crear acción para',
    selectAction:'Seleccione la acción',
    selectEmail:'Seleccionar correo electrónico',
    addOtherEmail:'Agregar otro correo electrónico',
    enterAnotherEmail:'Ingrese una identificación de correo electrónico y presione enter para agregar más',
    giveAName:'Dale un nombre a esta regla',
    searchLogs: 'Filtrar los registros por regla',
    details: 'Detalles',
    PENDING: 'PENDIENTE',
    SUCCESS: 'ÉXITO',
    FAILED: 'HA FALLADO',
    CREATED: 'CREADO',
    DELETED: 'BORRADO',
    ENABLED: 'ACTIVADO',
    DISABLED: 'DESACTIVADO',
    REACTIVATED: 'RE ACTIVADO',
    UPDATED: 'ACTUALIZADO',
    CONDITIONMATCHED : 'CONDICIÓN IGUAL',
    CONDITIONNOTMATCHED:'CONDICIÓN NO COINCIDENTE',
    runIndefinite:'Ejecutar indefinidamente',
    indefiniteNote:'NOTA: Cuando se selecciona, esta regla de automatización continuará aplicándose en el intervalo especificado y permanecerá vigente independientemente de cuántas veces se active.',
    sureToSave:'¿Está seguro de que desea guardar la regla de automatización?',
    update:'Actualizar',
    selectChannel:'Seleccionar canal',
    noDataAvailable:'Datos no disponibles',
    sureToDelete:'¿Quiere eliminar la regla de automatización?',
  },

  //select Account AR
  selectAccountAR: {
    chooseAcc: 'Elegir cuenta',
    cancel: 'Cancelar',
    apply: 'Solicitar',
    clearAll: 'Limpiar todo',
    accounts: 'Cuentas',
    accountSelected: 'Cuenta (s) seleccionada (s)',
  },

  //select Apps AR
  selectAppsAR: {
    chooseApp: 'Elija la aplicación',
    cancel: 'Cancelar',
    apply: 'Solicitar',
    apps: 'Aplicaciones',
    appSelected: 'Aplicaciones seleccionadas',
    clearAll: 'Limpiar todo',
  },

  //select Keywords AR
  selectKeywordsAR: {
    keepOneAdGroup: 'Please keep only one Ad-Group selected to continueAplicaciones seleccionadas.',
    close: 'Cerrar',
    chooseKeywords: 'Elija palabras clave',
    cancel: 'Cancelar',
    apply: 'Solicitar',
    campaigns: 'Campañas',
    expandAll: 'Expandir todo',
    collapseAll: 'Desplegar todo',
    adGroups: 'Grupos de anuncios',
    keywords: 'Palabras clave',
    keywordSelected: 'palabras clave seleccionadas',
    clearAll: 'Limpiar todo',
    excludePausedKeywords:'Excluir palabras clave en pausa',
  },

  //campaign
  //AddCreativeSet
  addCreativeSet: {
    addCreative: 'Agregar conjunto creativo',
    setup: 'Configuración',
    para:
      'Elija un nombre que describa el tema de su conjunto creativo. Seleccione el idioma de las capturas de pantalla y las vistas previas que le gustaría incluir en la página de producto de su App Store.',
    createSetName: 'Nombre del conjunto creativo ',
    localLang: 'Idioma de localización',
    assetSelection: 'Selección de activos',
    assetSelectionPararaph1:
      'Los conjuntos creativos consisten en capturas de pantalla y vistas previas de aplicaciones que aparecen en la página del producto de la App Store.',
    assetSelectionPararaph2: 'Para cada tamaño de pantalla de iPhone,',
    assetSelectionPararaph3:
      'seleccione al menos un paisaje o tres recursos de retrato.',
    assetSelectionPararaph4: 'Para cada tamaño de pantalla de iPad,',
    assetSelectionPararaph5:
      'seleccione al menos un paisaje o dos recursos de retrato.',
    title1: 'iPhone/iPod',
    screenshotsPara1: 'Por favor seleccione al menos',
    screenshotsPara2: 'un paisaje',
    screenshotsPara3: 'o',
    screenshotsPara4: 'dos retratos',
    screenshotsPara5: 'capturas de pantalla!',
    screenshotsPara6: 'Por favor seleccione al menos',
    screenshotsPara7: 'un paisaje',
    screenshotsPara8: 'o',
    screenshotsPara9: 'tres retratos',
    screenshotsPara10: 'capturas de pantalla!',
    cancel: 'Cancelar',
    save: 'Salvar',
  },
  strategy:{
    descriptionValid:"Se requiere descripción",
    alphaNumeric:"Sólo caracteres alfanuméricos son permitidos",
    textMax:"La descripción no debe exceder los 250 caracteres"
  },
  //DeleteStrategy
  deleteStrategy: {
    deleteCamp: 'Eliminar estrategia',
    para: '¿Estás segura de que quieres eliminar la estrategia?',
    cancel: 'Cancelar',
    delete: 'Borrar',
  },
  //DeleteCampaign
  deleteCampaign: {
    deleteCamp: 'Eliminar campaña',
    para: '¿Estás seguro de que quieres eliminar la campaña?',
    cancel: 'Cancelar',
    delete: 'Borrar',
  },

  //EditCampaign
  editCampaign: {
    editCampWizard: 'Asistente para editar campañas',
    campName: 'Nombre de campaña',
    app: 'Aplicación',
    countriesOrRegionsPara: 'Elija uno o más países o regiones',
    budget: 'Presupuesto',
    dailyCap: 'Límite diario',
    cancel: 'Cancelar',
    save: 'Salvar',
  },

  //SelectCampaign
  selectCamp: {
    chooseCamp: 'Elegir campaña',
    noSelectedCamp: 'Ninguna campaña seleccionada',
    campaigns: 'Campañas',
    expandAll: 'Expandir todo',
    collapseAll: 'Desplegar todo',
    selectedCampaigns: 'Campañas seleccionadas',
    clearAll: 'limpiar todo',
    cancel: 'Cancelar',
    apply: 'Solicitar',
  },

  //chargebee
  //EditCard
  editCard: {
    editCreditCard: 'Editar tarjeta de crédito',
    cardNumber: 'Número de tarjeta',
    expiryDate: 'Fecha de caducidad',
    cvv: 'CVV',
    saveCard: 'Tarjeta Salvar',
    updateExpiryPara: '* Solo puede actualizar la fecha de vencimiento',
  },

  //creativeset
  //EditCreativeSet
  editCreativeSet: {
    editCreative: 'Editar conjunto de creatividades',
    easywebinar: 'EasyWebinar',
    softobiz: 'Softobiz Technologies Private Limited',
    setup: 'Configuración',
    editCreativePara1:
      'Elija un nombre que describa el tema de su conjunto creativo. Seleccione el idioma de las capturas de pantalla y las vistas previas que le gustaría incluir en la página de producto de su App Store.',
    creativeSetName: 'Nombre del conjunto creativo',
    localLang: 'Idioma de localización',
    assetSelection: 'Selección de activos',
    assetSelectionPararaph1:
      'Los conjuntos creativos consisten en capturas de pantalla y vistas previas de aplicaciones que aparecen en la página del producto de la App Store.',
    assetSelectionPararaph2: 'Para cada tamaño de pantalla de iPhone,',
    assetSelectionPararaph3:
      'seleccione al menos un paisaje o tres recursos de retrato.',
    assetSelectionPararaph4: 'Para cada tamaño de pantalla de iPad,',
    assetSelectionPararaph5:
      'seleccione al menos un paisaje o dos recursos de retrato.',
    viewDevice: 'Ver tamaños de pantalla del dispositivo',
    title1: 'iPhone/iPod',
    ipad: 'iPad',
    display: 'Mostrar',
    default: 'Defecto',
    cancel: 'Cancelar',
    save: 'Salvar',
  },

  addIntegration: {
    invalid: 'Integración no válida, elimínela y vuelva a agregarla.'
  },

  navbar: {
    syncProgress: 'La sincronización de datos está en curso.'
  },

  messages: {
    onHold: 'Campaña en espera.',
    reasons: 'Razones'
  },

  //integration
  //DeleteIntegration
  deleteIntegration: {
    deleteInt: 'Eliminar integración',
    cardText: '¿Está seguro de que desea eliminar la integración?',
    cancel: 'Cancelar',
    delete: 'Borrar',
  },

  //keywords
  //AddKeys
  addKeys: {
    addKeywords: 'Agregar palabras clave',
    keywordSuggestionsFor: 'Sugerencias de palabras clave para',
    on: 'en',
    addedKeywords: 'Palabras clave agregadas',
    total: 'Total',
    add: 'Agregar',
    cancel: 'Cancelar',
    apply: 'Solicitar',
    matchExact: 'EXACTA',
    matchBroad: 'AMPLIA',
    searchHere: 'Busca aquí',
  },

  //AddKeysKai
  addKeysKai: {
    addKeywords: 'Agregar palabras clave',
    selectTarget: 'Seleccione un objetivo',
    h4Camp: 'Campañas:',
    selected: 'Seleccionado',
    change: 'Cambiar',
    h4Adgroups: 'Grupos de anuncios:',
    maxCPTBid: 'Oferta de CPT máx .:',
    keywordSuggestionsFor: 'Sugerencias de palabras clave para',
    on: 'en',
    recommendedKeywords: 'Palabras clave recomendadas',
    addedKeywords: 'Palabras clave agregadas',
    add: 'Agregar',
    total: 'Total',
    cancel: 'Cancelar',
    apply: 'Solicitar',
    ok: 'OK',
  },

  //AddKeywords
  addKeywords: {
    h6AddKeywords: 'Agregar palabras clave',
    selectTarget: 'Seleccione un objetivo',
    h4Camp: 'Campañas:',
    selected: 'Seleccionado',
    change: 'Cambiar',
    h4Adgroups: 'Grupos de anuncios:',
    maxCPTBid: 'Oferta de CPT máx .:',
    keywordSuggestionsFor: 'Sugerencias de palabras clave para',
    on: 'en',
    recommendedKeywords: 'Palabras clave recomendadas',
    addedKeywords: 'Palabras clave agregadas',
    add: 'Agregar',
    total: 'Total',
    cancel: 'Cancelar',
    apply: 'Solicitar',
    ok: 'OK',
  },

  //AddnKeys
  addnKeys: {
    addNegKeywords: 'Agregar palabras clave negativas',
    add: 'Agregar',
    total: 'Total',
    cancel: 'Cancelar',
    save: 'Salvar',
  },

  //EditBidAmount
  editBidAmount: {
    bidAmount: 'Importe de la oferta',
  },

  //SelectAdGroup
  selectAdGroup: {
    snackbar: 'Mantenga solo un Grupo de anuncios seleccionado para continuar.',
    close: 'Cerrar',
    chooseAdGroup: 'Elija el Grupo de anuncios',
    cancel: 'Cancelar',
    apply: 'Solicitar',
    campaigns : 'Campañas',
    expandAll: 'Expandir todo',
    collapseAll: 'Desplegar todo',
    adGroups : 'Grupos de anuncios',
    adGroupSelected : 'Grupos de anuncios seleccionados',
    clearAll : 'Limpiar todo'
  },

  //negativeKeywords
  //AddNegativeKeywords
  addNegativeKeywords : {
    addNegKeywords : 'Agregar palabras clave negativas',
    selectTarget : '1. Seleccione un objetivo',
    addNegKeyword : 'Agregar palabra clave negativa',
    paragraph1 : 'Se pueden agregar palabras clave negativas a campañas o Grupos de anuncios. Elija uno de ellos del cuadro de abajo.',
    paragraph2 : '¿Qué campaña desea cargar palabras clave?',
    selectCampaign : 'Seleccionar campaña',
    paragraph3 : '¿Qué Grupo de anuncios desea cargar palabras clave?',
    selectAdgroup : 'Seleccionar Grupo de anuncios',
    cancel: 'Cancelar',
    easywebinar : 'EasyWebinar',
    softobiz : 'Softobiz Technologies Private Limited',
    campaigns : 'Campañas:',
    selected : 'Seleccionado',
    change: 'Cambiar',
    paragraph4 : ' Las palabras clave negativas del Grupo de anuncios se aplican solo a este Grupo de anuncios. Si desea que las palabras clave negativas se apliquen a todos los Grupos de anuncios, configúrelas en el nivel de la campaña.',
    paragraph5 : ' Considere dar tiempo a las campañas para que se publiquen antes de analizar el rendimiento de los anuncios para saber qué palabras clave pueden no funcionar para usted. Agregar demasiadas palabras clave negativas puede limitar la exposición de su anuncio.',
    addedKeywords : 'Palabras clave agregadas',
    add: 'Agregar',
    total: 'Total',
    save: 'Salvar',
  },

  //oAuth
  //SigninSlackBtn
  signinSlackBtn : {
    addSlackChannel : 'Agregar canal de Slack',
    sno: 'S. No'
  },

  //DecisionAlert
   decisionAlert : {
cancel : 'Cancelar',
ok : 'Ok',
confirm:'Confirmar'
  },

  //HelloWorld
  helloWorld : {
welcome : 'Bienvenido a vuetify',
para1 : 'Para obtener ayuda y colaboración con otros desarrolladores de Vuetify,',
para2 : 'por favor únase a nuestro en línea',
discord : 'Comunidad Discord',
h2 : "¿Que sigue?",
impLinks : 'Links importantes',
ecosystem : 'Ecosistema',
  },

  //InfoAlert
  infoAlert : {
ok : 'OK',
  },

  successMsg: {
    campaignUpdate: 'Campaña actualizada.',
    campaignMovedMsg: 'Estado cambiado. La campaña se movió a otra pestaña',
    campaignMoveFailMsg: "No se pudo cambiar el estado de la campaña.",
    adgroupUpdateFailMsg: "No se pudo actualizar el estado del Grupo de anuncios.",
    addgroupUpdatedMsg: 'Estado cambiado. El Grupo de anuncios se movió a otra pestaña',
    keywordMovedMsg: 'Estado cambiado. Palabra clave movida a otra pestaña',
    keywordMoveFailMsg: "No se pudo actualizar el estado de la palabra clave.",
    creativeAddedMsg: 'Se agregó un conjunto creativo.',
    campaignSavedMsg: 'Campaña guardada como borrador.',
    campaignSaveFailMsg: 'No se puede guardar el borrador de la campaña en este momento. ¡Inténtalo de nuevo!',
    keyAddedMsg: 'Palabras clave agregadas.',
    signinFailMsg: 'No se puede iniciar sesión. Vuelve a intentarlo.',
    deleteMsg: 'Borrado exitosamente',
    deleteFailMsg: 'No se pudo borrar. ¡Inténtalo de nuevo!',
    dataUpdateMsg : 'Datos actualizados',
    dataUpdateFailMsg: 'Error al actualizar datos',
    AutoDeleteMsg: 'Regla de automatización eliminada correctamente',
    requestFailMsg: 'No se pudo procesar su solicitud. Por favor intente mas tarde',
    AutoRuleDisSuccessMsg: 'Regla de automatización inhabilitada con éxito',
    AutoRuleEnableMsg: 'La regla de automatización se habilitó correctamente',
    SCAutoRuleDisSuccessMsg: 'Regla con secuencias de comandos deshabilitada correctamente',
    SCAutoRuleEnableMsg: 'Regla con secuencias de comandos habilitada correctamente',
    bidChangeSuccessMsg: 'El monto de la oferta cambió correctamente.',
    wentWrongMsg: 'Algo salió mal.',
    integrateSuccessMsg : 'Integración agregada con éxito',
    userCreateMsg: 'Usuario creada con éxito',
    emailExistMsg: 'el Email ya existe',
    errorCreateUser: 'Error al crear usuario',
    inviteFailMsg: 'Error al enviar el enlace de invitación al correo electrónico',
    failTryLaterMsg: 'Algo está mal. Por favor intente mas tarde',
    adgroupAddedMsg: 'AdGroup agregado.',
    adgroupUpdatedMsg: 'AdGroup actualizado.',
    ruleUpdatedMsg: 'Regla actualizada correctamente',
    ruleUpdateFailMsg: 'Error al actualizar la regla. Por favor intente mas tarde',
    rCreatedMsg: 'Regla creada con éxito',
    rCreationFailMsg: 'Falló la creación de la regla. Por favor intente mas tarde',
    assetDataFailMsg: 'No se pueden recuperar los detalles del activo. ¡Inténtalo de nuevo!',
    deleteCampFailMsg: 'Error al eliminar la campaña',
    CampDeletedMsg: 'Campaña eliminada',
    creativeUpdated: 'Conjunto creativo actualizado.',
    keyAdded: 'Palabras clave agregadas.',
    negaKeyAddedMsg: 'Palabras clave negativas agregadas a los Grupos de anuncios.',
    negKeyAddCamp: 'Se agregaron palabras clave negativas a la campaña.',
    errorDeleteKey: 'Error al eliminar la palabra clave',
    keyDeleteS: 'Palabra clave eliminada. Palabra clave movida a palabras clave eliminadas',
    syncStarted: 'Se inició la sincronización.',
    firstSyncStarted:'¡Integración de Apple Search Ads completa! <br> <br> <p style="text-align: justify; color: inherit;">Espere unos minutos mientras los datos ASA se sincronizan por primera vez. Sus aplicaciones y datos ASA se completarán tan pronto como se complete esta primera sincronización. Siga el progreso de sincronización en el borde superior derecho de la página.<p>',
    integrationWarning:'Se requiere una integración de Apple Search Ads sincronizada antes de integrarse con este MMP. Agregue una integración de Apple Search Ads arriba y espere a que se complete la primera sincronización antes de integrarse con este MMP.',
    AdDeletedMsg: 'Anuncio eliminado',
    adUpdate: 'Anuncio actualizado',
    deleteAdFailMsg: 'Error al eliminar anuncio',
    deleteStrategyFailMsg: 'Error al eliminar la estrategia',
    StrategyDeletedMsg: 'Estrategia eliminada'
},

  createCampaignRules: {
    selectCampGrp: 'Seleccione el Grupo de campañas.',
    selectApp: '¡Seleccione una aplicación!',
    selectCountry: '¡Por favor seleccione país!',
    campRequired: '¡Se requiere el nombre de la campaña!',
    campNameSize: 'El nombre de su campaña debe tener menos de 200 caracteres',
    adGroupSize: 'El nombre de su Grupo de anuncios debe tener menos de 200 caracteres',
    keywordsize: 'Las palabras clave no pueden tener más de 80 caracteres',
    budgetRequired: '¡Se requiere una cantidad de presupuesto!',
    validBudget: 'Introduzca un presupuesto válido.',
    groupRequired: 'Se requiere el nombre del Grupo de anuncios.',
    defMaxCptRequired: 'Se requiere una oferta de CPT máxima predeterminada.',
    validCptBid: 'Ingrese una oferta de CPT válida.',
    validDailyCap: 'Introduzca un límite diario válido.',
    validCpaGoal: 'Introduzca un objetivo de CPA válido.',
    selectDevices: '¡Seleccione dispositivos!',
    fNamereq: 'Se requiere el primer nombre',
    fNameGrt: 'El nombre no debe tener más de 12 caracteres',
    textMax: 'No debe tener más de 50 caracteres',
    textMin: 'No debe tener menos de 2 caracteres',
    fNameValid: 'Introduzca un nombre válido.',
    lNameGrt: 'El apellido no debe tener más de 12 caracteres',
    lNameValid: '¡Ingrese un nombre válido!',
    phoneReq: 'Se requiere teléfono',
    cmpniNameReq: 'El nombre de la empresa es obligatorio',
    jobTitleReq: 'El título del trabajo es obligatorio',
    emailReq: 'correo electronico es requerido',
    emailValid: 'El email debe ser válido',
    selectCamp: 'Seleccionar campaña',
    cardNoReq: 'Se requiere el número de tarjeta',
    cardNo16Digit: 'El número de tarjeta debe ser numérico y tener 16 dígitos',
    expiryMonthReq: 'Se requiere el mes y año de vencimiento',
    expiryMMYY: 'La fecha de vencimiento debe ser MM / AAAA - ej. 20/0723',
    invalidExpiry: 'Mes de vencimiento no válido',
    invalidMonth: 'Año de vencimiento no válido',
    cvvReq: 'Se requiere tarjeta CVV',
    cvvNum3Digit: 'El CVV de la tarjeta debe ser numérico y de 3 dígitos',
    specialCharNotAllowed:'Caracteres especiales no están permitidos',
    onlySinglespaceAllowed:'Solo se permite un solo espacio en blanco',
    invalidPhoneFormat:'Formato de número de teléfono no válido'


  },

  settings: {
    profileInfo: 'información del perfil',
    updateProf: 'Actualización del perfil',
    payMethod: 'Método de pago',
    addCredit: 'Agregar tarjeta de crédito',
    cardNo: 'Número de tarjeta',
    invoiceHistory: 'Historial de facturas',
    invoiceId: 'Invoice_Id',
    dueDate: 'Fecha de vencimiento',
    paidAt: 'Paid_At',
    recure: 'Periódica',
    total: 'Total',
    status: 'Estado',
    active: 'Acción',
    cardId: 'Tarjeta de identificacion',
    type: 'Escribe',
    expiryMonth: 'Meses de vencimiento',
    expiry: 'Año de caducidad',
    action: 'Acción',
    addCard: 'Agregar tarjeta',
    settings: 'Ajustes',
    accountSetting: 'Configuraciones de la cuenta',
    payment: 'Pago',
    primaryPayment: 'Establecer esta tarjeta como fuente de pago principal',
    successTitle: "¡¡¡Éxito!!!",
    successMsg:"Cambio en la información de facturación actualizada correctamente",
    NoSuccessTitle: "¡¡¡No se detectaron cambios!!!",
    NoSuccessMsg:"No se encontraron cambios con la información de facturación existente."

  },

  dataRequest: {
    yourDataRequest: 'Sus solicitudes de datos',
    remain: 'Restante',
    complete: 'Terminada',
    Fetching: 'Obteniendo',
    Fetched: 'obtenido',
    appForKeyword: ' Aplicaciones para palabra clave - ',
    keywordsForApp: ' Palabras clave para la aplicación - ',
    appsForCategory: ' aplicaciones superiores para la categoría - ',
    competitorsForApp: ' competidores sugeridos para la aplicación - ',
  },

  users: {
    manageComp: 'Gestionar empresas',
    comp: 'Compañías',
    addDisManageComp: 'Agregar, deshabilitar, administrar todas las empresas',
    createCompAdmin: 'Crear nuevo administrador de la empresa',
    enterEmail: 'Ingrese correo electrónico',
    allCompAdmin: 'Administrador de todas las empresas',
    rowPerPages:"Keywords"
  },

  manageSubscription: {
    trialPeriod: "Estás en periodo de prueba",
    trialPeriodExpire: "Su versión de prueba caducará el",
    updateCard: "Actualiza tu tarjeta",
    subscriptionDetail: "Detalles de suscripción",
    p1: "Próxima facturación el",
    p2: "Administre la dirección de facturación, el historial de pagos y otros detalles desde aquí",
    planListing: 'Listado de planes',
    furtherPlan: 'Seleccione el plan para continuar',
    generalPlan: 'GENERAL',
    month: 'mes',
    tryIt: 'Pruébalo todo el tiempo que quieras',
    createCampaign: 'Crear campaña',
    keyAnalysis: 'Análisis de palabras clave',
    automationRule: 'Reglas de automatización',
    keyOpt: 'Optimización de palabras clave',
  },

  billingDetails: {
    billInfo: 'Datos de facturación',
    compName: 'nombre de empresa',
    addLine: 'Dirección',
    city: 'Ciudad',
    zip: 'Cremallera',
    vat: 'IVA Número',
    updateBillAdd: 'Actualizar dirección de facturación'
  },

  dateRangePresets: {
    today: 'hoy dia',
    yesterday: 'ayer',
    weekToDate: 'Semana hasta la fecha',
    lastWeek:'La semana pasada',
    lastSevenDays:'Los últimos 7 días',
    monthToDate:'Mes hasta la fecha',
    lastMonth:'El mes pasado',
    lastThirtyDays:'últimos 30 días'
  },

  chartHeaders: {
    accounts: 'Cuentas',
    apps: 'Aplicaciones',
    campaign: 'Campañas',
    adgroup: 'Grupos de anuncios',
    keyword: 'Palabras clave',
    creativeset: 'Conjuntos creativos',
    searchterm: 'Términos de búsqueda',
  },

  goals: {
    selectGoal: 'Seleccionar objetivo',
    selectEventType: 'Seleccionar tipo de evento',
    selectGoalRule: 'Seleccione el tipo de objetivo',
    selectEventRule: 'Por favor seleccione el tipo de evento',
    goalNameRule: 'Ingrese el nombre del objetivo',
    manageGoals: 'Administrar objetivos',
    goalDesc: 'Puedes gestionar tus objetivos desde aquí',
    createGoal: 'Crear objetivo',
    editGoal: 'Editar objetivo',
    chooseGoal: 'Elija un tipo de objetivo',
    chooseEvent: 'Eventos elegidos)',
    goalName: 'Nombre del objetivo',
    close: 'Cerca',
    update: 'Actualizar',
    create: 'Crear',
    name: 'Nombre',
    app: 'aplicación',
    appId: 'identificación de la aplicación',
    type: "Escribe",
    events: "Eventos",
    createdOn: "Creado en",
    createdBy: "Creado por",
    action: "Acción",
    revenue: "Ingresos",
    purchase: "Compra",
    subscribe: "Suscribir",
    userEngagement: "Compromiso del usuario",
    levelComplete: "Nivel completado",
    regComp: "Registro completo",
    tutComp: "Tutorial completa",
    startTrial: "Iniciar prueba",
    appLaunch: "Lanzamiento de la aplicación",
    userAction: "Acción del usuario",
    addToCart: "Añadir al carrito",
    addToWishlist: "Añadir a la lista de deseos",
    checkoutStart: "Inicio de pago"
  },

  campaignGenerator: {
    decisionMessage: '¿Estás segura de que quieres eliminar la plantilla?',
    addKeyPlaceholder: 'Escriba texto separado por comas y presione enter o haga clic en agregar',
    showDraft: 'Mostrar borrador',
    pageHeading : 'Generador de campañas',
    createCampaignHeading: 'Configuración de campaña automatizada',
    Segmentation: 'Segmentación',
    Categories: 'Categorías',
    Brand: 'Marca',
    Category: 'Categoría',
    Discovery: 'Descubrimiento',
    Competitor: 'Competidor',
    Custom: 'Personalizado',
    Criteria: 'Criterios',
    pleaseSelectCriteria: 'Por favor, seleccione criterios',
    pleaseEnterValue: 'Por favor ingrese valor',
    Value: 'Valor',
    To: 'Para',
    From: 'De',
    FromTo10: 'Ingrese un valor con una diferencia mayor a 10 desde el primer valor',
    customSlugHeading: 'Slug de campaña personalizado:',
    customSlugPH: 'Ingrese un nombre de slug',
    customSlugAdd: 'Agregar',
    customSlugDup: 'Nombre de slug duplicado',
    SegmentationRules: 'Reglas de segmentación',
    SegmentationDesc: 'Las campañas tienen presupuestos y grupos de anuncios que se centran en un tema o estrategia común para promocionar su aplicación.',
    age: 'Edad',
    geo: 'Ubicación',
    selectCustomerType: 'Seleccione el tipo de cliente',
    selectGender: 'Por favor seleccione Género',
    selectAge: 'Por favor seleccione Edad',
    keyManually: 'Agregar palabras clave manualmente',
    Summary: 'Resumen',
    SummaryText: 'Ver resumen de campañas y grupos de anuncios',
    TotalCampaigns: 'Total de campañas',
    TotalAdgroups: 'Total de grupos de anuncios',
    CampaignTitle: 'Título de la campaña',
    keysReady: 'las palabras clave están listas',
    custom: 'personalizado',
    keysFetch: 'Obteniendo palabras clave para',
    Campaignsarecreated: 'Se crean campañas',
    Creatingcampaigns: 'Creando campañas',
    Viewallitems: 'Ver todos los artículos',
    Adgroupsarecreated: 'Adgroupsss are created',
    Creatingadgroups: 'Se crearon los grupos de anuncios',
    Keywordsarecreated: 'Se crean palabras clave',
    Creatingkeywords: 'Creación de palabras clave',
    ready: 'listo',
    CreatedBy: 'Creado por',
    CreatedOn: 'Creado el',
    Campaigngenerationdetails: 'Detalles de generación de campaña',
    footerProps: {
      itemsPerPageText: "Filas por página",
      itemsPerPageAllText: 'Todos',
      showFirstLastPage: true,
    },
    generateCampaigns: 'Generar campañas',
    setDefaultValues: 'Establecer valores predeterminados',
    setDefaultValuesSub: 'Proporcione la configuración de presupuesto predeterminada para las ubicaciones objetivo.',
    nokeyPara: 'No es necesario ingresar palabras clave para las campañas de categoría, descubrimiento o competencia, ya que las palabras clave se generarán automáticamente utilizando nuestra tecnología de análisis de palabras clave.',
    waitingToSync: 'Esperando para sincronizar',
    campSyncHour:'Las campañas se sincronizarán con Apple en una hora.',
    //syncInProgress: 'Sincronización en curso',
    syncInProgress: 'Sincronizando',
    syncInProgressFewMin: 'La sincronización está en curso y debe completarse en unos minutos.',
    syncComplete: 'Sincronización completa',
    allSyncCompleted: 'Todas las campañas se han sincronizado correctamente.',
    campNameUniq: 'Ingrese nombres de campaña únicos',
    campNamExists: 'Ya existe una campaña con el mismo nombre',
    adNameUniq: 'Ingrese nombres de grupos de anuncios únicos',
    saveAsDraft: 'Guardar como borrador',
    draftHeading: 'Borrador de plantilla de campaña',
    draftSearch: 'Búsqueda',
    headerOrgName: 'Nombre de la organización',
    headerAppName: 'Nombre de la aplicación',
    headerResume: 'Reanudar'
  },

  appSetting: {
    manage: 'Administrar',
    setDefaultValues: 'Establecer valores predeterminados',
    edit: 'Editar',
    update:'Actualizar',
    noRecords: 'No se encontraron registros coincidentes',
    searchByCountry:'Buscar por país'
  },

  sr: {
    pageHeading: 'Informes programados',
    createScheduleReport: 'Crear informe programado',
    srDesc: 'Los informes programados le permiten enviar informes por correo o retrasarlos en su propia hora y día definidos',
    createSR: 'Programar nuevo informe',
    for: 'Reportar para',
    type: 'Repetir',
    dataDays: 'Datos para el último',
    reportNameExists: '¡El nombre ya existe!',
    enterReportName: 'Ingrese un nombre',
    repeatMode: 'Tipo de repetición',
    pleaseSelectFor: 'Seleccione un nivel de informe',
    pleaseSelectType: 'Seleccione un tipo de repetición',
    pleaseSelectDay: 'Por favor seleccione día(s)',
    pleaseSelectTime: 'Por favor seleccione una hora',
    noRepeat: 'No repetir',
    custom: 'Personalizado',
    daily: 'Diario',
    weekly: 'Semanal',
    monthly: 'Mensual',
    selectOrg: 'Seleccione una organización',
    selectGoal: 'Por favor seleccione una meta',
    goalsSelected: 'Objetivos seleccionados',
    orgsSelected: 'Organizaciones seleccionadas',
    scheduleReport: 'informe programado?',
    scheduleReports:'Programar informes',
    reportOf:'Informe de',
    selectTime:'Seleccionar hora',
    downloadReport:'Descargar Informe',
    addMore:'Añadir más',
    scheduleReport1:'Informe de programación',
    selectSlackChannel:'Seleccionar canal de holgura',
    emailNoSpaces:'El correo electrónico no puede contener espacios',
    emailWarning:'Seleccione al menos un correo electrónico o ingrese al menos un ID de correo electrónico externo a continuación',
    errorDuringSave:'Error al guardar el informe programado',
    scheduleSuccess:'Programado con éxito',
    scheduleSaveWarning:'¿Está seguro de que desea programar este informe?'
  },
  deleteAds: {
    deleteAd: 'Eliminar anuncio',
    para: '¿Estás segura de que quieres eliminar el anuncio?',
    cancel: 'Cancelar',
    delete: 'Borrar',
    ok:'OK'
  },
  //EditAds
  editAd: {
    editAdWizard: 'Asistente para editar anuncios',
    adName: 'Nombre del anuncio',
    cancel: 'Cancelar',
    save: 'Ahorrar'
  },
  createAdRules: {
    adRequired: '¡Se requiere el nombre del anuncio!',
    adNameSize: 'El nombre de su anuncio debe tener menos de 200 caracteres.'
},
  common : {
    save: 'Guardar',
    cancel: 'Cancelar',
    noDataText: 'No hay datos para mostrar',
    days: 'Día(s)',
    time: 'Tiempo',
    na: 'NA',
    goal: 'Objetivo(s)',
    deleteConfirmText: '¿Estás seguro de eliminar esto ',
    createConfirmText: '¿Proceder a crear este nuevo ',
    updateConfirmText: '¿Estás seguro de actualizar esto ',
    dataTableFooterProps: {
      itemsPerPageText: "Filas por página",
      itemsPerPageAllText: 'Todos',
      showFirstLastPage: true,
    },
  },

  welcomeOnboard: {
    yes: 'YES',
    no: 'NO',
    continue: 'Continuar',
    welcome: 'BIENVENIDOS A SEARCH ADS MAVEN',
    description: '¡Bienvenido a Search Ads Maven. Comencemos configurando su integración. necesitará su ID de Apple y si su ASA utiliza autenticación de doble factor, necesitará el dispositivo asociado.',
    getStart: 'Vamos a comenzar',
    setupLater: 'Ir a la aplicación y configuración más tarde',
    setup: 'Configuración',
    createIntegration: 'Crear integración',
    createIntDescription: 'Ingrese su ID de Apple para crear la sincronización bidireccional entre Search Ads Maven y Apple Search Ads. Si tiene un socio de MMP, esas integraciones también se pueden configurar aquí. Comencemos importando sus datos de Apple Search Ads"',
    enterAppleId: 'INTEGRE SU ID DE APPLE',
    success: 'Éxito',
    stepper: {
      title: '¡Sincronización en curso!',
      descreption1: 'Estamos importando sus datos históricos de Apple Search Ads. Dependiendo de la cantidad de datos, puede tardar algunas horas en completarse. Puede verificar el indicador de progreso en la esquina superior derecha en cualquier momento durante esta sincronización y, una vez que se haya completado, visite la página Integraciones.',   
      descreption2: 'Mientras tanto, familiaricémonos con la plataforma.',
    },
    stepperTwo: {      
      title: 'Integración MMP',
      descreption:'Los MMP son herramientas de medición de terceros que le permiten medir y comparar la efectividad de su gasto publicitario en muchos canales de marketing y socios diferentes en datos de participación de la aplicación de embudo completo. La característica más importante de MMP que nos ayudará aquí es analizar y optimizar nuestro rendimiento de ASA frente a los eventos que ocurren después de que el cliente abandona la tienda de aplicaciones y comienza a usar la aplicación.',
      successfullIntegrations:'Integraciones exitosas',
      receivedTypes: ' Tipos de eventos recibidos'
      },
    startGuide: 'Guia de início',
    syncMsg: {
      your: 'Su',
      syncWasLess: 'la sincronización fue menor que',
      moreTime: 'atrás. Espere más tiempo antes de volver a sincronizar.'
    },
    goToApplication: 'ir a la aplicación',
    overview: {
      title: '¡Bienvenido a la página de descripción general de Search Ad Maven!',
      description: 'Aquí es donde comenzará cada vez que inicie sesión en la plataforma. Aquí encontrará una descripción general de sus campañas actuales, métricas asociadas y más. Puede personalizar esta vista para mostrar lo que será más útil y le servirá como referencia rápida en todas sus actividades de ASA',
      popupSecond: {
        title: 'Gracias por explorar rápidamente Search Ads Maven con nosotros',
        description: ' Si aún tiene preguntas o problemas con la integración, comuníquese con su equipo de soporte de Search Ads Maven enviando un correo electrónico a ' 
      }
    },
    adsManager: {
      title: 'Esta es la pestaña Administrador de anuncios',
      description: 'Aquí puede ver análisis e informes detallados para sus aplicaciones, campañas, grupos de anuncios, palabras clave y más. Además, cree campañas manualmente para eliminar las conjeturas del proceso.  La sección Administrador de anuncios también es donde puede crear campañas, grupos de anuncios, palabras clave y palabras clave negativas.'
    },
    keywordAnalysis: {
      title: 'Esta es la pestaña Análisis de palabras clave',
      description: 'donde puede medir la efectividad general de las palabras clave de sus campañas y ver competidores sugeridos para su aplicación, y conquistar las palabras clave de su competidor. También puede agregar rápidamente palabras clave complementarias a su campaña'
    },
    keywordOptimization:{
      title: 'Esta es la pestaña de optimización de palabras clave',
      description: 'donde puede optimizar de manera rápida y efectiva los montos de sus ofertas para palabras clave que no se encuentran en el rango de ofertas mínimas y máximas sugeridas por ASA. Encuentre métricas de objetivos basadas en los eventos de su aplicación incluidos junto con las recomendaciones de ASA'
    },
    integrations: {
      title: 'Esta es la página de integración.',
      description: 'En la integración, puede integrar el ASA, la cuenta de atribución y la cuenta de Slack.'
    },
    automation: {
      title: 'Testa es la pagina de automatizacion',
      description: 'donde puede crear reglas de automatización para sus organizaciones, aplicaciones, campañas, grupos de anuncios y palabras clave'
    },
    goals: {
      title: 'Esta es la página de creación de objetivos.',
      description: 'donde puede crear objetivos de campaña basados ​​en aplicaciones en torno a métricas de ingresos, participación del usuario y eventos de acción del usuario'
    },
    campaignGenerator: {
      title: 'Esta es la pestaña del generador de campañas.',
      description: 'donde puede crear campañas ASA manualmente o elegir utilizar el generador de campañas automatizado de Search Ads Maven. Establezca los presupuestos de su campaña y personalice su campaña según sus especificaciones'
    },
    strategicView: {
      title: 'Bienvenido a la Vista Estratégica',
      description: 'Domina la complejidad de Apple Search Ads con las Estrategias de Search Ads Maven. Agrupa campañas, grupos de anuncios y palabras clave en entidades simplificadas, impulsadas por automatización y hitos claros. Construye, ejecuta y mide el éxito, todo al alcance de tus manos. Simplifica, optimiza, gana.'
    },
    thirdParty: {
      popupOne: {
        title: '¿Le gustaría integrar sus datos de eventos de terceros?',
        description: ''
      },
      popupTwo: {
        title: '',
        description: 'Los datos de eventos de terceros no se pueden importar hasta que se complete la sincronización de ASA. Regrese a esta página cuando su sincronización haya alcanzado el 100%'
      },
      popupThree: {
        title: '',
        description: 'Su información de ASA tardará un tiempo en completarse en Search Ads Maven, así que mientras tanto, familiaricémonos con la plataforma.'
      },    
    }
  }


}
