import { LS_DRAFT_KEY } from "@shared/config";
import { IAdGroup, IAudience, ICampaign, ICreativeSet, INegativeKeywords, ITargetKeywords, IkaiQuery, IDataRequests, IIntApps, OverviewDD, DatasetsTabs, DatatableTabs, AdsManagerFilters, IOwnApps, CustomViews, deleteInt } from "@shared/interfaces";

/**
* @class LocalStorageService
* @description
* A wrapper class for working with localstorage.
*/
class LocalStorageService {

  public static set token(token: string) {
    this.set('token', token);
  }

  public static get token(): string {
    return this.get<string>('token');
  }

  public static set adminToken(token: string) {
    this.set('adminToken', token);
  }

  public static get adminToken(): string {
    return this.get<string>('adminToken');
  }

  public static set sessId(sessId: string) {
    this.set('sessId', sessId);
  }

  public static get sessId(): string {
    return this.get<string>('sessId');
  }

  public static set locale(locale: string) {
    this.set('locale', locale);
  }

  public static get locale(): string {
    return this.get<string>('locale');
  }

  public static set orgId(orgId: string) {
    this.set('orgId', orgId);
  }

  public static get orgId(): string {
    return this.get<string>('orgId');
  }

  public static set adamId(adamId: string) {
    this.set('adamId', adamId);
  }

  public static get adamId(): string {
    return this.get<string>('adamId');
  }

  public static get audience(): IAudience {
    return this.get<IAudience>('audience');
  }

  public static set audience(audience: IAudience) {
    this.set<IAudience>('audience', audience);
  }

  public static get adGroup(): IAdGroup {
    return this.get<IAdGroup>('adGroup');
  }

  public static set adGroup(adGroup: IAdGroup) {
    this.set<IAdGroup>('adGroup', adGroup);
  }

  public static get targetKeywords(): ITargetKeywords[] {
    return this.get<ITargetKeywords[]>('targetKeywords');
  }

  public static set targetKeywords(targetKeywords: ITargetKeywords[]) {
    this.set<ITargetKeywords[]>('targetKeywords', targetKeywords);
  }

  public static get keysFromKai(): ITargetKeywords[] {
    return this.get<ITargetKeywords[]>('keysFromKai');
  }

  public static set keysFromKai(keysFromKai: ITargetKeywords[]) {
    this.set<ITargetKeywords[]>('keysFromKai', keysFromKai);
  }

  public static get negativeKeywords(): INegativeKeywords[] {
    return this.get<INegativeKeywords[]>('negativeKeywords');
  }

  public static set negativeKeywords(negativeKeywords: INegativeKeywords[]) {
    this.set<INegativeKeywords[]>('negativeKeywords', negativeKeywords);
  }

  public static get campaign(): ICampaign {
    return this.get<ICampaign>('campaign');
  }

  public static set campaign(campaign: ICampaign) {
    this.set<ICampaign>('campaign', campaign);
  }

  public static get creativeSet(): ICreativeSet {
    return this.get<ICreativeSet>('creativeSet');
  }

  public static set creativeSet(creativeSet: ICreativeSet) {
    this.set<ICreativeSet>('creativeSet', creativeSet);
  }

  public static get dataRequests(): IDataRequests[] {
    return this.get<IDataRequests[]>('dataRequests');
  }

  public static set dataRequests(dataRequests: IDataRequests[]) {
    this.set<IDataRequests[]>('dataRequests', dataRequests);
  }

  public static get kaiQuery(): IkaiQuery {
    return this.get<IkaiQuery>('kaiQuery');
  }

  public static set kaiQuery(kaiQuery: IkaiQuery) {
    this.set<IkaiQuery>('kaiQuery', kaiQuery);
  }

  public static get kaIntApps(): IIntApps[] {
    return this.get<IIntApps[]>('kaIntApps');
  }

  public static set kaIntApps(kaIntApps: IIntApps[]) {
    this.set<IIntApps[]>('kaIntApps', kaIntApps);
  }

  public static set company(company: string) {
    this.set('company', company);
  }

  public static get company(): string {
    return this.get<string>('company');
  }

  public static set loginSync(loginSync: string) {
    this.set('loginSync', loginSync);
  }

  public static get loginSync(): string {
    return this.get<string>('loginSync');
  }

  public static set overviewDD(overviewDD: OverviewDD) {
    this.set('overviewDD', overviewDD);
  }

  public static get overviewDD(): OverviewDD {
    return this.get<OverviewDD>('overviewDD');
  }

  public static set isFirstTimeLogin(isFirstTimeLogin: boolean) {
    this.set('isFirstTimeLogin', 'false');
  }

  public static get isFirstTimeLogin(): boolean {
    return this.get<boolean>('isFirstTimeLogin');
  }
  public static set drsba(drsba: number) {
    this.set('drsba', drsba);
  }

  public static get drsba(): number {
    return this.get<number>('drsba');
  }

  public static set drsbk(drsbk: string) {
    this.set('drsbk', drsbk);
  }

  public static get drsbk(): string {
    return this.get<string>('drsbk');
  }

  public static set drsc(drsc: number) {
    this.set('drsc', drsc);
  }

  public static get drsc(): number {
    return this.get<number>('drsc');
  }

  public static set drctd(drctd: number) {
    this.set('drctd', drctd);
  }

  public static get drctd(): number {
    return this.get<number>('drctd');
  }

  public static set drctt(drctt: string) {
    this.set('drctt', drctt);
  }

  public static get drctt(): string {
    return this.get<string>('drctt');
  }

  public static set draik(draik: string) {
    this.set('draik', draik);
  }

  public static get draik(): string {
    return this.get<string>('draik');
  }

  public static set attr(attr: boolean) {
    this.set('attr', attr);
  }

  public static get attr(): boolean {
    return this.get<boolean>('attr');
  }

  public static set chartDatasets(chartDatasets: DatasetsTabs) {
    this.set('chartDatasets', chartDatasets);
  }

  public static get chartDatasets(): DatasetsTabs {
    return this.get<DatasetsTabs>('chartDatasets');
  }

  public static set datatableDatasets(datatableDatasets: DatatableTabs) {
    this.set('datatableDatasets', datatableDatasets);
  }

  public static get datatableDatasets(): DatatableTabs {
    return this.get<DatatableTabs>('datatableDatasets');
  }

  public static set customViews(customViews: CustomViews[]) {
    this.set<CustomViews[]>('customViews', customViews);
  }

  public static get customViews(): CustomViews[] {
    return this.get<CustomViews[]>('customViews');
  }

  public static get ownApps(): IOwnApps[] {
    return this.get<IOwnApps[]>('ownApps');
  }

  public static set ownApps(ownApps: IOwnApps[]) {
    this.set<IOwnApps[]>('ownApps', ownApps);
  }

  public static set filters(filters: AdsManagerFilters) {
    this.set('filters', filters);
  }

  public static get filters(): AdsManagerFilters {
    return this.get<AdsManagerFilters>('filters');
  }

  public static set isRefetchRequired(isRefetchRequired: string) {
    this.set('isRefetchRequired', isRefetchRequired);
  }

  public static get isRefetchRequired(): string {
    return this.get<string>('isRefetchRequired');
  }

  public static set asaInt(asaInt: boolean) {
    this.set('asaInt', asaInt);
  }

  public static get asaInt(): boolean {
    return this.get<boolean>('asaInt');
  }

  public static set firstSyncDone(firstSyncDone: boolean) {
    this.set('firstSyncDone', firstSyncDone);
  }

  public static get firstSyncDone(): boolean {
    return this.get<boolean>('firstSyncDone');
  }

  public static set activeSync(activeSync: boolean) {
    this.set('activeSync', activeSync);
  }

  public static get activeSync(): boolean {
    return this.get<boolean>('activeSync');
  }

  public static set loginActivity(loginActivity: string[]) {
    this.set<string[]>('loginActivity', loginActivity);
  }

  public static get loginActivity(): string[] {
    return this.get<string[]>('loginActivity');
  }

  public static set onboard(onboard: boolean) {
    this.set('onboard', onboard);
  }

  public static get onboard(): boolean {
    return this.get<boolean>('onboard');
  }

  public static set editSAR(editSAR: string) {
    this.set('editSAR', editSAR);
  }

  public static get editSAR(): string {
    return this.get<string>('editSAR');
  }

  public static set persisteDataset(persisteDataset: string) {
    this.set('persisteDataset', persisteDataset);
  }

  public static get persisteDataset(): string {
    return this.get<string>('persisteDataset');
  }

  public static set selectedDefaultView(selectedDefaultView: string) {
    this.set('selectedDefaultView', selectedDefaultView);
  }

  public static get selectedDefaultView(): string {
    return this.get<string>('selectedDefaultView');
  }

  public static set isGoalSelected(isGoalSelected: string) {
    this.set('isGoalSelected', isGoalSelected);
  }

  public static get isGoalSelected(): string {
    return this.get<string>('isGoalSelected');
  }

  public static set disableTogglePopup(disableTogglePopup: boolean) {
    this.set('disableTogglePopup', disableTogglePopup);
  }

  public static get disableTogglePopup(): boolean {
    return this.get<boolean>('disableTogglePopup');
  }

  public static set bulkActionId(bulkActionId: string) {
    this.set('bulkActionId', bulkActionId);
  }

  public static get bulkActionId(): string {
    return this.get<string>('bulkActionId');
  }
  public static set ctoFlag(ctoFlag: boolean) {
    this.set('ctoFlag', ctoFlag);
  }

  public static get ctoFlag(): boolean {
    return this.get<boolean>('ctoFlag');
  }

  public static set dateRange(dateRange: string[]) {
    this.set<string[]>('dateRange', dateRange);
  }

  public static get dateRange(): string[] {
    return this.get<string[]>('dateRange');
  }
  public static set deleteIntStatus(deleteIntStatus: deleteInt[]) {
    this.set('deleteIntStatus', deleteIntStatus);
  }

  public static get deleteIntStatus(): deleteInt[] {
    return this.get<deleteInt[]>('deleteIntStatus');
  }

  /**
  * @function set
  * @description set a key value pair in localstorage
  * @param  {string} key
  * @param  {T} value
  * @returns {void}
  */
  public static set<T = any>(key: string, value: T): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
  * @function get
  * @description get a value by key from localstorage
  * @param  {string} key
  * @returns {T} value
  */
  public static get<T = any>(key: string): T {
    return JSON.parse(localStorage.getItem(key) as string);
  }

  /**
  * @function clear
  * @description clears all values from localstorage
  * @returns {void}
  */
  public static clear(): void {
    // localStorage.clear();
    localStorage.removeItem('token')
    localStorage.removeItem('adminToken')
    localStorage.removeItem('sessId')
    localStorage.removeItem('locale')
    localStorage.removeItem('orgId')
    localStorage.removeItem('adamId')
    localStorage.removeItem('audience')
    localStorage.removeItem('adGroup')
    localStorage.removeItem('targetKeywords')
    localStorage.removeItem('keysFromKai')
    localStorage.removeItem('negativeKeywords')
    localStorage.removeItem('campaign')
    localStorage.removeItem('creativeSet')
    localStorage.removeItem('dataRequests')
    localStorage.removeItem('kaiQuery')
    localStorage.removeItem('kaIntApps')
    localStorage.removeItem('company')
    localStorage.removeItem('loginSync')
    localStorage.removeItem('overviewDD')
    localStorage.removeItem('drsbk')
    localStorage.removeItem('drsba')
    localStorage.removeItem('drsc')
    localStorage.removeItem('drctd')
    localStorage.removeItem('drctt')
    localStorage.removeItem(LS_DRAFT_KEY)
    localStorage.removeItem('attr')
    localStorage.removeItem('chartDatasets')
    localStorage.removeItem('datatableDatasets')
    localStorage.removeItem('ownApps')
    localStorage.removeItem('filters')
    localStorage.removeItem('asaInt')
    localStorage.removeItem('onboard')
    localStorage.removeItem('activeSync')
    localStorage.removeItem('loginActivity')
    localStorage.removeItem('customViews')
    localStorage.removeItem('dateRange')
    localStorage.removeItem('deleteIntStatus');
  }

  /**
  * @function clearOther
  * @description clears all values from localstorage except user details
  * @returns {void}
  */
  public static clearOther(): void {

    localStorage.removeItem('orgId')
    localStorage.removeItem('adamId')
    localStorage.removeItem('audience')
    localStorage.removeItem('adGroup')
    localStorage.removeItem('targetKeywords')
    localStorage.removeItem('negativeKeywords')
    localStorage.removeItem('campaign')
    localStorage.removeItem('creativeSet')
    localStorage.removeItem(LS_DRAFT_KEY)
  }
}

export {
  LocalStorageService as LSS
}
