//Chinese
export const zh = {
  pages: {
    overview: '概述',
    adsManager: '广告经理',
    kai: '关键词分析',
    keyOpt: '关键词优化',
    teamMgmt: '团队',
    integrations: '集成',
    automationRule: '自动化',
    campaignGenerator: '活动生成器',
    goals: '目标',
    appSettings: '应用设置',
    kar: '关键字分析请求',
    manualCampaign:'添加手动广告系列',
    automatedCampaign:'添加自动营销活动',
  },
  menu: {
    settings: '设置',
    signOut: '登出',
    manageSubscription: "管理订阅",
    orgs: '组织'
  },
  buttons: {
    syncData: '同步最新数据',
    createCampaign: '创建活动',
    filter: '筛选',
    reset: '重启',
    apply: '申请'
  },
  labels: {
    charts: '图表',
    selectDs: '选择数据集',
    totalSummary: '总摘要',
    spend: '花费',
    search: '搜索',
    searchByKeySearchMsg: '输入文本并按 进入',
    competeSearchMsg : '选择或搜索应用',
    selectCategoryMsg: '选择一个类别',
    selectCategoryType: '选择类型',
    matchType: '比赛类型',
    filterItem: '在此处输入以过滤以下项目',
    cusType: '客户类型',
    createRule: '创建规则',
    fromSelected: '从选择',
    mmYY: '月/年'  ,
    enterKey: '输入关键字',
    selectLevel: '选择级别',
    org: '组织',
    app: '应用程序',
    camp: '广告系列',
    adGroup: '广告组',
    key: '关键字',
    searchTerm: '搜索词',
    yourApps: '你的应用程序',
    suggestedApps: '推荐的应用程序',
  },
  dropdowns: {
    noData: '无可用数据',
    daily: '日常的',
    weekly: '每周',
    monthly: '每月'
  },
  tabs: {
    accounts: '帐户',
    apps: '应用',
    campaigns: '活动',
    adgroups: '广告组',
    keywords: '关键词',
    creativeSets: '创意集',
    searchTerms: '搜索词',
    negKeywords: '否定关键字',
    ad: '广告'
  },
  summary: {
    taps: '点击',
    impressions: '展现',
    ttr: 'TTR',
    newDownloads: '新下载',
    reDownloads: '重新下载',
    installs: '安装',
  },
  accountHeaders: {
    account: '帐户',
    spend: '花费',
    averageCpa: '平均每次转化费用',
    averageCpt: '平均 CPT',
    impressions: '展现',
    taps: '点击',
    installs: '安装',
    ttr: 'TTR',
    conversionRate: '兑换率',
    newDownloads: '新下载',
    reDownloads: '重新下载'
  },
  appsHeaders: {
    appName: '应用',
    spend: '花费',
    averageCpa: '平均每次转化费用',
    averageCpt: '平均 CPT',
    impressions: '展现',
    taps: '点击',
    installs: '安装',
    ttr: 'TTR',
    conversionRate: '兑换率',
    attrInstall: '属性安装',
    attrInstallRate: 'Attr 安装率',
    cpi: '消费物价指数',
    goals: '目标',
    costPerGoal: '每个目标的成本',
    goalRate: '目标率',
    revenuePerGoal: '每个目标的收入',
    goalsRevenue: '目标收入',
    goalsRoas: '目标广告支出回报率',
    newDownloads: '新下载',
    reDownloads: '重新下载',
    account: '帐户',
    categoryRank: '品类排名',
    appId: '应用程序 ID',
    totalCamp: '活动总数',
    goalAMPU: '目标 AMPU'
  },
  campaignHeaders: {
    campaign: '活动',
    campaignStatus: '活动状态',
    spend: '花费',
    averageCpa: '平均每次转化费用',
    averageCpt: '平均 CPT',
    impressions: '展现',
    taps: '点击',
    installs: '安装',
    newDownloads: '新下载',
    reDownloads: '重新下载',
    ttr: 'TTR',
    conversionRate: '兑换率',
    attrInstallRate: 'Attr 安装率',
    cpi: '消费物价指数',
    goals: '目标',
    costPerGoal: '每个目标的成本',
    goalRate: '目标率',
    revenuePerGoal: '每个目标的收入',
    goalsRoas: '目标广告支出回报率',
    storefronts: '店面',
    account: '帐户',
    displayStatus: '显示状态',
    appName: '应用名称',
    appId: '应用程序 ID',
    campaignId: '广告系列 ID',
    delete: '删除',
    totalBudget: '总预算',
    dailyCap: '每日上限',
    supplySource:'类型'
  },
  campaignDraftHeaders: {
    campaignName: '活动名称',
    countries: '国家',
    budgetAmount: '预算额',
    resume: '恢复',
  },
  adGroupHeaders: {
    adgroup: '广告组',
    adgroupStatus: '广告组状态',
    spend: '花费',
    averageCpa: '平均每次转化费用',
    averageCpt: '平均 CPT',
    impressions: '展现',
    taps: '点击',
    installs: '安装',
    newDownloads: '新下载',
    reDownloads: '重新下载',
    ttr: 'TTR',
    conversionRate: '兑换率',
    attrInstallRate: 'Attr 安装率',
    cpi: '消费物价指数',
    goals: '目标',
    costPerGoal: '每个目标的成本',
    goalRate: '目标率',
    revenuePerGoal: '每个目标的收入',
    goalsRoas: '目标广告支出回报率',
    storefronts: '店面',
    campaignStatus: '活动状态',
    displayStatus: '显示状态',
    account: '帐户',
    appId: '应用程序 ID',
    campaignId: '广告系列 ID',
    adgroupId: '广告组 ID'
  },
  keyHeaders: {
    keyword: '关键词',
    keywordStatus: '关键字状态',
    bidAmount: '投标金额',
    spend: '花费',
    averageCpa: '平均每次转化费用',
    averageCpt: '平均 CPT',
    impressions: '展现',
    taps: '点击',
    installs: '安装',
    newDownloads: '新下载',
    reDownloads: '重新下载',
    ttr: 'TTR',
    conversionRate: '兑换率',
    attrInstallRate: 'Attr 安装率',
    cpi: '消费物价指数',
    goals: '目标',
    costPerGoal: '每个目标的成本',
    goalRate: '目标率',
    revenuePerGoal: '每个目标的收入',
    goalsRevenue: '目标收入',
    storefronts: '店面',
    campaignStatus: '活动状态',
    adgroupStatus: '广告组状态',
    organicRank: '有机排名',
    appName: '应用名称',
    difficultyScore: '难度分数',
    popularity: '人气',
    keywordId: '关键字 ID',
    appId: '应用程序 ID',
    campaignId: '广告系列 ID',
    adgroupId: '广告组 ID',
    suggestedMinBid: 'ASA 建议最低出价',
    suggestedMaxBid: 'ASA 建议最高出价',
    bidStrength: '投标强度',
    latOff: 'LAT 关闭安装',
    latOn: 'LAT 安装',
    matchtype: '比赛类型',
  },
  adsHeaders: {
    ads: '广告',
    creativeType: '广告素材类型',
    creativeSetStatus: '地位',
    spend: '花费',
    averageCpa: '平均每次转化费用',
    averageCpt: '平均 CPT',
    impressions: '展现',
    taps: '点击',
    installs: '安装',
    newDownloads: '新下载',
    reDownloads: '重新下载',
    ttr: 'TTR',
    conversionRate: '兑换率',
    attrInstallRate: 'Attr 安装率',
    cpi: '消费物价指数',
    goals: '目标',
    costPerGoal: '每个目标的成本',
    goalRate: '目标率',
    revenuePerGoal: '每个目标的收入',
    goalsRevenue: '目标收入',
    storefronts: '店面',
    campaignStatus: '活动状态',
    adgroupStatus: '广告组状态',
    account: '帐户',
    appId: '应用程序 ID',
    campaignId: '广告系列 ID',
    adgroupId: '广告组 ID',
    action:'行動',
    delete: '刪除'
  },
  creativeHeaders: {
    creativeSet: '创意集',
    creativeSetStatus: '地位',
    spend: '花费',
    averageCpa: '平均每次转化费用',
    averageCpt: '平均 CPT',
    impressions: '展现',
    taps: '点击',
    installs: '安装',
    newDownloads: '新下载',
    reDownloads: '重新下载',
    ttr: 'TTR',
    conversionRate: '兑换率',
    attrInstallRate: 'Attr 安装率',
    cpi: '消费物价指数',
    goals: '目标',
    costPerGoal: '每个目标的成本',
    goalRate: '目标率',
    revenuePerGoal: '每个目标的收入',
    goalsRevenue: '目标收入',
    storefronts: '店面',
    campaignStatus: '活动状态',
    adgroupStatus: '广告组状态',
    account: '帐户',
    appId: '应用程序 ID',
    campaignId: '广告系列 ID',
    adgroupId: '广告组 ID'
  },
  searchTermsHeader: {
    searchTerm: '搜索词文本',
    spend: '花费',
    averageCpa: '平均每次转化费用',
    averageCpt: '平均 CPT',
    impressions: '展现',
    taps: '点击',
    installs: '安装',
    ttr: 'TTR',
    conversionRate: '兑换率',
    newDownloads: '新下载',
    reDownloads: '重新下载',
    storefronts: '店面',
    campaignStatus: '活动状态',
    adgroupStatus: '广告组状态',
    keyword: '关键词',
    keywordId: '关键字 ID',
    appId: '应用程序 ID',
    campaignId: '广告系列 ID',
    adgroupId: '广告组 ID'
  },
  negativeHeaders: {
    keyword: '关键词',
    matchType: '比赛类型',
    status: '地位',
    adgroupId: '广告组 ID',
    campaignId: '广告系列 ID',
    deleted: '已删除',
    keywordId: '关键字 ID',
    modificationTime: '修改时间'
  },
  filter: {
    account: '选择账户',
    app: '选择应用',
    goal: '选择目标',
    campaign: '选择活动',
    adgroup: '选择广告组',
    ad: "选择广告"
  },
  subTabs: {
    all:'全部',
    live: '居住',
    paused: '暂停',
    deleted: '已删除',
    draft: '草案',
    campaigns: '活动',
    adgroups: '广告组',
    keywords: '关键词',
    creativeSet: '创意集',
    negKey: '否定关键字'
  },
  popups: {
    add: '添加',
    integration: '一体化',
    adgroup: '广告组',
    keywords: '关键词',
    creativeSet: '创意集',
    negKey: '否定关键字',
    campaigns: '活动',
  },

  //Team Management Page
  teamMgmt: {
    manageUsers: '管理会员',
    invite: '邀请和管理团队成员',
    add: '添加成员',
    first: '名',
    last: '姓',
    phone: '电话',
    email: '电子邮件',
    job: '职称',
    allMembers: '所有成员',
    processing: '加工...',
    ok: '好的',
    inviteStatus: '邀请状态',
    isDisabled: '被禁用',
    company: '公司名称'
  },

  //Add Integration Page
  integration: {
    assPart: '合作伙伴',
    apple: '苹果搜索广告',
    slack: '松弛',
    itunes: 'iTunes',
    tenjin: '天神',
    kochava: '科恰瓦',
    adjust: '调整',
    appsFlyer: '应用传单',
    singular: '单数',
    communication:'通讯',
    mmp:'移动测量合作伙伴',
    completeInt:'已完成的集成',
    receivedEventType:'接收事件类型',
    waitForEvents:'等待事件',
    integrateWith:'集成',
    intStep:'集成步骤',
    integrate:'整合',
    followIntSteps:'遵循这些集成步骤',
    //kochava steps
    kochavaStep1:'1. 在 https://go.kochava.com/apps 访问您的 Kochava 应用程序。',
    kochavaStep2:'2. 从应用的上下文菜单中选择合作伙伴配置。',
    kochavaStep3:'3. 单击“合作伙伴配置”页面中的“添加配置”。',
    kochavaStep4:'4. 从 Media Partner 菜单中选择 Search Ads Maven - iOS 并点击 Go。',
    kochavaStep5:'5. 从您希望联合到 Search Ads Maven 的每个事件的上下文菜单中选择创建。',
    kochavaStep6:'6. 为每个回发创建使用此处提供的 API KEY 和 App Store ID。',
    kochavaStep7:'7. 让 Search Ads Maven 识别事件至少需要 24 小时。请注意，只有归因于 Apple Search Ads 广告系列的事件才会被识别。',
    kochavaStep8:'8. 为 Search Ads Maven 识别的每个事件创建一个目标。创建目标后，仪表板中将提供 MMP 事件数据，例如计数和收入。',

    //branchSteps
    branchStep:'1. 在您的 Branch 仪表板中使用以下 API 密钥和 App Store ID。',

    //customSteps
    customStep:'1. 在回发中使用以下 API 密钥和 App Store ID。',

    apiKey:'API 密钥',
    appStoreId:'应用商店 ID',
    distinctEventType:'归因于 ASA 的不同事件类型已由该应用程序的 MMP 交付。这些事件类型可用于目标创建。',
    noDistinctEventType:'尚未收到任何归因于 Apple Search Ads 的事件。如果 24 小时后仍未收到事件，请仔细检查这些集成步骤并确保有效的 Apple Search Ads 广告系列正在运行，这会触发 MMP 传递的一个或多个事件。',
    dEventType:'不同的事件类型',
    noEventFound:'未找到任何事件',
    close:'关',
    note:'注意：已与其他 MMP 集成的应用不会出现在此列表中。',
    intDeletionInProgress:"目前正在进行集成删除，完成后我们会通知您。"

  },

  //Keyword Auction Insights Page
  kai: {
    addedKeywords: '添加的关键字',
    total: '全部的',
    cancel: '取消',
    runAds: '投放广告',
    ok: '好的',
    totalPaidKey: '总付费关键字',
    popularity: '人气',
    numOfApps: '应用数量',
    chance: '机会',
    appsRunAds: '运行广告的应用',
    maxPop: '您的最大人气',
    keyword: '关键词',
    clear: '清除',
    clrAllFilter: '清除所有过滤器',
    keywords: '关键词',
    organicRank: '有机排名',
    totalApps: '应用程序总数',
    app: '应用程序',
    catName: '分类名称',
    searchByApp: '按应用搜索',
    searchByKey: '按关键字搜索',
    addAllKey: '添加所有关键字',
    country: '国家',
    lang: '语',
    searchForCompetes: '推荐参赛者',
    searchAdsRecommends: '搜索广告推荐',
    categoryTop: '类别热门应用',
    compRating: '评分',
    compVersion: '版本',
    compPrice: '价钱',
    compGenres: '流派',
    monthlyDownloads: '每月下载',
    showDataText: '没有数据显示',
    free: '自由',
    paid: '有薪酬的',
    quickAddCriteria: '快速添加标准',
    tooltip: {
      keyText: "关键词标题",
      organicRank: "有机排名是指不受付费广告影响的搜索引擎的搜索结果。",
      popularity: "关键字的关键字流行度得分。更高的搜索分数意味着更高的关键字搜索量。",
      totalApps: "使用此关键字的应用程序总数",
      appDetails: "应用名称",
      organicRankApp: "有机排名是指不受付费广告影响的搜索引擎的搜索结果。",
      category: "应用所属的类别",
      rating: "应用评分",
      version: "应用程序版本",
      price: "应用程序价格",
      genres: "应用程序类型",
      chance: "机会分数是一个百分比分数，它基于您在应用特定类别中的特定关键字进入前十名的机会的估计值",
      appsRunning: "为关键字付费的应用总数"
    }
  },

  //Overview Page
  overview: {
    selectGoalFirstSelectApp:'要选择目标，请首先从左侧下拉菜单中选择一个应用程序。',
    count:'目标事件数',
    exportCSV:'导出CSV',
    period: '时期',
    topKeywords: '前 10 个关键词',
    key: '关键词',
    spend: '花费',
    impressions: '展现',
    taps: '点击',
    goToKey: '转到所有关键字',
    topAdgroup: '排名前 10 的广告组',
    adgroup: '广告组',
    goToAdgroup: '转到所有广告组',
    gender: '性别',
    device: '设备',
    searchApp: '搜索应用',
    lastDay: '最后一天',
    last7Days: '过去 7 天',
    last30Days: '过去 30 天',
    ttr: '点击率 (TTR)',
    installs: '安装',
    avgCPA: '平均每次转化费用',
    avgCPT: '平均 CPT',
    conversionRate: '兑换率 (CR)',
    latOnInstalls: 'LAT 安装',
    latOffInstalls: 'LAT 关闭安装',
    reDownloads: '重新下载',
    newDownloads: '新下载',
    all: '全部',
    tooltip: '根据选定的日期范围，随时间增加或减少的百分比与上一期间相比。',
    selectPer: '选择期间',
    prevPeriod: '上一期',
    currPeriod: '当前时段',

    GOLS: '目标',
    CPRG: '每个目标的成本',
    RPRG: '每个目标的收入',
    GREV: '目标收入',
    GRAS: '目标 ROAS',
    GETC: '目标 事件计数',
  },

  checklist: {
    title: '清单',
    integrations: '集成',
    searchads: '苹果搜索广告',
    slack: '添加松弛集成',
    kai: '熟悉关键字分析工具',
    competitorMsg: '看看你的竞争对手的表现如何',
    searchKeywords: '查看应用程序的关键字',
    seeApps: '有关关键字，请参阅出价应用程序',
    topCharts: '查看类别顶部图表',
    campaign: '活动',
    initCampaign: '发起活动',
    createAdgroup: '创建广告组',
    addKeywords: '添加关键字',
    addCreativeSet: '添加创意集',
    goLive: '上线',
    reviewMetrics: '审查活动成功',
    automate: '自动化您的广告系列',
    mmpIntegration: 'MMP 集成',
    createMmpGoals: '创建 MMP 目标',
    campGen:'活动生成器',
    scheduledReports:'预定报告'
  },

  //Keyword Optimization Page
 keyOpt: {
  keyBid: '关键词优化',
  keyToolTip: '您可以优化不在 ASA 建议的最低出价金额和 ASA 建议的最高出价金额范围内的关键字的出价金额。',
  optAll: '优化所有',
  opt: '优化',
  optBidAmt: '优化出价金额',
  key: '关键词',
  optBidForAll: '优化所有关键词的出价',
  campaign: '活动',
  adgroup: '广告组',
  bidAmt: '投标金额',
  asaMinBid: 'ASA 建议最低出价',
  asaMaxBid: 'ASA 建议最高出价',
  action: '行动',
  noData: "苹果目前没有任何建议。"
  },

  //ARRules Page
  aRules: {
  createAR: '创建自动化规则',
  view: '看法',
  edit: '编辑',
  delete: '删除',
  disable: '禁用',
  enable: '使能够',
  name: '名称',
  createdAt: '创建于',
  status: '地位',
  completed: '完成时间',
  actionFreq: '动作频率',
  rules: '规则',
  logs: '日志',
  createdFor: '为',
  chckFreq: '检查频率',
  actions: '行动',
  enableDisable: '启用/禁用',
  sno: 'S.No'
  },

  //Create Campaign
  //CampaignView
  createCampaign: {
  createCamp: '创建活动',
  h2: '选择一个应用程序开始制作广告系列',
  para: '广告系列的预算和广告组侧重于推广应用的共同主题或策略。',
  select: '选择广告的投放位置',
  searchResults: '搜索结果',
  srData: '广告系列的预算和广告组侧重于推广应用的共同主题或策略。',
  searchTab: '搜索标签',
  stData: '当用户访问“搜索”选项卡时，在建议的应用列表顶部宣传您的应用。',
  todayTab: '今天标签',
  todayData:'在用户首次来到应用商店发现应用时吸引他们。您的广告将显眼地出现在首页上。',
  productPageTab: '产品页面',
  productPageData: '在用户浏览应用程序时吸引他们。您的广告将出现在顶部。您可能还想列出已滚动到相关产品页面底部的用户。',
  continue: '继续',

  //Search Results and Search Tab Campaign
  countAndReg: '国家和地区',
  adgroupSettings: '广告组设置',
  searchMatch: '搜索匹配',
  key: '关键词',
  audience: '观众',
  crSets: '创意集',
  chooseCampGroup: '选择一个活动组',
  chooseApp: '选择一个应用程序',
  next: '下一个',
  countPara: '您的 app 将在您从列表中选择的符合条件的国家和地区的 App Store 上推广。',
  chooseCount: '选择一个或多个国家或地区',
  campName: '活动名称',
  budget: '预算',
  dailyCap: '每日上限（可选）',
  adgroupPara: '广告组包含决定向谁展示您的广告的定价目标和设置。',
  adgroupName: '广告组名称',
  devices: '设备',
  startTime: '开始时间',
  adScheduling: 'Ad Scheduling',
  endTime: '时间结束',
  dayParting: '分时段（可选）',
  ok: '好的',
  defaultMax: '默认最高 CPT 出价',
  cpaGoal: '每次转化费用目标（可选）',
  saveDraft: '保存为草稿',
  appleSearchMatch: '苹果搜索匹配',
  searchMatchPara: 'Apple Search Match 是让您的广告投放和投放的最简单方法。 Search Ads 会自动将您的广告与正在搜索与您类似的应用的用户进行匹配。',
  searchMatchPara1: '自动将我的广告与相关搜索匹配',
  keywords: '关键词',
  keyPara: '关键字是客户在搜索像您这样的应用程序时可能使用的相关字词或术语。 关键字可让搜索者快速找到您的应用，有助于推动应用安装。',
  upload: '上传',
  suppFormat: '支持格式：Excel、CSV',
  keyManually: '或手动添加关键字',
  targKey: '定位关键字',
  clear: '清除',
  adgroupNegKey: '广告组否定关键字',
  optional: '可选的',
  negKey: '否定关键字',
  audPara: ' 使用可选的高级功能优化广告组的受众。',
  custType: '客户类型',
  demo: '人口统计',
  gender: '性别',
  location: '地点 (搜索)',
  crSetPara: '广告是使用 App Store 产品页面上提供的元数据、屏幕截图和应用程序预览自动创建的。 这些也出现在 App Store 的自然搜索结果中。 此处显示的广告是默认广告示例。',
  crOptional: '创意集（可选）',
  noCrSet: '应用程序不满足最低创意集的要求',
  campCreated: '广告系列已创建',
  campCreatedPara: '现在，您可以在 Search Ads 仪表板上看到您的广告系列',
  close: '关闭',
  matchType: '比赛类型',
  amount: '数量',
  currency: '货币',
  allUsers: '所有用户',
  newUsers: '新用户',
  appUsers: '我的其他应用的用户',
  existingUsers: '现有用户',
  all: '全部',
  male: '男性',
  female: '女性',
  selectApp: '搜索应用',
  chooseCamp: '活动组',
  countries: '国家',
  ageRange: '年龄范围',
  selectDev: '选择设备',
  adsOpt: '广告（可选）',
  default: '默认',
  defaultAdPara: '使用默认应用商店产品页面中的资产。',
  custom: '风俗',
  customPara: '使用默认 App Store Connect 中的资产，并在用户点击广告时将其带到该页面。',
  pagesInfo: '自定义产品页面可用',
  createAdPara: '单击“创建广告”按钮以从您的自定义产品页面选项中进行选择并查看您的广告预览',
  createAdBtn: '制作广告',
  createAds: '制作广告',
  selectCustomPara: '选择一个自定义产品页面，将其资产用于制作您的广告。当用户在搜索结果中点击您的广告时，他们将被带到您选择的页面。',
  adName: '广告名称',
  search: '搜索',
  available: '可用的',
  sortBy: '排序方式',
  save: '节省',
  customAds: '自定义广告',
  edit: '编辑',
  previous: '以前的'
  },

  //Change Password
   changePass: {
     backSett: '返回设置',
     chngPass: '设置密码',
     newPass: '新密码',
     confirmPass: '确认密码',
     submit: '提交',
     h4: 'Kochava 营销人员操作系统™',
     para:
       '营销人员操作系统 (m/OS) 将面向广告商和发布商的全渠道营销解决方案无缝集成到一个运营平台中。',
     ok: '好的',
   },

   //Forgot Password
   forgotPass: {
     forPass: '忘记密码',
     typeEmail: '输入电子邮件地址',
     emailAdd: '电子邮件地址',
     submit: '提交',
     chckEmail: '请查看你的邮箱',
     para: '要设置新密码，请检查您的电子邮件',
     close: '关闭',
     alreadyAcc: '已经有账户？',
     signIn: '登入',
   },

   //Onboard
   onboard: {
     welcome: '欢迎来到 SearchAds Maven',
     personal: '报名',
     start: '开始',
     integration: '一体化',
     next: 'Next',
     trial: '开始试用',
     scheduleDemo: '或与我们一起安排演示并一起发现',
     addInt: '添加集成。',
     signInApple:
       '登录 Apple 以添加 Apple Search Ads 集成。 请注意：',
     step1:
       '1. 需要具有管理员权限才能授予对 Apple Search Ads 帐户的访问权限。',
     step2:
       '2. 为了从 SearchAdsNinja 管理您的广告系列，请在授予访问权限之前选择读取和写入选项。',
     skip: '跳过',
   },

   //AddAdgroup
   addAdgroup: {
     addAd: '添加广告组',
     para:
       '您尚未选择您的广告系列。 您可以从下面的框中选择它。',
     camGroup: '活动组',
     campaign: '活动',
     adgroupSett: '广告组设置',
     para1:
       '广告组包含决定向谁展示您的广告的定价目标和设置。',
     adgroupName: '广告组名称',
     devices: '设备',
     adScheduling: '广告排期',
     startTime: '开始时间',
     endTime: '时间结束',
     dayparting: '分时段（可选）',
     defaultMax: '默认最高 CPT 出价',
     cptTool:
       '这是您愿意为点击您的广告支付的最高金额。除非您应用单个关键字出价，否则此出价将应用于此广告组中的所有关键字。',
     cpaGoal: '每次转化费用目标（可选）',
     cpaTool:
       '您为每次下载支付的平均费用。 这是通过将总花费除以下载次数来计算的。',
     searchMatch: '搜索匹配',
     searchTool:
       '搜索匹配是让您的广告投放并投放的最简单方法。 Search Ads 会自动将您的广告与正在搜索与您类似的应用的用户进行匹配。',
     searchPara: '自动将我的广告与相关搜索匹配',
     key: '关键词',
     keyPara:
       '关键字是客户在搜索像您这样的应用程序时可能使用的相关字词或术语。 关键字可让搜索者快速找到您的应用，有助于推动应用安装。',
     inputCpt: '输入默认最高 CPT 出价以激活关键字部分',
     addKeyPara:
       '添加与您的应用和类型相关的关键字。 我们将通过分享其他建议来提供帮助。',
     upload: '上传',
     addKeyManually: '或手动添加关键字',
     negKey: '广告组否定关键字（可选）',
     supp: '支持格式：Excel、CSV',
     demAge: '人口统计年龄、性别',
     demPara: "根据用户的年龄或性别定位您的广告",
     ageRange: '年龄范围',
     cancel: '取消',
     apply: '申请',
   },

   //Edit Adgroup
   editAdgroups: {
     edit: '修改广告组',
     adgroup: '广告组',
     advance: '高级设置',
     viewEdit: '查看和编辑',
     general: '一般的',
     settings: '设置',
     schedule: '日程',
     custPara: '选择您想要接触的应用客户',
     selectAge: '选择年龄范围',
     save: '保存',
   },

   //Create AR
   createAR: {
     rule:"规则",
     addRule: '添加规则',
     selectDateRange:'选择日期范围',
     createAutoRule: '创建自动化规则',
     editAutoRule: '编辑自动化规则',
     choose: '选择',
     chooseAutoRule: '请选择自动化级别和项目。',
     conditions: '使适应',
     actions: '行动',
     settings: '设置',
     condition: '状况',
     addCondition: '添加条件',
     andCondition:
       '选择以上所有条件必须匹配还是仅匹配其中之一',
     checkCond: '检查条件',
     action: '行动',
     selectAll: '全选',
     enterEmail: '请输入正确的电子邮件 ID',
     addTo: '添加',
     selectAdgroups: '选择广告组',
     selectCampaigns: '选择广告系列',
     cptBid: 'CPT投标',
     customBid: '自定义投标',
     matchType: '比赛类型',
     addAction: '添加操作',
     chckFreq: '检查频率',
     ruleName: '规则名称',
     cancel: '取消',
     step2: '第2步',
     step3: '第 3 步',
     andOrTypesText1: '所有必须匹配',
     andOrTypesText2: '只有一个应该匹配',
     addToModelListNegText1: '所有选定的广告组',
     addToModelListNegText2: '所选广告系列的所有广告组）',
     addToModelListNegText3: '所有选定的广告系列',
     matchTypesText1: '广泛匹配',
     matchTypesText2: '完全符合',
     cptBidItemsText1: '设置为自定义',
     cptBidItemsText2: '广告组默认出价',
     actionFreqsText1: '每小时',
     actionFreqsText2: '每 6 小时',
     actionFreqsText3: '每 12 小时',
     actionFreqsText4: '每天',
     actionFreqsText5: '自定义时间',
     limitActionFreqsText1: '每 2 小时',
     limitActionFreqsText2: '每 3 小时',
     limitActionFreqsText3: '每 2 天',
     limitActionFreqsText4: '每 3 天',
     limitActionFreqsText5: '每周',
     mon: '周一',
     tue: '周二',
     wed: '周三',
     thu: '周四',
     fri: '星期五',
     sat: '周六',
     sun: '星期日',
     ciTimeRangesText1: '过去 1 小时',
     ciTimeRangesText2: '过去 1 天',
     ciTimeRangesText3: '过去 7 天',
     ciTimeRangesText4: '过去 30 天',
     ciTimeRangesText5: '上个月',
     ciTimeRangesText6: '自定义小时',
     ciTimeRangesText7: '日期范围',
     allConOperationText1: '比...更棒',
     allConOperationText2: '少于',
     allConOperationText3: '范围',
     allConOperationText4: '不在范围内',
     amount: '金额',
     budget: '% 预算百分比',
     incBy: '通过增加',
     decBy: '减少',
     setToCPA: '设置为平均每次转化费用',
     setToCPT: '设置为平均 CPT',
     value: '美元价值',
     change: '％ 改变',
     between: '之间',
    equalTo: '等于',
    appsSelected:'已选择应用',
    campaignsSelected:'选择的广告系列',
    adgroupsSelected:'已选择广告组',
    KeywordsSelected:'已选择关键字',
    selectField:'选择字段',
    selectOne:'选择一个',
    pleaseSelect:'请选择',
    pleaseEnteraValue:'请输入一个值',
    fromValue:'从价值',
    toValue:'重视',
    mustBeLesserThanToValue:'必须小于 To 值',
    mustBeHigherThanFromValue:'必须高于 from 值',
    cannotBeZero:'不能为 0',
    ruleAlreadyExist:'规则名称已存在',
    emailNotification:'电子邮件通知',
    slackNotification: '松弛通知',
    pause:'暂停',
    resume:'恢复',
    changeDailyCap:'更改每日上限',
    changeBid:'更改出价',
    changeBudget:'更改预算',
    addAsNegativeKeyword:'添加为否定关键字',
    createActionFor: '创建操作',
    selectAction:'选择动作',
    selectEmail:'选择电子邮件',
    addOtherEmail:'添加其他电子邮件',
    enterAnotherEmail:'输入电子邮件 ID，然后按 Enter 添加更多',
    giveAName:'给这条规则起个名字',
    searchLogs: '按规则过滤日志',
    details: '细节',
    PENDING: '待办的',
    SUCCESS: '成功',
    FAILED: '失败的',
    CREATED: '已创建',
    DELETED: '已删除',
    ENABLED: '已启用',
    DISABLED: '已禁用',
    REACTIVATED: '重新激活',
    UPDATED: '更新',
    CONDITIONMATCHED : '条件匹配',
    CONDITIONNOTMATCHED:'条件不匹配',
    runIndefinite:'无限期运行',
    indefiniteNote:'注意：选择后，此自动化规则将继续以指定的时间间隔应用，并且无论触发多少次都将保持有效。',
    sureToSave:'你确定，你想保存自动化规则吗？',
    update:'更新',
    selectChannel:'选择频道',
    noDataAvailable:'没有可用数据',
    sureToDelete:'您想删除自动化规则吗？',
  },

   //select Account AR
   selectAccountAR: {
     chooseAcc: '选择账户',
     cancel: '取消',
     apply: '申请',
     clearAll: '清除所有',
     accounts: '帐户',
     accountSelected: '选择的帐户',
   },

   //select Apps AR
   selectAppsAR: {
     chooseApp: '选择应用',
     cancel: '取消',
     apply: '申请',
     apps: '应用',
     appSelected: '选择的应用程序',
     clearAll: '清除所有',
   },

   //select Keywords AR
   selectKeywordsAR: {
     keepOneAdGroup: '请仅选择一个广告组以继续。',
     close: '关闭',
     chooseKeywords: '选择关键字',
     cancel: '取消',
     apply: '申请',
     campaigns: '活动',
     expandAll: '展开全部',
     collapseAll: '全部收缩',
     adGroups: '广告组',
     keywords: '关键词',
     keywordSelected: '已选择关键字',
     clearAll: '清除所有',
     excludePausedKeywords:'排除暂停关键字',
   },

   //campaign
   //AddCreativeSet
   addCreativeSet: {
     addCreative: '添加创意集',
     setup: '设置',
     para:
       '选择一个描述创意集主题的名称。 从 App Store 产品页面中选择您想要包含的屏幕截图和预览的语言。',
     createSetName: '创意集名称 ',
     localLang: '本地化语言',
     assetSelection: '资产选择',
     assetSelectionPararaph1:
       'Creative Sets 包含出现在 App Store 产品页面上的屏幕截图和应用程序预览。',
     assetSelectionPararaph2: '对于每个 iPhone 显示尺寸，',
     assetSelectionPararaph3:
       '选择至少一种横向资产或三项纵向资产。',
     assetSelectionPararaph4: '对于每个 iPad 显示尺寸，',
     assetSelectionPararaph5:
       '选择至少一种横向或两种纵向资产。',
     title1: 'iPhone/iPod',
     screenshotsPara1: '请至少选择',
     screenshotsPara2: '一景',
     screenshotsPara3: '或者',
     screenshotsPara4: '两个人像',
     screenshotsPara5: '截图！',
     screenshotsPara6: '请至少选择',
     screenshotsPara7: '一景',
     screenshotsPara8: '或者',
     screenshotsPara9: '三人像',
     screenshotsPara10: '截图！',
     cancel: '取消',
     save: '保存',
   },
   strategy:{
    descriptionValid:"描述为必填项",
    alphaNumeric:"只允许使用字母数字字符",
    textMax:"描述不应超过 250 个字符"
  },
   //DeleteStrategy
   deleteStrategy: {
    deleteCamp: '删除策略',
    para: '您确定要删除该策略吗?',
    cancel: '取消',
    delete: '删除',
  },
   //DeleteCampaign
   deleteCampaign: {
     deleteCamp: '删除广告系列',
     para: '您确定要删除广告系列吗？',
     cancel: '取消',
     delete: '删除',
   },

   //EditCampaign
   editCampaign: {
     editCampWizard: '活动编辑向导',
     campName: '活动名称',
     app: '应用程序',
     countriesOrRegionsPara: '选择一个或多个国家或地区',
     budget: '预算',
     dailyCap: '每日上限',
     cancel: '取消',
     save: '保存',
   },

   //SelectCampaign
   selectCamp: {
     chooseCamp: '选择广告系列',
     noSelectedCamp: '没有选定的活动',
     campaigns: '活动',
     expandAll: '展开全部',
     collapseAll: '全部收缩',
     selectedCampaigns: '选定的活动',
     clearAll: '清除所有',
     cancel: '取消',
     apply: '申请',
   },

   //chargebee
   //EditCard
   editCard: {
     editCreditCard: '编辑信用卡',
     cardNumber: '卡号',
     expiryDate: '到期日',
     cvv: '变异系数',
     saveCard: '存卡',
     updateExpiryPara: '* 您只能更新到期日',
   },

   //creativeset
   //EditCreativeSet
   editCreativeSet: {
     editCreative: '编辑创意集',
     easywebinar: '易网络研讨会',
     softobiz: 'Softobiz 技术私人有限公司',
     setup: '设置',
     editCreativePara1:
       '选择一个描述创意集主题的名称。 从 App Store 产品页面中选择您想要包含的屏幕截图和预览的语言。',
     creativeSetName: '创意集名称',
     localLang: '本地化语言',
     assetSelection: '资产选择',
     assetSelectionPararaph1:
       '创意集 包含出现在 应用商店 产品页面上的屏幕截图和应用程序预览。',
     assetSelectionPararaph2: '对于每个 iPhone 显示尺寸，',
     assetSelectionPararaph3:
       '选择至少一种横向资产或三项纵向资产。',
     assetSelectionPararaph4: '对于每个 iPad 显示尺寸，',
     assetSelectionPararaph5:
       '至少选择一景或两个人像资产。',
     viewDevice: '查看设备显示尺寸',
     title1: 'iPhone/iPod',
     ipad: 'iPad',
     display: '展示',
     default: '默认',
     cancel: '取消',
     save: '保存',
   },

  addIntegration: {
    invalid: '整合无效，请删除再添加'
  },

  navbar: {
    syncProgress: '正在进行数据同步。'
  },

  messages: {
    onHold: '广告系列暂停',
    reasons: '原因'
  },

   //integration
   //DeleteIntegration
   deleteIntegration: {
     deleteInt: '删除集成',
     cardText: '确定要删除集成吗？',
     cancel: '取消',
     delete: '删除',
   },

   //keywords
   //AddKeys
   addKeys: {
     addKeywords: '添加关键字',
     keywordSuggestionsFor: '关键字建议',
     on: '上',
     addedKeywords: '添加的关键字',
     total: '全部的',
     add: '添加',
     cancel: '取消',
     apply: '申请',
     matchExact: '精确的',
     matchBroad: '广阔',
     searchHere: '在这里搜索',
   },

   //AddKeysKai
   addKeysKai: {
     addKeywords: '添加关键字',
     selectTarget: '选择目标',
     h4Camp: '活动：',
     selected: '已选',
     change: '改变',
     h4Adgroups: '广告组:',
     maxCPTBid: '最高 CPT 出价：',
     keywordSuggestionsFor: '关键字建议',
     on: '上',
     recommendedKeywords: '推荐关键词',
     addedKeywords: '添加的关键字',
     add: '添加',
     total: '全部的',
     cancel: '取消',
     apply: '申请',
     ok: '好的',
   },

   //AddKeywords
   addKeywords: {
     h6AddKeywords: '添加关键字',
     selectTarget: '选择目标',
     h4Camp: '活动：',
     selected: '已选',
     change: '改变',
     h4Adgroups: '广告组:',
     maxCPTBid: '最高 CPT 出价：',
     keywordSuggestionsFor: '关键字建议',
     on: '上',
     recommendedKeywords: '推荐关键词',
     addedKeywords: '添加的关键字',
     add: '添加',
     total: '全部的',
     cancel: '取消',
     apply: '申请',
     ok: '好的',
   },

   //AddnKeys
   addnKeys: {
     addNegKeywords: '添加否定关键字',
     add: '添加',
     total: '全部的',
     cancel: '取消',
     save: '保存',
   },

   //EditBidAmount
   editBidAmount: {
     bidAmount: '投标金额',
   },

   //SelectAdGroup
   selectAdGroup: {
     snackbar: '请仅选择一个广告组以继续。',
     close: '关闭',
     chooseAdGroup: '选择广告组',
     cancel: '取消',
     apply: '申请',
     campaigns : '活动',
     expandAll: '展开全部',
     collapseAll: '全部收缩',
     adGroups : '广告组',
     adGroupSelected : '选择的广告组',
     clearAll : '清除所有'
   },

   //negativeKeywords
   //AddNegativeKeywords
   addNegativeKeywords : {
     addNegKeywords : '添加否定关键字',
     selectTarget : '1. 选择目标',
     addNegKeyword : '添加否定关键字',
     paragraph1 : '可以将否定关键字添加到广告系列或广告组。 请从下面的框中选择其中之一。',
     paragraph2 : '您要上传哪个广告系列的关键字？',
     selectCampaign : '选择活动',
     paragraph3 : '您要上传哪个广告组的关键字？',
     selectAdgroup : '选择广告组',
     cancel: '取消',
     easywebinar : '易网络研讨会',
     softobiz : 'Softobiz 技术私人有限公司',
     campaigns : '活动：',
     selected : '已选',
     change: '改变',
     paragraph4 : ' 广告组否定关键字仅适用于该广告组。 如果您希望否定关键字应用于所有广告组，请在广告系列级别进行设置。',
     paragraph5 : ' 在分析广告效果以了解哪些关键字可能不适合您之前，请考虑让广告系列有时间运行。 添加过多否定关键字可能会限制您的广告曝光率。',
     addedKeywords : '添加的关键字',
     add: '添加',
     total: '全部的',
     save: '保存',
   },

   //oAuth
   //SigninSlackBtn
   signinSlackBtn : {
     addSlackChannel : '添加松弛通道',
     sno: '否'
   },

   //DecisionAlert
    decisionAlert : {
 cancel : '取消',
 ok : '好的',
 confirm:'确认'
   },

   //HelloWorld
   helloWorld : {
 welcome : '欢迎使用 Vuetify',
 para1 : '如需与其他 Vuetify 开发人员的帮助和协作，',
 para2 : '请加入我们的在线',
 discord : '不和谐社区',
 h2 : "下一步是什么？",
 impLinks : '重要链接',
 ecosystem : '生态系统',
   },

   //InfoAlert
   infoAlert : {
 ok : '好的',
   },

   successMsg: {
    campaignUpdate: '广告系列已更新。',
    campaignMovedMsg: '状态已更改。活动已移至其他标签',
    campaignMoveFailMsg: "无法更改广告系列状态。",
    adgroupUpdateFailMsg: "无法更新广告组状态。",
    addgroupUpdatedMsg: '状态改变了。广告组移至其他标签',
    keywordMovedMsg: '状态改变了。关键字移至其他标签',
    keywordMoveFailMsg: "无法更新关键字状态。",
    creativeAddedMsg: '添加了创意集。',
    campaignSavedMsg: '广告系列另存为草稿。',
    campaignSaveFailMsg: '目前无法保存广告系列草稿。请再试一次！',
    keyAddedMsg: '添加了关键字。',
    signinFailMsg: '无法登录。请重试！',
    deleteMsg: '删除成功',
    deleteFailMsg: '无法删除。请再试一次！',
    dataUpdateMsg : '数据更新',
    dataUpdateFailMsg: '更新数据时出错',
    AutoDeleteMsg: '自动化规则删除成功',
    requestFailMsg: '无法处理您的请求。请稍后再试',
    AutoRuleDisSuccessMsg: '已成功禁用自动化规则',
    SCAutoRuleDisSuccessMsg: '已成功禁用脚本规则',
    SCAutoRuleEnableMsg: '已成功启用脚本规则',
    AutoRuleEnableMsg: '成功启用自动化规则',
    bidChangeSuccessMsg: '出价金额更改成功。',
    wentWrongMsg: '出了些问题。',
    integrateSuccessMsg : '集成添加成功',
    userCreateMsg: '用户创建成功',
    emailExistMsg: '电子邮件已经存在',
    errorCreateUser: '创建用户时出错',
    inviteFailMsg: '向电子邮件发送邀请链接时出错',
    failTryLaterMsg: '有什么不对劲。请稍后再试',
    adgroupAddedMsg: '添加了广告组。',
    adgroupUpdatedMsg: '广告组已更新。',
    ruleUpdatedMsg: '规则更新成功',
    ruleUpdateFailMsg: '规则更新失败。请稍后再试',
    rCreatedMsg: '规则创建成功',
    rCreationFailMsg: '规则创建失败。请稍后再试',
    assetDataFailMsg: '无法获取资产详细信息。请再试一次！',
    deleteCampFailMsg: '删除广告系列时出错',
    CampDeletedMsg: '广告系列已删除',
    creativeUpdated: '广告素材集已更新。',
    keyAdded: '添加了关键字。',
    negaKeyAddedMsg: '添加到广告组的否定关键字。',
    negKeyAddCamp: '添加到广告系列的否定关键字。',
    errorDeleteKey: '删除关键字时出错',
    keyDeleteS: '关键字已删除。关键字移至已删除的关键字',
    syncStarted: '同步开始。',
    firstSyncStarted:'Apple Search Ads 集成完成！<br> <br> <p style="text-align: justify; color: inherit;">首次同步 ASA 数据时，请等待几分钟。首次同步完成后，您的应用程序和 ASA 数据将立即填充。按照页面右上角的同步进度。<p>',
    integrationWarning:'在与此 MMP 集成之前，需要同步 Apple Search Ads 集成。请在上方添加 Apple Search Ads 集成并等待第一次同步完成，然后再与此 MMP 集成',
    AdDeletedMsg: '已刪除廣告',
    adUpdate: '廣告已更新',
    deleteAdFailMsg: '刪除廣告時出錯',
    deleteStrategyFailMsg: '删除策略时出错',
 StrategyDeletedMsg: '策略已删除',

 },

  createCampaignRules: {
    selectCampGrp: '请选择活动组！',
    selectApp: '请选择一个应用程序！',
    selectCountry: '请选择国家！',
    campRequired: '需要广告系列名称！',
    campNameSize: '您的广告系列名称必须少于 200 个字符',
    adGroupSize: '您的广告组名称必须少于 200 个字符',
    keywordsize: '关键字不能超过 80 个字符',
    budgetRequired: '预算金额为必填项！',
    validBudget: '请输入有效的预算！',
    groupRequired: '需要广告组名称！',
    defMaxCptRequired: '需要默认的最高 CPT 出价！',
    validCptBid: '请输入有效的 CPT 出价！',
    validDailyCap: '请输入有效的每日上限！',
    validCpaGoal: '请输入有效的每次转化费用目标！',
    selectDevices: '请选择设备！',
    fNamereq: '名字为必填项',
    fNameGrt: '名字不能超过 12 个字符',
    textMax: '不得超过 50 个字符',
    textMin: '不得少于 2 个字符',
    fNameValid: '请输入有效的名字！',
    lNameGrt: '姓氏不得超过 12 个字符',
    lNameValid: '请输入有效名称！',
    phoneReq: '需要电话',
    cmpniNameReq: '公司名称为必填项',
    jobTitleReq: '职位名称为必填项',
    emailReq: '电子邮件是必需的',
    emailValid: '电子邮件必须有效',
    selectCamp: '选择活动',
    cardNoReq: '卡号为必填项',
    cardNo16Digit: '卡号必须是数字且长度为 16 位',
    expiryMonthReq: '需要到期月份和年份',
    expiryMMYY: '到期日期必须是 MM/YYYY - 例如。 07/2023',
    invalidExpiry: '无效的到期月份',
    invalidMonth: '无效的到期年份',
    cvvReq: '需要卡 CVV',
    cvvNum3Digit: '卡 CVV 必须是数字和 3 位数字',



  },

  settings: {
    djbcn: '更改密码',
    profileInfo: '档案信息',
    updateProf: '更新配置文件',
    payMethod: '付款方法',
    addCredit: '添加信用卡',
    cardNo: '卡号',
    invoiceHistory: '发票历史',
    invoiceId: '发票_ID',
    dueDate: '到期日',
    paidAt: '付费时间',
    recure: '再次发生的',
    total: '全部的',
    status: '地位',
    active: '行动',
    cardId: 'Card_Id',
    type: '类型',
    expiryMonth: '到期月份',
    expiry: '到期年份',
    action: '行动',
    addCard: '添加卡片',
    settings: '设置',
    accountSetting: '帐号设定',
    payment: '支付',
    primaryPayment: '将此卡设为主要付款来源',
    successTitle: "成功！！！",
    successMsg:"账单信息更改已成功更新",
    NoSuccessTitle: "没有检测到任何变化！！！",
    NoSuccessMsg:"现有账单信息未发现任何变化。"

  },

  dataRequest: {
    yourDataRequest: '您的数据请求',
    remain: '其余的',
    complete: '完全的',
    Fetching: '获取',
    Fetched: '获取',
    appForKeyword: ' 关键字的应用程序 - ',
    keywordsForApp: ' 应用程序 的关键字 - ',
    appsForCategory: ' 类别的热门应用 - ',
    competitorsForApp: ' 为应用推荐的竞争对手 - ',

  },

  users: {
    manageComp: '管理公司',
    comp: '公司',
    addDisManageComp: '添加、禁用、管理所有公司',
    createCompAdmin: '创建新的公司管理员',
    enterEmail: '输入电子邮件',
    allCompAdmin: '所有公司管理员',
    rowPerPages:'关键词'
  },

  manageSubscription: {
    trialPeriod: "您正处于试用期",
    trialPeriodExpire: "您的试用期将于",
    updateCard: "更新您的卡",
    subscriptionDetail: "订阅详情",
    p1: "下一个结算时间",
    p2: "从此处管理帐单地址、付款历史记录和其他详细信息",
    planListing: '计划清单',
    furtherPlan: '选择计划以进一步进行',
    generalPlan: '一般的',
    month: '月',
    tryIt: '只要你喜欢就试试',
    createCampaign: '创建活动',
    keyAnalysis: '关键词分析',
    automationRule: '自动化规则',
    keyOpt: '关键字优化',
  },

  billingDetails: {
    billInfo: '账单信息',
    compName: '公司名',
    addLine: '地址栏',
    city: '城市',
    zip: '压缩',
    vat: '增值税 数字',
    updateBillAdd: '更新帐单地址'
  },

  dateRangePresets: {
    today: '今天',
    yesterday: '昨天',
    weekToDate: '本周至今',
    lastWeek:'上个星期',
    lastSevenDays:'过去 7 天',
    monthToDate:'本月至今',
    lastMonth:'上个月',
    lastThirtyDays:'过去 30 天'
  },

  chartHeaders: {
    accounts: '帐户',
    apps: '应用',
    campaign: '活动',
    adgroup: '广告组',
    keyword: '关键词',
    creativeset: '创意集',
    searchterm: '搜索词',
  },

  goals: {
    selectGoal: '选择目标',
    selectEventType: '选择事件类型',
    selectGoalRule: '请选择目标类型',
    selectEventRule: '请选择活动类型',
    goalNameRule: '请输入目标名称',
    manageGoals: '管理目标',
    goalDesc: '您可以从这里管理您的目标',
    createGoal: '创建目标',
    editGoal: '编辑目标',
    chooseGoal: '选择目标类型',
    chooseEvent: '选择活动',
    goalName: '目标名称',
    close: '关',
    update: '更新',
    create: '创造',
    name: '姓名',
    app: '应用程序',
    appId: '应用 ID',
    type: "类型",
    events: "活动",
    createdOn: "创建于",
    createdBy: "由...制作",
    action: "行动",
    revenue: "收入",
    purchase: "购买",
    subscribe: "订阅",
    userEngagement: "用户参与",
    levelComplete: "关卡完成",
    regComp: "注册完成",
    tutComp: "教程完成",
    startTrial: "开始试用",
    appLaunch: "应用启动",
    userAction: "用户操作",
    addToCart: "添加到购物车",
    addToWishlist: "添加到愿望清单",
    checkoutStart: "结帐开始"
  },
  campaignGenerator: {
    decisionMessage: '您确定要删除模板吗?',
    addKeyPlaceholder: '键入逗号分隔的文本并按回车键或单击添加',
    showDraft: '显示草稿',
    pageHeading : '广告系列生成器',
    createCampaignHeading: '自动营销活动配置',
    Segmentation: '分割',
    Categories: '类别',
    Brand: '品牌',
    Category: '类别',
    Discovery: '发现',
    Competitor: '竞争对手',
    Custom: '自定义',
    Criteria: '标准',
    pleaseSelectCriteria: '请选择标准',
    pleaseEnterValue: '请输入值',
    Value: '价值',
    To: '到',
    From: '来自',
    FromTo10: '请输入与第一个值相差大于 10 的值',
    customSlugHeading: '自定义广告系列标签：',
    customSlugPH: '输入一个 蛞蝓 名称',
    customSlugAdd: '添加',
    customSlugDup: '重复的 蛞蝓 名称',
    SegmentationRules: '分段规则',
    SegmentationDesc: '广告系列的预算和广告组专注于推广您的应用的共同主题或策略。',
    age: '年龄',
    geo: '位置',
    selectCustomerType: '请选择客户类型',
    selectGender: '请选择性别',
    selectAge: '请选择年龄',
    keyManually: '手动添加关键字',
    Summary: '摘要',
    SummaryText: '检查活动和广告组的摘要',
    TotalCampaigns: '总广告系列',
    TotalAdgroups: '广告组总数',
    CampaignTitle: '活动标题',
    keysReady: '关键字已准备好',
    custom: '自定义',
    keysFetch: '获取关键字',
    Campaignsarecreated: '广告系列已创建',
    Creatingcampaigns: '创建活动',
    Viewallitems: '查看所有项目',
    Adgroupsarecreated: '广告组已创建',
    Creatingadgroups: '创建广告组',
    Keywordsarecreated: '关键字已创建',
    Creatingkeywords: '创建关键字',
    ready: '准备好了',
    CreatedBy: '创建者',
    CreatedOn: '创建于',
    Campaigngenerationdetails: '广告系列生成详细信息',
    footerProps: {
      itemsPerPageText: '每页行数',
      itemsPerPageAllText: '全部',
      showFirstLastPage: true,
    },
    generateCampaigns: '生成广告系列',
    setDefaultValues: '设置默认值',
    setDefaultValuesSub: '请提供目标位置的默认预算设置。',
    nokeyPara: '类别、发现或竞争对手活动不需要输入关键字，因为关键字将使用我们的关键字分析技术自动生成。',
    waitingToSync: '等待同步',
    campSyncHour:'活动将在一小时内同步到 Apple。',
    //syncInProgress: '同步进行中',
    syncInProgress: '同步中',
    syncInProgressFewMin: '同步正在进行中，应在几分钟内完成。',
    syncComplete: '同步完成',
    allSyncCompleted: '所有广告系列均已成功同步。',
    campNameUniq: '请输入唯一的广告系列名称',
    campNamExists: '已存在同名活动',
    adNameUniq: '请输入唯一的广告组名称',
    saveAsDraft: '保存为草稿',
    draftHeading: '活动模板草稿',
    draftSearch: '搜索',
    headerOrgName: '组织名称',
    headerAppName: '应用名称',
    headerResume: '恢复'
  },

  appSetting: {
    manage: '管理',
    setDefaultValues: '设置默认值',
    edit: '编辑',
    update:'更新',
    noRecords: '未找到匹配的记录',
    searchByCountry:'按国家搜索'
  },

  sr: {
    pageHeading: '预定报告',
    createScheduleReport: '创建预定报告',
    srDesc: '预定的报告让您可以在自己定义的时间和日期邮寄或松弛报告',
    createSR: '安排新报告',
    for: '报告',
    type: '重复',
    dataDays: '最后的数据',
    reportNameExists: '名称已经存在!',
    enterReportName: '输入一个名字',
    repeatMode: '重复类型',
    pleaseSelectFor: '请选择报告级别',
    pleaseSelectType: '请选择重复类型',
    pleaseSelectDay: '请选择日期',
    pleaseSelectTime: '请选择时间',
    noRepeat: '不要重复',
    custom: '自定义',
    daily: '每日',
    weekly: '每周',
    monthly: '每月',
    selectOrg: '请选择一个组织',
    selectGoal: '请选择一个目标',
    goalsSelected: '目标选择',
    orgsSelected: '组织选择',
    scheduleReport: '预定的报告?',
    scheduleReports:'安排报告',
    reportOf:'的报告',
    selectTime:'选择时间',
    downloadReport:'下载报告',
    addMore:'添加更多',
    scheduleReport1:'计划报告',
    selectSlackChannel:'选择松弛通道',
    emailNoSpaces:'电子邮件不能包含空格',
    emailWarning:'请选择至少一封电子邮件或在下面输入至少一封外部电子邮件 ID',
    errorDuringSave:'保存计划报告时出错',
    scheduleSuccess:'预约成功',
    scheduleSaveWarning:'您确定要安排此报告吗？'
  },
  //Delete Ad
  deleteAds: {
    deleteAd: '刪除廣告',
    para: '您確定要刪除該廣告嗎？',
    cancel: '取消',
    delete: '刪除',
    ok:'好的'
  },
  //EditAds
  editAd: {
    editAdWizard: '廣告編輯嚮導',
    adName: '廣告名稱',
    cancel: '取消',
    save: '節省'
  },
  createAdRules: {
    adRequired: '廣告名稱必填！',
    adNameSize: '您的廣告名稱必須少於 200 個字符'
},
  common : {
    save: '保存',
    cancel: '取消',
    noDataText: '没有数据显示',
    days: '天数',
    time: '时间',
    na: 'NA',
    goal: '目标',
    deleteConfirmText: '你确定要删除这个 ',
    createConfirmText: '继续创建这个新的 ',
    updateConfirmText: '你确定要更新这个 ',
    dataTableFooterProps: {
      itemsPerPageText: '每页行数',
      itemsPerPageAllText: '全部',
      showFirstLastPage: true,
    },
  },



  welcomeOnboard: {
    yes: '是的',
    no: '不',
    continue: '继续',
    welcome: '欢迎使用搜索广告专家',
    description: '欢迎搜索 Ads Maven. 让我们从设置您的集成开始。您将需要您的 Apple ID，如果您的 ASA 使用双因素身份验证，您将需要关联的设备。',
    getStart: '开始',
    setupLater: '稍后转到应用程序和设置',
    setup: '设置',
    createIntegration: '创建集成',
    createIntDescription: '输入您的 Apple ID 以在 Search Ads Maven 和 Apple Search Ads 之间创建双向同步。如果您有 MMP 合作伙伴，也可以在此处设置这些集成。让我们从导入您的 Apple Search Ads 数据开始”',
    enterAppleId: '整合您的 Apple ID',
    success: '成功',
    stepper: {
      title: '同步进行中！',
      descreption1: '我们正在从 Apple Search Ads 导入您的历史数据。根据数据量，可能需要几个小时才能完成。您可以在此同步期间随时查看右上角的进度指示器，同步完成后，访问集成页面。',   
      descreption2: '同时，让我们熟悉一下这个平台。',
    },
    stepperTwo: {      
      title: 'MMP 集成',
      descreption:'MMP 是第三方衡量工具，可让您衡量和比较跨许多不同营销渠道和合作伙伴的广告支出在全渠道应用参与度数据上的有效性。在这里可以帮助我们的最大 MMP 功能是针对客户离开应用商店并开始使用应用后发生的事件分析和优化我们的 ASA 性能。',
      successfullIntegrations:'成功整合',
      receivedTypes: '收到的事件类型'
      },
    startGuide: '入门指南',
    syncMsg: {
      your: '您的',
      syncWasLess: '同步小于',
      moreTime: '前。请在再次同步之前等待更多时间。'
    },
    goToApplication: '前往申请',


    overview: {
      title: '欢迎来到概览 ',
      description: '在这里，您将找到当前活动效果、相关指标等的摘要。您可以自定义此视图以满足您的需要。',
      popupSecond: {
        title: '感谢您探索 Search Ads Maven',
        description: '如果您对集成有任何疑问或问题，请随时联系您的支持团队' 
      }
    },
    adsManager: {
      title: '欢迎使用广告管理器',
      description: '在这里，您可以查看应用、广告系列、广告组、关键字等的精细分析和报告。此外，手动创建广告系列以消除流程中的猜测。 您还可以在广告管理工具版块创建广告系列、广告组、关键字和否定关键字。'
    },
    keywordAnalysis: {
      title: '欢迎使用关键字分析 ',
      description: '在这里，您可以衡量您的广告系列关键字的整体效果，并查看您的应用程序的建议竞争对手，并征服您的竞争对手的关键字。您还可以快速为您的广告系列添加补充关键字。'
    },
    keywordOptimization:{
      title: '欢迎来到关键字优化',
      description: '在这里，您可以快速有效地优化不在 ASA 建议的最低和最高出价范围内的关键字的出价金额。根据 ASA 建议旁边包含的应用事件查找目标指标。'
    },
    integrations: {
      title: '欢迎来到集成',
      description: '您可以在此处添加 ASA 集成、定义第 3 方 MMP 集成并设置通信渠道。'
    },
    automation: {
      title: ' 欢迎来到自动化 ',
      description: '在这里，您可以为您的组织、应用程序、广告系列、广告组和关键字创建自动化规则.'
    },
    goals: {
      title: '欢迎来到目标',
      description: '在这里，您可以定义如何使用第 3 方 MMP 事件来根据收入、参与度和用户活动创建活动目标. '
    },
    campaignGenerator: {
      title: '欢迎使用活动生成器',
      description: '在这里，您可以手动快速创建广告系列或使用我们的自动广告系列生成器来消除流程中的猜测.'
    },
    strategicView: {
      title: '欢迎来到战略视角',
      description: '利用 Search Ads Maven 策略克服 Apple Search Ads 的复杂性。在自动化和明确的里程碑的支持下，将广告系列、广告组和关键字分组为简化的实体。构建、执行和衡量成功——一切尽在您的掌握之中。简化、优化、获胜。'
    },
    thirdParty: {
      popupOne: {
        title: '您想整合您的第三方事件数据吗？',
        description: ''
      },
      popupTwo: {
        title: '',
        description: '在您的 ASA 同步完成之前，无法导入第三方事件数据。请在您的同步达到 100% 后返回此页面'
      },
      popupThree: {
        title: '',
        description: '您的 ASA 信息需要一些时间才能填充到 Search Ads Maven 中，因此在此期间，让我们熟悉一下该平台'
      },    
    }
  }
}

