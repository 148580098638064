var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            "content-class": "guided-tour-dialog",
            "hide-overlay": "",
            "max-width": "46%",
            persistent: "",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-end px-6 pt-6" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "primary--text",
                        on: {
                          click: function ($event) {
                            return _vm.startExploring()
                          },
                        },
                      },
                      [_vm._v("mdi-close")]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "px-10" }, [
                  _c(
                    "p",
                    { staticClass: "medium-font font-25 color-primary" },
                    [_c("strong", [_vm._v(_vm._s(_vm.currentTourStep.title))])]
                  ),
                  _c(
                    "p",
                    { staticClass: "medium-font font-18 color-primary" },
                    [_vm._v(_vm._s(_vm.currentTourStep.text) + " ")]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-start mt-10" },
                    [
                      _c("v-img", {
                        attrs: {
                          "max-width": "30%",
                          contain: "",
                          src: _vm.currentTourStep.icon,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("v-card-actions", { staticClass: "justify-end" }, [
                _c(
                  "div",
                  { staticClass: "my-5" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "color-primary",
                        attrs: { text: "", outlined: "" },
                        on: {
                          click: function ($event) {
                            return _vm.startExploring()
                          },
                        },
                      },
                      [_c("strong", [_vm._v("START EXPLORING")])]
                    ),
                    _c(
                      "v-btn",
                      {
                        staticClass: "mx-4",
                        attrs: { color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.moveToNext()
                          },
                        },
                      },
                      [_c("strong", [_vm._v("NEXT SECTION")])]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "hide-overlay": "", "max-width": "46%", persistent: "" },
          model: {
            value: _vm.endTourDialog,
            callback: function ($$v) {
              _vm.endTourDialog = $$v
            },
            expression: "endTourDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-text", [
                _c(
                  "div",
                  { staticClass: "d-flex justify-end px-6 pt-6" },
                  [
                    _c(
                      "v-icon",
                      {
                        staticClass: "primary--text",
                        on: {
                          click: function ($event) {
                            return _vm.startExploring()
                          },
                        },
                      },
                      [_vm._v("mdi-close")]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "px-10" }, [
                  _c(
                    "p",
                    { staticClass: "medium-font font-25 color-primary" },
                    [_c("strong", [_vm._v("Thank You!")])]
                  ),
                  _c(
                    "p",
                    { staticClass: "medium-font font-18 color-primary" },
                    [
                      _vm._v(
                        "Thank you for quickly exploring Search Ads Maven with us. If you have any remaining questions or problems with integration, please reach out to your Search Ads Maven support team by emailing support@searchadsmaven.com "
                      ),
                    ]
                  ),
                ]),
              ]),
              _c("v-card-actions", { staticClass: "justify-center" }, [
                _c(
                  "div",
                  { staticClass: "my-6" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "color-primary",
                        attrs: { text: "", outlined: "" },
                        on: {
                          click: function ($event) {
                            return _vm.startExploring()
                          },
                        },
                      },
                      [_c("strong", [_vm._v("START EXPLORING")])]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }