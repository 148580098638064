





































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue'
import { GET_CHECKLIST, DELETE_DR, GET_DATA_REQUESTS } from '../utils/apptweakqueries'
import { decodeToken } from '@shared/utils/commom.util'
import { LSS } from '../core/services';
import { aptweak_categories } from '../utils/aptweak-categories';
import DecisionAlert from '../components/DecisionAlert.vue';

export default Vue.extend({
  name: 'DataRequests',
  components: {
    DecisionAlert,
  },
  data() {
    return {
      open : false,
      tasks: [],
      filteredTasks: [],
      loadingCL: false,
      checkList: null,
      progress: 0,
      fullscreenLoader : null,
      uid: '',
      decisionAlertFlag: false,
      decisionAlertIcon:2,
      decisionAlertTitle: 'Are you sure, you want to stop processing the request?',
      decisionAlertMessage: '',
      decisionAlertData: {},
    }
  },
  props: {
    openDR: {
      type: Object,
      required: false,
    },
  },
  watch: {
    openDR: function (data) {
      this.open = true
      this.getDataRequests()
    }
  },
  methods: {
    askConfirm(task, type) {
      this.decisionAlertFlag = true;
      this.decisionAlertData = {
        from: 'dr',
        now: Date.now(),
        data : {
          dbId : task.id,
          opId : task.opId,
          type : type
        }
      };
    },
    decisionOkAction(event) {
      this.decisionAlertFlag = false;
      if(event.data){
        if(event.data.dbId){
          this.deleteTask(event.data)
        }
      }
    },
    clearDecisionModel() {
      this.decisionAlertFlag = false;
    },
    deleteTask(data){
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
      .query({
        query: DELETE_DR,
        variables: {
          dr_id: data.dbId
        }
      })
      .then(res => {
        this.fullscreenLoader.hide();
        // console.log(res.data.deleteDataRequest);
        if(res.data.deleteDataRequest.code == 0) {
          this.filteredTasks = this.filteredTasks.filter((e) => e.id != data.dbId)
          this.tasks = this.tasks.filter((e) => e.id != data.dbId)
          LSS.dataRequests = this.tasks
          if(data.type === 2) this.$root.$emit('dr-cancel', { opId: data.opId })
          this.calculateProgress()
        }else{
          alert('Server error. Please try later')
        }
      })
      .catch(error => {
        this.fullscreenLoader.hide();
      });
    },
    disableRest(n){
      const cnt = this.tasks.length
      for (let i = 0; i < n; i++) {
        this.tasks[i].disabled = false
      }
      for (let i = n; i < cnt; i++) {
        this.tasks[i].disabled = true
      }
    },
    taskClicked(task){
      // if(task.opId == 4 || task.opId == 5) return
      const appName = (task.text.split(' - '))[1] || 'unknown'
      this.open = false
      if(task.fetched == 0 && task.disabled){
        alert('Progress is still zero, Please wait.')
        return
      }
      if(task.opId == 2){
        LSS.kaiQuery = {
          tab: 1,
          keyword: task.keyword,
          location:task.location,
          language:task.language,
          device:task.device,
          appId: LSS.kaiQuery.appId || 0,
          title: LSS.kaiQuery.title || '',
          icon: LSS.kaiQuery.icon || ''
        }
        LSS.drsba = 0
        // this.$router.push({ name: 'keyword-auction-insights', path : task.path, query : { tab: task.query, keyword: task.keyword }})
      }
      else if(task.opId == 1){
        LSS.kaiQuery = {
          tab: 0,
          appId: task.appId,
          title: appName,
          location:task.location,
          language:task.language,
          device:task.device,
          icon: task.appIcon || '',
          keyword: LSS.kaiQuery.keyword || '',
          CountPref:task.total
        }
        // this.$router.push({ name: 'keyword-auction-insights', path : task.path, query : { tab: task.query, appid: task.appId, title: (task.text).substring(28)}})
      }
      else if(task.opId == 4){
        LSS.kaiQuery = {
          tab: 2,
          appId: task.appId,
          title: appName,
          location:task.location,
          language:task.language,
          device:task.device,
          icon: task.appIcon || '',
          keyword: LSS.kaiQuery.keyword || '',
        }
        LSS.drsba = 0
        // this.$router.push({ name: 'keyword-auction-insights', path : task.path, query : { tab: task.query, appid: task.appId, title: (task.text).substring(28)}})
      }
      else if(task.opId == 5){
        LSS.kaiQuery = {
          tab: 3,
          appId: 0,
          title: appName,
          location:task.location,
          language:task.language,
          device:task.device,
          icon: task.appIcon || '',
          keyword: LSS.kaiQuery.keyword || '',
          catId: task.catId,
          catType: task.catType
        }
        LSS.drsba = 0
        // this.$router.push({ name: 'keyword-auction-insights', path : task.path, query : { tab: task.query, appid: task.appId, title: (task.text).substring(28)}})
      }
      // else if(task.opId == 3){
      //   LSS.kaiQuery = {
      //     tab: 4,
      //     appId: task.appId,
      //   }
      // }
      this.$router.push({path : task.path, query : { q : Date.now() }})
    },
    calculateProgress(){
      let totalProgress = 0
      let cnt = 0
      this.tasks.map((x,i) => {
        if(!this.tasks[i].done) {
          totalProgress = totalProgress + this.tasks[i].progress
          cnt++
        }
      })
      if(cnt == 0){
        this.progress = 100
        this.$root.$emit('new-ds-progress', 100)
      }else{
        this.progress = totalProgress/cnt
        // if(Number.isNaN(this.progress)) this.progress = 0
        if(this.progress || this.progress == 0) this.$root.$emit('new-ds-progress', this.progress)
      }      
    },
    showFiltered(type){
      if(!LSS.dataRequests || LSS.dataRequests.length == 0) return
      switch (type) {
        case 0:
          this.filteredTasks = LSS.dataRequests
          break;
        case 1:
          this.filteredTasks = LSS.dataRequests
          this.filteredTasks = this.tasks.filter(e => !e.done)
          break;
        case 2:
          this.filteredTasks = LSS.dataRequests
          this.filteredTasks = this.tasks.filter(e => e.done)
          break;
        default:
          break;
      }
    },
    getDataRequests() {
      if (!LSS.token || !decodeToken(LSS.token).UserId) return;
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: GET_DATA_REQUESTS,
          variables: {
            at: Date.now(),
          },
        })
        .then((res) => {
          if (res.data.getDataRequests) {
            const items = res.data.getDataRequests;
            let tasks = [];
            if (items.length == 0) {
              this.fullscreenLoader.hide();
              this.calculateProgress()
              return;              
            }            
            items.map((e) => {
              const done = e.completed;
              const disabled = !e.completed;
              const preText = e.completed ? 'Fetched' : 'Fetching';
              if (e.opId == 2) {
                const a = {
                  id: e._id,
                  done: done,
                  preText: preText,
                  text: ' Apps for keyword - ' + e.keyword,
                  path: 'keyword-auction-insights',
                  query: 'key',
                  icon: 'mdi-apps',
                  disabled: disabled,
                  progress: e.progress,
                  total: e.totalCount,
                  fetched: e.currentCount,
                  clientId: e.clientId,
                  appId: 'searching for a keyword',
                  opId: 2,
                  keyword: e.keyword,
                  location: e.location,
                  language: e.language,
                  device: e.device,
                  appIcon: '',
                  catId: 6014,
                  catType: 'free',
                  mlText: (e.completed ? this.$t('dataRequest.Fetched') : this.$t('dataRequest.Fetching'))
                          + this.$t('dataRequest.appForKeyword') + e.keyword
                };
                tasks.push(a);
              } else if (e.opId == 1) {
                const b = {
                  id: e._id,
                  done: (e.currentCount>=e.CountPref)?true:false,
                  preText: preText,
                  text: ' Keywords for App - ' + e.appName,
                  path: 'keyword-auction-insights',
                  query: 'app',
                  icon: 'mdi-order-alphabetical-ascending',
                  disabled: disabled,
                  progress: (e.currentCount>=e.CountPref)?100:((e.currentCount/e.CountPref)*100),
                  total: e.CountPref,
                  fetched: (e.currentCount>=e.CountPref)?e.CountPref:e.currentCount,
                  clientId: e.clientId,
                  appId: e.appId,
                  opId: 1,
                  keyword: 'searching for an app',
                  location: e.location,
                  language: e.language,
                  device: e.device,
                  appIcon: e.icon || '',
                  catId: 6014,
                  catType: 'free',
                  mlText: (e.completed ? this.$t('dataRequest.Fetched') : this.$t('dataRequest.Fetching')) + this.$t('dataRequest.keywordsForApp') + e.appName
                };
                tasks.push(b);
              } else if (e.opId == 5) {
                const b = {
                  id: e._id,
                  done: done,
                  preText: preText,
                  text: ' top Apps for category - ' + aptweak_categories[e.catId] + ` (${e.catType})`,
                  path: 'keyword-auction-insights',
                  query: 'top',
                  icon: 'mdi-filter-variant',
                  disabled: disabled,
                  progress: e.progress,
                  total: e.totalCount,
                  fetched: e.currentCount,
                  clientId: e.clientId,
                  appId: e.appId,
                  opId: e.opId,
                  keyword: 'forCT',
                  location: e.location,
                  language: e.language,
                  device: e.device,
                  appIcon: e.icon || '',
                  catId: e.catId,
                  catType: e.catType,
                  mlText: (e.completed ? this.$t('dataRequest.Fetched') : this.$t('dataRequest.Fetching')) + this.$t('dataRequest.appsForCategory') + aptweak_categories[e.catId] + ` (${e.catType})`
                };
                tasks.push(b);
              } else if (e.opId == 4) {
                const b = {
                  id: e._id,
                  done: done,
                  preText: preText,
                  text: ' suggested competitors for app - ' + e.appName,
                  path: 'keyword-auction-insights',
                  query: 'comp',
                  icon: 'mdi-axis-arrow',
                  disabled: disabled,
                  progress: e.progress,
                  total: e.totalCount,
                  fetched: e.currentCount,
                  clientId: e.clientId,
                  appId: e.appId,
                  opId: e.opId,
                  keyword: 'forSC',
                  location: e.location,
                  language: e.language,
                  device: e.device,
                  appIcon: e.icon || '',
                  catId: 6014,
                  catType: 'free',
                  mlText: (e.completed ? this.$t('dataRequest.Fetched') : this.$t('dataRequest.Fetching')) + this.$t('dataRequest.competitorsForApp') + e.appName
                };
                tasks.push(b);
              } 
            });
            this.tasks = tasks
            LSS.dataRequests = tasks
            this.fullscreenLoader.hide();
            this.calculateProgress()
            this.showFiltered(1)
          }
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
        });
    },
  },
  computed: {
    completedTasks () {
      return this.tasks.filter(task => task.done).length
    },
    remainingTasks () {
      return this.tasks.filter(task => !task.done).length
    },
  },
  mounted(){
    if(LSS.dataRequests){
      this.tasks = LSS.dataRequests
      this.$nextTick(() => {
        this.calculateProgress()
      })
    }

    if(LSS.token && decodeToken(LSS.token)){
      this.uid = decodeToken(LSS.token).UserId
    }

    Vue.prototype.$sckt.on(this.uid, (message) => {
      // console.log('msgToClient', message)
      // console.log('userId', this.uid)
      // let uid = decodeToken(LSS.token).UserId
      this.tasks.map((x,i) => {
        // if(message.userId == uid){
          if(message.opId === 2 && message.data !== 'over' && message.keyword == x.keyword && !message.context){
            this.tasks[i].fetched = this.tasks[i].fetched + 5
            this.tasks[i].progress = this.tasks[i].progress + (500/this.tasks[i].total)
            if(this.tasks[i].fetched != 0 && this.tasks[i].fetched >= this.tasks[i].total){
              this.tasks[i].fetched = this.tasks[i].total
              this.tasks[i].done = true
              this.tasks[i].disabled = false
              this.tasks[i].progress = 100
              this.tasks[i].preText = 'Fetched'
            }
            if(message.keyword === LSS.drsbk) this.$root.$emit('socket-msg-sbk', message, this.tasks[i])
          }
          if(message.opId === 2 && message.data !== 'over' && message.keyword == x.keyword && message.context === 1){
            if(message.keyword === LSS.drsbk)  this.$root.$emit('socket-msg-sbk-stat', message)
            //if this socket msg is missed, populate details from tasks or DB for all details(later through a API call to fetch from here)
          }
          if(message.opId === 2 && message.data !== 'over' && message.keyword == x.keyword && message.context === 2){
            const stat = JSON.parse(message.data)
            this.tasks[i].total = +stat.appsRunningAds
            if(message.keyword === LSS.drsbk)  this.$root.$emit('socket-msg-sbk-stat', message)
            //if this socket msg is missed, populate details from tasks or DB for all details(later through a API call to fetch from here)
          }

          //Search By App
          if(message.opId === 1 && message.data !== 'over' && message.appId == x.appId && !message.context){
            this.tasks[i].fetched = this.tasks[i].fetched + 10
            this.tasks[i].progress = this.tasks[i].progress + (1000/this.tasks[i].total)
            if(this.tasks[i].fetched != 0 && this.tasks[i].fetched >= this.tasks[i].total){
              this.tasks[i].fetched = this.tasks[i].total
              this.tasks[i].done = true
              this.tasks[i].disabled = false
              this.tasks[i].progress = 100
              this.tasks[i].preText = 'Fetched'
            }
            if(message.appId === LSS.drsba) this.$root.$emit('socket-msg-sba', message, this.tasks[i])
          }
          // if(message.opId === 1 && message.appId == x.appId && message.context === 1){
          //   if(message.appId === LSS.drsba) this.$root.$emit('socket-msg-sba-meta', message)
          //   //if this socket msg is missed, populate details from tasks or DB for all details(later through a API call to fetch from here)
          // }
          if(message.opId === 1 && message.appId == x.appId && message.context === 2){
            this.tasks[i].total = +message.data
            if(message.appId === LSS.drsba) this.$root.$emit('socket-msg-sba-count', message)
            //if this socket msg is missed, then populate count from tasks
          }

          //recommendations
          if(message.opId === 3 && message.data !== 'over' && message.appId == x.appId){
            this.tasks[i].fetched = this.tasks[i].fetched + 10
            this.tasks[i].progress = this.tasks[i].progress + (1000/this.tasks[i].total)
            if(this.tasks[i].fetched != 0 && this.tasks[i].fetched >= this.tasks[i].total){
              this.tasks[i].fetched = this.tasks[i].total
              this.tasks[i].done = true
              this.tasks[i].disabled = false
              this.tasks[i].progress = 100
              this.tasks[i].preText = 'Fetched'
            }
          }
        // }
      })
      LSS.dataRequests = this.tasks
      this.calculateProgress()

      //suggested competitors - not added in task list
      if(message.opId === 4 && message.appId === LSS.drsc){
        // console.log(message)
        this.$root.$emit('socket-msg-sc', message)
      }
      //category top - not added in task list
      if(message.opId === 5 && message.categoryId == LSS.drctd && message.type === LSS.drctt){
        // console.log(message)
        this.$root.$emit('socket-msg-ct', message)
      }
      if(message.opId === 60){
        if(LSS.bulkActionId==message.bulkActionId){

        }else{
          LSS.bulkActionId = message.bulkActionId
          this.$root.$emit('socket-msg-ba', message)
        }
        
      }
      if(message.opId === 11){
        // console.log(message)
        this.$root.$emit('socket-msg-cg-catKeys', message)
      }
      if(message.opId === 12){
        // console.log(message)
        this.$root.$emit('socket-msg-cg-compKeys', message)
      }
      if(message.opId === 21){
        // console.log(21,message)
        this.$root.$emit('socket-msg-cg-campaigns', message)
      }
      if(message.opId === 22){
        // console.log(22, message)
        this.$root.$emit('socket-msg-cg-adgroups', message)
      }
      if(message.opId === 23){
        // console.log(23, message)
        this.$root.$emit('socket-msg-cg-keywords', message)
      }    
      if(message.opId === 71 && message.context === 21){
        // if(message.clientId === LSS.draik) 
        this.$root.$emit('socket-msg-aik-list', message)
      }
      if(message.opId === 71 && message.context === 23){
        // if(message.clientId === LSS.draik) 
        this.$root.$emit('socket-msg-aik-set', message)
      }
      if(message.opId === 71 && message.context === 25){
        // if(message.clientId === LSS.draik) 
        this.$root.$emit('socket-msg-aik-nocredits', message)
      }
    })

    this.$root.$on('get-dsrba', (id) => {
      //console.log('get-dsrba',id)
      this.tasks.map(e => {
        if(e.appId === id) this.$root.$emit('socket-msg-sba-get', null, e)
      })
    });

    this.$root.$on('get-dsrbk', (id) => {
      //console.log('get-dsrba',id)
      this.tasks.map(e => {
        if(e.keyword === id) this.$root.$emit('socket-msg-sbk-get', null, e)
      })
    });

    this.$root.$on('get-dr', (from) => {
      this.getDataRequests()     
    });
  }
});
