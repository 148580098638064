




















// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
export default Vue.extend({
  props: {
    ASASyncMetadata: {
      type: Object,
      required: false,
      default: () => { return {} }
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    ASASyncMetadata(data) {
      if(data && data.status) this.dialog = true;
    },
  },
  methods: {


  },
   async mounted() {
 
   }

  
});
