import { API_BASE_URL } from '@config';
import { AUTH_ROUTES } from '@shared/config';
import { HttpService } from '@core/services';
import { ISuccess } from '@shared/interfaces';
import { joinUrl, replaceParamsWithValues } from '@shared/utils';

/**
 * @class LoginService
 * @description service class for handling login process.
 */
export class LoginService {
  private static get url() {
    return joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.signinOry);
  }

  /**
   * @function
   * @name signinOry
   * @description service function for signing in
   * @param {any} signInDetails
   * @returns {Promise<any>} response as a promise
   */
  public static async signinOry(signInDetails: any): Promise<ISuccess> {
    const response = await HttpService.post<ISuccess>(joinUrl(this.url), signInDetails);
    return response;
  }

  /**
 * @function
 * @name isOnObaorded
 * @description service function for isOnObaorded
 * @param {any} userId
 * @returns {Promise<any>} response as a promise
 */
  public static async isOnObaorded(req: any): Promise<ISuccess> {
    const url = joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.isOnBoarded);
    const response = await HttpService.post<ISuccess>(joinUrl(url), req);
    return response;
  }

  /**
 * @function
 * @name getUserDetails
 * @description get user details by user id
 * @param {string} UserID
 * @returns {Promise<any>} response as a promise
 */
  public static async getUserDetails(req: {
    UserID: string,
    SessId: string
  }): Promise<ISuccess> {
    try{
      const url = joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.getUserById);
    
      const response = await HttpService.post<ISuccess>(joinUrl(url), req, {withCredentials: true});
      return response;
    }catch(error){
      throw error;
    }
  }

  /**
 * @function
 * @name getImgKitAuth
 * @description get auth details for imagekit
 * @returns {Promise<any>} response as a promise
 */
   public static async getImgKitAuth(): Promise<ISuccess> {
    try{
      const url = joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.imgKitAuth);
      const response = await HttpService.get<ISuccess>(joinUrl(url), {withCredentials: true});
      return response;
    }catch(error){
      throw error;
    }
  }

  /**
   * @function
   * @name isInvited
   * @description to check user if invited or not
   * @param {string} email
   * @returns {Promise<any>} response as a promise
   */
  public static async isInvited(email: string): Promise<any[]> {
    try{
      const url = joinUrl(API_BASE_URL as string, 'auth/', replaceParamsWithValues(AUTH_ROUTES.isInvited, email));
      const response = await HttpService.get<any[]>(joinUrl(url));
      return response;
    }catch(error){
      throw error;
    }
  }

    /**
 * @function
 * @name pwdVer
 * @description update password version
 * @param {string} UserID
 * @returns {Promise<any>} response as a promise
*/
    public static async pwdVer(req: {
    UserID: string,
    SessId: string
  }): Promise<ISuccess> {
    try{
      const url = joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.pwdVer);
      const response = await HttpService.post<ISuccess>(joinUrl(url), req);
      return response;
    }catch(error){
      throw error;
    }
  }

    /**
 * @function
 * @name isUserDisabled
 * @description get user disable status
 * @param {string} Email
 * @returns {Promise<any>} response as a promise
 */
     public static async isUserDisabled(Email: string): Promise<any[]> {
      try{
        const url = joinUrl(API_BASE_URL as string, 'auth/', replaceParamsWithValues(AUTH_ROUTES.isUserDisabled, Email));
        const response = await HttpService.get<any[]>(joinUrl(url));
        return response;
      }catch(error){
        throw error;
      }
    }
}
