<template>
  <v-app class="grey lighten-4">
    <Navbar v-bind:class="{ active: miniSidebar }" v-if="!isPlanExpired && sidebar" />
    <Nav v-bind:class="{ active: miniSidebar }" v-if="isPlanExpired || !sidebar " />
    <v-main v-bind:class="{ active: miniSidebar }" v-if="!isPlanExpired && sidebar">
      <div class="d-flex flex-column w-100">
      <router-view></router-view>
      <template v-if="version()">
          <p class="text-right pr-6 text-body-1 primary--text ">{{'Version '+ version()}} </p>
        </template>


      </div>
    </v-main>

    <div class="main-outer h-100 d-flex" v-if="isPlanExpired || !sidebar">
      <v-main class="spacing-left" style="padding:0;" >
        <router-view></router-view>
      </v-main>
    </div>

  </v-app>
</template>

<script>
import Navbar from './components/Navbar.vue';
import Nav from './components/Nav.vue';
import { bus } from './main';
import { BusEvents } from './utils/enums';
import { decodeToken } from '@shared/utils/commom.util';
import { LSS } from '@core/services';
import { SAM_CLIENT_VERSION } from '@config';
import LogRocket from 'logrocket';

export default {
  components: { Navbar, Nav },
  name: 'App',
  data() {
    return {
      miniSidebar: true,
      isPlanExpired: false,
      sidebar: false
    };
  },
  methods:{
    version() {
      return SAM_CLIENT_VERSION?.replace('v', '');
    },
    _toggleSidebar() {
      if (['WelcomeMessage', 'Setup'].includes(this.$route.name)) {
        this.sidebar = false;
      } else {
        this.sidebar = true;
      }
    },
    callLogRocket(){
      //related to LogRocket -- start
        let hostEndsWith = (host, ends) => {
            let value = false;
            value = ends.some(element => {
                return host.endsWith(element);
            });
            return value
          };
        let excludeDomain = ["@kochava.com","@searchadsmaven.com","@yopmail.com","pankajsharma303@yahoo.com", "@mailinator.com", "@dispostable.com", "@outlook.com","@machineadvertising.com","@mohmal.im","@mymaily.lol","@mohmal.in"];
        if (decodeToken(LSS.token) && (decodeToken(LSS.token).Email) &&  !hostEndsWith(decodeToken(LSS.token).Email, excludeDomain) ) {
            // LogRocket.init('f59ovk/search-ads-maven');
            LogRocket.init('f59ovk/search-ads-maven', {
              network: {
                requestSanitizer: request => {
                  // if the url contains 'onboard/save'
                  if (request.url.toLowerCase().indexOf('onboard/save') !== -1) {
                    // ignore the request response pair
                    request.body = null;
                  }
                  // if the url contains 'auth/signup-ory'
                  if (request.url.toLowerCase().indexOf('auth/signup-ory') !== -1) {
                    // ignore the request response pair
                    request.body = null;
                  }

                  // if the url contains 'self-service/login'
                  if (request.url.toLowerCase().indexOf('self-service/login') !== -1) {
                    // ignore the request response pair
                    request.body = null;
                  }
                  // otherwise log the request normally
                  return request;
                },
              },
            });
              LogRocket.identify(decodeToken(LSS.token).Email, {
                name: decodeToken(LSS.token).FirstName + decodeToken(LSS.token).LastName,
                email: decodeToken(LSS.token).Email,
              });
          }
    //related to LogRocket -- end
    }
  },
  created() {
    bus.$on(BusEvents.NAV_TOGGLE, (data) => {
      this.miniSidebar = !data;
    });
  },
  beforeMount() {
    if (decodeToken(LSS.token).subscriptionStatus == 'cancelled') {
      this.isPlanExpired = true;
    }
    
    this._toggleSidebar();
    if(window.location.host == 'app.searchadsmaven.com') this.callLogRocket()
    
  },
  destroyed(){
    if(Vue.prototype.$sckt) {
      Vue.prototype.$sckt.disconnect()
      Vue.prototype.$sckt = null
    }
  },
  beforeDestroy(){
    if(Vue.prototype.$sckt) {
      Vue.prototype.$sckt.disconnect()
      Vue.prototype.$sckt = null
    }
    this.$root.$off('userNameFetched');
  this.$root.$off('signInOpen');
  this.$root.$off('overviewOpen');
  },

  mounted() {
    this.$root.$on('userNameFetched', (user) => {
      if (user.subscriptionStatus == 'cancelled') {
        this.isPlanExpired = true;
      }
    });

    this.$root.$on('signInOpen', (user) => {
      this.isPlanExpired = true;
    });

    this.$root.$on('overviewOpen', (data) => {
      this.isPlanExpired = false;
      if(window.location.host == 'app.searchadsmaven.com') this.callLogRocket()
    });
  },
  watch:{
    $route (to, from){
      this._toggleSidebar();
    }
  }
};
</script>

<!--<template>
  <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/registration">Registration</router-link> |
      <router-link to="/login">Login</router-link>
    </div>
    <router-view />
  </div>
</template>-->


<style lang="scss">
@import 'assets/styles/main';

.main-outer{
  .v-main__wrap{
    background: #ffffff;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 601px) {
  .main-outer .spacing-left {
    padding-left: 0px !important;
  }

}
</style>


