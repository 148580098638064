import { ISuccess } from '@shared/interfaces';
import { joinUrl, replaceParamsWithValues } from '@shared/utils';

import { API_BASE_URL } from '@config';
import { CHARGEBEE_ROUTES } from '@shared/config';
import { HttpService } from '@core/services';

/**
 * @class ChargebeeService
 * @description service class for handling Chargebee related operations.
 */
export class ChargebeeService {
  private static get url() {
    return joinUrl(API_BASE_URL as string);
  }

  public static async createPortalSession(): Promise<ISuccess> {
    const cust_id = '';
    const url = joinUrl(this.url, 'chargebee' ,CHARGEBEE_ROUTES.portalSession)
    const response = await HttpService.post<ISuccess>(url, cust_id);
    return response;
  }

  public static async reActivateSubscription(subscription_id: string): Promise<ISuccess> {
    const url = joinUrl(this.url, 'chargebee' , CHARGEBEE_ROUTES.reActivateSub)
    const response = await HttpService.post<ISuccess>(url, subscription_id);
    return response;
  }
}
