import 'vue-loading-overlay/dist/vue-loading.css';

import * as Sentry from "@sentry/vue";
import * as messages from './locales';

import App from './App.vue';
import AxiosReqIntercetor from './interceptors/request.interceptor';
import CountryFlag from 'vue-country-flag'
import Croppa from 'vue-croppa';
import 'vue-croppa/dist/vue-croppa.css';
import DatetimePicker from 'vuetify-datetime-picker'
import { Integrations } from "@sentry/tracing";
import Loading from 'vue-loading-overlay';
import { SENTRY_DSN } from '@config';
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import VueAxios from 'vue-axios'
import { VueHorizontalTimeline } from "vue-horizontal-timeline";
import VueI18n from 'vue-i18n';
import VueNotify from 'vuejs-notify';
import { apolloClient } from "./apollo/client";
import axios from 'axios'
import router from './router/index';
import vuetify from  './plugins/vuetify';
import * as moment from 'moment';
import io from 'socket.io-client';
import { API_WS_URL} from '@config'
import ImageKit from "imagekitio-vue";

export const bus = new Vue();
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';
import VueGtag from "vue-gtag";

Vue.use(VueTelInput);
Vue.component('country-flag', CountryFlag);

Vue.use(VueI18n);

Vue.use(DatetimePicker);
Vue.use(VueHorizontalTimeline);
Vue.use(Croppa);

Vue.config.productionTip = false;
Vue.config.silent = process.env.NODE_ENV === 'development' ? false : true


// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'en', // set locale
  messages, // set locale messages
  silentFallbackWarn: true
})

// HTTP interface.
Vue.use(VueAxios, axios);
AxiosReqIntercetor();
Vue.use(VueGtag, {
  config: { id: "G-N883GJDC7G" }
});
Vue.use(VueApollo);

Vue.use(Loading, {
  color: '#6cd581',
  loader: 'spinner',
  width: 64,
  height: 64,
});

// https://github.com/noxludio/vuejs-notify
Vue.use(VueNotify, {
  position: 'top center',
  presets: {
    success: {
      title: 'Success!',
      timeout: 3000,

      
    },
    error: {
      title: 'Error!',
      timeout: 6000
    },
    warning: {
      title: 'Warning!'
    },
    info: {
      title: 'Info!'
    }
  },
  transition: 300,
  styles: { // these are binded to vue notification
    // Number values get 'px' postfix (except opacity)
    minWidth: null, // default 250
    maxWidth: 500,
    //width: 480 // default null
    
  },
});

Vue.filter('readableNumber', function (value: number) {
  if (value === undefined || value === null ) return ''

  return Number(value).toLocaleString();
})

Vue.filter('localTime', function (value: any) {
  // console.log(value)
  if (value === undefined || value === null ) return ''

  return moment.utc(value).local().format('YYYY-MM-DD HH:MM')
})

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

Vue.prototype.$sckt = io(API_WS_URL, {
  reconnection: true,
  reconnectionDelay: 500,
  transports: ['websocket']
}),

Vue.prototype.$sckt.on("connect", () => {
  console.log('WS-ready');
});

Vue.prototype.$sckt.on("error", (error) => {
  // console.log('Socket error', error);
  if(Vue.prototype.$sckt) Vue.prototype.$sckt.disconnect()
});

Vue.prototype.$sckt.on("disconnect", () => {
  // console.log('Client Disconnected');
});

Vue.prototype.$sckt.emit('msgToServer', 'Hello')


new Vue({
  router,
  vuetify,
  apolloProvider,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
