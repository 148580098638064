// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import VueRouter, { RouteConfig } from 'vue-router';
import multiguard from 'vue-router-multiguard';
import { FlowService } from '@services/flow.service';
import { LSS } from '@core/services';
import Vue from 'vue';
import { decodeToken } from '@shared/utils/commom.util'
import { Kochava } from 'kochava';
import { SubscriptionPermissions } from '../../../../libs/shared/src/lib/enums/shared.enums';
const AddIntegration = () => import('../views/AddIntegration.vue')
const CBPoc = () => import('../views/CBPoc.vue')
const CampaignView = () => import(/* webpackChunkName: "group-createcampaign" */ '../views/CampaignView.vue')
const ChangePasswordOld = () => import('../views/ChangePassword.vue')
const CreateCampaign = () => import(/* webpackChunkName: "group-createcampaign" */ '../views/CreateCampaign.vue')
const Dashboard = () => import('../views/Dashboard.vue')
const ForgotPassword = () => import('../views/ForgotPassword.vue')
const KeywordAuctionInsights = () => import('../views/kai.vue')
const LoginProxy = () => import('../views/LoginProxy.vue')
const Logout = () => import('../views/Logout.vue')
const Onboard = () => import('../views/Onboard.vue')
const WelcomeOnboard = () => import('../views/WelcomeOnboard/WelcomeOnboard.vue');
const WelcomeMessage = () => import('../views/WelcomeOnboard/WelcomeMessage/WelcomeMessage.vue');
const Setup = () => import('../views/WelcomeOnboard/Setup/Setup.vue');
const OnboardFork = () => import('../views/OnboardFork.vue')
const OrganicKeywordRanking = () => import('../views/OrganicKeywordRanking.vue')
const Overview = () => import('../views/Overview.vue')
const SAIntegration = () => import('../views/SAIntegration.vue')
const SearchTabCampaign = () => import(/* webpackChunkName: "group-createcampaign" */ '../views/SearchTabCampaign.vue')
const TodaysTabCampaign = () => import('../views/TodaysTabCampaign.vue')
const ProductPageCampaign = () => import('../views/ProductPageCampaign.vue')
const Settings = () => import('../views/Settings.vue')
const SignUp = () => import('../views/SignUp.vue')
const TeamManagement = () => import('../views/TeamManagement/TeamManagement.vue')
const Verify = () => import('../views/Verify.vue')
const Signin = () => import('../views/Signin.vue');
const Auth = () => import('../views/Auth/Auth.vue');
const Entry = () => import('../views/Auth/Entry/Entry.vue');
const ChangePassword = () => import('../views/Auth/ChangePassword/ChangePassword.vue');
const Recovery = () => import('../views/Auth/Recovery/Recovery.vue');
const KeywordOptimization = () => import('../views/KeywordOptimization.vue');
const ARRules = () => import('../views/ARRules.vue');
const ARRules1 = () => import('../views/ARRules1.vue');
const SlackIntegration = () => import('../views/SlackIntegration.vue');
const CampaignGenerator = () => import('../views/CampaignGenerator.vue');
const CampaignGeneratorDetails = () => import('../views/CampaignGeneratorDetails.vue');
const KochavaIntegration = () => import('../views/KochavaIntegration.vue');
const CampaignList = () => import('../views/CampaignList.vue');
const CampaignListDialog = () => import('../views/CampaignListDialog.vue');
// const Users = () => import('../views/Users.vue')
const SubscriptionPlan = () => import('../views/SubscriptionPlan.vue')
const ExpiredFreeTrial = () => import('../views/ExpiredFreeTrial.vue')
const PricingPlan = () => import('../views/PricingPlan.vue')
const ManageSubscription= () => import('../views/ManageSubscription.vue');
const Goals= () => import('../views/Goals.vue')
const ScheduleReports = () => import('../views/ScheduleReport.vue')
const ScriptedRule = () => import('../views/ScriptedRule.vue')
const KeywordJourney = () => import('../views/KeywordJourney.vue')
const StrategicLosing = () => import('../views/StrategicLosing.vue');
const LateDayWinning = () => import('../views/LastDayWining.vue');
const SARProxy = () => import('../views/SARProxy.vue')
const ActivityLog = () => import('../views/ActivityLog/ActivityLog.vue')
const Strategies= () => import('../views/Strategies.vue')
const UsersV2= () => import('../views/UsersV2/UsersV2.vue')
const kochava = createKochavaInstance();
import { SignUpService } from '@services/signup.service';
import { Roles } from 'src/utils/enums';
import { checkASAIntegrationsState } from './asa-state.guard';
Vue.use(VueRouter);

async function CheckSession(to: any, from: any, next: any)
{
  FlowService.whoIamI()
  .then((res) => {
    if(res.error?.status == 'Unauthorized'){
      next('/login');
    } else {
      
      if(decodeToken(LSS.token).subscriptionStatus == 'cancelled' && decodeToken(LSS.adminToken) && decodeToken(LSS.adminToken).Role != Roles.SUPERADMIN){
        return next('/expired-free-trial');
      }
      if (decodeToken(LSS.token).Role != 'SUPERADMIN') {
        CheckPermission(to.path, next);
      } 
      next();
    }
  })
  .catch((err) => {
    next('/login');
  });
}

async function CheckPermission(path, next)
{
  if(decodeToken(LSS.token) && decodeToken(LSS.token).permissions?.length ){
    const permissions = decodeToken(LSS.token).permissions;
    switch(path){
      case '/goals':
        if(permissions.includes(SubscriptionPermissions.GOALS)){
          next()
        } else{
          next('/overview')
        }
        break;
      case '/ads-manager':
        if(permissions.includes(SubscriptionPermissions.ADSMANAGER)){
          next()
        } else{
          next('/overview')
        }
        break;
      case '/arrules':
        if(permissions.includes(SubscriptionPermissions.AUTOMATIONRULES)){
          next()
        } else{
          next('/overview')
        }
        break;
      case '/team-management':
        if(permissions.includes(SubscriptionPermissions.TEAM)){
          next()
        } else{
          next('/overview')
        }
        break;
      case '/schedule-reports':
        if(permissions.includes(SubscriptionPermissions.REPORTS)){
          next()
        } else{
          next('/overview')
        }
        break;
      case '/campaign-generator':
        if(permissions.includes(SubscriptionPermissions.CAMPAIGNGENERATOR)){
          next()
        } else{
          next('/overview')
        }
        break;
      case '/keyword-auction-insights':
        if(permissions.includes(SubscriptionPermissions.KEYWORDANALYSIS)){
          next()
        } else{
          next('/overview')
        }
        break;
      case '/keyword-optimization':
        if(permissions.includes(SubscriptionPermissions.KEYWORDOPTIMIZATION)){
          next()
        } else{
          next('/overview')
        }
        break;
        case '/strategies':
          if(permissions.includes(SubscriptionPermissions.STRATEGIES)){
            next()
          } else{
            next('/overview')
          }
          break;
      case '/create-campaign':
      case '/searchtab-campaign':
      case '/todaystab-campaign':
      case '/productpage-campaign':
      case '/campaign-view':
        if(permissions.includes(SubscriptionPermissions.CREATECAMPAIGN)){
          next()
        } else{
          next('/overview')
        }
        break;
      default:
        next()
    }
  }else{
    next('/logout');
  }
}

async function CheckSession2(to: any, from: any, next: any)
{
  FlowService.whoIamI()
  .then((res) => {
    if(res.error?.status == 'Unauthorized'){
      next('/login');
    }
    else{
      if(decodeToken(LSS.token).FirstName != '' &&
      decodeToken(LSS.token).FirstName &&
      decodeToken(LSS.token).LastName != '' &&
      decodeToken(LSS.token).LastName &&
      decodeToken(LSS.token).Phone != '' &&
      decodeToken(LSS.token).Phone &&
      decodeToken(LSS.token).CompanyName != '' &&
      decodeToken(LSS.token).CompanyName &&
      decodeToken(LSS.token).JobTitle != '' &&
      decodeToken(LSS.token).JobTitle
      ){
        next('/overview');
      }
      else if(decodeToken(LSS.token).subscriptionStatus == 'cancelled' && decodeToken(LSS.adminToken) && decodeToken(LSS.adminToken).Role != Roles.SUPERADMIN){
        return next('/expired-free-trial');
      }
      next();
    }
  })
  .catch((err) => {
    next('/login');
  });
}

async function CheckSession3(to: any, from: any, next: any)
{
  FlowService.whoIamI()
  .then((res) => {
    if(res.error?.status == 'Unauthorized'){
      next('/login');
    }
    else{
      if((to.name == 'users') && decodeToken(LSS.token).Role != 'SUPERADMIN'){
        next('/login');
      }
      next();
    }
  })
  .catch((err) => {
    next('/login');
  });
}

async function CheckSession5(to: any, from: any, next: any)
{
  FlowService.whoIamI()
  .then((res) => {
    if(res.error?.status == 'Unauthorized'){
      next('/login');
    }
    else{
      if(decodeToken(LSS.token).subscriptionStatus != 'cancelled'){
        next('/overview');
      }
      next();
    }
  })
  .catch((err) => {
    next('/login');
  });
}

async function CheckSession6(to: any, from: any, next: any)
{
  FlowService.whoIamI()
  .then((res) => {
    if(res.error?.status == 'Unauthorized'){
      next('/login');
    }
    else{
      next();
    }
  })
  .catch((err) => {
    next('/login');
  });
}

function createKochavaInstance(): Kochava {
  return new Kochava(
    process.env.VUE_APP_KOCHAVA_APP_ID || 'kokochava-sam-internal-ioww1e', 
    process.env.VUE_APP_KOCHAVA_VERBOSE ?? true, 
    process.env.VUE_APP_KOCHAVA_USE_COOKIE ?? true, 
    process.env.VUE_APP_KOCHAVA_DISABLE_AUTO_INSTALL ?? false, 
    process.env.VUE_APP_KOCHAVA_CUSTOM_TRAITS ?? null, 
  );
} 
async function CheckSession4(to: any, from: any, next: any)
{
  let ssEnabled = false
  ssEnabled = await SignUpService.getSS()
  .catch((err) => {
  });
  FlowService.whoIamI()
  .then((res) => {
    if(res.error?.status == 'Unauthorized'){
      if(ssEnabled) next();
      else window.location.href = 'https://www.searchadsmaven.com/join-the-beta/';
    }
    else{
      if(LSS.token && LSS.sessId){
        next('/overview');
      }else{
        if(ssEnabled) next();
        else window.location.href = 'https://www.searchadsmaven.com/join-the-beta/';
      }
    }
  })
  .catch((err) => {
    next();
  });
}

const routes: Array<RouteConfig> = [

  {
    path: '/',
    redirect: '/login'
  },
  
  {
    path: '/login',
    name: 'Login',
    component: LoginProxy
  },
  {
    path: '/onboard-fork',
    name: 'onboard-fork',
    component: OnboardFork
  },
  {
    path: '/welcome-onboard',
    name: 'WelcomeOnboard',
    component: WelcomeOnboard,
    beforeEnter: CheckSession,
    children: [
      {
        path: '',
        redirect: 'message'
      },
      {
        path: 'message',
        name: 'WelcomeMessage',
        component: WelcomeMessage
      },
      {
        path: 'setup',
        name: 'Setup',
        component: Setup
      }
    ]
  },

  {
    path: '/keyword-journey',
    name: 'KeywordJourney',
    beforeEnter : multiguard([CheckSession, checkASAIntegrationsState]),
    component: KeywordJourney
  },
  {
    path: '/strategic-losing',
    name: 'StrategicLosing',
    beforeEnter : multiguard([CheckSession, checkASAIntegrationsState]),
    component: StrategicLosing
  },
  {
    path: '/late-day-winning',
    name: 'LateDayWinning',
    beforeEnter : multiguard([CheckSession, checkASAIntegrationsState]),
    component: LateDayWinning
  },
  {
    path: '/signin',
    name: 'signin',
    component: Auth,
    children: [
      {
        path: '',
        component: Entry,
      },
      {
        path: 'recovery',
        component: Recovery
      },
      {
        path: 'change-password',
        component: ChangePassword
      }
    ]
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword
  },
  {
    path: '/change-password',
    name: 'change-password', 
    // TODO: this is temporary as we'll remove the old component
    component: ChangePasswordOld
  },
  {
    path: '/signup',
    name: 'signup',
    beforeEnter :  CheckSession4,
    component: SignUp
  },
  {
    path: '/settings',
    name: 'Settings',
    beforeEnter: multiguard([CheckSession, checkASAIntegrationsState]),
    component: Settings
  },
  {
    path: '/onboard',
    name: 'onboard',
    props: {
      kochava
    },
    beforeEnter :  CheckSession2,
    component: Onboard
  },
  


  {
    path: '/users',
    name: 'users',
    beforeEnter :  multiguard([CheckSession3, checkASAIntegrationsState]),
    /* INFO : Allowing the new UsersV2.vue component instead of old one Users.vue */
    // component: Users
    component: UsersV2
  },
  {
    path: '/ads-manager',
    name: 'dashboard',
    beforeEnter: multiguard([CheckSession, checkASAIntegrationsState]),
    props: {
      kochava
    },
    component: Dashboard
  },
  {
    path: '/create-campaign',
    name: 'create-campaign',
    beforeEnter : multiguard([CheckSession, checkASAIntegrationsState]),
    component: CreateCampaign
  },

   {
    /** Note: this route is used as named route in integration redirect */
    path: '/integrations',
    name: 'add-integration',
    beforeEnter : multiguard([CheckSession, checkASAIntegrationsState]),
    component: AddIntegration
  },
  {
    path: '/cb-poc',
    name: 'cb-poc',
    beforeEnter :  CheckSession,
    component: CBPoc
  },
  {
    path: '/keyword-auction-insights',
    name: 'keyword-auction-insights',
    props: {
      kochava
    },
    beforeEnter :  multiguard([CheckSession, checkASAIntegrationsState]),
    component: KeywordAuctionInsights
  },
  {
    path: '/organic-keyword-ranking',
    name: 'organic-keyword-ranking',
    beforeEnter : multiguard([CheckSession, checkASAIntegrationsState]),
    component: OrganicKeywordRanking,
  },

  {
    path: '/settings',
    name: 'settings',
    beforeEnter :  CheckSession6,
    component: Settings,
  },
  {
    path: '/overview',
    name: 'overview',
    props: {
      kochava
    },
    beforeEnter : multiguard([CheckSession, checkASAIntegrationsState]),
    component: Overview
  },
  {
    path: '/verify',
    name: 'verify',
    component: Verify
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/team-management',
    name: 'team-management',
    beforeEnter : multiguard([CheckSession, checkASAIntegrationsState]),
    component: TeamManagement
  },
  {
    path: '/searchtab-campaign',
    name: 'searchtab-campaign',
   beforeEnter  : CheckSession,
    component: SearchTabCampaign
  },
  {
    path: '/campaign-view',
    name: 'CampaignView',
    beforeEnter  : CheckSession,
    component: CampaignView
  },
  {
    path: '/todaystab-campaign',
    name: 'todaystab-campaign',
    beforeEnter  : CheckSession,
    component: TodaysTabCampaign
  },
  {
    path: '/productpage-campaign',
    name: 'productpage-campaign',
    beforeEnter  : CheckSession,
    component: ProductPageCampaign
  },
  {
    path: '/sa-integration/auth/cb',
    name: 'sa-integration',
    beforeEnter :  CheckSession,
    component: SAIntegration
  },
  {
    path: '/sa-integration/auth/cb/onboard',
    // name: 'sa-integration',
    name: 'sa-integration-onboard',
    beforeEnter :  CheckSession,
    component: SAIntegration
  },
  /* {
    path: '/arrules',
    name: 'arrules',
    beforeEnter :  CheckSession,
    component: ARRules
  }, */
  {
    path: '/arrules',
    name: 'arrules',
    beforeEnter : multiguard([CheckSession, checkASAIntegrationsState]),
    component: ARRules1
  },
  // {
  //   path: '/rules',
  //   name: 'rules',
  //   //beforeEnter :  CheckSession,
  //   component: Rule
  // },
  {
    path: '/slack-integration/auth/cb',
    name: 'slack-integration',
    beforeEnter :  CheckSession,
    component: SlackIntegration
  },
  // {
  //   path: '/campaign-generator',
  //   name: 'campaign-generator',
  //   beforeEnter :  CheckSession,
  //   component: CampaignGenerator
  // },
  {
    path: '/campaign-generator-details',
    name: 'campaign-generator-details',
    beforeEnter :  CheckSession,
    component: CampaignGeneratorDetails
  },
  {
    path: '/kochava-integration',
    name: 'kochava-integration',
    beforeEnter :  CheckSession,
    component: KochavaIntegration
  },
  {
    path: '/campaign-list',
    name: 'campaign-list',
    beforeEnter :  CheckSession,
    component: CampaignList
  },
  {
    path: '/campaign-list-dialog',
    name: 'campaign-list-dialog',
    beforeEnter :  CheckSession,
    component: CampaignListDialog
  },
  {
    path: '*',
    redirect: '/login'
  },
  {
    path: '/keyword-optimization',
    name: 'KeywordOptimization',
    props: {
      kochava
    },
    beforeEnter : multiguard([CheckSession, checkASAIntegrationsState]),
    component: KeywordOptimization
  },
  {
    path: '/subscription-plan',
    name: 'SubscriptionPlan',
    beforeEnter :  CheckSession,
    component: SubscriptionPlan
  },
  {
    path: '/pricing-plan',
    name: 'PricingPlan',
    beforeEnter :  CheckSession,
    component: PricingPlan
  },

  {
    path: '/expired-free-trial',
    name: 'ExpiredFreeTrial',
    beforeEnter :  CheckSession5,
    component:  ExpiredFreeTrial
  },
  {
    path: '/manage-subscription',
    name: 'ManageSubscription',
    beforeEnter :  CheckSession,
    component: ManageSubscription
  },
  {
    path: '/goals',
    name: 'Goals',
    beforeEnter : multiguard([CheckSession, checkASAIntegrationsState]),
    component: Goals
  },
  {
    path: '/schedule-reports',
    name: 'ScheduleReports',
    beforeEnter : multiguard([CheckSession, checkASAIntegrationsState]),
    component: ScheduleReports
  },
  {
    path: '/scripted-rule',
    name: 'ScriptedRule',
    beforeEnter : multiguard([CheckSession, checkASAIntegrationsState]),
    component: ScriptedRule
  },
  {
    path: '/sar-proxy',
    name: 'SARProxy',
    beforeEnter: CheckSession,
    component: SARProxy
  },
  {
    path: '/searchads-manage',
    name: 'ActivityLog',
    beforeEnter: multiguard([CheckSession3, checkASAIntegrationsState]),
    component: ActivityLog
  },
  {
    path: '/strategies',
    name: 'Strategies',
    beforeEnter: multiguard([CheckSession, checkASAIntegrationsState]),
    component: Strategies
  }
];

const router = new VueRouter({
  mode: 'history',
  linkExactActiveClass: 'active',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
});

// router.beforeEach((to, from, next) => {
//   // redirect to login page if not logged in and trying to access a restricted page
//   const publicPages = ['/login', '/signup']
//   const authRequired = !publicPages.includes(to.path)
//   const loggedIn = localStorage.getItem('user')
//   if (authRequired && !loggedIn) {
//     return next('/login')
//   }
//   next()
// })

export default router;
