
export enum IntegrationTypes {
  ADJUST = 'ADJUST',
  APPLE_SEARCH_ADS = 'APPLE_SEARCH_ADS',
  APPSFLYER = 'APPSFLYER',
  KOCHAVA = 'KOCHAVA',
  SINGULAR = 'SINGULAR',
  SLACK = 'SLACK',
  TENJIN = 'TENJIN',
}

export enum CheckListFields {
  createCampaign = 'createCampaign',
  saIntegration = 'saIntegration',
  onboarding = 'onboarding',
  overviewPage = 'overviewPage',
  kaiApp = 'kaiApp',
  kaiComp = 'kaiComp',
  kaiRecom = 'kaiRecom',
  adsManager = 'adsManager',
  addMember = 'addMember',
  createRule = 'createRule',
  checkLogs = 'checkLogs',
  optimization = 'optimization',
  buyPlan = 'buyPlan',
  automationRules = 'automationRules',
  addKeys = 'addKeys',
  addnKeys = 'addnKeys',
  addCS = 'addCS',
  createAdgroup = 'createAdgroup',
  kaiKeyword = 'kaiKeyword',
  kaiTop = 'kaiTop',
  slackIntegration = 'slackIntegration',
  teamManagement = 'teamManagement',
  mmpIntegration = 'mmpIntegration',
  createMmpGoals = 'createMmpGoals',
  campGen = 'campGen',
  scheduledReports = 'scheduledReports'
}

export enum GoalTypes {
  //Revenue Level
  Purchase = 'Revenue-Purchase',
  Subscribe = 'Revenue-Subscribe',

  //User Engagement Level
  AppLaunch = 'User Engagement-App Launch',
  RegistrationComplete = 'User Engagement-Registration Complete',
  StartTrial = 'User Engagement-Start Trial', 
  TutorialComplete = 'User Engagement-Tutorial Complete', 
  UELevelComplete = 'User Engagement-Level Complete',

  //User Action Level
  AddtoCart = 'User Action-Add to Cart',
  AddtoWishlist = 'User Action-Add to Wishlist',
  CheckoutStart = 'User Action-Checkout Start',
  UALevelComplete = 'User Action-Level Complete',

  //Other
  Achievement = 'Achievement',
  AdClick = 'Ad Click',
  AdView = 'Ad View',
  AuthenticationSuccess = 'Authentication Success',
  FirstVideoView = 'First Video View',
  QSS = 'QSS',
  Rating = 'Rating',
  Search = 'Search',
  ShareWithFriend = 'Share With Friend',
  View = 'View',
  WatchLater = 'Watch Later'
}

export enum GoalStatus {
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  DISABLED = 'DISABLED',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED'
}


export enum SubscriptionPermissions {
  OVERVIEW = 'OVERVIEW',
  ASAINTEGRATION = 'ASAINTEGRATION',
  CREATECAMPAIGN = 'CREATECAMPAIGN',
  DASHBOARD = 'DASHBOARD',
  ADSMANAGER = 'ADSMANAGER',
  GOALS = 'GOALS',
  ATTRINTEGRATION = 'ATTRINTEGRATION',
  AUTOMATIONRULES = 'AUTOMATIONRULES',
  SCRIPTEDAUTOMATION ='SCRIPTEDAUTOMATION',
  TEAM = 'TEAM',
  REPORTS = 'REPORTS',
  CUSTOMVIEWS = 'CUSTOMVIEWS',
  CAMPAIGNGENERATOR = 'CAMPAIGNGENERATOR',
  KEYWORDANALYSIS = 'KEYWORDANALYSIS',
  KEYWORDOPTIMIZATION = 'KEYWORDOPTIMIZATION',
  STRATEGIES='STRATEGIES'
}

export enum SARMetricsFields {
  rule_run = 'rule_run',
  rule_tested = 'rule_tested',
  rule_created = 'rule_created',
  rule_updated = 'rule_updated',
  rule_run_with_goal = 'rule_run_with_goal',
  campaign_paused = 'campaign_paused',
  adgroup_paused = 'adgroup_paused',
  keyword_paused = 'keyword_paused',
  keyword_bid_increased = 'keyword_bid_increased',
  keyword_bid_decreased = 'keyword_bid_decreased',
  campaign_budget_increased = 'campaign_budget_increased',
  campaign_budget_decreased = 'campaign_budget_decreased',
  campaign_resumed = 'campaign_resumed',
  adgroup_resumed = 'adgroup_resumed',
  keyword_resumed = 'keyword_resumed',
  keyword_moved = 'keyword_moved',
  keyword_copied = 'keyword_copied',
  report_sent = 'report_sent',
  email_sent = 'email_sent',
  email_report_sent = 'email_report_sent',
  slack_report_sent = 'slack_report_sent',
  cpa = 'cpa',
  cpg = 'cpg',
  spend = 'spend',
  installs = 'installs',
  taps = 'taps',
  impressions = 'impressions',
  goal_count = 'goal_count',
  revenue = 'revenue',
  /* campaigns = 'campaigns',
  adgroups = 'adgroups', */
  keywords = 'keywords',
  roas = 'roas',
  cpt = 'cpt'
}

export enum SARMetricsFormattedFields {
  rule_run = 'Rules Ran',
  rule_tested = 'Rules Tested',
  rule_created = 'Rules Created',
  rule_updated = 'Rules Updated',
  rule_run_with_goal = 'Rules Ran With Goal',
  campaign_paused = 'Campaigns Paused',
  adgroup_paused = 'Adgroups Paused',
  keyword_paused = 'Keywords Paused',
  keyword_bid_increased = 'Keyword Bid Increased',
  keyword_bid_decreased = 'Keyword Bid Decreased',
  campaign_budget_increased = 'Campaign Budget Increased',
  campaign_budget_decreased = 'Campaign Budget Decreased',
  campaign_resumed = 'Campaigns Resumed',
  adgroup_resumed = 'Adgroups Resumed',
  keyword_resumed = 'Keywords Resumed',
  keyword_moved = 'Keywords Moved',
  keyword_copied = 'Keywords Copied',
  report_sent = 'Reports Sent',
  email_sent = 'Emails Sent',
  email_report_sent = 'Email Reports Sent',
  slack_report_sent = 'Slack Reports Sent',
  cpa = 'Avg CPA',
  cpg = 'Avg CPG',
  spend = 'Spend',
  installs = 'Installs',
  taps = 'Taps',
  impressions = 'Impressions',
  goal_count = 'Goal Count',
  revenue = 'Revenue',
  /* campaigns = 'Campaigns',
  adgroups = 'Adgroups', */
  keywords = 'Keywords',
  roas = 'Avg ROAS',
  cpt = 'Avg CPT'
}