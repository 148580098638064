import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink, from } from 'apollo-link'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { CORID_HEADER_NAME, SESSION_HEADER_NAME, TEAM_HEADER_NAME, TOKEN_HEADER_NAME, USER_HEADER_NAME } from '@shared/config'
import { LSS } from '@core/services'
import { decodeToken } from '@shared/utils/commom.util'
import { API_GRAPHQL } from '@config'
import { ServerError } from 'apollo-link-http-common'
import VueRouter, { RouteConfig } from 'vue-router';
import { API_ORY_URL } from '@config'
import { v4 as uuidv4 } from 'uuid';
// console.log('app.client')
Vue.use(VueApollo)
Vue.use(VueRouter);

// console.log(API_GRAPHQL, API_ORY_URL)

const acceptJsonMiddleware = new ApolloLink((operation, forward) => {
  //console.log(operation.operationName)
  const decoded = decodeToken(LSS.token)
  operation.setContext((settings: any) => ({ headers: {
    // TODO any auth props go here
    // [USER_HEADER_NAME]: decoded.UserId, // appending userId
    // [TEAM_HEADER_NAME]: decoded.TeamID,
    [SESSION_HEADER_NAME]: LSS.sessId,
    [TOKEN_HEADER_NAME]: LSS.token,
    [CORID_HEADER_NAME]: uuidv4(),
    ...settings.headers
  }}));
  return forward(operation);
})
let alerted = false
const errorHandler = onError(({ graphQLErrors, networkError }) => {
    if (networkError) {
        //console.log(networkError.message) 
        // console.log((<any>networkError).statusCode)
        // console.log((<any>networkError).response)
        // console.log((<any>networkError).result)        
        // if(window.location.pathname.toString() == '/settings') {
        //     console.log('yes')
        //     return
        // }
        
        if((<any>networkError).statusCode === 401){            
          if((<any>networkError).result?.code === 22){    
              if(alerted) return // alerted var is to make sure alert doesn't pop up twice
              alerted = true
              alert('Your account is either disabled or unauthorized. Try login again or contact Admin')
              LSS.clear();
              window.location.href = Vue.prototype.$out_url
          }
          if((<any>networkError).result?.code === 23){    
            if(alerted) return // alerted var is to make sure alert doesn't pop up twice
            alerted = true
            alert('Your password has been changed in some other device, please login again')
            LSS.clear();
           window.location.href = Vue.prototype.$out_url
          }
          
        }
        if((<any>networkError).statusCode === 500 || 413){            
          if((<any>networkError).result?.errors?.status==413){
            alert('Payload size is too high')
          }
        }
    }
    if (graphQLErrors) {
        //console.log(graphQLErrors)
    }
})

// Cache implementation
const cache = new InMemoryCache({
  addTypename: false
})

const httpLink = new (createHttpLink  as any)({
  uri: API_GRAPHQL,
}) as any


export const apolloClient = new ApolloClient({
  link: from([
    acceptJsonMiddleware,
    errorHandler,
    httpLink,
  ]),
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache'
    }
  }
})
