




























































import Vue from 'vue';
export default Vue.extend({
  props: {},

  data() {
    return {
      dialog: false,
      endTourDialog: false,
      tourSteps: {
        overview: {
          title: 'Overview Page',
          text: 'This is where you will start every time you log into the platform. Here you will find a general overview of your current campaigns, associated metrics and more. You can customize this view to show what will be most helpful and will serve as your quick reference into all your ASA activities.',
          icon: require('@assets/images/tour_overview.png'),
          next: 'dashboard'
        },
        dashboard: {
          title: 'Ads Manager',
          text: 'This is where you can pull analytics based on campaigns, applications and more. Those analytics can also be visualised and customised by selecting the fields most important to you.',
          icon: require('@assets/images/tour_adsmanager.png'),
          next: 'keyword-auction-insights'
        },
        'keyword-auction-insights': {
          title: 'Keyword Analysis',
          text: 'This is the Keyword Analysis tab where you can gauge the overall effectiveness of your campaigns keywords and see suggested competitors to your app, and conquest your competitor\'s keywords.You can also quickly add supplemental keywords to your campaign.',
          icon: require('@assets/images/tour_kai.png'),
          next: 'KeywordOptimization'
        },
        KeywordOptimization: {
          title: 'Keyword Optimization',
          text: 'This is the keyword optimization tab where you can quickly and effectively optimize your bid amounts for keywords not in the range of ASA suggested minimum and maximum bids. Find goal metrics based on your app\'s events included alongside the ASA recommendations.',
          icon: require('@assets/images/tour_kwoptimization.png'),
          next: 'arrules'
        },
        arrules: {
          title: 'Automation Studio',
          text: 'This is the automation page where you can create automation rules for your organizations, application, campaigns, Ad groups and keywords.',
          icon: require('@assets/images/tour_autostudio.png'),
          next: 'Goals'
        },
        Goals: {
          title: 'Goals',
          text: 'This is the goals creation page where you can create app based campaign goals around revenue metrics, user engagement and user action events.',
          icon: require('@assets/images/tour_goals.png')
        }
      }
    };
  },
  watch: {
    $route() {
      this._checkRouteAndTourStatus();
    }
  },
  methods: {
    startExploring() {
      this.dialog = false;
      this.endTourDialog = false;
    },
    _checkRouteAndTourStatus() {
      if (this.$route.query.tour && this.$route.query.tour == "true") {
        this.dialog = true
      } else {
        this.dialog = false
      }

      this.endTourDialog = this.$route.query.endTour;
    },
    moveToNext() {
      if (this.currentTourStep.next) this.$router.push({ name: this.currentTourStep.next, query: { tour: "true" } });
      else this.$router.push({ name: 'overview', query: { endTour: "true" } })
    }
  },
  computed: {
    currentTourStep() {
      return this.tourSteps[this.$route.name] || {};
    }
  },
  mounted() {
    this._checkRouteAndTourStatus();
  }
});
