import gql from 'graphql-tag';

export const ACCOUNTS_REPORTS = gql`
  query accountsReports(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $accountSearch: AccountSearchReq!
  ) {
    accountsReports(filter: $filter, offset: $offset, limit: $limit, sortBy: $sortBy, sortDesc: $sortDesc, accountSearch: $accountSearch) {
      totalRecords
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
          latOnInstalls
          latOffInstalls
        }
        orgMetadata {
          orgName
          orgId
          currency
        }
      }
    }
  }
`;

export const APPS_REPORTS = gql`
  query appsReports($filter: Filters!, $offset: Float!, $limit: Float!, $sortBy: String!, $sortDesc: Boolean!, $search: AppsSearchReq!) {
    appsReports(filter: $filter, offset: $offset, limit: $limit, sortBy: $sortBy, sortDesc: $sortDesc, search: $search) {
      totalRecords
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
          latOnInstalls
          latOffInstalls
        }
        metadata {
          campaignStatus
          displayStatus
          app {
            adamId
            appName
          }
          campaignId
          orgId
        }
        orgMetadata {
          orgName
          orgId
          currency
        }
        appAttributionData(filter: $filter) {
          successMessage
          content
          secondaryContent
        }
      }
    }
  }
`;

export const CAMPAIGN_REPORTS = gql`
  query campaignReports($filter: Filters!) {
    campaignReports(filter: $filter) {
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
          latOnInstalls
          latOffInstalls
        }
        metadata {
          app {
            adamId
            appName
          }
          adChannelType
        }
        campaignMetadata {
          id
          orgId
          name
          deleted
          status
          displayStatus
          servingStateReasons
          countriesOrRegions
          supplySources
          budgetAmount {
            amount
            currency
          }
          dailyBudgetAmount {
            amount
            currency
          }
        }
        orgMetadata {
          orgId
          orgName
          currency
        }
        campaignAttributionData(filter: $filter) {
          successMessage
          content
          secondaryContent
        }
      }
    }
  }
`;

export const CAMPAIGN_GRAPH = gql`
  query campaignGraph($filter: GraphRequest!, $attrGraphString: String!, $getGoals: Boolean!) {
    campaignChart: campaignGraph(filter: $filter, attrGraphString: $attrGraphString, getGoals: $getGoals) {
      datasets {
        label
        data
        timelineData
      }
      labels
    }
  }
`;

export const APPS_GRAPH = gql`
  query campaignGraph($filter: GraphRequest!) {
    appsChart: campaignGraph(filter: $filter, attrGraphString: "", getGoals: false) {
      datasets {
        label
        data
      }
      labels
    }
  }
`;

export const KEYWORDS_GRAPH = gql`
  query keywordsGraph($filter: GraphRequest!, $attrGraphString: String!, $getGoals: Boolean!) {
    keywordsChart: keywordsGraph(filter: $filter, attrGraphString: $attrGraphString, getGoals: $getGoals) {
      datasets {
        label
        data
      }
      labels
    }
  }
`;

export const AD_GROUP_GRAPH = gql`
  query adGroupGraph($filter: GraphRequest!, $attrGraphString: String!, $getGoals: Boolean!) {
    adGroupChart: adGroupGraph(filter: $filter, attrGraphString: $attrGraphString, getGoals: $getGoals) {
      datasets {
        label
        data
      }
      labels
    }
  }
`;

export const CREATIVE_SET_GRAPH = gql`
  query creativeSetGraph($filter: GraphRequest!) {
    creativeSetChart: creativeSetGraph(filter: $filter) {
      datasets {
        label
        data
      }
      labels
    }
  }
`;

export const PRODUCT_PAGES_GRAPH = gql`
  query productPagesGraph($filter: GraphRequest!, $attrGraphString: String!, $getGoals: Boolean!) {
    productPagesChart: productPagesGraph(filter: $filter, attrGraphString: $attrGraphString, getGoals: $getGoals) {
      datasets {
        label
        data
      }
      labels
    }
  }
`;

export const SEARCH_TERMS_GRAPH = gql`
  query searchTermsGraph($filter: GraphRequest!, $attrGraphString: String!, $getGoals: Boolean!) {
    searchTermsChart: searchTermsGraph(filter: $filter, attrGraphString: $attrGraphString, getGoals: $getGoals) {
      datasets {
        label
        data
      }
      labels
    }
  }
`;

export const SEARCH_TERM_REPORTS_FOR_FILTER = gql`
  query searchTermsReports($filter: Filters!) {
    searchTermsReportsForFilter: searchTermsReportsForFilter(filter: $filter) {
      totalRecords
      row {
        metadata {
          keywordId
        }
      }
    }
  }
`;

export const ACCOUNTS_GRAPH = gql`
  query campaignGraph($filter: GraphRequest!) {
    accountsChart: campaignGraph(filter: $filter, attrGraphString: "", getGoals: false) {
      datasets {
        label
        data
      }
      labels
    }
  }
`;

export const SEARCH_TERMS_REPORTS = gql`
  query searchTermsReports(
    $filter: Filters!
    $offset: Float!
    $limit: Float!
    $sortBy: String!
    $sortDesc: Boolean!
    $searchTermSearch: searchReq!
    $getGoals: Boolean!
  ) {
    searchTermsReports(
      filter: $filter
      offset: $offset
      limit: $limit
      sortBy: $sortBy
      sortDesc: $sortDesc
      searchTermSearch: $searchTermSearch
    ) {
      totalRecords
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
        }
        campaignMetadata {
          name
        }
        adgroupMetadata {
          status
        }
        metadata {
          app {
            adamId
            appName
          }
          keyword
          keywordId
          bidAmount {
            amount
            currency
          }
          keywordDisplayStatus
          adGroupId
          adGroupName
          adGroupDeleted
          searchTermText
          searchTermSource
          campaignId
        }
        searchTermAttributionData(filter: $filter, getGoals: $getGoals) {
          successMessage
          content
          secondaryContent
        }
        orgMetadata {
          orgName
          orgId
          currency
        }
      }
    }
  }
`;

export const CREATIVE_SET_REPORTS = gql`
  query creativeSetReports($filter: Filters!) {
    creativeSetReports(filter: $filter) {
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
        }
        metadata {
          creativeSetName
          displayStatus
        }
        creativeSetMetadata {
          id
          creativeSetId
          name
          status
          campaignId
          adGroupId
          languageCode
          creativeSetId
          deleted
        }
        adgroupMetadata {
          orgId
          id
          status
        }
        orgMetadata {
          orgName
          currency
        }
        campaignMetadata {
          adamId
          countriesOrRegions
          id
          status
          deleted
        }
      }
    }
  }
`;

export const PRODUCT_PAGE_REPORTS = gql`
  query productPageReports($filter: Filters!, $offset: Float!, $limit: Float!) {
    productPageReports(filter: $filter, offset: $offset, limit: $limit) {
      totalRecords
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
        }
        metadata {
          adId
          adName
          status
          app {
            adamId
            appName
          }
        }
        adsMetadata {
          id
          creativeId
          name
          status
          campaignId
          adGroupId
          deleted
          creativeType
          orgId
        }
        adgroupMetadata {
          orgId
          id
          status
        }
        orgMetadata {
          orgName
          currency
        }
        campaignMetadata {
          adamId
          countriesOrRegions
          id
          status
          deleted
        }
        productPageAttributionData(filter: $filter) {
          successMessage
          content
          secondaryContent
        }
      }
    }
  }
`;

export const TARGETING_KEYWORD_REPORTS = gql`
  query targetingKeywordReports($filter: Filters!, $offset: Float!, $limit: Float!) {
    targetingKeywordReports(filter: $filter, offset: $offset, limit: $limit) {
      counts {
        livekeywords
        pausedkeywords
        deletedkeywords
      }
      filterCount
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
          latOnInstalls
          latOffInstalls
        }
        metadata {
          # campaignStatus,
          # displayStatus,
          # app {
          #   adamId,
          #   appName
          # },
          campaignId
          # orgId,
          # campaignName,
          # countriesOrRegions,
          adGroupName
        }
        insights {
          bidRecommendation {
            bidMin {
              amount
              currency
            }
            bidMax {
              amount
              currency
            }
          }
        }
        campaignMetadata {
          name
          adamId
          status
          countriesOrRegions
          deleted
        }
        adgroupMetadata {
          campaignId
          orgId
          status
        }
        keywordAttributionData(filter: $filter) {
          successMessage
          content
          secondaryContent
        }
        keywordMetadata {
          id
          text
          status
          adGroupId
          bidAmount {
            amount
            currency
          }
          deleted
        }
      }
    }
  }
`;

export const NEGATIVE_KEYWORD_REPORTS = gql`
  query negativeKeywordReports($filter: Filters!) {
    negativeKeywordReports(filter: $filter) {
      adGroupId
      campaignId
      deleted
      id
      matchType
      modificationTime
      status
      text
    }
  }
`;

export const GET_INTEGRATION = gql`
  query {
    getIntegration {
      IntegrationID
      type
      name
      invalid
    }
  }
`;

export const GET_CAMPAIGN_GROUPS = gql`
  query {
    getCampaignGroups {
      orgName
      orgId
      currency
      timeZone
      paymentModel
      roleNames
      IntegrationID
    }
  }
`;

export const GET_CAMPAIGNS_BY_ORG = gql`
  query getCampaignsByOrg($orgId: Float!) {
    getCampaignsByOrg(orgId: $orgId) {
      name
      id
      adamId
      orgId
      countriesOrRegions
      status
      adChannelType
      supplySources
    }
  }
`;

export const GET_CAMPAIGNS_BY_APP = gql`
  query getCampaignsByApp($appId: Float!) {
    getCampaignsByApp(appId: $appId) {
      name
      id
      adamId
      orgId
      countriesOrRegions
      status
      adChannelType
      supplySources
    }
  }
`;

export const DELETE_INTEGRATION = gql`
  query {
    deleteIntegration
  }
`;

// TODO
export const GET_CAMPAIGN_DRAFT = gql`
  query getDraft($draftId: String!) {
    campaignDraft: getDraft(draftId: $draftId) {
      campaign {
        name
        adamId
        orgId
        adChannelType
        budgetAmount {
          currency
          amount
        }
        countriesOrRegions
        dailyBudgetAmount {
          currency
          amount
        }
        budgetOrders
        supplySources
      }
      adGroup {
        name
        orgId
        status
        startTime
        endTime
        defaultBidAmount {
          amount
        }
        cpaGoal {
          amount
        }
        automatedKeywordsOptIn
        targetingDimensions {
          adminArea {
            included
          }
          age {
            included {
              minAge
              maxAge
            }
          }
          appDownloaders {
            included
            excluded
            value
          }
          country {
            included
          }
          daypart {
            userTime {
              included
            }
          }
          deviceClass {
            included
          }
          gender {
            included
          }
          locality {
            included
          }
          populateLocality {
            id
            entity
            displayName
          }
        }
      }
      targetKeywords {
        bidAmount {
          currency
          amount
        }
        matchType
        text
      }
      negativeKeywords {
        matchType
        text
      }
      creativeSet {
        assetsGenIds
        adamId
        name
        languageCode
      }
      cpp
      adName
      step
    }
  }
`;

export const AD_GROUP_REPORTS = gql`
  query adGroupReports($filter: Filters!) {
    adGroupReports(filter: $filter) {
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          avgCPM {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
          latOnInstalls
          latOffInstalls
        }
        metadata {
          # campaignStatus,
          # displayStatus,
          app {
            adamId
            appName
          }
          campaignId
          orgId
          adGroupId
          # campaignName,
          # countriesOrRegions
          adGroupDisplayStatus
        }
        orgMetadata {
          orgName
          orgId
          currency
        }
        campaignMetadata {
          id
          name
          adamId
          status
          countriesOrRegions
          deleted
          adChannelType
          supplySources
        }
        adgroupAttributionData(filter: $filter) {
          successMessage
          content
          secondaryContent
        }
        adgroupMetadata {
          id
          name
          orgId
          campaignId
          status
          deleted
          displayStatus
          startTime
          endTime
          automatedKeywordsOptIn
          defaultBidAmount {
            currency
            amount
          }
          cpaGoal {
            currency
            amount
          }
          targetingDimensions {
            adminArea {
              included
            }
            appDownloaders {
              included
              excluded
            }
            appCategories {
              included
              excluded
            }
            country {
              included
            }
            deviceClass {
              included
            }
            gender {
              included
            }
            locality {
              included
            }
            daypart {
              userTime {
                included
              }
            }
            age {
              included {
                minAge
                maxAge
              }
            }
          }
        }
      }
    }
  }
`;

export const APPSLIST_WITH_CAMPAIGNS = gql`
  query getAppsListWithCampaigns($at: Float!) {
    getAppsListWithCampaigns(at: $at) {
      adamId
      appName
      campaigns_exist
      adgroups_exist
      keywords_exist
      attr_exist
      campaigns {
        id
        orgId
        name
        adamId
        countriesOrRegions
        status
        adgroups_exist
        keywords_exist
        adChannelType
        adgroups {
          name
          id
          campaignId
          orgId
          defaultBidAmount {
            currency
            amount
          }
          keywords_exist
          keywords {
            id
            text
            campaignId
            adGroupId
            status
          }
        }
      }
    }
  }
`;

export const USER_DETAIL_BY_ID = gql`
  query findUserDetailById($securityPrincipleID: String!) {
    findUserDetailById(securityPrincipleID: $securityPrincipleID) {
      FirstName
      LastName
      Phone
      CompanyName
      JobTitle
      Role
    }
  }
`;

export const MEMBER_DETAILS = gql`
  query findByTeamId {
    allMemberDetails: findByTeamId {
      TeamID
      FirstName
      LastName
      Phone
      Role
      Email
      CompanyName
      JobTitle
      InviteStatus
      IsDisabled
      CreatedOn
      IsReadOnly
    }
  }
`;

export const ALL_USERS = gql`
  query allUsers {
    allUsers: allUsers {
      FirstName
      LastName
      Phone
      Email
      CompanyName
      JobTitle
      InviteStatus
      IsDisabled
      CreatedOn
      TeamID
      UserId
      IsDeleted
      aiCredits {
        remainingUsage
        usageLimit
        isFilledManually
      }
      onAutomationV2
      Status
      picture
    }
  }
`;

export const UPDATED_INVITE = gql`
  query updateInvite {
    updateInvite: updateInviteAccepted {
      code
      successMessage
    }
  }
`;

export const UPDATED_PWDVER = gql`
  query updatePwdVer($at: Float!) {
    updatePwdVer: updatePwdVer(at: $at) {
      code
      successMessage
    }
  }
`;

export const APPS_BY_ORG = gql`
  query getAppsByOrg($orgId: Float!, $query: String) {
    apps: getAppsByOrg(orgId: $orgId, query: $query) {
      adamId
      appName
    }
  }
`;

export const CHECK_CAMPAIGN_NAME = gql`
  query checkCampaignName($name: String!) {
    checkCampaignName(name: $name) {
      doesCampaignExist
    }
  }
`;

export const GET_OAUTH_URL = gql`
  query getOAuthState($req: OAuthUrlReq!) {
    getOAuthState(req: $req) {
      url
    }
  }
`;

export const GET_CAMPAIGN_DRAFTS = gql`
  query {
    getCampaignDrafts {
      _id
      campaign {
        name
        budgetAmount {
          amount
        }
        countriesOrRegions
      }
    }
  }
`;

export const ACCOUNTS_REPORTS_FOR_FILTER = gql`
  query accountsReportsfilter($filter: Filters!) {
    accountsReportsForFilter: accountsReportsfilter(filter: $filter) {
      totalRecords
      row {
        orgMetadata {
          orgName
          orgId
          currency
        }
      }
    }
  }
`;

export const APPS_REPORTS_FOR_FILTER = gql`
  query appsReportsforfilter($filter: Filters!) {
    appsReportsForFilter: appsReportsforfilter(filter: $filter) {
      totalRecords
      row {
        metadata {
          app {
            adamId
            appName
          }
        }
      }
    }
  }
`;

export const CAMPAIGN_REPORTS_FOR_FILTER = gql`
  query campaignReports($filter: Filters!) {
    campaignReportsForFilter: campaignReports(filter: $filter) {
      row {
        campaignMetadata {
          id
          name
        }
      }
    }
  }
`;

export const AD_GROUP_REPORTS_FOR_FILTER = gql`
  query adGroupReports($filter: Filters!) {
    adgroupReportsForFilter: adGroupReports(filter: $filter) {
      row {
        adgroupMetadata {
          id
          name
          campaignId
          orgId
        }
      }
    }
  }
`;

export const PRODUCT_PAGE_REPORTS_FOR_FILTER = gql`
  query productPageReports($filter: Filters!) {
    productPageReportsForFilter: productPageReports(filter: $filter) {
      totalRecords
      row {
        adsMetadata {
          id
          name
        }
      }
    }
  }
`;

export const APPS_WITH_ORG = gql`
  query {
    getAppsWithOrg {
      orgName
      orgId
      appName
      adamId
    }
  }
`;
export const APPS_WITH_ORG_NEW = gql`
  query {
    getAppsWithOrgNew {
      orgName
      orgId
      appName
      adamId
    }
  }
`;
export const COUNTRIES_FOR_APP = gql`
  query countriesForApp($adamId: Float!) {
    countriesForApp(adamId: $adamId) {
      adamId
      countryOrRegionCodes
      valuesForAutoCampaign {
        Code
        Name
        totalBudget
        dailyCap
        cpt
      }
    }
  }
`;

export const COMPANY_DETAILS = gql`
  query companyDetails($TeamID: String!) {
    companyDetails(TeamID: $TeamID) {
      FirstName
      LastName
      Phone
      Email
      CompanyName
      JobTitle
      InviteStatus
      IsDisabled
      CreatedOn
      Role
      UserId
      picture
    }
  }
`;

export const GET_INTEGRATION_FOR_COMPANY = gql`
  query getIntegrationForCompany($TeamID: String!) {
    getIntegrationForCompany(TeamID: $TeamID) {
      IntegrationID
      type
      name
    }
  }
`;

export const CAMPAIGN_REPORTS_FOR_COMPANY = gql`
  query campaignReportsForCompany($TeamID: String!, $reportDateRange: [String!]!) {
    campaignReportsForCompany(TeamID: $TeamID, reportDateRange: $reportDateRange) {
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
        }
        metadata {
          app {
            adamId
            appName
          }
          campaignName
          campaignStatus
          displayStatus
        }
        orgMetadata {
          orgId
          orgName
          currency
        }
      }
    }
  }
`;

export const APPS_REPORTS_FOR_COMPANY = gql`
  query appsReportsForCompany($TeamID: String!, $reportDateRange: [String!]!) {
    appsReportsForCompany(TeamID: $TeamID, reportDateRange: $reportDateRange) {
      grandTotals {
        total {
          installs
          newDownloads
          redownloads
          ttr
          taps
          impressions
          localSpend {
            amount
          }
        }
      }
      row {
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
        }
        metadata {
          campaignStatus
          displayStatus
          app {
            adamId
            appName
          }
          campaignId
          orgId
        }
        orgMetadata {
          orgName
          orgId
          currency
        }
      }
    }
  }
`;

export const TARGETING_KEYWORD_REPORTS_FOR_COMPANY = gql`
  query targetingKeywordReportsForCompany($TeamID: String!, $reportDateRange: [String!]!) {
    targetingKeywordReportsForCompany(TeamID: $TeamID, reportDateRange: $reportDateRange) {
      row {
        total {
          localSpend {
            amount
          }
          avgCPA {
            amount
          }
          avgCPT {
            amount
          }
          impressions
          taps
          installs
          ttr
          conversionRate
          newDownloads
          redownloads
        }
      }
    }
  }
`;

export const APPS_GRAPH_FOR_COMPANY = gql`
  query appsGraphForCompany($graphFilter: GraphRequestForCompany!) {
    appsGraphForCompany(graphFilter: $graphFilter) {
      datasets {
        label
        data
      }
      labels
    }
  }
`;

export const TRIGGER_MANUAL_SYNC = gql`
  query invokeManualSync($teamId: String, $firstSync: Boolean) {
    invokeManualSync(teamId: $teamId, firstSync: $firstSync) {
      successMessage
      data
    }
  }
`;

export const GET_TRIAL_ACTIVITY = gql`
  query getTrailActivity($TeamID: String!) {
    getTrailActivity(TeamID: $TeamID) {
      updatedAt
      updatedBy
      lastTrialEndDate
      UpdatedTrialEndDate
    }
  }
`;

export const SUB_ACTIVATE_DATE = gql`
  query subActivateDate($TeamID: String!) {
    subActivateDate(TeamID: $TeamID) {
      ActivatedOn
    }
  }
`;

export const SWITCH_TEAM = gql`
  query switchTeam($TeamId: String!, $Role: String!, $Company: String!) {
    switchTeam(TeamID: $TeamId, Role: $Role, Company: $Company) {
      successMessage
      code
      data
    }
  }
`;

export const DETAILS_FROM_USER_TEAMS = gql`
  query getDetailsFromUserTeam($TeamId: String!, $UserId: String!) {
    getDetailsFromUserTeam(TeamId: $TeamId, UserId: $UserId) {
      IsDisabled
    }
  }
`;

export const GET_BILLING_ADDR = gql`
  query {
    getCBBillingAddr {
      company
      line1
      line2
      line3
      city
      zip
      country
      vat_number
    }
  }
`;

export const INTEGRATIONS_INFO = gql`
  query {
    getIntegrationInfo {
      info
      firstSyncDone
      demoMode
    }
  }
`;
export const CURRENCY_INFO = gql`
  query {
    getCurrencyInfo {
      currencies
    }
  }
`;
export const CHECK_OLDEST_CAMPAIGN_DATE = gql`
  query checkOldestCampaignDate($integrationId: String!) {
    checkOldestCampaignDate(IntegrationId: $integrationId) {
      successMessage
    }
  }
`;

export const GET_OAUTH_STATE_INFO = gql`
  query getOAuthStateInfo($state: String!) {
    getOAuthStateInfo(state: $state) {
      state
      referrer
    }
  }
`;

export const DELETE_COMPANY = gql`
  query deleteCompany($TeamID: String!) {
    deleteCompany(TeamID: $TeamID) {
      successMessage
      code
    }
  }
`;

export const ATTR_APPS = gql`
  query {
    attrApps {
      adamId
      appName
    }
  }
`;

export const MMP_INTEGRATIONS = gql`
  query listAttrIntegrations {
    listAttrIntegrations: listAttrIntegrations {
      appId
      mmpId
      mmpName
      appName
      apiKey
      appEvents
    }
  }
`;

export const CREATE_GOAL = gql`
  query createGoal($inputs: GoalInputs!) {
    createGoal(inputs: $inputs) {
      successMessage
      code
    }
  }
`;

export const APP_EVENT_NAMES = gql`
  query appEventNames($appId: Float!) {
    appEventNames(appId: $appId) {
      eventName
    }
  }
`;

export const GET_GOALS = gql`
  query {
    getGoals {
      _id
      name
      appId
      eventId
      status
      type
      datetime
      createdBy
      mysqlPopulatedAt
      daysSinceInstall
    }
  }
`;

export const GET_GOALS_FROM_APP = gql`
  query getGoalsFromApp($appId: [Float!]!) {
    getGoalsFromApp(appId: $appId) {
      _id
      name
      appId
      eventId
      status
      type
      datetime
      createdBy
    }
  }
`;

export const UPDATE_GOAL = gql`
  query updateGoal($goalId: String!, $inputs: GoalInputs!) {
    updateGoal(goalId: $goalId, inputs: $inputs) {
      successMessage
      code
    }
  }
`;

export const DEL_GOAL = gql`
  query delGoal($goalId: String!) {
    delGoal(goalId: $goalId) {
      successMessage
      code
    }
  }
`;

export const GET_APP_EVENTS_LIST = gql`
  query getAppEventsList($appId: Float!) {
    getAppEventsList(appId: $appId) {
      data
    }
  }
`;

export const GET_APP_EVENTS_DATA = gql`
  query getAppEventsDataForOverview($appId: Float!, $period: Float!, $at: Float!, $currdate: String!, $prevdate: String!, $goal: String!) {
    currMMPData: getAppEventsDataForOverview(appId: $appId, period: $period, at: $at, date: $currdate, goal: $goal) {
      data
    }

    prevMMPData: getAppEventsDataForOverview(appId: $appId, period: $period, at: $at, date: $prevdate, goal: $goal) {
      data
    }
  }
`;

export const FETCH_CHART_PRESETS = gql`
  query fetchChartPresets {
    fetchChartPresets {
      accounts
      apps
      campaign
      adgroup
      keyword
      creativeset
      searchterm
      negativekeyword
      ads
    }
  }
`;

export const FETCH_TABLE_PRESETS = gql`
  query fetchTablePresets {
    fetchTablePresets {
      accounts
      apps
      livecampaign
      pausedcampaign
      deletedcampaign
      draftcampaign
      liveadgroup
      pausedadgroup
      deletedadgroup
      livekeyword
      pausedkeyword
      deletedkeyword
      livecreativeset
      pausedcreativeset
      deletedcreativeset
      searchterm
      livenegativekeyword
      pausednegativekeyword
      deletednegativekeyword
      ads
    }
  }
`;

export const CHECK_GOAL_NAME = gql`
  query checkGoalName($name: String!) {
    checkGoalName(name: $name) {
      doesGoalExist
    }
  }
`;

export const FETCH_OVERVIEW_PRESETS = gql`
  query fetchOverviewPresets {
    fetchOverviewPresets {
      overview
    }
  }
`;

export const DELETE_TEAM_MEMBER = gql`
  query deleteTeamMember($email: String!, $TeamID: [String!]!) {
    deleteTeamMember(email: $email, TeamID: $TeamID) {
      successMessage
      code
    }
  }
`;

export const COMPANY_LIST_FOR_STAFF = gql`
  query companyListForStaff($email: String!) {
    companyListForStaff(email: $email) {
      successMessage
      content
    }
  }
`;

export const CAMPAIGN_TEMPLATE_DRAFT_LIST = gql`
  query {
    getDraftTemplateList {
      TeamID
      UserID
      draftId
      appData {
        selectedApp {
          adamId
          appIcon
          appName
          appGenre
        }
        orgId
        selectedAccount
      }
      categories {
        brandCheck
        categoryCheck
        discoveryCheck
        competitorCheck
        customCheck
        organicRankSwitch
        popularitySwitch
        totalAppsSwitch
        customSlugs
        organic {
          type
          value
          toValue
          fromValue
        }
        popularity {
          type
          value
          toValue
          fromValue
        }
        totalApps {
          type
          value
          toValue
          fromValue
        }
        manualKeyCategory
        selectedCategories
        selectedCountries
      }
      segmentation {
        selectedAge
        selectedGender
        selectedCustomerType
        selectedCountries
        selectedDevice
        finalAdgroups {
          age
          country
          device
          gender
          customerType
        }
      }
      keywords {
        currency
        tKeys {
          campaign
          key {
            bidAmount {
              currency
              amount
            }
            matchType
            text
          }
        }
      }
    }
  }
`;

export const CAMPAIGN_TEMPLATE_DRAFT = gql`
  query getDraftTemplate($draftId: String!) {
    getDraftTemplate(draftId: $draftId) {
      TeamID
      UserID
      draftId
      step
      appData {
        selectedApp {
          adamId
          appIcon
          appName
          appGenre
        }
        orgId
        selectedAccount
      }
      categories {
        brandCheck
        categoryCheck
        discoveryCheck
        competitorCheck
        customCheck
        organicRankSwitch
        popularitySwitch
        totalAppsSwitch
        customSlugs
        organic {
          type
          value
          toValue
          fromValue
        }
        popularity {
          type
          value
          toValue
          fromValue
        }
        totalApps {
          type
          value
          toValue
          fromValue
        }
        manualKeyCategory
        selectedCategories
        selectedCountries
      }
      segmentation {
        selectedAge
        selectedGender
        selectedCustomerType
        selectedCountries
        selectedDevice
        finalAdgroups {
          age
          country
          device
          gender
          customerType
        }
      }
      keywords {
        currency
        tKeys {
          campaign
          key {
            bidAmount {
              currency
              amount
            }
            matchType
            text
          }
        }
      }
    }
  }
`;

export const CAMPAIGN_GEN_LIST = gql`
  query {
    getCampGenList {
      _id
      templateId
      appName
      adamId
      segmentationRules {
        age
        country
        device
        gender
        customerType
      }
      segmentationDetails
      cpt {
        amount
        currency
      }
      totalBudget {
        amount
        currency
      }
      dailyCap {
        amount
        currency
      }
      categories
      nKeys
      tKeys {
        campaign
        key {
          bidAmount {
            currency
            amount
          }
          matchType
          text
        }
      }
      competitorKeys
      categoryKeys
      categoryKeysFetched
      competitorKeysFetched
      campaignsCreated
      adGroupsCreated
      allDone
      campaigns {
        name
        type
      }
      adGroups {
        name
        type
      }
      createdBy
      createdOn
      createdAt
      lastRetryAt
      appCatId
      catType
      templateName
    }
  }
`;

export const DELETE_TEMPLATE = gql`
  query deleteTemplate($dbId: String!) {
    deleteTemplate(dbId: $dbId) {
      successMessage
      code
    }
  }
`;

export const AD_KEY_RETRY = gql`
  query adKeyRetry($templateId: String!, $retryAt: Float!) {
    adKeyRetry(templateId: $templateId, retryAt: $retryAt) {
      successMessage
      code
    }
  }
`;

export const FETCH_CUSTOM_PRESETS = gql`
  query {
    fetchCustomView {
      _id
      CustomView
      tab
      ChartDatasets
      DatatableDatasets
      Granularity
      Filters
      SortBy
      SortDesc
      PinnedStatus
    }
  }
`;

export const RESET_CUSTOM_PRESETS = gql`
  query {
    resetPinnedStatusView {
      _id
      CustomView
      tab
      UserID
      TeamID
      PinnedStatus
    }
  }
`;

export const GET_SCHEDULE_REPORTS = gql`
  query getScheduledReports {
    getScheduledReports {
      _id
      reportName
      reportLimit
      createdAt
      lastRunAt
      nextRunAt
      nextRunLocal
      disabled
      completed
      repeatType
      days
      daysLocal
      hoursLocal
      hours
      reportFor
      reportForDays
      emails
      slacks
      goals {
        _id
        name
        appId
        eventId
        type
      }
      reportForList {
        orgId
        orgName
        campaignId
        campName
        adamId
        appName
        adgroupId
        adgroupName
        keywordId
        keywordName
      }
    }
  }
`;

export const DELETE_SCHEDULE_REPORT = gql`
  query deleteScheduleReport($dbId: String!) {
    deleteScheduleReport(dbId: $dbId) {
      successMessage
      code
    }
  }
`;

export const END_SUBSCRIPTION = gql`
  query endSubscription($subId: String!) {
    endSubscription(subId: $subId) {
      Status
    }
  }
`;

export const GET_LOGIN_ACTIVITY = gql`
  query getLoginActivity($teamId: String!) {
    getLoginActivity(teamId: $teamId) {
      successMessage
      content
    }
  }
`;

export const GENERATE_REST_PASSWORD_LINK = gql`
  query generateResetPasswordLink($userId: String!) {
    generateResetPasswordLink(userId: $userId) {
      successMessage
      data
      code
    }
  }
`;

export const TAR_KEY_REPORT_FOR_KEY_OPT = gql`
  query {
    targKeyReportsForKeyOpt {
      row {
        metadata {
          orgId
          app {
            adamId
            appName
          }
          campaignId
          countriesOrRegions
          adGroupName
          adGroupId
        }
        insights {
          bidRecommendation {
            bidMin {
              amount
              currency
            }
            bidMax {
              amount
              currency
            }
          }
        }
        campaignMetadata {
          name
        }
        keywordMetadata {
          id
          text
          status
          adGroupId
          bidAmount {
            amount
            currency
          }
          deleted
        }
      }
    }
  }
`;

export const PROCESS_KWGEN_MODEL = gql`
  query generateKeywords($model: String!) {
    generateKeywords(model: $model) {
      successMessage
    }
  }
`;

export const LIST_MODELS = gql`
  query {
    listModels {
      modelName
      model
      keywords
      logMessages
      updatedOn
      csv
    }
  }
`;
export const LIST_SUGGESTIVE_MODELS = gql`
  query {
    listSuggestiveModels {
      _id
      modelName
      model
      keywords
      logMessages
      updatedOn
      csv
    }
  }
`;

export const EXPORT_REPORT = gql`
  query exportReport($filter: Filters!, $receiverMail: String!, $reportDatasets: [ReportDatasets!]!) {
    exportReport(filter: $filter, receiverMail: $receiverMail, reportDatasets: $reportDatasets) {
      successMessage
      data
    }
  }
`;

export const UPDATE_CAMPAIGN_TEMPLATE = gql`
  query updateCampTemplate($campTemplateName: String!, $templateId: String!) {
    updateCampTemplate(campTemplateName: $campTemplateName, templateId: $templateId) {
      successMessage
      data
    }
  }
`;

export const TOGGLE_SIGNUP = gql`
  query toggleSelfSignUp($at: Float!, $state: Boolean!) {
    toggleSelfSignUp: toggleSelfSignUp(at: $at, state: $state) {
      code
      successMessage
    }
  }
`;

export const GET_GLOBAL_AI_CREDITS = gql`
  query {
    getGlobalCredits {
      globalLimit
    }
  }
`;

export const GET_AI_CREDITS = gql`
  query {
    getCredits {
      remainingUsage
      usageLimit
      refillsOn
    }
  }
`;

export const GENERATE_AI_KEYWORDS_KAI = gql`
  query suggestedKeywordListKAI($inputs: searchByAiProfileInput!) {
    suggestedKeywordListKAI(inputs: $inputs) {
      details
      keywords {
        name
        popularity
        totalApps
        organicRank
      }
      currentPage
      nextPage
      prevPage
      count
      fromDB
    }
  }
`;
export const GET_ACTIVITY_LOGS = gql`
  query($reportDateRange: [String!]!) {
    getActivityLogs(reportDateRange: $reportDateRange) {
      logId
      logType
      message
      createdOn
      watched
      TeamID
      companyName
      featureName
      featurePath
      data
    }
  }
`;

export const SAVE_SAM_ACTIVITY = gql`
  query($activity: SystemLogsIn!) {
    saveSamActivity(activity: $activity) {
      successMessage
    }
  }
`;

export const DELETE_ACTIVITY_LOG = gql`
  query deleteActivityLog($logId: String!) {
    deleteActivityLog(logId: $logId) {
      successMessage
      code
    }
  }
`;

export const CHANGE_ACTIVITY_WATCHED = gql`
  query changeActivityWatched($logId: String!, $watched: Boolean!) {
    changeActivityWatched(logId: $logId, watched: $watched) {
      successMessage
      code
    }
  }
`;

export const LOG_RETENTION_PERIOD = gql`
  query changeLogRetentionPeriod($activityRetentionDays: Float!) {
    changeLogRetentionPeriod(activityRetentionDays: $activityRetentionDays) {
      successMessage
      code
    }
  }
`;

export const GET_LOG_RETENTION_PERIOD = gql`
  query {
    getLogRetentionPeriod {
      successMessage
      code
      data
    }
  }
`;

export const PROCESS_KBBU = gql`
  query($inputsKBBU: String!, $at: Float!) {
    processKBBU(inputsKBBU: $inputsKBBU, at: $at) {
      successMessage
      data
    }
  }
`;

export const GET_MOCK_DATA = gql`
  query getMockData {
    getMockData: getMockData {
      TeamID
      appCount
      campaignCount
      adgroupCount
      keywordCount
      progress
      deleting
    }
  }
`;

export const GET_QUEUED_TASKS = gql`
  query getQueuedTasks($at: Float!) {
    getQueuedTasks: getQueuedTasks(at: $at) {
      taskId
      category
      totalCount
      dispatchCount
      failedCount
      errorCount
      messages
      lastQueuedAt
      successCount
      UserID
      TeamID
      ruleId
      ruleName
      runId
      categoryId
      completed
      status
    }
  }
`;

export const DELETE_KBBU = gql`
  query($taskId: Float!) {
    deleteQueuedTask(taskId: $taskId) {
      successMessage
    }
  }
`;

export const REMOVE_SINGLE_USER = gql`
  query removeSingleUser($Email: String!) {
    removeSingleUser(Email: $Email) {
      successMessage
      code
    }
  }
`;
export const GET_ALL_STRATEGIC_VIEW = gql`
  query getAllStrategies {
    getAllStrategies: getAllStrategies {
      TeamID
      strategyId
      name
      description
      goals
      deleted
      targetEntities {
        appName
        appId
        type
        name
        id
      }
      createdOn
      createdBy
    }
  }
`;
export const GET_ALL_TIMELINE_VIEW = gql`
  query getAllTimelineEntries($strategyId: String!) {
    getAllTimelineEntries(strategyId: $strategyId) {
      TeamID
      strategyId
      timelineId
      createdOn
      description
      category
      source
      deleted
    }
  }
`;
export const DELETE_STRATEGY = gql`
  query($strategyId: String!) {
    delStrategy(strategyId: $strategyId) {
      successMessage
    }
  }
`;
export const DELETE_TIMELINE = gql`
  query($timelineId: String!) {
    delStrategyTimeline(timelineId: $timelineId) {
      successMessage
    }
  }
`;

export const GET_CUSTOM_FN_FILETREE = gql`
  query {
    getCustomFnsFileTree {
      id
      name
      children
    }
  }
`;

export const GET_CUSTOM_FN = gql`
  query($id: String!) {
    getSarCustomFunction(id: $id) {
      id
      name
      description
      code
      isLocked
      isPublic
    }
  }
`;

export const DELETE_CUSTOM_FN = gql`
  query($id: String!) {
    deleteCustomFn(id: $id) {
      successMessage
    }
  }
`;

export const PB_PVT_FN = gql`
  query($id: String!, $currentValue: Boolean!) {
    publicOrPvtCustomFn(id: $id, currentValue: $currentValue) {
      successMessage
    }
  }
`;

export const LOCK_UNLOCK_FN = gql`
  query($id: String!, $currentValue: Boolean!) {
    lockUnlockCustomFn(id: $id, currentValue: $currentValue) {
      successMessage
    }
  }
`;
