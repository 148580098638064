import { IntegrationService } from "@services/integration.service";
import { ALLOWED_INTEGRATION_ROLES } from "src/utils/constants";
import { NavigationGuardNext, Route } from "vue-router";

export async function checkASAIntegrationsState(to: Route, from: Route, next: NavigationGuardNext) {
    try {
        const integrationStateResponse = await IntegrationService.getASAIntegrationsState();

        if (!integrationStateResponse.length) return next('/welcome-onboard');

        const firstBadPermissionsIntegration = integrationStateResponse.find(int => !ALLOWED_INTEGRATION_ROLES.includes(int.roleName));

        if (firstBadPermissionsIntegration) return next({ path: '/welcome-onboard/setup', query: { integrated: 'true', id: firstBadPermissionsIntegration.id } });

        const firstInvalidIntegration = integrationStateResponse.find(int => int.invalid);

        if (firstInvalidIntegration) return next({ path: '/welcome-onboard/setup', query: { invalid: 'true', id: firstBadPermissionsIntegration.id } });

        next();
    } catch (error) {
        next();
    }
}