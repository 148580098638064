var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isNavbar
    ? _c(
        "nav",
        { staticClass: "v-navbar" },
        [
          _c("GuidedTourWizard"),
          _vm.errorBanner
            ? _c("v-banner", { staticClass: "top-banner text-center" }, [
                _c(
                  "span",
                  { staticClass: "white--text" },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("navbar.bannerErr1")) + " "),
                    _c(
                      "router-link",
                      {
                        staticStyle: {
                          "text-decoration": "none",
                          color: "inherit",
                        },
                        attrs: { to: "/integrations" },
                      },
                      [
                        _c(
                          "strong",
                          {
                            staticClass: "text-decoration-underline",
                            staticStyle: { cursor: "pointer" },
                            attrs: { to: "/integrations" },
                          },
                          [_vm._v(_vm._s(_vm.$t("navbar.bannerErr2")))]
                        ),
                      ]
                    ),
                    _vm._v(" " + _vm._s(_vm.$t("navbar.bannerErr3")) + " "),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.infoBanner
            ? _c("v-banner", { staticClass: "top-banner text-center" }, [
                _c("span", { staticClass: "white--text" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("navbar.infoBanner1")) +
                      " " +
                      _vm._s(_vm.loggedInAs) +
                      ". "
                  ),
                  _c(
                    "strong",
                    {
                      staticClass: "text-decoration-underline",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.returnToAdmin()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("navbar.infoBanner2")))]
                  ),
                ]),
              ])
            : _vm._e(),
          !_vm.isInSetup
            ? _c(
                "v-navigation-drawer",
                {
                  staticClass: "no-shadow ma-1 br-8 ",
                  class: {
                    "nav-with-banner": _vm.errorBanner || _vm.infoBanner,
                  },
                  attrs: {
                    color: "primarybase",
                    app: "",
                    "mini-variant": _vm.mini,
                    permanent: "",
                    width: 94,
                    "mini-variant-width": "100",
                    "hide-overlay": "",
                    height: "calc(100% - 8px)",
                    screen: "$vuetify.breakpoint.smAndDown",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "append",
                        fn: function () {
                          return [
                            true
                              ? [
                                  _c(
                                    "v-list",
                                    { staticClass: "v-left-menu" },
                                    [
                                      _c(
                                        "v-list-item",
                                        {
                                          class: "white--text text-caption",
                                          attrs: { link: "" },
                                          on: { click: _vm.openDataRequests },
                                        },
                                        [
                                          _vm.mini == true
                                            ? _c(
                                                "v-tooltip",
                                                {
                                                  attrs: {
                                                    bottom: "",
                                                    color: "primarydark",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function (ref) {
                                                          var on = ref.on
                                                          return [
                                                            _c(
                                                              "v-list-item-icon",
                                                              _vm._g({}, on),
                                                              [
                                                                _c(
                                                                  "v-progress-circular",
                                                                  {
                                                                    staticClass:
                                                                      "mb-0",
                                                                    attrs: {
                                                                      value:
                                                                        _vm.drProgress,
                                                                      color:
                                                                        "white",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        parseInt(
                                                                          _vm.drProgress
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "v-list-item-content",
                                                              [
                                                                _c(
                                                                  "v-list-item-title",
                                                                  {
                                                                    staticClass:
                                                                      "white--text text-caption",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "pages.kar"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    1346147746
                                                  ),
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("pages.kar")
                                                      )
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3276126749
                  ),
                  model: {
                    value: _vm.drawer,
                    callback: function ($$v) {
                      _vm.drawer = $$v
                    },
                    expression: "drawer",
                  },
                },
                [
                  _c(
                    "v-card",
                    _vm._g(
                      {
                        staticClass: "  bg-transparent top-logo cursor-pointer",
                        class: { "onscroll-shadow": _vm.applyBoxShadowonlogo },
                        attrs: { elevation: "0" },
                      },
                      {
                        click: function () {
                          return this$1.$router.push("/overview")
                        },
                      }
                    ),
                    [
                      _c("v-img", {
                        attrs: {
                          src: require("../assets/images/updated-logo.svg"),
                          width: "35px",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-list",
                    {
                      staticClass: "v-left-menu mt-16 pt-1",
                      staticStyle: { padding: "0px 0px" },
                    },
                    _vm._l(_vm.items, function (item) {
                      return _c(
                        "v-list-item",
                        _vm._g(
                          {
                            key: item.title,
                            class: [
                              item.path === _vm.$route.path
                                ? "active d-flex flex-column  br-4   nav-icon"
                                : "white--text text-caption d-flex flex-column br-4 nav-icon",
                              _vm.isSuperAdmin
                                ? ""
                                : _vm.permissions.includes(item.guard)
                                ? ""
                                : "disabled-class",
                            ],
                            attrs: {
                              link: "",
                              to: _vm.isSuperAdmin
                                ? item.path
                                : _vm.permissions.includes(item.guard)
                                ? item.path
                                : "",
                              "active-class": "active",
                            },
                          },
                          _vm.isSuperAdmin
                            ? {}
                            : _vm.permissions.includes(item.guard) == false
                            ? {
                                click: function () {
                                  return _vm.openPermissionPopup()
                                },
                              }
                            : {
                                click: function () {
                                  return _vm.openSyncMsgPopup(item)
                                },
                              }
                        ),
                        [
                          _vm.mini == false
                            ? [
                                _c(
                                  "v-list-item-icon",
                                  [
                                    _c("v-img", {
                                      attrs: { src: _vm.getIconUrl(item.path) },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item-content",
                                  { staticClass: "pa-0 nav-label" },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      {
                                        staticClass:
                                          "white--text text-caption white-space-normal text-center",
                                      },
                                      [_vm._v(" " + _vm._s(item.title) + " ")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-app-bar",
            {
              staticClass:
                "no-shadow lighten-4 text-center background-color: color('grey-1','lighten-5');",
              class: {
                "header-with-banner": _vm.errorBanner || _vm.infoBanner,
                "onscroll-shadow": _vm.applyBoxShadow,
              },
              attrs: { app: "", height: "80px", text: "" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "primarybase--text brand-name  ml-2 mt-2 mb-2 sm-hide cursor-pointer regular-font ",
                },
                [
                  _vm.isInSetup
                    ? _c("v-img", {
                        attrs: {
                          width: "80%",
                          src: require("../assets/images/search-ads-maven.svg"),
                          alt: "logo",
                        },
                      })
                    : _c("v-img", {
                        attrs: {
                          src: require("../assets/images/fullogo.svg"),
                          alt: "logo",
                        },
                      }),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", color: "primarydark" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            !_vm.syncing
                              ? _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        class:
                                          !_vm.asaIntegrationPreset ||
                                          _vm.firstSyncCD ||
                                          _vm.dailySyncCD
                                            ? "mr-3 sync-disabled"
                                            : "mr-3 primarylight--text secondarybase",
                                        attrs: {
                                          color: " ",
                                          fab: "",
                                          elevation: "1",
                                          height: "48px",
                                          width: "48px",
                                          text: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.pullData()
                                          },
                                        },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "svg",
                                      {
                                        attrs: {
                                          width: "22",
                                          height: "30",
                                          viewBox: "0 0 22 30",
                                          fill: "none",
                                          xmlns: "http://www.w3.org/2000/svg",
                                        },
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            d:
                                              "M11 4.33331V0.333313L5.66668 5.66665L11 11V6.99998C15.4133 6.99998 19 10.5866 19 15C19 16.3466 18.6667 17.6266 18.0667 18.7333L20.0133 20.68C21.0533 19.04 21.6667 17.0933 21.6667 15C21.6667 9.10665 16.8933 4.33331 11 4.33331ZM11 23C6.58668 23 3.00001 19.4133 3.00001 15C3.00001 13.6533 3.33334 12.3733 3.93334 11.2666L1.98668 9.31998C0.946677 10.96 0.333344 12.9066 0.333344 15C0.333344 20.8933 5.10668 25.6666 11 25.6666V29.6666L16.3333 24.3333L11 19V23Z",
                                            fill: "#18438F",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c("span", {
                                      staticClass: "md-down-hide-1",
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2013689516
                  ),
                },
                [_c("span", [_vm._v("Sync Data ")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-progress-circular",
                              _vm._g(
                                _vm._b(
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.syncing,
                                        expression: "syncing",
                                      },
                                    ],
                                    attrs: {
                                      rotate:
                                        _vm.syncProgress > 0 ? -90 : undefined,
                                      size: 48,
                                      width: 4,
                                      value: _vm.syncProgress || 0,
                                      color: _vm.morethan24months
                                        ? "error"
                                        : "primary",
                                      indeterminate: !_vm.syncProgress,
                                    },
                                  },
                                  "v-progress-circular",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.syncProgress) + "%"),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    922727123
                  ),
                },
                [
                  !_vm.morethan24months
                    ? _c("span", [
                        _vm._v(_vm._s(_vm.$t("navbar.syncProgress"))),
                      ])
                    : _vm._e(),
                  _vm.morethan24months
                    ? _c("span", [
                        _vm._v(
                          "Historical data sync is limited to the past 24 months."
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _vm.lastSyncAt && _vm.asaIntegrationPreset && !_vm.syncing
                ? _c(
                    "p",
                    {
                      staticClass:
                        "ma-0 px-1 grey--text cursor-default d-flex flex-column align-start",
                    },
                    [
                      _c("span", { staticClass: "whitespace-nowrap" }, [
                        _vm._v("Last sync: " + _vm._s(_vm.syncFromNow) + " "),
                      ]),
                      _c("span", { staticClass: "whitespace-nowrap" }, [
                        _vm._v(_vm._s(_vm.lastSyncAtLocal)),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.syncing
                ? _c(
                    "p",
                    {
                      staticClass:
                        "ma-0 px-1 grey--text cursor-default d-flex flex-column align-start",
                    },
                    [
                      _c("span", { staticClass: "whitespace-nowrap" }, [
                        _vm._v("Last sync: In progress "),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", color: "primarydark" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "h6",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass:
                                      "greybase--text ml-5 d-flex align-center",
                                  },
                                  "h6",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { staticClass: "mr-1" }, [
                                  _vm._v(" mdi-earth "),
                                ]),
                                _vm._v(" UTC "),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    249170912
                  ),
                },
                [_c("span", [_vm._v("All data in UTC time zone")])]
              ),
              _c(
                "p",
                { staticClass: "greybase--text ml-5 d-flex align-center mb-0" },
                [_vm._v(_vm._s(_vm.currency))]
              ),
              _c(
                "v-menu",
                {
                  attrs: {
                    bottom: "",
                    left: "",
                    "offset-y": "",
                    "close-on-content-click": false,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-avatar",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "ml-4 cursor-pointer",
                                    attrs: { color: " greyxlight", size: "50" },
                                  },
                                  "v-avatar",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "white--text font-18 regular-font",
                                  },
                                  [_vm._v(_vm._s(_vm.name))]
                                ),
                              ]
                            ),
                            _c(
                              "v-tooltip",
                              {
                                attrs: { bottom: "", color: "primarydark" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "a",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "secondary--text ml-3 d-flex align-center",
                                                  attrs: {
                                                    href:
                                                      "https://support.searchadsmaven.com/?utm_source=marketing_site_menu",
                                                  },
                                                },
                                                "a",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c(
                                                "v-icon",
                                                { staticClass: "mr-1" },
                                                [
                                                  _vm._v(
                                                    " mdi-help-circle-outline "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [_c("span", [_vm._v("Support")])]
                            ),
                            _c(
                              "v-menu",
                              {
                                attrs: { bottom: "", left: "", "offset-y": "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function (ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  staticClass:
                                                    "no-ripple select-language change-language mx-4",
                                                  attrs: {
                                                    icon: "",
                                                    width: "70",
                                                  },
                                                },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c("country-flag", {
                                                attrs: {
                                                  country:
                                                    _vm.selectedLocale.flag,
                                                },
                                              }),
                                              _c(
                                                "h6",
                                                {
                                                  staticClass:
                                                    "greybase--text ",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.selectedLocale.text
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "v-list",
                                  { staticClass: "v-profile-menu style-2" },
                                  _vm._l(_vm.locales, function (locale, i) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: i,
                                        attrs: { link: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.switchLocale(locale)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          { staticClass: "mx-2" },
                                          [
                                            _c("country-flag", {
                                              attrs: { country: locale.flag },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          { staticClass: "mt-0" },
                                          [
                                            _c("v-list-item-title", {}, [
                                              _vm._v(_vm._s(locale.text)),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2231294718
                  ),
                  model: {
                    value: _vm.menu,
                    callback: function ($$v) {
                      _vm.menu = $$v
                    },
                    expression: "menu",
                  },
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "v-profile-menu" },
                    _vm._l(_vm.orgitems, function (item, i) {
                      return _c(
                        "v-list-group",
                        {
                          key: i,
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", {
                                          domProps: {
                                            textContent: _vm._s(item.title),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: item.active,
                            callback: function ($$v) {
                              _vm.$set(item, "active", $$v)
                            },
                            expression: "item.active",
                          },
                        },
                        _vm._l(item.items, function (child) {
                          return _c(
                            "v-list-item",
                            {
                              key: child.TeamId,
                              attrs: { disabled: child.IsDisabled },
                              on: {
                                click: function ($event) {
                                  return _vm.switchOrg(child)
                                },
                              },
                            },
                            [
                              child.TeamId == _vm.TeamID
                                ? _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        staticClass: "secondary--text",
                                        domProps: {
                                          textContent: _vm._s(child.Company),
                                        },
                                      }),
                                      _c("v-list-item-subtitle", {
                                        staticClass: "secondary--text",
                                        staticStyle: { "font-size": "x-small" },
                                        domProps: {
                                          textContent: _vm._s(child.Role),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              child.TeamId != _vm.TeamID
                                ? _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          textContent: _vm._s(child.Company),
                                        },
                                      }),
                                      _c("v-list-item-subtitle", {
                                        staticStyle: { "font-size": "x-small" },
                                        domProps: {
                                          textContent: _vm._s(child.Role),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c("v-list-item-content"),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    }),
                    1
                  ),
                  _c(
                    "v-list",
                    { staticClass: "pt-0" },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: { bottom: "", left: "", "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass:
                                              "no-ripple select-language change-language-mobile",
                                            attrs: { icon: "", width: "94" },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("country-flag", {
                                          attrs: {
                                            country: _vm.selectedLocale.flag,
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "black--text text-caption",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.selectedLocale.text)
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3633831798
                          ),
                        },
                        [
                          _c(
                            "v-list",
                            { staticClass: "v-profile-menu style-2" },
                            _vm._l(_vm.locales, function (locale, i) {
                              return _c(
                                "v-list-item",
                                {
                                  key: i,
                                  attrs: { link: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.switchLocale(locale)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    { staticClass: "mx-2" },
                                    [
                                      _c("country-flag", {
                                        attrs: { country: locale.flag },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item-content",
                                    { staticClass: "mt-0" },
                                    [
                                      _c("v-list-item-title", {}, [
                                        _vm._v(_vm._s(locale.text)),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-list",
                    { staticClass: "v-profile-menu" },
                    _vm._l(_vm.menuitems, function (item, i) {
                      return _c(
                        "v-list-item",
                        {
                          key: i,
                          attrs: { link: "" },
                          on: {
                            click: function ($event) {
                              return _vm.menuClick(item.path)
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { medium: "" } }, [
                                _vm._v(_vm._s(item.icon)),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {}, [
                                _vm._v(_vm._s(item.title)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("DataRequests", { attrs: { openDR: _vm.triggerDR } }),
          _c("PermissionPopup", {
            attrs: { permissionMetadata: _vm.permissionMetadata },
          }),
          _vm.preventAccess
            ? _c("SyncPopup", {
                attrs: { ASASyncMetadata: _vm.ASASyncMetadata },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }