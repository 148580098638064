//French
export const fr = {
    pages: {
      overview: 'Aperçu',
      adsManager: `Gestionnaire d'annonces`,
      kai: `Analyse des mots clés`,
      keyOpt: 'Optimisation des mots clés',
      teamMgmt: `Équipe`,
      integrations: `Intégrations`,
      automationRule: `Automatisation`,
      campaignGenerator: 'Générateur de campagne',
      goals: 'Objectifs',
      appSettings: "Paramètres de l'application",
      kar: "Demandes d'analyse de mots clés",
      manualCampaign:'Ajouter une campagne manuelle',
    automatedCampaign:'Ajouter une campagne automatisée',
    },
    menu: {
      settings: `Paramètres`,
      signOut: 'Se déconnecter',
      manageSubscription: "Gérer l'abonnement",
      orgs: 'Organisations'
    },
    buttons: {
      syncData: 'Synchroniser les dernières données',
      createCampaign: 'Créer une campagne',
      filter: 'Filtre',
      reset: 'Réinitialiser',
      apply: 'Appliquer'
    },
    labels: {
      charts: 'Graphiques',
      selectDs: 'Sélectionner des ensembles de données',
      totalSummary: 'Résumé total',
      spend: 'Dépenser',
      search: 'Chercher',
      searchByKeySearchMsg: 'Tapez un texte et appuyez sur Entrée',
      competeSearchMsg : 'Sélectionnez ou recherchez une application',
      selectCategoryMsg: 'Choisir une catégorie',
      selectCategoryType: 'Sélectionner le genre',
      matchType: 'Type de match',
      filterItem: 'Tapez ici pour filtrer les éléments ci-dessous',
      cusType: 'Types de clients',
      createRule: 'Créer une règle pour',
      fromSelected: 'À partir de la sélection',
    mmYY: 'MM/AA'   ,
    enterKey: 'Saisir des mots-clés' ,
    selectLevel: 'Choisir le niveau',
    org: 'Organisation(s)',
    app: 'Applications)',
    camp: 'Campagne(s)',
    adGroup: "Groupe(s) d'annonces",
    key: 'Mots clés)',
    searchTerm: 'Terme(s) de recherche',
    yourApps: 'Vos applications',
    suggestedApps: 'Applications suggérées',
    },
    dropdowns: {
      noData: 'Pas de données disponibles',
      daily: 'DU QUOTIDIEN',
      weekly: 'HEBDOMADAIRE',
      monthly: 'MENSUEL'
    },
    tabs: {
      accounts: 'Comptes',
      apps: 'applications',
      campaigns: 'Campagnes',
      adgroups: `Groupes d'annonces`,
      keywords: 'Mots clés',
      creativeSets: 'Ensembles créatifs',
      searchTerms: 'Termes de recherche',
      negKeywords: 'Mots clés à exclure',
      ad: 'Un d'
    },
    summary: {
      taps: 'Robinets',
      impressions: 'Impressions',
      ttr: 'TTR',
      newDownloads: 'Nouveaux téléchargements',
      reDownloads: 'Re-téléchargements',
      installs: 'Installe',
    },
    accountHeaders: {
      account: 'Compte',
      spend: 'Dépenser',
      averageCpa: 'CPA moyen',
      averageCpt: 'CPT moyen',
      impressions: 'Impressions',
      taps: 'Robinets',
      installs: 'Installe',
      ttr: 'TTR',
      conversionRate: 'Taux de conversion',
      newDownloads: 'Nouveaux téléchargements',
      reDownloads: 'Re-téléchargements'
    },
    appsHeaders: {
      appName: 'Application',
      spend: 'Dépenser',
      averageCpa: 'CPA moyen',
      averageCpt: 'CPT moyen',
      impressions: 'Impressions',
      taps: 'Robinets',
      installs: 'Installe',
      ttr: 'TTR',
      conversionRate: 'Taux de conversion',
      attrInstall: 'Attr Installer',
      attrInstallRate: `Taux d'installation d'attr`,
      cpi: 'CPI',
      goals: 'Buts',
      costPerGoal: 'Coût par objectif',
      goalRate: `Taux d'objectif`,
      revenuePerGoal: 'Revenu par objectif',
      goalsRevenue: 'Objectifs Revenus',
      goalsRoas: 'Objectifs ROAS',
      newDownloads: 'Nouveaux téléchargements',
      reDownloads: 'Re-téléchargements',
      account: 'Compte',
      categoryRank: 'Classement de la catégorie',
      appId: `Identifiant de l'application`,
      totalCamp: 'Nombre total de campagnes',
      goalAMPU: 'Objectif AMPU'
    },
    campaignHeaders: {
      campaign: 'Campagne',
      campaignStatus: 'État de la campagne',
      spend: 'Dépenser',
      averageCpa: 'CPA moyen',
      averageCpt: 'CPT moyen',
      impressions: 'Impressions',
      taps: 'Robinets',
      installs: 'Installe',
      newDownloads: 'Nouveaux téléchargements',
      reDownloads: 'Re-téléchargements',
      ttr: 'TTR',
      conversionRate: 'Taux de conversion',
      attrInstallRate: `Taux d'installation d'attr`,
      cpi: 'CPI',
      goals: 'Buts',
      costPerGoal: 'Coût par objectif',
      goalRate: `Taux d'objectif`,
      revenuePerGoal: 'Revenu par objectif',
      goalsRoas: 'Objectifs ROAS',
      storefronts: 'Devantures',
      account: 'Compte',
      displayStatus: `Statut d'affichage`,
      appName: `Nom de l'application`,
      appId: `Identifiant de l'application`,
      campaignId: 'Identifiant de la campagne',
      delete: 'Effacer',
      totalBudget: 'Budget total',
      dailyCap: 'Cap journalier',
      supplySource:'Taper'
    },
    campaignDraftHeaders: {
      campaignName: 'Nom de la campagne',
      countries: 'Des pays',
      budgetAmount: 'Montant budgétaire',
      resume: 'Reprendre',
    },
    adGroupHeaders: {
      adgroup: `Groupe d'annonces`,
      adgroupStatus: `Statut du groupe d'annonces`,
      spend: 'Dépenser',
      averageCpa: 'CPA moyen',
      averageCpt: 'CPT moyen',
      impressions: 'Impressions',
      taps: 'Robinets',
      installs: 'Installe',
      newDownloads: 'Nouveaux téléchargements',
      reDownloads: 'Re-téléchargements',
      ttr: 'TTR',
      conversionRate: 'Taux de conversion',
      attrInstallRate: `Taux d'installation d'attr`,
      cpi: 'CPI',
      goals: 'Buts',
      costPerGoal: 'Coût par objectif',
      goalRate: `Taux d'objectif`,
      revenuePerGoal: 'Revenu par objectif',
      goalsRoas: 'Objectifs ROAS',
      storefronts: 'Devantures',
      campaignStatus: 'État de la campagne',
      displayStatus: `Statut d'affichage`,
      account: 'Compte',
      appId: `Identifiant de l'application`,
      campaignId: 'Identifiant de la campagne',
      adgroupId: `Identifiant du groupe d'annonces`
    },
    keyHeaders: {
      keyword: 'Mot-clé',
      keywordStatus: 'État du mot clé',
      bidAmount: `Montant de l'enchère`,
      spend: 'Dépenser',
      averageCpa: 'CPA moyen',
      averageCpt: 'CPT moyen',
      impressions: 'Impressions',
      taps: 'Robinets',
      installs: 'Installe',
      newDownloads: 'Nouveaux téléchargements',
      reDownloads: 'Re-téléchargements',
      ttr: 'TTR',
      conversionRate: 'Taux de conversion',
      attrInstallRate: `Taux d'installation d'attr`,
      cpi: 'CPI',
      goals: 'Buts',
      costPerGoal: 'Coût par objectif',
      goalRate: `Taux d'objectif`,
      revenuePerGoal: 'Revenu par objectif',
      goalsRevenue: 'Objectifs Revenus',
      storefronts: 'Devantures',
      campaignStatus: 'État de la campagne',
      adgroupStatus: `Statut du groupe d'annonces`,
      organicRank: 'Rang organique',
      appName: `Nom de l'application`,
      difficultyScore: 'Score de difficulté',
      popularity: 'Popularité',
      keywordId: 'Identifiant du mot clé',
      appId: `Identifiant de l'application`,
      campaignId: 'Identifiant de la campagne',
      adgroupId: `Identifiant du groupe d'annonces`,
      suggestedMinBid: `Enchère minimum suggérée par l'ASA`,
      suggestedMaxBid: `Enchère maximale suggérée par l'ASA`,
      bidStrength: `Force de l'enchère`,
      latOff: 'Installations hors LAT',
      latOn: 'LAT sur les installations',
      matchType: 'Type de match',
    },
    adsHeaders: {
      ads: 'Annonce',
      creativeType: 'Type de création',
      creativeSetStatus: 'Statut',
      spend: 'Dépenser',
      averageCpa: 'CPA moyen',
      averageCpt: 'CPT moyen',
      impressions: 'Impressions',
      taps: 'Robinets',
      installs: 'Installe',
      newDownloads: 'Nouveaux téléchargements',
      reDownloads: 'Re-téléchargements',
      ttr: 'TTR',
      conversionRate: 'Taux de conversion',
      attrInstallRate: `Taux d'installation d'attr`,
      cpi: 'CPI',
      goals: 'Buts',
      costPerGoal: 'Coût par objectif',
      goalRate: `Taux d'objectif`,
      revenuePerGoal: 'Revenu par objectif',
      goalsRevenue: 'Objectifs Revenus',
      storefronts: 'Devantures',
      campaignStatus: 'État de la campagne',
      adgroupStatus: `Statut du groupe d'annonces`,
      account: 'Compte',
      appId: `Identifiant de l'application`,
      campaignId: 'Identifiant de la campagne',
      adgroupId: `Identifiant du groupe d'annonces`,
      action:'action',
      delete: 'Effacer'
    },
    creativeHeaders: {
      creativeSet: 'Ensemble créatif',
      creativeSetStatus: 'Statut',
      spend: 'Dépenser',
      averageCpa: 'CPA moyen',
      averageCpt: 'CPT moyen',
      impressions: 'Impressions',
      taps: 'Robinets',
      installs: 'Installe',
      newDownloads: 'Nouveaux téléchargements',
      reDownloads: 'Re-téléchargements',
      ttr: 'TTR',
      conversionRate: 'Taux de conversion',
      attrInstallRate: `Taux d'installation d'attr`,
      cpi: 'CPI',
      goals: 'Buts',
      costPerGoal: 'Coût par objectif',
      goalRate: `Taux d'objectif`,
      revenuePerGoal: 'Revenu par objectif',
      goalsRevenue: 'Objectifs Revenus',
      storefronts: 'Devantures',
      campaignStatus: 'État de la campagne',
      adgroupStatus: `Statut du groupe d'annonces`,
      account: 'Compte',
      appId: `Identifiant de l'application`,
      campaignId: 'Identifiant de la campagne',
      adgroupId: `Identifiant du groupe d'annonces`
    },
    searchTermsHeader: {
      searchTerm: 'Texte du terme de recherche',
      spend: 'Dépenser',
      averageCpa: 'CPA moyen',
      averageCpt: 'CPT moyen',
      impressions: 'Impressions',
      taps: 'Robinets',
      installs: 'Installe',
      ttr: 'TTR',
      conversionRate: 'Taux de conversion',
      newDownloads: 'Nouveaux téléchargements',
      reDownloads: 'Re-téléchargements',
      storefronts: 'Devantures',
      campaignStatus: 'État de la campagne',
      adgroupStatus: `Statut du groupe d'annonces`,
      keyword: 'Mot-clé',
      keywordId: 'Identifiant du mot clé',
      appId: `Identifiant de l'application`,
      campaignId: 'Identifiant de la campagne',
      adgroupId: `Identifiant du groupe d'annonces`
    },
    negativeHeaders: {
      keyword: 'Mot-clé',
      matchType: 'Type de match',
      status: 'Statut',
      adgroupId: `Identifiant du groupe d'annonces`,
      campaignId: 'Identifiant de la campagne',
      deleted: 'Supprimé',
      keywordId: 'Identifiant du mot clé',
      modificationTime: 'Heure de modification'
    },
    filter: {
      account: 'Sélectionnez un compte',
      app: `Sélectionnez l'application`,
      goal: 'Sélectionnez l\'objectif',
      campaign: 'Sélectionnez la campagne',
      adgroup: `Sélectionnez le groupe d'annonces`,
      ad: "Sélectionnez l'annonce"
    },
    subTabs: {
      all:'Tous',
      live: 'Vivre',
      paused: 'En pause',
      deleted: 'Supprimé',
      draft: 'Brouillon',
      campaigns: 'Campagnes',
      adgroups: `Groupes d'annonces`,
      keywords: 'Mots clés',
      creativeSet: 'Ensemble créatif',
      negKey: 'Mots clés à exclure'
    },
    popups: {
      add: 'Ajouter',
      integration: `L'intégration`,
      adgroup: `Groupe d'annonces`,
      keywords: 'Mots clés',
      creativeSet: 'Ensemble créatif',
      negKey: 'Mots clés à exclure',
      campaigns:'Campagnes'
    },

    //Team Management Page
    teamMgmt: {
      manageUsers: 'Gérer les membres',
      invite: `Inviter et gérer les membres de l'équipe`,
      add: 'Ajouter un membre',
      first: 'Prénom',
      last: 'Nom de famille',
      phone: 'Téléphoner',
      email: 'E-mail',
      job: `Titre d'emploi`,
      allMembers: 'Tous les membres',
      processing: 'Traitement...',
      ok: `D'accord`,
      inviteStatus: `Statut d'invitation`,
      isDisabled: 'est désactivé',
      company: 'Nom de la compagnie'
    },

    //Add Integration Page
    integration: {
      assPart: 'Partenaires associés',
      apple: 'Apple Search Ads',
      slack: 'Slack',
      itunes: 'Itunes',
      tenjin: 'Tenjin',
      kochava: 'Kochava',
      adjust: 'Adjust',
      appsFlyer: 'AppsFlyer',
      singular: 'Singular',
      communication:'Communication',
      mmp:'Partenaires de mesure mobile',
      completeInt:'Intégrations terminées',
      receivedEventType:'Types d\'événements reçus',
      waitForEvents:'En attente d\'événements',
      integrateWith:'Intégrer avec',
      intStep:'Étapes d\'intégration',
      integrate:'Intégrer',
      followIntSteps:'Suivez ces étapes d\'intégration',
      //kochava steps
      kochavaStep1:'1. accédez à vos applications Kochava sur https://go.kochava.com/apps.',
      kochavaStep2:'2. sélectionnez Configuration du partenaire dans le menu contextuel de votre application.',
      kochavaStep3:'3. Cliquez sur Ajouter une configuration sur la page Configuration du partenaire.',
      kochavaStep4:'4. Choisissez Search Ads Maven - iOS dans le menu Media Partner et cliquez sur Go.',
      kochavaStep5:'5. Choisissez Créer dans le menu contextuel de chaque événement que vous souhaitez syndiquer à Search Ads Maven.',
      kochavaStep6:'6. Utilisez la clé API et l\'ID App Store fournis ici pour chaque création de publication.',
      kochavaStep7:'7. Attendez au moins 24 heures pour que les événements soient reconnus par Search Ads Maven. Notez que seuls les événements attribués aux campagnes Apple Search Ads sont reconnus.',
      kochavaStep8:'8. Créez un objectif pour chaque événement reconnu par Search Ads Maven. Une fois les objectifs créés, les données d\'événement MMP telles que le nombre et les revenus seront disponibles dans le tableau de bord.',

      //branchSteps
      branchStep:'1. Utilisez la clé API et l\'ID App Store suivants dans votre tableau de bord Branch.',

      //customSteps
      customStep:'1. Utilisez la clé API et l\'ID App Store suivants dans votre postback.',

      apiKey:'Clé API',
      appStoreId:'Magasin d\'applications',
      distinctEventType:'des types d\'événements distincts attribués à ASA ont été livrés par un MMP pour cette application. Ces types d\'événements peuvent être utilisés dans la création d\'objectifs.',
      noDistinctEventType:'Aucun événement attribué à Apple Search Ads n\'a encore été reçu. Si les événements n\'ont pas été reçus après 24 heures, veuillez revérifier ces étapes d\'intégration et vous assurer que des campagnes Apple Search Ads actives sont en cours d\'exécution, ce qui déclencherait la diffusion d\'un ou plusieurs événements par le MMP.',
      dEventType:'Types d\'événements distincts',
      noEventFound:'Aucun événement trouvé',
      close:'proche',
      note:'REMARQUE : Les applications déjà intégrées à un autre MMP n\'apparaîtront pas dans cette liste.',
      intDeletionInProgress:"La suppression de l'intégration est actuellement en cours et nous vous informerons une fois terminée."
    },

    //Keyword Auction Insights Page
    kai: {
      addedKeywords: 'Mots-clés ajoutés',
      total: 'Le total',
      cancel: 'Annuler',
      runAds: 'Diffuser des annonces',
      ok: `D'accord`,
      totalPaidKey: 'Total des mots-clés payés',
      popularity: 'Popularité',
      numOfApps: `Nombre d'applications`,
      chance: 'Chance',
      appsRunAds: 'Applications diffusant des annonces',
      maxPop: 'Popularité maximale pour la vôtre',
      keyword: 'Mot-clé',
      clear: 'Dégager',
      clrAllFilter: 'Tout effacer Filtrer',
      keywords: 'Mots clés',
      organicRank: 'Rang organique',
      totalApps: `Nombre total d'applications`,
      app: 'Application',
      catName: 'Nom de catégorie',
      searchByApp: 'Recherche par application',
      searchByKey: 'recherche par mots-clés',
      addAllKey: 'Ajouter tous les mots clés',
      country: 'Pays',
      lang: 'Langue',
      searchForCompetes: 'Concurrents suggérés',
      searchAdsRecommends: 'Recommandations pour les annonces du Réseau de Recherche',
      categoryTop: 'Catégorie Meilleures applications',
      compRating: 'Évaluation',
      compVersion: 'Version',
      compPrice: 'Prix',
      compGenres: 'Genres',
      monthlyDownloads: 'Téléchargements mensuels',
      showDataText: 'Aucune donnée à afficher',
      free: 'Libérer',
      paid: 'Payé',
      quickAddCriteria: "Critères d'ajout rapide",
      tooltip: {
        keyText: "Titre du mot-clé",
        organicRank: "Le classement organique fait référence aux résultats de recherche d'un moteur de recherche qui ne peuvent pas être influencés par la publicité payante.",
        popularity: "Le score de popularité du mot-clé. Un score de recherche plus élevé signifie un volume de recherche plus élevé sur le mot-clé.",
        totalApps: "Nombre total d'applications utilisant ce mot clé",
        appDetails: "Nom de l'application",
        organicRankApp: "Le classement organique fait référence aux résultats de recherche d'un moteur de recherche qui ne peuvent pas être influencés par la publicité payante.",
        category: "Les catégories auxquelles appartient l'application",
        rating: "Évaluation de l'application",
        version: "Version de l'application",
        price: "Prix ​​de l'application",
        genres: "Genre d'application",
        chance: "Chance Score est un score en pourcentage basé sur une estimation de vos chances d'être dans le top dix pour un mot clé particulier dans la catégorie spécifique de votre application",
        appsRunning: "Nombre total d'applications payant pour le mot-clé"
      }


    },

    //Overview Page
    overview: {
      selectGoalFirstSelectApp:' Pour sélectionner un objectif, sélectionnez d\'abord une application dans la liste déroulante à gauche.',
      count:'Nombre d\'événements objectifs',
      exportCSV:'Exporter CSV',
      period: 'période',
      topKeywords: 'Top 10 des mots clés par',
      key: 'Mot-clé',
      spend: 'Dépenser',
      impressions: 'Impressions',
      taps: 'Robinets',
      goToKey: 'Aller à tous les mots clés',
      topAdgroup: `Top 10 des groupes d'annonces par`,
      adgroup: `Groupe d'annonces`,
      goToAdgroup: `Accédez à tous les groupes d'annonces`,
      gender: 'Genre',
      device: 'Appareil',
      searchApp: 'Application de recherche',
      lastDay: 'Dernier jour',
      last7Days: 'Les 7 derniers jours',
      last30Days: 'Les 30 derniers jours',
      ttr: 'Taux de passage (TTR)',
      installs: 'Installe',
      avgCPA: 'CPA moyen',
      avgCPT: 'CPT moyen',
      conversionRate: 'Taux de conversion (CR)',
      latOnInstalls: 'LAT sur les installations',
      latOffInstalls: 'Installations hors LAT',
      reDownloads: 'Re-téléchargements',
      newDownloads: 'Nouveaux téléchargements',
      all: 'Tout',
      tooltip: 'Laugmentation ou la diminution au fil du temps en pourcentage par rapport à la période précédente en fonction de la plage de dates sélectionnée.',
      selectPer: 'Sélectionnez la période',
      prevPeriod: 'Période Préc.',
      currPeriod: 'Période actuelle',

      GOLS: 'Buts',
      CPRG: 'Coût par objectif',
      RPRG: 'Revenu par objectif',
      GREV: "Chiffre d'affaires des objectifs",
      GRAS: 'But ROAS',
      GETC: "But Nombre d'événements"
    },

    checklist: {
      title: 'Liste de contrôle',
      integrations: 'Intégrations',
      searchads: 'Annonces de recherche Apple',
      slack: 'Ajouter une intégration Slack',
      kai: 'Familiarisez-vous avec les outils d\'analyse de mots-clés',
      competitorMsg: 'Voyez comment vos concurrents s\'en sortent.',
      searchKeywords: 'Voir les mots-clés pour une application.',
      seeApps: 'Voir les applications d\'enchères pour un mot-clé.',
      topCharts: 'Voir les meilleurs classements de la catégorie.',
      campaign: 'Campagne',
      initCampaign: 'Lancer une campagne.',
      createAdgroup: 'Créer un groupe d\'annonces.',
      addKeywords: 'Ajouter des mots-clés.',
      addCreativeSet: 'Ajouter un ensemble de créations.',
      goLive: 'Passez en direct.',
      reviewMetrics: 'Examiner le succès de la campagne.',
      automate: 'Automatisez votre campagne.',
      mmpIntegration: 'Intégration MMP',
      createMmpGoals: 'Créer des objectifs MMP',
      campGen:'Générateur de campagne',
      scheduledReports:'Rapports planifiés'
    },

    //Keyword Optimization Page
   keyOpt: {
   keyBid: 'Optimisation des mots clés',
   keyToolTip: `Vous pouvez optimiser le montant de l'enchère des mots-clés qui ne se situent pas dans la plage du montant de l'enchère minimale suggérée par l'ASA et du montant de l'enchère maximale suggérée par l'ASA.`,
   optAll: 'Optimiser tout',
   opt: 'Optimiser',
   optBidAmt: `Optimiser le montant de l'enchère`,
   key: 'Mot-clé',
   optBidForAll: `Optimiser le montant de l'enchère de tous les mots clés`,
   campaign: 'Campagne',
   adgroup: `Groupe d'annonces`,
   bidAmt: `Montant de l'enchère`,
   asaMinBid: `Enchère minimum suggérée par l'ASA`,
   asaMaxBid: `Enchère maximale suggérée par l'ASA`,
   action: 'action',
   noData: "Apple n'a aucune suggestion pour le moment."
   },

   //ARRules Page
   aRules: {
   createAR: `Créer une règle d'automatisation`,
   view: 'Vue',
   edit: 'Éditer',
   delete: 'Effacer',
   disable: 'Désactiver',
   enable: 'Activer',
   name: 'Nom',
   createdAt: 'Créé à',
   status: 'Statut',
   completed: 'Terminé à',
   actionFreq: `Fréquence d'action`,
   rules: 'Des règles',
   logs: 'Journaux',
   createdFor: 'Créé pour',
   chckFreq: 'Vérifier la fréquence',
   actions: 'Actions',
   enableDisable: 'Activer désactiver',
   sno: 'S.No'
   },

   //Create Campaign
   //CampaignView
   createCampaign: {
   createCamp: 'Créer une campagne',
   h2: 'Choisissez une application pour commencer à créer une campagne',
   para: `Les campagnes ont des budgets et des groupes d'annonces qui se concentrent sur un thème ou une stratégie commun pour la promotion de votre application.`,
   select: 'Sélectionnez où les annonces seront diffusées',
   searchResults: 'Résultats de recherche',
   srData: `Les campagnes ont des budgets et des groupes d'annonces qui se concentrent sur un thème ou une stratégie commun pour la promotion de votre application.`,
   searchTab: 'Onglet Rechercher',
   stData: `Faites la promotion de votre application en haut de la liste des applications suggérées lorsque les utilisateurs visitent l'onglet Rechercher.`,
   todayTab: `Onglet Aujourd'hui`,
   todayData:`Atteignez les utilisateurs lorsqu'ils accèdent pour la première fois à l'App Store pour découvrir des applications. Votre annonce apparaîtra bien en évidence sur la page d'accueil.`,

   productPageTab: `Fiches produits`,
   productPageData: `Atteignez les utilisateurs lorsqu'ils parcourent des applications. Votre annonce apparaîtra en haut. Vous pouvez également répertorier les utilisateurs qui ont fait défiler vers le bas les pages de produits pertinentes.`,
   cancel: 'Annuler',
   continue: 'Continuer',

   //Search Results and Search Tab Campaign
   countAndReg: 'Pays et régions',
   adgroupSettings: `Paramètres du groupe d'annonces`,
   searchMatch: 'Recherche de correspondance',
   key: 'Mot-clé',
   audience: 'Public',
   crSets: 'Ensembles créatifs',
   chooseCampGroup: 'Choisissez un groupe de campagnes',
   chooseApp: 'Choisissez une application',
   next: 'Suivant',
   countPara: `Votre application sera promue sur l'App Store dans les pays et régions éligibles que vous choisissez dans la liste.`,
   chooseCount: 'Choisissez un ou plusieurs pays ou régions',
   campName: 'Nom de la campagne',
   budget: 'Budget',
   dailyCap: 'Plafond quotidien (facultatif)',
   adgroupPara: `Les groupes d'annonces contiennent des objectifs et des paramètres de tarification qui déterminent à qui diffuser votre annonce.`,
   adgroupName: `Nom du groupe d'annonces`,
   devices: 'Dispositifs',
   startTime: 'Heure de début',
   adScheduling: 'Planification des annonces',
   endTime: 'Heure de fin',
   dayParting: 'Heures de départ (facultatif)',
   ok: `d'accord`,
   defaultMax: 'Enchère CPT max. par défaut',
   cpaGoal: 'Objectif CPA (facultatif)',
   saveDraft: 'Enregistrer comme brouillon',
   appleSearchMatch: 'Correspondance de recherche Apple',
   searchMatchPara: 'Apple Search Match est le moyen le plus simple de diffuser vos annonces. Search Ads fera automatiquement correspondre votre annonce aux utilisateurs qui recherchent des applications comme la vôtre.',
   searchMatchPara1: 'Faire correspondre automatiquement mon annonce aux recherches pertinentes',
   keywords: 'Mots clés',
   keyPara: `Les mots clés sont des mots ou des termes pertinents que les clients peuvent utiliser lorsqu'ils recherchent une application comme la vôtre. Les mots clés permettent aux chercheurs de trouver rapidement votre application, ce qui contribue à générer des installations d'applications.`,
   upload: 'Télécharger',
   suppFormat: 'formats pris en charge : Excel, CSV',
   keyManually: 'Ou ajoutez des mots-clés manuellement',
   targKey: 'Ciblage par mots-clés',
   clear: 'Dégager',
   adgroupNegKey: `Groupe d'annonces Mots clés à exclure`,
   optional: 'Optionnel',
   negKey: 'Mots clés à exclure',
   audPara: ` Affinez l'audience de votre groupe d'annonces à l'aide de fonctionnalités avancées facultatives.`,
   custType: 'Type de client',
   demo: 'Démographie',
   gender: 'Genre',
   location: 'Emplacement (Recherche)',
   crSetPara: `Les annonces sont automatiquement créées à l'aide des métadonnées, des captures d'écran et des aperçus d'application fournis sur la page produit de l'App Store. Ceux-ci apparaissent également dans les résultats de recherche organiques sur l'App Store. Les annonces affichées ici sont des exemples d'annonces par défaut.`,
   crOptional: 'Ensemble de créations (facultatif)',
   noCrSet: `L'application ne remplit pas l'exigence d'un ensemble Annonce minimum`,
   campCreated: 'Campagne créée',
   campCreatedPara: 'Désormais, vous pouvez voir vos campagnes sur le tableau de bord des annonces du Réseau de Recherche',
   close: 'Fermer',
   matchType: 'Type de match',
   amount: 'Montant',
   currency: 'Devise',
   allUsers: 'Tous les utilisateurs',
   newUsers: 'Nouveaux utilisateurs',
   appUsers: 'Utilisateurs de mes autres applications',
   existingUsers: 'Utilisateurs existants',
   all: 'Tout',
   male: 'Mâle',
   female: 'Femelle',
   chooseCamp: 'Groupe de campagne',
   selectApp: 'Application de recherche',
   countries: 'Des pays',
   ageRange: "Tranche d'âge",
   selectDev: 'Sélectionnez les appareils',
   adsOpt: 'Annonce (facultatif)',
   default: 'Défaut',
   defaultAdPara: 'Utilise les actifs de votre page de produit App Store par défaut.',
   custom: 'Personnalisé',
   customPara: 'Utilise les actifs de votre App Store Connect par défaut et redirige les utilisateurs vers cette page lorsqu\'ils appuient sur l\'annonce.',
   pagesInfo: 'Des pages de produits personnalisées sont disponibles',
   createAdPara: 'Cliquez sur le bouton Créer une annonce pour sélectionner parmi les options de votre page produit personnalisée et voir un aperçu de votre annonce',
   createAdBtn: 'Créer une publicité',
   createAds: 'Créer des annonces',
   selectCustomPara: 'Sélectionnez une page de produit personnalisée pour que ses éléments soient utilisés pour créer votre annonce. Lorsque les utilisateurs appuient sur votre annonce dans les résultats de recherche, ils sont redirigés vers la page que vous sélectionnez.',
   adName: 'Nom de l\'annonce',
   search: 'Chercher',
   available: 'Disponible',
   sortBy: 'Trier par',
   save: 'Sauvegarder',
   customAds: 'Annonces personnalisées',
   edit: 'Éditer',
   previous: 'Précédente'
   },

   //Change Password
  changePass: {
    backSett: 'Retour aux paramètres',
    chngPass: 'Mot de passe de configuration',
    newPass: 'nouveau mot de passe',
    confirmPass: 'Confirmez le mot de passe',
    submit: 'Soumettre',
    h4: `Le système d'exploitation Kochava Marketers™`,
    para:
      `Le Marketers Operating System (m/OS) intègre de manière transparente des solutions de marketing omnicanal pour les annonceurs et les éditeurs dans une plate-forme opérationnelle.`,
    ok: `d'accord`,
  },

  //Forgot Password
  forgotPass: {
    forPass: `Mot de passe oublié`,
    typeEmail: `Tapez l'adresse e-mail`,
    emailAdd: 'Adresse e-mail',
    submit: 'Soumettre',
    chckEmail: 'Merci de consulter vos emails',
    para: 'Pour définir un nouveau mot de passe, veuillez vérifier votre e-mail',
    close: 'Fermer',
    alreadyAcc: 'Vous avez déjà un compte?',
    signIn: `S'identifier`,
  },

  //Onboard
  onboard: {
    welcome: 'Bienvenue à SearchAds Maven',
    personal: "S'inscrire",
    start: 'Début',
    integration: `L'intégration`,
    next: 'Prochain',
    trial: 'Commencez votre essai',
    scheduleDemo: 'Ou programmez une démo avec nous et découvrez ensemble',
    addInt: 'Ajouter une intégration.',
    signInApple:
      'Connectez-vous avec Apple pour ajouter une intégration Apple Search Ads. Veuillez noter:',
    step1:
      `1. Il est nécessaire d'avoir des droits d'administrateur pour accorder l'accès au compte Apple Search Ads.`,
    step2:
      `2. Afin de gérer vos campagnes à partir de SearchAdsNinja, veuillez sélectionner l'option Lire et écrire avant d'accorder l'accès.`,
    skip: 'Sauter',
  },

  //AddAdgroup
  addAdgroup: {
    addAd: `Ajouter un groupe d'annonces`,
    para:
      `Vous n'avez pas encore sélectionné votre campagne. Vous pouvez le choisir dans la boîte ci-dessous.`,
    camGroup: 'Groupe de campagne',
    campaign: 'Campagne',
    adgroupSett: `Paramètres du groupe d'annonces`,
    para1:
      `Les groupes d'annonces contiennent des objectifs et des paramètres de tarification qui déterminent à qui diffuser votre annonce.`,
    adgroupName: `Nom du groupe d'annonces`,
    devices: 'Dispositifs',
    adScheduling: 'Planification des annonces',
    startTime: 'Heure de début',
    endTime: 'Heure de fin',
    dayparting: 'Heures de départ (facultatif)',
    defaultMax: 'Enchère CPT max. par défaut',
    cptTool:
      `Il s'agit du montant maximum que vous êtes prêt à payer pour un clic sur votre annonce. Cette enchère s'appliquera à tous les mots clés de ce groupe d'annonces, sauf si vous appliquez une enchère de mot clé individuelle.`,
    cpaGoal: 'Objectif CPA (facultatif)',
    cpaTool:
      'La moyenne que vous avez payée pour chaque téléchargement. Ceci est calculé en divisant le total dépensé par le nombre de téléchargements.',
    searchMatch: 'Recherche de correspondance',
    searchTool:
      'Search Match est le moyen le plus simple de diffuser vos annonces. Search Ads fera automatiquement correspondre votre annonce aux utilisateurs qui recherchent des applications comme la vôtre.',
    searchPara: 'Faire correspondre automatiquement mon annonce aux recherches pertinentes',
    key: 'Mots clés',
    keyPara:
      `Les mots clés sont des mots ou des termes pertinents que les clients peuvent utiliser lorsqu'ils recherchent une application comme la vôtre. Les mots clés permettent aux chercheurs de trouver rapidement votre application, ce qui contribue à générer des installations d'applications.`,
    inputCpt: `Saisissez l'enchère CPT max. par défaut pour activer la section des mots-clés`,
    addKeyPara:
      'Ajoutez des mots-clés pertinents pour votre application et votre genre. Nous vous aiderons en partageant des recommandations supplémentaires.',
    upload: 'Télécharger',
    addKeyManually: 'Ou ajoutez des mots-clés manuellement',
    negKey: `Mots clés à exclure du groupe d'annonces (facultatif)`,
    supp: 'formats pris en charge : Excel, CSV',
    demAge: 'Démographie Âge, Sexe',
    demPara: "Ciblez votre annonce en fonction de l'âge ou du sexe de votre utilisateur",
    ageRange: `Tranche d'âge`,
    cancel: 'Annuler',
    apply: 'Appliquer',
  },

  //Edit Adgroup
  editAdgroups: {
    edit: `Modifier le groupe d'annonces`,
    adgroup: `Groupe d'annonces`,
    advance: 'Paramètres avancés',
    viewEdit: 'Afficher et modifier',
    general: 'Général',
    settings: 'Paramètres',
    schedule: 'Programme',
    custPara: `Sélectionnez les clients de l'application que vous souhaitez atteindre`,
    selectAge: `Sélectionnez la tranche d'âge`,
    save: 'sauver',
  },

  //Create AR
  createAR: {
    rule:"Régner",
    addRule: 'Ajouter une règle',
    selectDateRange:'Sélectionnez la plage de dates',
    createAutoRule: `Créer une règle d'automatisation`,
    editAutoRule: `Modifier la règle d'automatisation`,
    choose: 'Choisir',
    chooseAutoRule: `Veuillez choisir le niveau d'automatisation et les éléments.`,
    conditions: 'Les conditions',
    actions: 'Actions',
    settings: 'Paramètres',
    condition: 'État',
    addCondition: 'Ajouter une condition',
    andCondition:
      `Choisissez si toutes les conditions ci-dessus doivent correspondre ou si une seule d'entre elles doit correspondre`,
    checkCond: 'Vérifiez les conditions pour',
    action: 'Action',
    selectAll: 'Tout sélectionner',
    enterEmail: 'Veuillez saisir les identifiants de messagerie appropriés',
    addTo: 'Ajouter à',
    selectAdgroups: `Sélectionnez les groupes d'annonces`,
    selectCampaigns: 'Sélectionnez des campagnes',
    cptBid: 'Enchère CPT',
    customBid: 'Enchère personnalisée',
    matchType: 'Type de match',
    addAction: 'Ajouter une action',
    chckFreq: 'Vérifier la fréquence',
    ruleName: 'Nom de la règle',
    cancel: 'Annuler',
    step2: 'Étape 2',
    step3: 'Étape 3',
    andOrTypesText1: 'Tout doit correspondre',
    andOrTypesText2: 'Un seul doit correspondre',
    addToModelListNegText1: `Tous les groupes d'annonces sélectionnés`,
    addToModelListNegText2: `Tous les groupes d'annonces des campagnes sélectionnées)`,
    addToModelListNegText3: 'Toutes les campagnes sélectionnées',
    matchTypesText1: 'Requête large',
    matchTypesText2: 'Correspondance exacte',
    cptBidItemsText1: 'Définir sur personnalisé',
    cptBidItemsText2: `Enchère par défaut du groupe d'annonces`,
    actionFreqsText1: 'Horaire',
    actionFreqsText2: 'Toutes les 6 heures',
    actionFreqsText3: 'Toutes les 12 heures',
    actionFreqsText4: 'Tous les jours',
    actionFreqsText5: 'Horaires personnalisés',
    limitActionFreqsText1: 'Toutes les 2 heures',
    limitActionFreqsText2: 'Toutes les 3 heures',
    limitActionFreqsText3: 'Tous les 2 jours',
    limitActionFreqsText4: 'Tous les 3 jours',
    limitActionFreqsText5: 'Toutes les semaines',
    mon: 'Lundi',
    tue: 'mardi',
    wed: 'mercredi',
    thu: 'jeudi',
    fri: 'Vendredi',
    sat: 'samedi',
    sun: 'dimanche',
    ciTimeRangesText1: 'Dernière 1 heure',
    ciTimeRangesText2: '1 dernier jour',
    ciTimeRangesText3: 'Les 7 derniers jours',
    ciTimeRangesText4: 'Les 30 derniers jours',
    ciTimeRangesText5: 'Le mois dernier',
    ciTimeRangesText6: 'Heure personnalisée',
    ciTimeRangesText7: 'Plage de dates',
    allConOperationText1: 'Plus grand que',
    allConOperationText2: 'Moins que',
    allConOperationText3: 'Varier',
    allConOperationText4: 'Pas à portée',
    amount: 'Montant en $',
    budget: '% Pourcentage du budget',
    incBy: 'Augmenté de',
    decBy: 'Diminuer de',
    setToCPA: 'Définir sur CPA moyen',
    setToCPT: 'Définir sur CPT moyen',
    value: 'Valeur en $',
    change: '% Changement',
    between: 'Entre',
    equalTo: 'Égal à',
    appsSelected:'Applications sélectionnées',
    campaignsSelected:'Campagnes sélectionnées',
    adgroupsSelected:'Groupes d\'annonces sélectionnés',
    KeywordsSelected:'Mots clés sélectionnés',
    selectField:'Sélectionnez un champ',
    selectOne:'Sélectionnez-en un',
    pleaseSelect:'Veuillez sélectionner',
    pleaseEnteraValue:'entrer un nombre, SVP',
    fromValue:'De la valeur',
    toValue:'Évaluer',
    mustBeLesserThanToValue:'Doit être inférieur à la valeur To',
    mustBeHigherThanFromValue:'Doit être supérieur à la valeur',
    cannotBeZero:'Ne peut pas être 0',
    ruleAlreadyExist:'Le nom de la règle existe déjà',
    emailNotification:'Notification par e-mail',
    slackNotification: 'Notification de mou',
    pause:'la pause',
    resume:'reprendre',
    changeDailyCap:'Changer la limite quotidienne',
    changeBid:'Modifier l\'enchère',
    changeBudget:'Modifier le budget',
    addAsNegativeKeyword:'Ajouter comme mot clé négatif',
    createActionFor: 'Créer une action pour',
    selectAction:'Sélectionnez l\'action',
    selectEmail:'Sélectionnez E-mail',
    addOtherEmail:'Ajouter un autre e-mail',
    enterAnotherEmail:'Entrez un identifiant de messagerie et appuyez sur Entrée pour en ajouter d\'autres',
    giveAName:'Donnez un nom à cette règle',
    searchLogs: 'Filtrer les journaux par règle',
    details: 'Détails',
    PENDING: 'EN ATTENDANT',
    SUCCESS: 'SUCCÈS',
    FAILED: 'MANQUÉ',
    CREATED: 'ÉTABLI',
    DELETED: 'SUPPRIMÉ',
    ENABLED: 'ACTIVÉ',
    DISABLED: 'DÉSACTIVÉ',
    REACTIVATED: 'RÉACTIVÉ',
    UPDATED: 'ACTUALISÉ',
    CONDITIONMATCHED : 'CONDITION CORRESPONDANTE',
    CONDITIONNOTMATCHED:'CONDITION NON CORRESPONDANTE',
    runIndefinite:'Exécuter indéfiniment',
    indefiniteNote:'REMARQUE : Lorsqu\'elle est sélectionnée, cette règle d\'automatisation continuera à être appliquée à l\'intervalle spécifié et restera en vigueur quel que soit le nombre de fois où elle est déclenchée.',
    sureToSave:'Êtes-vous sûr de vouloir enregistrer la règle d\'automatisation ?',
    update:'Mise à jour',
    selectChannel:'Sélectionnez le canal',
    noDataAvailable:'Pas de données disponibles',
    sureToDelete:'Vous souhaitez supprimer la règle d\'automatisation ?',
    
  },

  //select Account AR
  selectAccountAR: {
    chooseAcc: 'Choisissez un compte',
    cancel: 'Annuler',
    apply: 'Appliquer',
    clearAll: 'Tout effacer',
    accounts: 'Comptes',
    accountSelected: 'Compte(s) sélectionné(s)',
  },

  //select Apps AR
  selectAppsAR: {
    chooseApp: `Choisissez l'application`,
    cancel: 'Annuler',
    apply: 'Appliquer',
    apps: 'applications',
    appSelected: 'Application(s) sélectionnée(s)',
    clearAll: 'Tout effacer',
  },

  //select Keywords AR
  selectKeywordsAR: {
    keepOneAdGroup: `Veuillez ne conserver qu'un seul groupe d'annonces sélectionné pour continuer.`,
    close: 'Fermer',
    chooseKeywords: 'Choisissez des mots clés',
    cancel: 'Annuler',
    apply: 'Appliquer',
    campaigns: 'Campagnes',
    expandAll: 'Développer tout',
    collapseAll: 'Tout réduire',
    adGroups: `Groupes d'annonces`,
    keywords: 'Mots clés',
    keywordSelected: 'mot-clé(s) sélectionné(s)',
    clearAll: 'Tout effacer',
    excludePausedKeywords:'Exclure les mots clés suspendus',
  },

  //campaign
  //AddCreativeSet
  addCreativeSet: {
    addCreative: 'Ajouter un ensemble créatif',
    setup: 'Installer',
    para:
      `Choisissez un nom qui décrit le thème de votre Creative Set. Sélectionnez la langue des captures d'écran et des aperçus que vous souhaitez inclure à partir de votre page produit App Store.`,
    createSetName: `Nom de l'ensemble de créations `,
    localLang: 'Langue de localisation',
    assetSelection: `Sélection d'actifs`,
    assetSelectionPararaph1:
      `Les Creative Sets se composent de captures d'écran et d'aperçus d'applications qui apparaissent sur la page produit de l'App Store.`,
    assetSelectionPararaph2: `Pour chaque taille d'affichage iPhone,`,
    assetSelectionPararaph3:
      'sélectionnez au moins un paysage ou trois éléments de portrait.',
    assetSelectionPararaph4: `Pour chaque taille d'écran iPad,`,
    assetSelectionPararaph5:
      'sélectionnez au moins un paysage ou deux éléments de portrait.',
    title1: 'iPhone/iPod',
    screenshotsPara1: 'Veuillez sélectionner au moins',
    screenshotsPara2: 'un paysage',
    screenshotsPara3: 'ou alors',
    screenshotsPara4: 'deux portraits',
    screenshotsPara5: `captures d'écran!`,
    screenshotsPara6: 'Veuillez sélectionner au moins',
    screenshotsPara7: 'un paysage',
    screenshotsPara8: 'ou alors',
    screenshotsPara9: 'trois portraits',
    screenshotsPara10: `captures d'écran!`,
    cancel: 'Annuler',
    save: 'sauver',
  },

  //DeleteCampaign
  deleteCampaign: {
    deleteCamp: 'Supprimer la campagne',
    para: 'Voulez-vous vraiment supprimer la campagne ?',
    cancel: 'Annuler',
    delete: 'Supprimer',
  },

  //EditCampaign
  editCampaign: {
    editCampWizard: 'Assistant de modification de campagne',
    campName: 'Nom de la campagne',
    app: 'Application',
    countriesOrRegionsPara: 'Choisissez un ou plusieurs pays ou régions',
    budget: 'Budget',
    dailyCap: 'Cap journalier',
    cancel: 'Annuler',
    save: 'sauver',
  },

  //SelectCampaign
  selectCamp: {
    chooseCamp: 'Choisissez la campagne',
    noSelectedCamp: 'Aucune campagne sélectionnée',
    campaigns: 'Campagnes',
    expandAll: 'Développer tout',
    collapseAll: 'Tout réduire',
    selectedCampaigns: 'Campagnes sélectionnées',
    clearAll: 'tout effacer',
    cancel: 'Annuler',
    apply: 'Appliquer',
  },

  //chargebee
  //EditCard
  editCard: {
    editCreditCard: 'Modifier la carte de crédit',
    cardNumber: 'Numéro de carte',
    expiryDate: `Date d'expiration`,
    cvv: 'CVV',
    saveCard: 'sauver Carte',
    updateExpiryPara: `* Vous pouvez uniquement mettre à jour la date d'expiration`,
  },

  //creativeset
  //EditCreativeSet
  editCreativeSet: {
    editCreative: `Modifier l'ensemble de créations`,
    easywebinar: 'EasyWebinar',
    softobiz: 'Softobiz Technologies Private Limited',
    setup: 'Installer',
    editCreativePara1:
      `Choisissez un nom qui décrit le thème de votre Creative Set. Sélectionnez la langue des captures d'écran et des aperçus que vous souhaitez inclure à partir de votre page produit App Store.`,
    creativeSetName: `Nom de l'ensemble de créations`,
    localLang: 'Langue de localisation',
    assetSelection: `Sélection d'actifs`,
    assetSelectionPararaph1:
      `Les Creative Sets se composent de captures d'écran et d'aperçus d'applications qui apparaissent sur la page produit de l'App Store.`,
    assetSelectionPararaph2: `Pour chaque taille d'affichage iPhone,`,
    assetSelectionPararaph3:
      'sélectionnez au moins un paysage ou trois éléments de portrait.',
    assetSelectionPararaph4: `Pour chaque taille d'écran iPad,`,
    assetSelectionPararaph5:
      'sélectionnez au moins un paysage ou deux éléments de portrait.',
    viewDevice: `Afficher les tailles d'affichage de l'appareil`,
    title1: 'iPhone/iPod',
    ipad: 'iPad',
    display: 'Afficher',
    default: 'Défaut',
    cancel: 'Annuler',
    save: 'sauver',
  },

  addIntegration: {
    invalid: 'Intégration invalide, veuillez supprimer et ajouter à nouveau.'
  },

  navbar: {
    syncProgress: 'La synchronisation des données est en cours.'
  },

  messages: {
    onHold: 'Campagne en attente',
    reasons: 'Les raisons'
  },

  //integration
  //DeleteIntegration
  deleteIntegration: {
    deleteInt: `Supprimer l'intégration`,
    cardText: `Voulez-vous vraiment supprimer l'intégration?`,
    cancel: 'Annuler',
    delete: 'Supprimer',
  },
  
  //keywords
  //AddKeys
  addKeys: {
    addKeywords: 'Ajouter des mots clés',
    keywordSuggestionsFor: 'Suggestions de mots clés pour',
    on: 'sur',
    addedKeywords: 'Mots-clés ajoutés',
    total: 'Total',
    add: 'Ajouter',
    cancel: 'Annuler',
    apply: 'Appliquer',
    matchExact: 'EXACTE',
    matchBroad: 'VASTE',
    searchHere: 'Cherche ici',
  },

  //AddKeysKai
  addKeysKai: {
    addKeywords: 'Ajouter des mots clés',
    selectTarget: 'Sélectionnez la cible',
    h4Camp: 'Campagnes :',
    selected: 'Choisi',
    change: 'Changement',
    h4Adgroups: `Groupes d'annonces:`,
    maxCPTBid: 'Enchère CPT max :',
    keywordSuggestionsFor: 'Suggestions de mots clés pour',
    on: 'sur',
    recommendedKeywords: 'Mots-clés recommandés',
    addedKeywords: 'Mots-clés ajoutés',
    add: 'Ajouter',
    total: 'Total',
    cancel: 'Annuler',
    apply: 'Appliquer',
    ok: `D'accord`,
  },

  //AddKeywords
  addKeywords: {
    h6AddKeywords: 'Ajouter des mots clés',
    selectTarget: 'Sélectionnez la cible',
    h4Camp: 'Campagnes :',
    selected: 'Choisi',
    change: 'Changement',
    h4Adgroups: `Groupes d'annonces:`,
    maxCPTBid: 'Enchère CPT max :',
    keywordSuggestionsFor: 'Suggestions de mots clés pour',
    on: 'sur',
    recommendedKeywords: 'Mots-clés recommandés',
    addedKeywords: 'Mots-clés ajoutés',
    add: 'Ajouter',
    total: 'Total',
    cancel: 'Annuler',
    apply: 'Appliquer',
    ok: `D'accord`,
  },

  //AddnKeys
  addnKeys: {
    addNegKeywords: 'Ajouter des mots clés à exclure',
    add: 'Ajouter',
    total: 'Total',
    cancel: 'Annuler',
    save: 'sauver',
  },

  //EditBidAmount
  editBidAmount: {
    bidAmount: `Montant de l'enchère`,
  },

  //SelectAdGroup
  selectAdGroup: {
    snackbar: `Veuillez ne conserver qu'un seul groupe d'annonces sélectionné pour continuer.`,
    close: 'Fermer',
    chooseAdGroup: `Choisir un groupe d'annonces`,
    cancel: 'Annuler',
    apply: 'Appliquer',
    campaigns : 'Campagnes',
    expandAll: 'Développer tout',
    collapseAll: 'Tout réduire',
    adGroups : `Groupes d'annonces`,
    adGroupSelected : `Groupe(s) d'annonces sélectionnés`,
    clearAll : 'Tout effacer'
  },

  //negativeKeywords
  //AddNegativeKeywords
  addNegativeKeywords : {
    addNegKeywords : 'Ajouter des mots clés à exclure',
    selectTarget : '1. Sélectionnez la cible',
    addNegKeyword : 'Ajouter un mot clé à exclure',
    paragraph1 : `Des mots-clés négatifs peuvent être ajoutés aux campagnes ou aux groupes d'annonces. Veuillez choisir l'un d'entre eux dans la case ci-dessous.`,
    paragraph2 : 'Quelle campagne souhaitez-vous importer des mots clés ?',
    selectCampaign : 'Sélectionnez la campagne',
    paragraph3 : `Quel groupe d'annonces souhaitez-vous importer des mots clés?`,
    selectAdgroup : `Sélectionnez le groupe d'annonces`,
    cancel: 'Annuler',
    easywebinar : 'EasyWebinar',
    softobiz : 'Softobiz Technologies Private Limited',
    campaigns : 'Campagnes :',
    selected : 'Choisi',
    change: 'Changement',
    paragraph4 : `Les mots clés à exclure du groupe d'annonces s'appliquent uniquement à ce groupe d'annonces. Si vous souhaitez que les mots clés à exclure s'appliquent à tous les groupes d'annonces, définissez-les au niveau de la campagne.`,
    paragraph5 : `Pensez à laisser aux campagnes le temps de s'exécuter avant d'analyser les performances des annonces pour savoir quels mots clés peuvent ne pas fonctionner pour vous. L'ajout d'un trop grand nombre de mots clés à exclure peut limiter l'exposition de votre annonce.`,
    addedKeywords : 'Mots-clés ajoutés',
    add: 'Ajouter',
    total: 'Total',
    save: 'sauver',
  },

  //oAuth
  //SigninSlackBtn
  signinSlackBtn : {
    addSlackChannel : 'Ajouter une chaîne Slack',
    sno: 'S.Non'
  },

  //DecisionAlert
   decisionAlert : {
cancel : 'Annuler',
ok : `D'accord`,
confirm:'Confirmer'
  },

  //HelloWorld
  helloWorld : {
welcome : 'Bienvenue sur Vuetify',
para1 : `Pour obtenir de l'aide et collaborer avec d'autres développeurs Vuetify,`,
para2 : `s'il vous plaît rejoindre notre en ligne`,
discord : 'Communauté Discord',
h2 : "Et après?",
impLinks : 'Liens importants',
ecosystem : 'Écosystème',
  },

  //InfoAlert
  infoAlert : {
ok : `D'accord`,
  },

  successMsg: {
    campaignUpdate: 'Campagne mise à jour.',
    campaignMovedMsg: 'Statut modifié. Campagne déplacée vers un autre onglet',
    campaignMoveFailMsg: "Impossible de modifier l'état de la campagne.",
    adgroupUpdateFailMsg: "Impossible de mettre à jour l'état du groupe d'annonces.",
    addgroupUpdatedMsg: "Statut changé. Groupe d'annonces déplacé vers un autre onglet",
    keywordMovedMsg: 'Statut changé. Mot clé déplacé vers un autre onglet',
    keywordMoveFailMsg: "Impossible de mettre à jour l'état du mot clé.",
    creativeAddedMsg: 'Ensemble créatif ajouté.',
    campaignSavedMsg: 'Campagne enregistrée en tant que brouillon.',
    campaignSaveFailMsg: "Impossible d'enregistrer le brouillon de la campagne pour le moment. Veuillez réessayer!",
    keyAddedMsg: 'Mots-clés ajoutés.',
    signinFailMsg: 'Connexion impossible. Veuillez réessayer !',
    deleteMsg: 'Supprimé avec succès',
    deleteFailMsg: 'Impossible de supprimer. Veuillez réessayer!',
    dataUpdateMsg : 'Données mises à jour',
    dataUpdateFailMsg: 'Erreur lors de la mise à jour des données',
    AutoDeleteMsg: "Règle d'automatisation supprimée avec succès",
    requestFailMsg: "Impossible de traiter votre demande. Merci d'essayer plus tard",
    AutoRuleDisSuccessMsg: "Règle d'automatisation désactivée avec succès",
    AutoRuleEnableMsg: "Règle d'automatisation activée avec succès",
    SCAutoRuleDisSuccessMsg: 'Règle scriptée désactivée avec succès',
    SCAutoRuleEnableMsg: 'Règle scriptée activée avec succès',
    bidChangeSuccessMsg: "Le montant de l'enchère a bien été modifié.",
    wentWrongMsg: "Quelque chose s'est mal passé.",
    integrateSuccessMsg : 'Intégration ajoutée avec succès',
    userCreateMsg: 'Utilisateur créé avec succès',
    emailExistMsg: "l'email existe déjà",
    errorCreateUser: "Erreur lors de la création de l'utilisateur",
    inviteFailMsg: "Erreur lors de l'envoi du lien d'invitation à l'e-mail",
    failTryLaterMsg: "Quelque chose ne va pas. Merci d'essayer plus tard",
    adgroupAddedMsg: "Groupe d'annonces ajouté.",
    adgroupUpdatedMsg: "Groupe d'annonces mis à jour.",
    ruleUpdatedMsg: 'Règle mise à jour avec succès',
    ruleUpdateFailMsg: "Échec de la mise à jour de la règle. Merci d'essayer plus tard",
    rCreatedMsg: 'Règle créée avec succès',
    rCreationFailMsg: "Échec de la création de la règle. Merci d'essayer plus tard",
    assetDataFailMsg: "Impossible de récupérer les détails de l'élément. Veuillez réessayer!",
    deleteCampFailMsg: 'Erreur lors de la suppression de la campagne',
    CampDeletedMsg: 'Campagne supprimée',
    creativeUpdated: 'Ensemble créatif mis à jour.',
    keyAdded: 'Mots-clés ajoutés.',
    negaKeyAddedMsg: "Mots clés à exclure ajoutés au(x) groupe(s) d'annonces.",
    negKeyAddCamp: 'Mots clés à exclure ajoutés à la campagne.',
    errorDeleteKey: 'Erreur lors de la suppression du mot clé',
    keyDeleteS: 'Mot clé supprimé. Mot clé déplacé vers les mots clés supprimés',
    syncStarted: 'La synchronisation a commencé.',
    firstSyncStarted:'Intégration Apple Search Ads terminée !<br> <br> <p style="text-align: justify; color: inherit;">Veuillez patienter quelques minutes pendant que les données ASA sont synchronisées pour la première fois. Vos applications et vos données ASA seront remplies dès que cette première synchronisation sera terminée. Suivez la progression de la synchronisation dans le coin supérieur droit de la page.<p>',
    integrationWarning:'Une intégration Apple Search Ads synchronisée est requise avant l\'intégration avec ce MMP. Veuillez ajouter une intégration Apple Search Ads ci-dessus et attendre la fin de la première synchronisation avant d\'intégrer ce MMP',
    AdDeletedMsg: 'Annonce supprimée',
    adUpdate: 'Annonce mise à jour',
    deleteAdFailMsg: 'Erreur lors de la suppression de l\'annonce',
    deleteStrategyFailMsg: 'Erreur lors de la suppression de la stratégie',
    StrategyDeletedMsg: 'Stratégie supprimée',
  },
  strategy:{
    descriptionValid:"Une description est requise",
    alphaNumeric:"Seuls les caractères alphanumériques sont autorisés",
    textMax:"La description ne doit pas dépasser 250 caractères"
  },
//DeleteStrategy
deleteStrategy: {
  deleteCamp: 'Supprimer la stratégie',
  para: 'Êtes-vous sûr de vouloir supprimer la stratégie?',
  cancel: 'Annuler',
  delete: 'Supprimer',
},
  createCampaignRules: {
    selectCampGrp: 'Veuillez sélectionner le groupe de campagne !',
    selectApp: 'Veuillez sélectionner une application !',
    selectCountry: 'Veuillez sélectionner le pays !',
    campRequired: 'Nom de la campagne requis !',
    campNameSize: 'Le nom de votre campagne doit comporter moins de 200 caractères',
    adGroupSize: 'Le nom de votre groupe d\'annonces doit comporter moins de 200 caractères',
    keywordsize: 'Les mots clés ne peuvent pas dépasser 80 caractères',
    budgetRequired: 'Le montant du budget est requis!',
    validBudget: 'Veuillez saisir un budget valide !',
    groupRequired: "Nom du groupe d'annonces requis !",
    defMaxCptRequired: "L'enchère CPT max par défaut est requise !",
    validCptBid: 'Veuillez saisir une enchère CPT valide !',
    validDailyCap: 'Veuillez saisir une limite quotidienne valide !',
    validCpaGoal: 'Veuillez saisir un objectif CPA valide !',
    selectDevices: 'Veuillez sélectionner des appareils !',
    fNamereq: 'Le prénom est requis',
    fNameGrt: 'Le prénom ne doit pas dépasser 12 caractères',
    textMax: 'Ne doit pas dépasser 50 caractères',
    textMin: 'Ne doit pas être inférieur à 2 caractères',
    fNameValid: 'Veuillez saisir un prénom valide !',
    lNameGrt: 'Le nom de famille ne doit pas dépasser 12 caractères',
    lNameValid: 'Veuillez saisir un nom valide !',
    phoneReq: 'Le téléphone est requis',
    cmpniNameReq: "Le nom de l'entreprise est requis",
    jobTitleReq: 'Le titre du poste est requis',
    emailReq: "L'e-mail est requis",
    emailValid: "L'email doit être valide",
    selectCamp: 'Sélectionnez la campagne',
    cardNoReq: 'Le numéro de carte est requis',
    cardNo16Digit: 'Le numéro de carte doit être numérique et comporter 16 chiffres',
    expiryMonthReq: "Le mois et l'année d'expiration sont requis",
    expiryMMYY: "La date d'expiration doit être MM/AAAA - ex. 07/2023",
    invalidExpiry: "Mois d'expiration invalid",
    invalidMonth: "Année d'expiration invalide",
    cvvReq: 'La carte CVV est requise',
    cvvNum3Digit: 'La carte CVV doit être numérique et comporter 3 chiffres',
    specialCharNotAllowed:'Les caractères spéciaux ne sont pas autorisés',
    onlySinglespaceAllowed:'Un seul espace blanc n’est autorisé que',
    invalidPhoneFormat:'Format de numéro de téléphone non valide'


  },

  settings: {
    profileInfo: 'Informations sur le profil',
    updateProf: 'Mettre à jour le profil',
    payMethod: 'Mode de paiement',
    addCredit: 'Ajouter une carte de crédit',
    cardNo: 'Numéro de carte',
    invoiceHistory: 'Historique des factures',
    invoiceId: 'Invoice_Id',
    dueDate: "Date d'échéance",
    paidAt: 'Payé_à',
    recure: 'Récurrente',
    total: 'Le total',
    status: 'Statut',
    cardId: 'Id_carte',
    type: 'Taper',
    expiryMonth: "Mois d'expiration",
    expiry: "Année d'expiration",
    action: 'Action',
    addCard: 'Ajouter une carte',
    settings: 'Paramètres',
    accountSetting: 'Paramètres du compte',
    payment: 'Paiement',
    primaryPayment: 'Faire de cette carte la source de paiement principale',
    successTitle: "Succès!!!",
    successMsg:"La modification des informations de facturation a été mise à jour avec succès",
    NoSuccessTitle: "Aucun changement détecté !!!",
    NoSuccessMsg:"Aucune modification trouvée avec les informations de facturation existantes."

  },

  dataRequest: {
    yourDataRequest: 'Vos demandes de données',
    remain: 'Remaining',
    complete: 'Complété',
    Fetching: 'Récupération',
    Fetched: 'Récupéré',
    appForKeyword: ' Apps pour le mot-clé - ',
    keywordsForApp: " Mots-clés pour l'application - ",
    appsForCategory: ' meilleures applications pour la catégorie - ',
    competitorsForApp: " concurrents suggérés pour l'application - ",

  },

  users: {
    manageComp: 'Gérer les entreprises',
    comp: 'Entreprises',
    addDisManageComp: 'Ajouter, désactiver, gérer toutes les entreprises',
    createCompAdmin: "Créer un nouvel administrateur d'entreprise",
    enterEmail: "Entrez l'e-mail",
    allCompAdmin: 'Administrateur de toutes les entreprises',
    rowPerPages:"Keywords"
  },

  manageSubscription: {
    trialPeriod: "Vous êtes en période d'essai",
    trialPeriodExpire: "Votre essai expirera le",
    updateCard: "Mettez à jour votre carte",
    subscriptionDetail: "Détails de l'abonnement",
    p1: "Facturation suivante le",
    p2: "Gérez l'adresse de facturation, l'historique des paiements et d'autres détails à partir d'ici",
    planListing: 'Liste des plans',
    furtherPlan: 'Sélectionnez le plan pour continuer',
    generalPlan: 'GÉNÉRALE',
    month: 'mois',
    tryIt: 'Essayez-le aussi longtemps que vous le souhaitez',
    createCampaign: 'Créer une campagne',
    keyAnalysis: 'Analyse des mots clés',
    automationRule: "Règles d'automatisation",
    keyOpt: 'Optimisation des mots clés',
  },

  billingDetails: {
    billInfo: "détails de facturation",
    compName: "Nom de l'entreprise",
    addLine: "Ligne d'adresse",
    city: 'Ville',
    zip: 'Zipper',
    vat: 'T.V.A. Nombre',
    updateBillAdd: "Mettre à jour l'adresse de facturation"
  },

  dateRangePresets: {
    today: 'Aujourd\'hui',
    yesterday: 'Hier',
    weekToDate: 'Semaine à ce jour',
    lastWeek:'La semaine dernière',
    lastSevenDays:'Les 7 derniers jours',
    monthToDate:'Le mois courant',
    lastMonth:'Le mois dernier',
    lastThirtyDays:'Les 30 derniers jours'
  },

  chartHeaders: {
    accounts: 'Comptes',
    apps: 'applications',
    campaign: 'Campagnes',
    adgroup: `Groupes d'annonces`,
    keyword: 'Mots clés',
    creativeset: 'Ensembles créatifs',
    searchterm: 'Termes de recherche',
  },

  goals: {
    selectGoal: "Sélectionnez l'objectif",
    selectEventType: "Sélectionnez le type d'événement",
    selectGoalRule: "Veuillez sélectionner le type d'objectif",
    selectEventRule: "Veuillez sélectionner le type d'événement",
    goalNameRule: "Veuillez saisir le nom de l'objectif",
    manageGoals: 'Gérer les objectifs',
    goalDesc: "Vous pouvez gérer vos objectifs à partir d'ici",
    createGoal: 'Créer un objectif',
    editGoal: "Modifier l'objectif",
    chooseGoal: "Choisissez un type d'objectif",
    chooseEvent: 'Choisissez des événements',
    goalName: "Nom de l'objectif",
    close: 'proche',
    update: 'Mise à jour',
    create: 'Créer',
    name: 'Nom',
    app: 'Application',
    appId: "Identifiant de l'application",
    type: "Taper",
    events: "Événements",
    createdOn: "Créé sur",
    createdBy: "Créé par",
    action: "Action",
    revenue: "Revenu",
    purchase: "Acheter",
    subscribe: "S'abonner",
    userEngagement: "Engagement des utilisateurs",
    levelComplete: "Niveau complet",
    regComp: "Inscription complète",
    tutComp: "Tutoriel terminé",
    startTrial: "Commencer procès",
    appLaunch: "Lancement de l'application",
    userAction: "Action de l'utilisateur",
    addToCart: "Ajouter au chariot",
    addToWishlist: "Ajouter à la liste de souhaits",
    checkoutStart: "Départ de la caisse"
  },
  
  campaignGenerator: {
    decisionMessage: 'Êtes-vous sûr de vouloir supprimer le modèle?',
    addKeyPlaceholder: 'Tapez du texte séparé par des virgules et appuyez sur Entrée ou cliquez sur Ajouter',
    showDraft: 'Afficher le brouillon',
    pageHeading : 'Générateur de campagne',
    createCampaignHeading: 'Configuration de campagne automatisée',
    Segmentation: 'Segmentation',
    Categories: 'Catégories',
    Brand: 'Marque',
    Category: 'Catégorie',
    Discovery: 'Découverte',
    Competitor: 'Concurrent',
    Custom: 'Personnalisé',
    Criteria: 'Critères',
    pleaseSelectCriteria: 'Veuillez sélectionner des critères',
    pleaseEnterValue: 'Veuillez entrer une valeur',
    Value: 'Valeur',
    To: 'À',
    From: 'De',
    FromTo10: 'Veuillez entrer une valeur avec une différence supérieure à 10 par rapport à la première valeur',
    customSlugHeading: 'Slug de campagne personnalisé :',
    customSlugPH: 'Entrez un nom de slug',
    customSlugAdd: 'Ajouter',
    customSlugDup: 'Nom de slug en double',
    SegmentationRules: 'Règles de segmentation',
    SegmentationDesc: "Les campagnes ont des budgets et des groupes d'annonces qui se concentrent sur un thème ou une stratégie commune pour la promotion de votre application.",
    age: 'Âge',
    geo: 'Emplacement',
    selectCustomerType: 'Veuillez sélectionner le type de client',
    selectGender: 'Veuillez sélectionner le sexe',
    selectAge: "Veuillez sélectionner l'âge",
    keyManually: 'Ajouter des mots-clés manuellement',
    Summary: 'Résumé',
    SummaryText: "Vérifier le résumé des campagnes et des groupes d'annonces",
    TotalCampaigns: 'Total des campagnes',
    TotalAdgroups: 'Total des Adgroups',
    CampaignTitle: 'Titre de la campagne',
    keysReady: 'les mots clés sont prêts',
    custom: 'personnalisé',
    keysFetch: 'Récupération des mots-clés pour',
    Campaignsarecreated: 'Les campagnes sont créées',
    Creatingcampaigns: 'Création de campagnes',
    Viewallitems: 'Voir tous les éléments',
    Adgroupsarecreated: "Les groupes d'annonces sont créés",
    Creatingadgroups: "Création de groupes d'annonces",
    Keywordsarecreated: 'Les mots clés sont créés',
    Creatingkeywords: 'Création de mots clés',
    ready: 'Prêt',
    CreatedBy: 'Créé par',
    CreatedOn: 'Créé le',
    Campaigngenerationdetails: 'Détails de la génération de la campagne',
    footerProps: {
      itemsPerPageText: 'Lignes par page',
      itemsPerPageAllText: 'Tous',
      showFirstLastPage: true,
    },
    generateCampaigns: 'Générer des campagnes',
    setDefaultValues: 'Définir les valeurs par défaut',
    setDefaultValuesSub: 'Veuillez fournir les paramètres de budget par défaut pour les emplacements ciblés.',
    nokeyPara: "Aucune saisie de mot-clé n'est nécessaire pour les campagnes de catégorie, de découverte ou de concurrence, car les mots-clés seront automatiquement générés à l'aide de notre technologie d'analyse des mots-clés.",
    waitingToSync: 'En attente de synchronisation',
    campSyncHour:'Les campagnes seront synchronisées avec Apple dans l\'heure.',
    //syncInProgress: 'Synchronisation en cours',
    syncInProgress: 'Synchronisation',
    syncInProgressFewMin: 'La synchronisation est en cours et devrait être terminée dans quelques minutes.',
    syncComplete: 'Synchronisation terminée',
    allSyncCompleted: 'Toutes les campagnes ont été synchronisées avec succès.',
    campNameUniq: 'Veuillez saisir des noms de campagne uniques',
    campNamExists: 'Une campagne portant le même nom existe déjà',
    adNameUniq: "Veuillez saisir des noms de groupe d'annonces uniques",
    saveAsDraft: 'Enregistrer comme brouillon',
    draftHeading: 'Ébauche de modèle de campagne',
    draftSearch: 'Chercher',
    headerOrgName: "Nom de l'organisation",
    headerAppName: "Nom de l'application",
    headerResume: 'CV'
  },

  appSetting: {
    manage: 'Faire en sorte',
    setDefaultValues: 'Définir les valeurs par défaut',
    edit: 'Éditer',
    update:'Mise à jour',
    noRecords: 'Aucun enregistrements correspondants trouvés',
    searchByCountry:'Recherche par pays'
  },

  sr: {
    pageHeading: 'Rapports planifiés',
    createScheduleReport: 'Créer un rapport planifié',
    srDesc: "Les rapports planifiés vous permettent d'envoyer des rapports ou de les signaler à l'heure et au jour que vous avez définis",
    createSR: 'Planifier un nouveau rapport',
    for: 'Signaler pour',
    type: 'Répéter',
    dataDays: 'Données pour la dernière',
    reportNameExists: 'Le nom existe déjà!',
    enterReportName: 'Entrez un nom',
    repeatMode: 'Type de répétition',
    pleaseSelectFor: 'Veuillez sélectionner un niveau de rapport',
    pleaseSelectType: 'Veuillez sélectionner un type de répétition',
    pleaseSelectDay: 'Veuillez sélectionner le(s) jour(s)',
    pleaseSelectTime: 'Veuillez sélectionner une heure',
    noRepeat: 'Ne pas répéter',
    custom: 'Personnalisé',
    daily: 'Quotidien',
    weekly: 'Hebdomadaire',
    monthly: 'Mensuel',
    selectOrg: 'Veuillez sélectionner une organisation',
    selectGoal: 'Veuillez sélectionner un objectif',
    goalsSelected: 'Objectifs sélectionnés',
    orgsSelected: 'Organisations sélectionnées',
    scheduleReport: 'rapport planifié?',
    scheduleReports:'Planifier des rapports',
    reportOf:'Rapport de',
    selectTime:'Sélectionnez l\'heure',
    downloadReport:'Télécharger le rapport',
    addMore:'Ajouter plus',
    scheduleReport1:'Rapport de planification',
    selectSlackChannel:'Sélectionnez le canal Slack',
    emailNoSpaces:'L\'e-mail ne peut pas contenir d\'espaces',
    emailWarning:'Veuillez sélectionner au moins un e-mail ou saisir au moins un identifiant d\'e-mail externe ci-dessous',
    errorDuringSave:'Erreur lors de l\'enregistrement du rapport planifié',
    scheduleSuccess:'Planifié avec succès',
    scheduleSaveWarning:'Voulez-vous vraiment programmer ce rapport ?'
  },
  //Delete Ad
  deleteAds: {
    deleteAd: 'Supprimer l\'annonce',
    para: 'Voulez-vous vraiment supprimer l\'annonce ?',
    cancel: 'Annuler',
    delete: 'Effacer',
    ok:'D\'ACCORD'
  },
  //EditAds
  editAd: {
    editAdWizard: 'Assistant de modification des annonces',
    adName: 'Nom de l\'annonce',
    cancel: 'Annuler',
    save: 'sauvegarder'
  },
  createAdRules: {
    adRequired: 'Nom de l\'annonce requis !',
    adNameSize: 'Le nom de votre annonce doit comporter moins de 200 caractères'
},
  common : {
    save: 'Enregistrer',
    cancel: 'Annuler',
    noDataText: 'Aucune donnée à afficher',
    days: 'Jour(s)',
    time: 'Temps',
    na: 'NA',
    goal: 'Objectif(s)',
    deleteConfirmText: 'Êtes-vous sûr de vouloir supprimer ceci ',
    createConfirmText: 'Procédez à la création de ce nouveau ',
    updateConfirmText: 'Êtes-vous sûr de mettre à jour ceci ',
    dataTableFooterProps: {
      itemsPerPageText: 'Lignes par page',
      itemsPerPageAllText: 'Tous',
      showFirstLastPage: true,
    },
  },
  welcomeOnboard: {
    yes: 'oui',
    no: 'non',
    continue: 'Continuer',
    welcome: 'BIENVENUE CHEZ SEARCH ADS MAVEN',
    description: 'Bienvenue sur Search Ads Maven. Commençons par configurer votre intégration. vous aurez besoin de votre identifiant Apple et si votre ASA utilise l\'authentification à double facteur, vous aurez besoin de l\'appareil associé.',
    getStart: 'Commencer',
    setupLater: 'Accédez à l\'application et configurez plus tard',
    setup: 'Installer',
    createIntegration: 'Créer une intégration',
    createIntDescription: 'Saisissez votre identifiant Apple pour créer la synchronisation bidirectionnelle entre Search Ads Maven et Apple Search Ads. Si vous avez un partenaire MMP, ces intégrations peuvent également être configurées ici. Commençons par importer vos données Apple Search Ads"',
    enterAppleId: 'INTÉGREZ VOTRE IDENTIFIANT APPLE',
    success: 'Succès',
    stepper: {
      title: 'Synchronisation en cours !',
      descreption1: 'Nous importons vos données historiques depuis Apple Search Ads. Selon la quantité de données, cela peut prendre quelques heures. Vous pouvez vérifier l\'indicateur de progression en haut à droite à tout moment pendant cette synchronisation et une fois la synchronisation terminée, visitez la page Intégrations.',   
      descreption2: 'En attendant, familiarisons-nous avec la plateforme.',
    },
    stepperTwo: {      
      title: 'Intégration MMP',
      descreption:'Les MMP sont des outils de mesure tiers qui vous permettent de mesurer et de comparer l\'efficacité de vos dépenses publicitaires sur de nombreux canaux marketing et partenaires différents sur des données d\'engagement d\'application complètes. La plus grande fonctionnalité MMP qui nous aidera ici est l\'analyse et l\'optimisation de nos performances ASA par rapport aux événements qui se produisent après que le client quitte l\'App Store et commence à utiliser l\'application.',
      successfullIntegrations:'Intégrations réussies',
      receivedTypes: 'Types d\'événements reçus'
      },
    startGuide: 'Guide de démarrage',
    syncMsg: {
      your: 'Ton',
      syncWasLess: 'la synchronisation était inférieure à',
      moreTime: 'depuis. Veuillez prévoir plus de temps avant de synchroniser à nouveau.'
    },
    goToApplication: 'Aller à l\'application',
    overview: {
      title: 'Bienvenue dans l\'aperçu ',
      description: 'Vous trouverez ici un résumé des performances de votre campagne actuelle, les mesures associées et plus encore. Vous pouvez personnaliser cette vue en fonction de vos besoins.',
      popupSecond: {
        title: 'Merci d\'avoir exploré Search Ads Maven',
        description: 'Si vous avez des questions ou des problèmes d\'intégration, veuillez contacter votre équipe d\'assistance à tout moment à l\'adresse' 
      }
    },
    adsManager: {
      title: 'Bienvenue dans le gestionnaire de publicités',
      description: 'Ici, vous pouvez afficher des analyses et des rapports granulaires pour vos applications, campagnes, groupes d’annonces, mots clés, etc. Créez également des campagnes manuellement pour éliminer les conjectures du processus.  La section Gestionnaire de publicités vous permet également de créer des campagnes, des groupes d’annonces, des mots clés et des mots clés à faire valoir.'
    },
    keywordAnalysis: {
      title: 'Bienvenue dans l\'analyse des mots clés ',
      description: 'Ici, vous pouvez évaluer l\'efficacité globale des mots-clés de vos campagnes et voir les concurrents suggérés pour votre application, et conquérir les mots-clés de vos concurrents. Vous pouvez également ajouter rapidement des mots-clés supplémentaires à vos campagnes.'
    },
    keywordOptimization:{
      title: 'Bienvenue dans l\'optimisation des mots clés',
      description: 'Ici, vous pouvez optimiser rapidement et efficacement les montants de vos enchères pour les mots clés qui ne se situent pas dans la plage des enchères minimales et maximales suggérées par ASA. Trouvez des métriques d\'objectif basées sur les événements de votre application inclus aux côtés des recommandations ASA.'
    },
    integrations: {
      title: 'Bienvenue dans l\'intégration',
      description: 'C\'est ici que vous pouvez ajouter des intégrations ASA, définir des intégrations MMP tierces et configurer des canaux de communication.'
    },
    automation: {
      title: 'Bienvenue dans l\'automatisation  ',
      description: 'Ici, vous pouvez créer des règles d\'automatisation pour vos organisations, applications, campagnes, groupes d\'annonces et mots clés.'
    },
    goals: {
      title: 'Bienvenue sur Objectifs ',
      description: ' Ici, vous pouvez définir comment les événements MMP tiers sont utilisés pour créer des objectifs de campagne basés sur les revenus, l\'engagement et les activités des utilisateurs.'
    },
    campaignGenerator: {
      title: 'Bienvenue dans le générateur de campagne',
      description: 'Ici, vous pouvez créer rapidement des campagnes manuellement ou utiliser notre générateur de campagne automatisé pour éliminer les conjectures du processus.'
    },
    strategicView: {
      title: 'Bienvenue dans la Vue Stratégique',
      description: 'Dominez la complexité des Apple Search Ads avec les Stratégies Search Ads Maven. Regroupez les campagnes, les groupes d\'annonces et les mots clés en entités rationalisées, alimentées par l\'automatisation et des jalons clairs. Construisez, exécutez et mesurez le succès, le tout à portée de main. Simplifiez, optimisez, gagnez.'
    },
    thirdParty: {
      popupOne: {
        title: 'souhaitez-vous intégrer vos données événementielles tierces? ',
        description: ''
      },
      popupTwo: {
        title: '',
        description: 'Les données d\'événements tiers ne peuvent pas être importées tant que votre synchronisation ASA n\'est pas terminée. Veuillez revenir sur cette page lorsque votre synchronisation a atteint 100 %'
      },
      popupThree: {
        title: '',
        description: 'Vos informations ASA prendront un certain temps à se remplir dans Search Ads Maven, donc en attendant, familiarisons-nous avec la plate-forme'
      },    
    }
  }
};

