import { API_BASE_URL } from '@config';
import { AUTH_ROUTES } from '@shared/config';
import { HttpService } from '@core/services';
import { ISuccess } from '@shared/interfaces';
import { joinUrl } from '@shared/utils';
import { API_ORY_URL } from '@config'

/**
 * @class FlowService
 * @description service class for handling flows
 *  */
export class FlowService {
  private static get url() {
    return joinUrl(API_BASE_URL as string, 'auth/', AUTH_ROUTES.getFlow);
  }

  /**
   * @function
   * @name flow
   * @description service function for getting flow
   * @param {any} flowId
   * @returns {Promise<ISuccess>} response as a promise
   */
  public static async flow(req: any): Promise<ISuccess> {
    //console.log('flow')
    const response = await HttpService.post<ISuccess>(joinUrl(this.url), req, {withCredentials: true});
    return response;
  }

  /**
   * @function
   * @name whoIamI
   * @description service function for checking session
   * @param {any} none
   * @returns {Promise<ISuccess>} response as a promise
   */
   public static async whoIamI(): Promise<any> {
    let result;
    await fetch(API_ORY_URL as string +"sessions/whoami", {credentials: "include"})
    .then(response => {
      //console.log(response);
      result = response.json()
    })
    return result;
  }

  public static async fetchFlow(flow: string, type: string){
    let endpoint = `${API_ORY_URL}self-service/${type}/flows?id=${flow}`;
    if(type === 'logout') 
    endpoint = `${API_ORY_URL}self-service/${type}/browser`;

    const options = {
        headers: {
            'Accept': 'application/json',
        },
        credentials: 'include'
    } as any

    return fetch(endpoint, options).then(response => response.json());
  }
}
