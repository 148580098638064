var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isNavbar
    ? _c(
        "nav",
        { staticClass: "v-navbar" },
        [
          _c("GuidedTourWizard"),
          _vm.errorBanner
            ? _c("v-banner", { staticClass: "top-banner text-center" }, [
                _c(
                  "span",
                  { staticClass: "white--text" },
                  [
                    _vm._v(" " + _vm._s(_vm.$t("navbar.bannerErr1")) + " "),
                    _c(
                      "router-link",
                      {
                        staticStyle: {
                          "text-decoration": "none",
                          color: "inherit",
                        },
                        attrs: { to: "/integrations" },
                      },
                      [
                        _c(
                          "strong",
                          {
                            staticClass: "text-decoration-underline",
                            staticStyle: { cursor: "pointer" },
                            attrs: { to: "/integrations" },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("navbar.bannerErr2")) + " "
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" " + _vm._s(_vm.$t("navbar.bannerErr3")) + " "),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.infoBanner
            ? _c("v-banner", { staticClass: "top-banner text-center" }, [
                _c("span", { staticClass: "white--text" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("navbar.infoBanner1")) +
                      " " +
                      _vm._s(_vm.loggedInAs.CompanyName) +
                      ". "
                  ),
                  _c(
                    "strong",
                    {
                      staticClass: "text-decoration-underline",
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.returnToAdmin()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("navbar.infoBanner2")))]
                  ),
                ]),
              ])
            : _vm._e(),
          !_vm.isInSetup
            ? _c(
                "v-navigation-drawer",
                {
                  staticClass: "no-shadow ma-1 br-8",
                  class: {
                    "nav-with-banner": _vm.errorBanner || _vm.infoBanner,
                  },
                  attrs: {
                    color: "primarybase",
                    app: "",
                    permanent: "",
                    width: 94,
                    "mini-variant-width": "100",
                    "hide-overlay": "",
                    height: "75",
                    screen: "$vuetify.breakpoint.smAndDown",
                  },
                  model: {
                    value: _vm.drawer,
                    callback: function ($$v) {
                      _vm.drawer = $$v
                    },
                    expression: "drawer",
                  },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "  bg-transparent top-logo cursor-pointer ",
                      class: { "onscroll-shadow": _vm.applyBoxShadowonlogo },
                      attrs: { elevation: "0" },
                    },
                    [
                      _c("v-img", {
                        attrs: {
                          src: require("../assets/images/updated-logo.svg"),
                          width: "35px",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-app-bar",
            {
              staticClass: " white lighten-4 text-center v-header",
              class: {
                "v-toolbar-with-banner lighten-4 text-center v-header":
                  _vm.errorBanner || _vm.infoBanner,
              },
              attrs: { app: "", flat: "", height: "80px" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "primarybase--text brand-name  ml-2 mt-2 mb-2 sm-hide cursor-pointer regular-font ",
                },
                [
                  _vm.isInSetup
                    ? _c("v-img", {
                        attrs: {
                          width: "80%",
                          src: require("../assets/images/search-ads-maven.svg"),
                          alt: "logo",
                        },
                      })
                    : _c("v-img", {
                        attrs: {
                          src: require("../assets/images/fullogo.svg"),
                          alt: "logo",
                        },
                      }),
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-menu",
                {
                  attrs: {
                    bottom: "",
                    left: "",
                    "offset-y": "",
                    "close-on-content-click": false,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "no-ripple ",
                                    attrs: { icon: "", width: "30px" },
                                  },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { staticClass: "grey--text" }, [
                                  _vm._v("mdi-chevron-down"),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-avatar",
                              {
                                staticClass: "ml-2 cursor-pointer",
                                attrs: { color: " greyxlight", size: "50" },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "white--text font-18 regular-font",
                                  },
                                  [_vm._v(_vm._s(_vm.name))]
                                ),
                              ]
                            ),
                            _c(
                              "v-menu",
                              {
                                attrs: { bottom: "", left: "", "offset-y": "" },
                                scopedSlots: _vm._u(
                                  [
                                    !_vm.sidebar
                                      ? {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "no-ripple select-language change-language mx-4",
                                                      attrs: {
                                                        icon: "",
                                                        width: "70",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c("country-flag", {
                                                    attrs: {
                                                      country:
                                                        _vm.selectedLocale.flag,
                                                    },
                                                  }),
                                                  _c(
                                                    "h6",
                                                    {
                                                      staticClass:
                                                        "black--text ",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.selectedLocale
                                                            .text
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        }
                                      : null,
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _c(
                                  "v-list",
                                  { staticClass: "v-profile-menu style-2" },
                                  _vm._l(_vm.locales, function (locale, i) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: i,
                                        attrs: { link: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.switchLocale(locale)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-list-item-icon",
                                          { staticClass: "mx-2" },
                                          [
                                            _c("country-flag", {
                                              attrs: { country: locale.flag },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item-content",
                                          { staticClass: "mt-0" },
                                          [
                                            _c("v-list-item-title", {}, [
                                              _vm._v(
                                                " " + _vm._s(locale.text) + " "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2959858579
                  ),
                  model: {
                    value: _vm.menu,
                    callback: function ($$v) {
                      _vm.menu = $$v
                    },
                    expression: "menu",
                  },
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "v-profile-menu" },
                    _vm._l(_vm.orgitems, function (item, i) {
                      return _c(
                        "v-list-group",
                        {
                          key: i,
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", {
                                          domProps: {
                                            textContent: _vm._s(item.title),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: item.active,
                            callback: function ($$v) {
                              _vm.$set(item, "active", $$v)
                            },
                            expression: "item.active",
                          },
                        },
                        _vm._l(item.items, function (child) {
                          return _c(
                            "v-list-item",
                            {
                              key: child.TeamId,
                              attrs: { disabled: child.IsDisabled },
                              on: {
                                click: function ($event) {
                                  return _vm.switchOrg(child)
                                },
                              },
                            },
                            [
                              child.TeamId == _vm.TeamID
                                ? _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        staticClass: "secondary--text",
                                        domProps: {
                                          textContent: _vm._s(child.Company),
                                        },
                                      }),
                                      _c("v-list-item-subtitle", {
                                        staticClass: "secondary--text",
                                        staticStyle: { "font-size": "x-small" },
                                        domProps: {
                                          textContent: _vm._s(child.Role),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              child.TeamId != _vm.TeamID
                                ? _c(
                                    "v-list-item-content",
                                    [
                                      _c("v-list-item-title", {
                                        domProps: {
                                          textContent: _vm._s(child.Company),
                                        },
                                      }),
                                      _c("v-list-item-subtitle", {
                                        staticStyle: { "font-size": "x-small" },
                                        domProps: {
                                          textContent: _vm._s(child.Role),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        1
                      )
                    }),
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-list",
                    { staticClass: "v-profile-menu" },
                    _vm._l(_vm.menuitems, function (item, i) {
                      return _c(
                        "v-list-item",
                        {
                          key: i,
                          attrs: { link: "" },
                          on: {
                            click: function ($event) {
                              return _vm.menuClick(item.path)
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", { attrs: { medium: "" } }, [
                                _vm._v(_vm._s(item.icon)),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {}, [
                                _vm._v(_vm._s(item.title)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }