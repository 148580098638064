//Korean

export const ko = {
  pages: {
    overview: '개요',
    adsManager: '광고 관리자',
    kai: '키워드 분석',
    keyOpt: '키워드 최적화',
    teamMgmt: '팀',
    integrations: '통합',
    automationRule: '오토메이션',
    campaignGenerator: '캠페인 생성기',
    goals: '목표',
    appSettings: '앱 설정',
    kar: '키워드 분석 요청',
    manualCampaign:'수동 캠페인 추가',
    automatedCampaign:'자동 캠페인 추가',
  },
  menu: {
    settings: '설정',
    signOut: '로그아웃',
    manageSubscription: "구독 관리",
    orgs: '조직'
  },
  buttons: {
    syncData: '최신 데이터 동기화',
    createCampaign: '캠페인 만들기',
    filter: '필터',
    reset: '초기화',
    apply: '대다'
  },
  labels: {
    charts: '차트',
    selectDs: '데이터 세트 선택',
    totalSummary: '총 요약',
    spend: '보내다',
    search: '찾다',
    searchByKeySearchMsg: '텍스트를 입력하고 Enter 키를 누릅니다.',
    competeSearchMsg : '앱 선택 또는 검색',
    selectCategoryMsg: '카테고리 선택',
    selectCategoryType: '유형 선택',
    matchType: '일치 유형',
    filterItem: '아래 항목을 필터링하려면 여기에 입력',
    cusType: '고객 유형',
    createRule: '규칙 생성',
    fromSelected: '선택 항목에서',
    mmYY: 'MM/YYYY'    ,
    enterKey: '키워드 입력'   ,
    selectLevel: '레벨 선택',
    org: '조직',
    app: '애플리케이션',
    camp: '캠페인',
    adGroup: '광고그룹',
    key: '키워드',
    searchTerm: '검색어)',
    yourApps: '내 앱',
    suggestedApps: '추천 앱',
  },
  dropdowns: {
    noData: '자료 없음',
    daily: '매일',
    weekly: '주간',
    monthly: '월간 간행물'
  },
  tabs: {
    accounts: '계정',
    apps: '앱',
    campaigns: '캠페인',
    adgroups: '광고그룹',
    keywords: '키워드',
    creativeSets: '크리에이티브 세트',
    searchTerms: '검색어',
    negKeywords: '제외 키워드',
    ad: '기원 후'
  },
  summary: {
    taps: '탭',
    impressions: '노출수',
    ttr: 'TTR',
    newDownloads: '새로운 다운로드',
    reDownloads: '재다운로드',
    installs: '설치',
  },
  accountHeaders: {
    account: '계정',
    spend: '보내다',
    averageCpa: '평균 CPA',
    averageCpt: '평균 CPT',
    impressions: '노출수',
    taps: '탭',
    installs: '설치',
    ttr: 'TTR',
    conversionRate: '전환율',
    newDownloads: '새로운 다운로드',
    reDownloads: '재다운로드'
  },
  appsHeaders: {
    appName: '신청',
    spend: '보내다',
    averageCpa: '평균 CPA',
    averageCpt: '평균 CPT',
    impressions: '노출수',
    taps: '탭',
    installs: '설치',
    ttr: 'TTR',
    conversionRate: '전환율',
    attrInstall: '속성 설치',
    attrInstallRate: '속성 설치 비율',
    cpi: 'CPI',
    goals: '목표',
    costPerGoal: '목표당 비용',
    goalRate: '목표율',
    revenuePerGoal: '목표당 수익',
    goalsRevenue: '목표 수익',
    goalsRoas: '목표 ROAS',
    newDownloads: '새로운 다운로드',
    reDownloads: '재다운로드',
    account: '계정',
    categoryRank: '카테고리 순위',
    appId: '앱 ID',
    totalCamp: '총 캠페인',
    goalAMPU: '목표 AMPU'
  },
  campaignHeaders: {
    campaign: '운동',
    campaignStatus: '캠페인 상태',
    spend: '보내다',
    averageCpa: '평균 CPA',
    averageCpt: '평균 CPT',
    impressions: '노출수',
    taps: '탭',
    installs: '설치',
    newDownloads: '새로운 다운로드',
    reDownloads: '재다운로드',
    ttr: 'TTR',
    conversionRate: '전환율',
    attrInstallRate: '속성 설치 비율',
    cpi: 'CPI',
    goals: '목표',
    costPerGoal: '목표당 비용',
    goalRate: '목표율',
    revenuePerGoal: '목표당 수익',
    goalsRoas: '목표 ROAS',
    storefronts: '상점',
    account: '계정',
    displayStatus: '디스플레이 상태',
    appName: '앱 이름',
    appId: '앱 ID',
    campaignId: '캠페인 ID',
    delete: '지우다',
    totalBudget: '총예산',
    dailyCap: '일일 한도',
    supplySource:'유형'
  },
  campaignDraftHeaders: {
    campaignName: '캠페인 이름',
    countries: '국가',
    budgetAmount: '예산 금액',
    resume: '이력서',
  },
  adGroupHeaders: {
    adgroup: '광고 그룹',
    adgroupStatus: '광고 그룹 상태',
    spend: '보내다',
    averageCpa: '평균 CPA',
    averageCpt: '평균 CPT',
    impressions: '노출수',
    taps: '탭',
    installs: '설치',
    newDownloads: '새로운 다운로드',
    reDownloads: '재다운로드',
    ttr: 'TTR',
    conversionRate: '전환율',
    attrInstallRate: '속성 설치 비율',
    cpi: 'CPI',
    goals: '목표',
    costPerGoal: '목표당 비용',
    goalRate: '목표율',
    revenuePerGoal: '목표당 수익',
    goalsRoas: '목표 ROAS',
    storefronts: '상점',
    campaignStatus: '캠페인 상태',
    displayStatus: '디스플레이 상태',
    account: '계정',
    appId: '앱 ID',
    campaignId: '캠페인 ID',
    adgroupId: '광고 그룹 ID'
  },
  keyHeaders: {
    keyword: '예어',
    keywordStatus: '키워드 상태',
    bidAmount: '입찰 금액',
    spend: '보내다',
    averageCpa: '평균 CPA',
    averageCpt: '평균 CPT',
    impressions: '노출수',
    taps: '탭',
    installs: '설치',
    newDownloads: '새로운 다운로드',
    reDownloads: '재다운로드',
    ttr: 'TTR',
    conversionRate: '전환율',
    attrInstallRate: '속성 설치 비율',
    cpi: 'CPI',
    goals: '목표',
    costPerGoal: '목표당 비용',
    goalRate: '목표율',
    revenuePerGoal: '목표당 수익',
    goalsRevenue: '목표 수익',
    storefronts: '상점',
    campaignStatus: '캠페인 상태',
    adgroupStatus: '광고 그룹 상태',
    organicRank: '유기적 순위',
    appName: '앱 이름',
    difficultyScore: '난이도 점수',
    popularity: '인기',
    keywordId: '키워드 ID',
    appId: '앱 ID',
    campaignId: '캠페인 ID',
    adgroupId: '광고 그룹 ID',
    suggestedMinBid: 'ASA 권장 최소 입찰가',
    suggestedMaxBid: 'ASA 권장 최대 입찰가',
    bidStrength: '입찰 강도',
    latOff: 'LAT 끄기 설치',
    latOn: '설치 시 LAT',
    matchType: '일치 유형',
  },
  adsHeaders: {
    ads: '광고',
    creativeType: '크리에이티브 유형',
    creativeSetStatus: '상태',
    spend: '보내다',
    averageCpa: '평균 CPA',
    averageCpt: '평균 CPT',
    impressions: '노출수',
    taps: '탭',
    installs: '설치',
    newDownloads: '새로운 다운로드',
    reDownloads: '재다운로드',
    ttr: 'TTR',
    conversionRate: '전환율',
    attrInstallRate: '속성 설치 비율',
    cpi: 'CPI',
    goals: '목표',
    costPerGoal: '목표당 비용',
    goalRate: '목표율',
    revenuePerGoal: '목표당 수익',
    goalsRevenue: '목표 수익',
    storefronts: '상점',
    campaignStatus: '캠페인 상태',
    adgroupStatus: '광고 그룹 상태',
    account: '계정',
    appId: '앱 ID',
    campaignId: '캠페인 ID',
    adgroupId: '광고 그룹 ID',
    action:'동작',
    delete: '삭제'
  },
  creativeHeaders: {
    creativeSet: '크리에이티브 세트',
    creativeSetStatus: '상태',
    spend: '보내다',
    averageCpa: '평균 CPA',
    averageCpt: '평균 CPT',
    impressions: '노출수',
    taps: '탭',
    installs: '설치',
    newDownloads: '새로운 다운로드',
    reDownloads: '재다운로드',
    ttr: 'TTR',
    conversionRate: '전환율',
    attrInstallRate: '속성 설치 비율',
    cpi: 'CPI',
    goals: '목표',
    costPerGoal: '목표당 비용',
    goalRate: '목표율',
    revenuePerGoal: '목표당 수익',
    goalsRevenue: '목표 수익',
    storefronts: '상점',
    campaignStatus: '캠페인 상태',
    adgroupStatus: '광고 그룹 상태',
    account: '계정',
    appId: '앱 ID',
    campaignId: '캠페인 ID',
    adgroupId: '광고 그룹 ID'
  },
  searchTermsHeader: {
    searchTerm: '검색어 텍스트',
    spend: '보내다',
    averageCpa: '평균 CPA',
    averageCpt: '평균 CPT',
    impressions: '노출수',
    taps: '탭',
    installs: '설치',
    ttr: 'TTR',
    conversionRate: '전환율',
    newDownloads: '새로운 다운로드',
    reDownloads: '재다운로드',
    storefronts: '상점',
    campaignStatus: '캠페인 상태',
    adgroupStatus: '광고 그룹 상태',
    keyword: '예어',
    keywordId: '키워드 ID',
    appId: '앱 ID',
    campaignId: '캠페인 ID',
    adgroupId: '광고 그룹 ID'
  },
  negativeHeaders: {
    keyword: '예어',
    matchType: '일치 유형',
    status: '상태',
    adgroupId: '광고 그룹 ID',
    campaignId: '캠페인 ID',
    deleted: '삭제됨',
    keywordId: '키워드 ID',
    modificationTime: '수정 시간'
  },
  filter: {
    account: '계정 선택',
    app: '앱 선택',
    goal: '목표 선택',
    campaign: '캠페인 선택',
    adgroup: '광고그룹 선택',
    ad: "광고 선택"
  },
  subTabs: {
    all:'모두',
    live: '라이브',
    paused: '일시중지됨',
    deleted: '삭제됨',
    draft: '초안',
    campaigns: '캠페인',
    adgroups: '광고그룹',
    keywords: '키워드',
    creativeSet: '크리에이티브 세트',
    negKey: '제외 키워드'
  },
  popups: {
    add: '더하다',
    integration: '완성',
    adgroup: '광고 그룹',
    keywords: '키워드',
    creativeSet: '크리에이티브 세트',
    negKey: '제외 키워드',
    campaigns:'캠페인'
  },

  //Team Management Page
  teamMgmt: {
    manageUsers: '회원 관리',
    invite: '팀원 초대 및 관리',
    add: '회원 추가',
    first: '이름',
    last: '성',
    phone: '전화',
    email: '이메일',
    job: '직위',
    allMembers: '전원',
    processing: '처리 중...',
    ok: '확인',
    inviteStatus: '초대 상태',
    isDisabled: '비활성화됨',
    company: '회사 이름'
  },

  //Add Integration Page
  integration: {
    assPart: '관련 파트너',
    apple: '애플 검색 광고',
    slack: '느슨하게',
    itunes: '아이튠즈',
    tenjin: '텐진',
    kochava: '코 차바',
    adjust: '맞추다',
    appsFlyer: 'AppsFlyer',
    singular: '단수형',
    communication:'연락',
    mmp:'모바일 측정 파트너',
    completeInt:'완료된 통합',
    receivedEventType:'수신된 이벤트 유형',
    waitForEvents:'이벤트 대기 중',
    integrateWith:'통합',
    intStep:'통합 단계',
    integrate:'통합',
    followIntSteps:'다음 통합 단계를 따르십시오.',
    //kochava steps
    kochavaStep1:'1. https://go.kochava.com/apps에서 Kochava 앱에 액세스합니다.',
    kochavaStep2:'2. 앱의 컨텍스트 메뉴에서 파트너 구성을 선택합니다.',
    kochavaStep3:'3. 파트너 구성 페이지에서 구성 추가를 클릭합니다.',
    kochavaStep4:'4. 미디어 파트너 메뉴에서 Search Ads Maven - iOS를 선택하고 이동을 클릭합니다.',
    kochavaStep5:'5. Search Ads Maven에 신디케이트하려는 각 이벤트의 컨텍스트 메뉴에서 만들기를 선택합니다.',
    kochavaStep6:'6. 각 포스트백 생성에 대해 여기에 제공된 API KEY 및 App Store ID를 사용합니다.',
    kochavaStep7:'7. Search Ads Maven에서 이벤트를 인식할 때까지 최소 24시간을 허용합니다. Apple Search Ads 캠페인에 기인한 이벤트만 인식됩니다.',
    kochavaStep8:'8. Search Ads Maven에서 인식하는 각 이벤트에 대한 목표를 만듭니다. 목표가 생성되면 대시보드에서 개수 및 수익과 같은 MMP 이벤트 데이터를 사용할 수 있습니다.',

    //branchSteps
    branchStep:'1. Branch 대시보드 내에서 다음 API 키와 App Store ID를 사용하십시오.',

    //customSteps
    customStep:'1. 포스트백 내에서 다음 API 키와 App Store ID를 사용하세요.',

    apiKey:'API 키',
    appStoreId:'앱 스토어 ID',
    distinctEventType:'ASA에 기인한 고유한 이벤트 유형은 이 앱에 대해 MMP에 의해 전달되었습니다. 이러한 이벤트 유형은 목표 생성에 사용할 수 있습니다.',
    noDistinctEventType:'Apple Search Ads로 인한 이벤트는 아직 수신되지 않았습니다. 24시간 후에도 이벤트가 수신되지 않은 경우 이러한 통합 단계를 다시 확인하고 MMP에서 전달할 하나 이상의 이벤트를 트리거하는 활성 Apple Search Ads 캠페인이 실행 중인지 확인하십시오.',
    dEventType:'고유한 이벤트 유형',
    noEventFound:'이벤트를 찾을 수 없습니다.',
    close:'닫다',
    note:'참고: 이미 다른 MMP와 통합된 앱은 이 목록에 나타나지 않습니다.',
    intDeletionInProgress:"현재 통합 삭제가 진행 중이며, 완료되면 알려드리겠습니다."
  },

  //Keyword Auction Insights Page
  kai: {
    addedKeywords: '추가 된 키워드',
    total: '합계',
    cancel: '취소',
    runAds: '광고 실행',
    ok: '확인',
    totalPaidKey: '총 유료 키워드',
    popularity: '인기',
    numOfApps: '앱 수',
    chance: '기회',
    appsRunAds: '광고를 실행하는 앱',
    maxPop: '최대 인기도',
    keyword: '예어',
    clear: '맑은',
    clrAllFilter: '모든 필터 지우기',
    keywords: '키워드',
    organicRank: '유기적 순위',
    totalApps: '총 앱',
    app: '앱',
    catName: '카테고리 이름',
    searchByApp: '앱으로 검색',
    searchByKey: '키워드 검색',
    addAllKey: '모든 키워드 추가',
    country: '국가',
    lang: '언어',
    searchForCompetes: '추천 경쟁사',
    searchAdsRecommends: '검색 광고 추천',
    categoryTop: '카테고리 인기 앱',
    compRating: '평가',
    compVersion: '버전',
    compPrice: '가격',
    compGenres: '장르',
    monthlyDownloads: '월간 다운로드',
    showDataText: '표시할 데이터가 없습니다.',
    free: '무료',
    paid: '유급의',
    quickAddCriteria: '빠른 추가 기준',
    tooltip: {
      keyText: "키워드 제목",
      organicRank: "오가닉 랭크는 유료 광고의 영향을 받지 않는 검색 엔진의 검색 결과를 말합니다.",
      popularity: "키워드의 키워드 인기도 점수입니다. 검색 점수가 높다는 것은 해당 키워드에 대한 검색량이 많다는 것을 의미합니다.",
      totalApps: "이 키워드를 사용하는 총 앱 수",
      appDetails: "애플리케이션 이름",
      organicRankApp: "오가닉 랭크는 유료 광고의 영향을 받지 않는 검색 엔진의 검색 결과를 말합니다.",
      category: "앱이 속한 카테고리앱이 속한 카테고리",
      rating: "앱 평가",
      version: "앱 버전",
      price: "앱 가격",
      genres: "앱의 장르",
      chance: "기회 점수는 앱의 특정 카테고리에 있는 특정 키워드에 대해 상위 10위 안에 들 가능성에 대한 추정치를 기반으로 한 백분율 점수입니다.",
      appsRunning: "키워드에 대해 지불하는 총 앱 수"
    }
  },

  //Overview Page
  overview: {
    selectGoalFirstSelectApp:'목표를 선택하려면 먼저 왼쪽 드롭다운 메뉴에서 앱을 선택하십시오。',
    count:'목표 이벤트 수',
    exportCSV:'CSV 내보내기',
    period: '기간',
    topKeywords: '상위 10개 키워드 기준',
    key: '예어',
    spend: '보내다',
    impressions: '노출수',
    taps: '탭',
    goToKey: '모든 키워드로 이동',
    topAdgroup: '상위 10 개 광고 그룹 기준',
    adgroup: '광고그룹',
    goToAdgroup: '모든 광고그룹으로 이동',
    gender: '성별',
    device: '장치',
    searchApp: '앱 검색',
    lastDay: '마지막 날',
    last7Days: '지난 7 일',
    last30Days: '지난 30일',
    ttr: '탭스루율(TTR)',
    installs: '설치',
    avgCPA: '평균 CPA',
    avgCPT: '평균 CPT',
    conversionRate: '전환율 (CR)',
    latOnInstalls: '설치 시 LAT',
    latOffInstalls: 'LAT 끄기 설치',
    reDownloads: '재다운로드',
    newDownloads: '새로운 다운로드',
    all: '모두',
    tooltip: '선택한 날짜 범위를 기준으로 이전 기간의 백분율로 표시되는 시간 경과에 따른 증가 또는 감소입니다.',
    selectPer: '기간 선택',
    prevPeriod: '이전 기간',
    currPeriod: '현재 기간',

    GOLS: '목표',
    CPRG: '목표당 비용',
    RPRG: '목표당 수익',
    GREV: '목표 수익',
    GRAS: '목표 ROAS',
    GETC: '목표 이벤트 수',
  },

  checklist: {
    title: '체크리스트',
    integrations: '통합',
    searchads: '애플 검색 광고',
    slack: 'Slack 통합 추가',
    kai: '키워드 분석 도구에 익숙해지기',
    competitorMsg: '경쟁업체의 실적 확인',
    searchKeywords: '앱 키워드 보기',
    seeApps: '키워드에 대한 입찰 앱 참조',
    topCharts: '카테고리 상위 차트 보기',
    campaign: '운동',
    initCampaign: '캠페인 시작',
    createAdgroup: '광고그룹 생성',
    addKeywords: '키워드 추가',
    addCreativeSet: '크리에이티브 세트 추가',
    goLive: 'ライブ配信',
    reviewMetrics: 'キャンペーンの成功を確認する',
    automate: 'キャンペーンを自動化する',
    mmpIntegration: 'MMP 통합',
    createMmpGoals: 'MMP 목표 만들기',
    campGen:'캠페인 생성기',
    scheduledReports:'예약된 보고서'
  },

  //Keyword Optimization Page
 keyOpt: {
  keyBid: '키워드 최적화',
  keyToolTip: 'ASA 권장 최소 입찰가 및 ASA 권장 최대 입찰가 범위를 벗어나는 키워드의 입찰가를 최적화할 수 있습니다.',
  optAll: '모두 최적화',
  opt: '최적화',
  optBidAmt: '입찰 금액 최적화',
  key: '예어',
  optBidForAll: '모든 키워드의 입찰가 최적화',
  campaign: '운동',
  adgroup: '광고그룹',
  bidAmt: '입찰 금액',
  asaMinBid: 'ASA 권장 최소 입찰가',
  asaMaxBid: 'ASA 권장 최대 입찰가',
  action: '동작',
  noData: "현재 Apple은 제안 사항이 없습니다."
  },

  //ARRules Page
  aRules: {
  createAR: '자동화 규칙 생성',
  view: '전망',
  edit: '편집하다',
  delete: '지우다',
  disable: '비활성화',
  enable: '활성화',
  name: '이름',
  createdAt: '만든 시간',
  status: '상태',
  completed: '완료 시간',
  actionFreq: '행동 빈도',
  rules: '규칙',
  logs: '로그',
  createdFor: '에 대해 생성됨',
  chckFreq: '주파수 확인',
  actions: '행위',
  enableDisable: '켜기 끄기',
  sno: 'S.No'
  },

  //Create Campaign
  //CampaignView
  createCampaign: {
  createCamp: '캠페인 만들기',
  h2: '캠페인 생성을 시작할 앱 선택',
  para: '캠페인에는 앱 홍보를 위한 공통 주제 또는 전략에 중점을 둔 예산과 광고그룹이 있습니다.',
  select: '광고가 게재될 위치 선택',
  searchResults: '검색 결과',
  srData: '캠페인에는 앱 홍보를 위한 공통 주제 또는 전략에 중점을 둔 예산과 광고그룹이 있습니다.',
  searchTab: '검색 탭',
  stData: '사용자가 검색 탭을 방문할 때 추천 앱 목록 상단에서 앱을 홍보하세요.',
  todayTab: '오늘 탭',
  todayData:'사용자가 앱을 발견하기 위해 App Store를 처음 방문했을 때 도달하십시오. 귀하의 광고는 첫 페이지에 눈에 띄게 나타납니다.',

  productPageTab: '제품 페이지',
  productPageData: '사용자가 앱을 탐색할 때 도달합니다. 광고가 상단에 표시됩니다. 관련 제품 페이지의 맨 아래로 스크롤한 사용자에게 목록을 표시할 수도 있습니다.',
  cancel: '취소',
  continue: '계속하다',

  //Search Results and Search Tab Campaign
  countAndReg: '국가 및 지역',
  adgroupSettings: '광고그룹 설정',
  searchMatch: '검색 일치',
  key: '예어',
  audience: '청중',
  crSets: '크리에이티브 세트',
  chooseCampGroup: '캠페인 그룹 선택',
  chooseApp: '앱 선택',
  next: '다음',
  countPara: '목록에서 선택한 적격 국가 및 지역의 App Store에서 앱이 홍보됩니다.',
  chooseCount: '하나 이상의 국가 또는 지역을 선택하십시오.',
  campName: '캠페인 이름',
  budget: '예산',
  dailyCap: '일일 한도(선택 사항)',
  adgroupPara: '광고 그룹에는 광고를 게재할 대상을 결정하는 가격 책정 목표 및 설정이 포함되어 있습니다.',
  adgroupName: '광고그룹 이름',
  devices: '장치',
  startTime: '시작 시간',
  adScheduling: '광고 예약',
  endTime: '종료 시간',
  dayParting: '시간대(선택 사항)',
  ok: '확인',
  defaultMax: '기본 최대 CPT 입찰가',
  cpaGoal: 'CPA 목표(선택사항)',
  saveDraft: '임시 보관함에 저장',
  appleSearchMatch: '애플 검색 매치',
  searchMatchPara: 'Apple Search Match는 광고를 시작하고 실행하는 가장 쉬운 방법입니다. Search Ads는 귀하와 유사한 앱을 검색하는 사용자에게 귀하의 광고를 자동으로 일치시킵니다.',
  searchMatchPara1: '내 광고를 관련 검색어에 자동으로 일치시킵니다.',
  keywords: '키워드',
  keyPara: '키워드는 고객이 귀하의 앱과 유사한 앱을 검색할 때 사용할 수 있는 관련 단어 또는 용어입니다. 키워드를 사용하면 검색자가 앱을 빠르게 찾을 수 있어 앱 설치를 유도할 수 있습니다.',
  upload: '업로드',
  suppFormat: '지원 형식: Excel, CSV',
  keyManually: '또는 수동으로 키워드 추가',
  targKey: '타겟팅 키워드',
  clear: '맑은',
  adgroupNegKey: '광고그룹 제외어 키워드',
  optional: '선택 과목',
  negKey: '제외 키워드',
  audPara: ' 고급 기능(선택 사항)을 사용하여 광고그룹의 잠재고객을 세분화합니다.',
  custType: '고객 유형',
  demo: '인구통계',
  gender: '성별',
  location: '위치 (찾다)',
  crSetPara: '광고는 App Store 제품 페이지에서 제공되는 메타데이터, 스크린샷 및 앱 미리보기를 사용하여 자동으로 생성됩니다. 이는 App Store의 자연 검색 결과에도 나타납니다. 여기에 표시된 광고는 기본 광고 예입니다.',
  crOptional: '크리에이티브 세트(선택 사항)',
  noCrSet: '앱이 최소 Creative Set의 요구 사항을 충족하지 않습니다.',
  campCreated: '캠페인 생성됨',
  campCreatedPara: '이제 Search Ads 대시보드에서 캠페인을 볼 수 있습니다.',
  close: '닫기',
  matchType: '일치 유형',
  amount: '양',
  currency: '통화',
  allUsers: '모든 사용자들',
  newUsers: '신규 사용자',
  appUsers: '내 다른 앱의 사용자',
  existingUsers: '기존 사용자',
  all: '모두',
  male: '남성',
  female: '여자',
  selectApp: '검색 앱',
  chooseCamp: '캠페인 그룹',
  countries: '국가',
  ageRange: '연령대',
  selectDev: '장치 선택',
  adsOpt:'광고(선택사항)',
  default: '기본',
  defaultAdPara: '기본 앱 스토어 제품 페이지의 자산을 사용합니다',
  custom: '관습',
  customPara: '기본 App Store Connect의 자산을 사용하고 사용자가 광고를 탭하면 해당 페이지로 이동합니다.',
  pagesInfo: '사용자 정의 제품 페이지를 사용할 수 있습니다.',
  createAdPara: '광고 만들기 버튼을 클릭하여 맞춤 제품 페이지 옵션에서 선택하고 광고 미리보기를 확인하세요.',
  createAdBtn: '광고 만들기',
  createAds: '광고 만들기',
  selectCustomPara: '광고를 만드는 데 해당 자산을 사용하려면 맞춤 제품 페이지를 선택하세요. 사용자가 검색 결과에서 광고를 탭하면 선택한 페이지로 이동합니다.',
  adName: '광고 이름',
  search: '검색',
  available: '사용 가능',
  sortBy: '정렬 기준',
  save: '구하다',
  customAds: '맞춤 광고',
  edit: '편집하다',
  previous: '이전의'
  },

   //Change Password
   changePass: {
    backSett: '설정으로 돌아가기',
    chngPass: '비밀번호 설정',
    newPass: '새 비밀번호',
    confirmPass: '비밀번호 확인',
    submit: '제출',
    h4: 'Kochava 마케터 운영 체제™',
    para:
      'm/OS(Marketers Operating System)는 광고주와 퍼블리셔를 위한 옴니채널 마케팅 솔루션을 하나의 운영 플랫폼에 완벽하게 통합합니다.',
    ok: '확인',
  },

  //Forgot Password
  forgotPass: {
    forPass: '비밀번호를 잊으 셨나요',
    typeEmail: '이메일 주소 입력',
    emailAdd: '이메일 주소',
    submit: '제출',
    chckEmail: '이메일을 확인하세요',
    para: '새 비밀번호를 설정하려면 이메일을 확인하세요',
    close: '닫기',
    alreadyAcc: '이미 계정이 있습니까?',
    signIn: '로그인',
  },

  //Onboard
  onboard: {
    welcome: '코차바에 오신 SearchAds Maven',
    personal: '가입하기',
    start: '스타트',
    integration: '완성',
    next: '다음',
    trial: '평가판 시작',
    scheduleDemo: '또는 우리와 함께 데모를 예약하고 함께 발견하십시오.',
    addInt: '통합을 추가합니다.',
    signInApple:
      'Apple에 로그인하여 Apple Search Ads 통합을 추가하십시오. 참고:',
    step1:
      '1. Apple Search Ads 계정에 대한 접근을 허용하기 위해서는 관리자 권한이 필요합니다.',
    step2:
      '2. SearchAdsNinja에서 캠페인을 관리하려면 액세스 권한을 부여하기 전에 읽기 및 쓰기 옵션을 선택하십시오.',
    skip: '건너 뛰기',
  },

  //AddAdgroup
  addAdgroup: {
    addAd: '광고그룹 추가',
    para:
      '아직 캠페인을 선택하지 않았습니다. 아래 상자에서 선택할 수 있습니다.',
    camGroup: '캠페인 그룹',
    campaign: '운동',
    adgroupSett: '광고그룹 설정',
    para1:
      '광고 그룹에는 광고를 게재할 대상을 결정하는 가격 책정 목표 및 설정이 포함되어 있습니다.',
    adgroupName: '광고그룹 이름',
    devices: '기기',
    adScheduling: '광고 예약',
    startTime: '시작 시간',
    endTime: '종료 시간',
    dayparting: '시간대(선택 사항)',
    defaultMax: '기본 최대 CPT 입찰가',
    cptTool:
      '추가 탭에 대해 지불할 의사가 있는 최대 금액입니다. 개별 키워드 입찰가를 적용하지 않는 한 이 입찰가는 이 광고그룹의 모든 키워드에 적용됩니다.',
    cpaGoal: 'CPA 목표(선택사항)',
    cpaTool:
      '각 다운로드에 대해 지불한 평균입니다. 총 지출액을 다운로드 횟수로 나누어 계산합니다.',
    searchMatch: '검색 일치',
    searchTool:
      'Search Match는 광고를 게재하고 실행하는 가장 쉬운 방법입니다. Search Ads는 귀하와 유사한 앱을 검색하는 사용자에게 귀하의 광고를 자동으로 일치시킵니다.',
    searchPara: '내 광고를 관련 검색어에 자동으로 일치시킵니다.',
    key: '키워드',
    keyPara:
      '키워드는 고객이 귀하의 앱과 유사한 앱을 검색할 때 사용할 수 있는 관련 단어 또는 용어입니다. 키워드를 사용하면 검색자가 앱을 빠르게 찾을 수 있어 앱 설치를 유도할 수 있습니다.',
    inputCpt: '키워드 섹션을 활성화하려면 기본 최대 CPT 입찰가를 입력하세요.',
    addKeyPara:
      '앱 및 장르와 관련된 키워드를 추가하세요. 추가 권장 사항을 공유하여 도움을 드리겠습니다.',
    upload: '업로드',
    addKeyManually: '또는 수동으로 키워드 추가',
    negKey: '광고그룹 제외어 키워드(선택사항)',
    supp: '지원 형식: Excel, CSV',
    demAge: '인구통계 연령, 성별',
    demPara: "사용자의 연령 또는 성별에 따라 광고 타겟팅",
    ageRange: '연령대',
    cancel: '취소',
    apply: '대다',
  },

  //Edit Adgroup
  editAdgroups: {
    edit: '광고그룹 수정',
    adgroup: '광고그룹',
    advance: '고급 설정',
    viewEdit: '보기 및 편집',
    general: '일반',
    settings: '설정',
    schedule: '시간표',
    custPara: '도달하려는 앱 고객을 선택하세요.',
    selectAge: '연령대 선택',
    save: '저장',
  },

  //Create AR
  createAR: {
    rule:"규칙",
    addRule: '규칙 추가',
    selectDateRange:'날짜 범위 선택',
    createAutoRule: '자동화 규칙 생성',
    editAutoRule: '자동화 규칙 편집',
    choose: '고르다',
    chooseAutoRule: '자동화 수준과 항목을 선택하십시오.',
    conditions: '정황',
    actions: '행위',
    settings: '설정',
    condition: '질환',
    addCondition: '조건 추가',
    andCondition:
      '위의 모든 조건이 일치해야 하는지 아니면 하나만 일치해야 하는지 선택하십시오.',
    checkCond: '조건 확인',
    action: '동작',
    selectAll: '모두 선택',
    enterEmail: '올바른 이메일 ID를 입력하세요.',
    addTo: '추가',
    selectAdgroups: '광고그룹 선택',
    selectCampaigns: '캠페인 선택',
    cptBid: 'CPT 입찰',
    customBid: '맞춤 입찰가',
    matchType: '일치 유형',
    addAction: '작업 추가',
    chckFreq: '주파수 확인',
    ruleName: '규칙 이름',
    cancel: '취소',
    step2: '2 단계',
    step3: '3단계',
    andOrTypesText1: '모두 일치해야 합니다.',
    andOrTypesText2: '하나만 일치해야 합니다.',
    addToModelListNegText1: '선택한 모든 광고그룹',
    addToModelListNegText2: '선택한 캠페인의 모든 광고 그룹)',
    addToModelListNegText3: '선택한 모든 캠페인',
    matchTypesText1: '확장검색',
    matchTypesText2: '정확히 일치',
    cptBidItemsText1: '사용자 지정으로 설정',
    cptBidItemsText2: '광고그룹 기본 입찰가',
    actionFreqsText1: '매시간',
    actionFreqsText2: '6시간마다',
    actionFreqsText3: '12시간마다',
    actionFreqsText4: '매일',
    actionFreqsText5: '사용자 지정 시간',
    limitActionFreqsText1: '2시간마다',
    limitActionFreqsText2: '3시간마다',
    limitActionFreqsText3: '2일마다',
    limitActionFreqsText4: '3일마다',
    limitActionFreqsText5: '매주',
    mon: '월요일',
    tue: '화요일',
    wed: '수요일',
    thu: '목요일',
    fri: '금요일',
    sat: '토요일',
    sun: '일요일',
    ciTimeRangesText1: '지난 1시간',
    ciTimeRangesText2: '지난 1일',
    ciTimeRangesText3: '지난 7일',
    ciTimeRangesText4: '지난 30일',
    ciTimeRangesText5: '지난 달',
    ciTimeRangesText6: '사용자 지정 시간',
    ciTimeRangesText7: '날짜 범위',
    allConOperationText1: '보다 큰',
    allConOperationText2: '미만',
    allConOperationText3: '범위',
    allConOperationText4: '범위에 들지 않다',
    amount: '$ 금액',
    budget: '예산 비율 %',
    incBy: '증가',
    decBy: '감소',
    setToCPA: '평균 CPA로 설정',
    setToCPT: '평균 CPT로 설정',
    value: '$ 가치',
    change: '% 변경',
    between: '사이',
    equalTo: '같음',
    appsSelected:'선택한 앱',
    campaignsSelected:'선택한 캠페인',
    adgroupsSelected:'선택한 광고그룹',
    KeywordsSelected:'선택한 키워드',
    selectField:'필드 선택',
    selectOne:'하나를 고르시 오',
    pleaseSelect:'선택 해주세요',
    pleaseEnteraValue:'값을 입력 해주세요',
    fromValue:'값에서',
    toValue:'값으로',
    mustBeLesserThanToValue:'To 값보다 작아야 합니다.',
    mustBeHigherThanFromValue:'값보다 높아야 합니다.',
    cannotBeZero:'0이 될 수 없다',
    ruleAlreadyExist:'규칙 이름이 이미 있습니다.',
    emailNotification:'이메일 알림',
    slackNotification: '슬랙 알림',
    pause:'끊다',
    resume:'다시 차지하다',
    changeDailyCap:'일일 한도 변경',
    changeBid:'입찰가 변경',
    changeBudget:'예산 변경',
    addAsNegativeKeyword:'제외 키워드로 추가',
    createActionFor: '작업 생성',
    selectAction:'작업 선택',
    selectEmail:'이메일 선택',
    addOtherEmail:'다른 이메일 추가',
    enterAnotherEmail:'이메일 ID를 입력하고 Enter 키를 눌러 더 추가하세요.',
    giveAName:'이 규칙의 이름을 지정하십시오.',
    searchLogs: '규칙에 따라 로그 필터링',
    details: '세부',
    PENDING: '보류 중',
    SUCCESS: '성공',
    FAILED: '성공',
    CREATED: '만들어진',
    DELETED: '삭제됨',
    ENABLED: '활성화됨',
    DISABLED: '장애가 있는',
    REACTIVATED: '재활성화됨',
    UPDATED: '업데이트됨',
    CONDITIONMATCHED : '조건 일치',
    CONDITIONNOTMATCHED:'조건이 일치하지 않음',
    runIndefinite:'무기한 실행',
    indefiniteNote:'참고: 이 자동화 규칙을 선택하면 지정된 간격으로 계속 적용되며 트리거 횟수에 관계없이 계속 적용됩니다.',
    sureToSave:'계속하시겠습니까? 자동화 규칙을 저장하시겠습니까?',
    update:'업데이트',
    selectChannel:'채널 선택',
    noDataAvailable:'자료 없음',
    sureToDelete:'자동화 규칙을 삭제하시겠습니까?',
  },

  //select Account AR
  selectAccountAR: {
    chooseAcc: '계정 선택',
    cancel: '취소',
    apply: '대다',
    clearAll: '모두 지우기',
    accounts: '계정',
    accountSelected: '선택한 계정',
  },

  //select Apps AR
  selectAppsAR: {
    chooseApp: '앱 선택',
    cancel: '취소',
    apply: '대다',
    apps: '앱',
    appSelected: '선택한 앱',
    clearAll: '모두 지우기',
  },

  //select Keywords AR
  selectKeywordsAR: {
    keepOneAdGroup: '계속하려면 하나의 광고 그룹만 선택하십시오.',
    close: '닫기',
    chooseKeywords: '키워드 선택',
    cancel: '취소',
    apply: '대다',
    campaigns: '캠페인',
    expandAll: '모두 확장',
    collapseAll: '모든 축소',
    adGroups: '광고그룹',
    keywords: '키워드',
    keywordSelected: '선택한 키워드',
    clearAll: '모두 지우기',
    excludePausedKeywords:'일시중지된 키워드 제외',
  },

  //campaign
  //AddCreativeSet
  addCreativeSet: {
    addCreative: '크리에이티브 세트 추가',
    setup: '설정',
    para:
      'Creative Set의 테마를 설명하는 이름을 선택합니다. App Store 제품 페이지에서 포함하려는 스크린샷 및 미리보기의 언어를 선택하십시오.',
    createSetName: '크리에이티브 세트 이름',
    localLang: '현지화 언어',
    assetSelection: '자산 선택',
    assetSelectionPararaph1:
      'Creative Sets는 App Store 제품 페이지에 표시되는 스크린샷과 앱 미리보기로 구성됩니다.',
    assetSelectionPararaph2: '각 iPhone 디스플레이 크기에 대해',
    assetSelectionPararaph3:
      '하나 이상의 가로 또는 세 개의 세로 자산을 선택합니다.',
    assetSelectionPararaph4: '각 iPad 디스플레이 크기에 대해',
    assetSelectionPararaph5:
      '하나 이상의 가로 또는 두 개의 세로 자산을 선택합니다.',
    title1: 'iPhone/iPod',
    screenshotsPara1: '적어도 선택하십시오',
    screenshotsPara2: '하나의 풍경',
    screenshotsPara3: '또는',
    screenshotsPara4: '두 초상화',
    screenshotsPara5: '스크린샷!',
    screenshotsPara6: '적어도 선택하십시오',
    screenshotsPara7: '하나의 풍경',
    screenshotsPara8: '또는',
    screenshotsPara9: '세 초상화',
    screenshotsPara10: '스크린샷!',
    cancel: '취소',
    save: '저장',
  },
  strategy:{
    descriptionValid:"설명은 필수입니다",
    alphaNumeric:"영숫자만 허용됩니다.",
    textMax:"설명은 250자를 초과할 수 없습니다."
  },
  //DeleteStrategy
  deleteStrategy: {
    deleteCamp: '전략 삭제',
    para: '정말 상태를 삭제하시겠습니까?',
    cancel: '취소',
    delete: '삭제',
  },
  //DeleteCampaign
  deleteCampaign: {
    deleteCamp: '캠페인 삭제',
    para: '캠페인을 삭제하시겠습니까?',
    cancel: '취소',
    delete: '삭제',
  },

  //EditCampaign
  editCampaign: {
    editCampWizard: '캠페인 편집 마법사',
    campName: '캠페인 이름',
    app: '앱',
    countriesOrRegionsPara: '하나 이상의 국가 또는 지역을 선택하십시오.',
    budget: '예산',
    dailyCap: '일일 한도',
    cancel: '취소',
    save: '저장',
  },

  //SelectCampaign
  selectCamp: {
    chooseCamp: '캠페인 선택',
    noSelectedCamp: '선택한 캠페인이 없습니다.',
    campaigns: '캠페인',
    expandAll: '모두 확장',
    collapseAll: '모든 축소',
    selectedCampaigns: '선택한 캠페인',
    clearAll: '모두 지우기',
    cancel: '취소',
    apply: '대다',
  },

  //chargebee
  //EditCard
  editCard: {
    editCreditCard: '신용카드 수정',
    cardNumber: '카드 번호',
    expiryDate: '만료일',
    cvv: 'CVV',
    saveCard: '저장 카드',
    updateExpiryPara: '* 만료 날짜만 업데이트할 수 있습니다.',
  },

  //creativeset
  //EditCreativeSet
  editCreativeSet: {
    editCreative: '크리에이티브 세트 수정',
    easywebinar: 'EasyWebinar',
    softobiz: 'Softobiz Technologies Private Limited',
    setup: '설정',
    editCreativePara1:
      'Creative Set의 테마를 설명하는 이름을 선택합니다. App Store 제품 페이지에서 포함하려는 스크린샷 및 미리보기의 언어를 선택하십시오.',
    creativeSetName: '크리에이티브 세트 이름',
    localLang: '현지화 언어',
    assetSelection: '자산 선택',
    assetSelectionPararaph1:
      'Creative Sets는 App Store 제품 페이지에 표시되는 스크린샷과 앱 미리보기로 구성됩니다.',
    assetSelectionPararaph2: '각 iPhone 디스플레이 크기에 대해',
    assetSelectionPararaph3:
      '하나 이상의 가로 또는 세 개의 세로 자산을 선택합니다.',
    assetSelectionPararaph4: '각 iPad 디스플레이 크기에 대해',
    assetSelectionPararaph5:
      '하나 이상의 가로 또는 두 개의 세로 자산을 선택합니다.',
    viewDevice: '장치 디스플레이 크기 보기',
    title1: 'iPhone/iPod',
    ipad: 'iPad',
    display: '표시하다',
    default: '기본',
    cancel: '취소',
    save: '저장',
  },

  addIntegration: {
    invalid: '잘못된 통합입니다. 삭제하고 다시 추가하세요.'
  },

  navbar: {
    syncProgress: '데이터 동기화가 진행 중입니다.'
  },

  messages: {
    onHold: '보류 중인 캠페인',
    reasons: '원인'
  },

  //integration
  //DeleteIntegration
  deleteIntegration: {
    deleteInt: '통합 삭제',
    cardText: '통합을 삭제하시겠습니까?',
    cancel: '취소',
    delete: '삭제',
  },

  //keywords
  //AddKeys
  addKeys: {
    addKeywords: '키워드 추가',
    keywordSuggestionsFor: '키워드 제안',
    on: '~에',
    addedKeywords: '추가된 키워드',
    total: '총',
    add: '추가하다',
    cancel: '취소',
    apply: '대다',
    matchExact: '정확한',
    matchBroad: '넓은',
    searchHere: '여기에서 검색'
  },

  //AddKeysKai
  addKeysKai: {
    addKeywords: '키워드 추가',
    selectTarget: '대상 선택',
    h4Camp: '캠페인:',
    selected: '선택된',
    change: '변화',
    h4Adgroups: '광고그룹:',
    maxCPTBid: '최대 CPT 입찰가:',
    keywordSuggestionsFor: '키워드 제안',
    on: '~에',
    recommendedKeywords: '추천 키워드',
    addedKeywords: '추가된 키워드',
    add: '추가하다',
    total: '총',
    cancel: '취소',
    apply: '대다',
    ok: '확인',
  },

  //AddKeywords
  addKeywords: {
    h6AddKeywords: '키워드 추가',
    selectTarget: '대상 선택',
    h4Camp: '캠페인:',
    selected: '선택된',
    change: '변화',
    h4Adgroups: '광고그룹:',
    maxCPTBid: '최대 CPT 입찰가:',
    keywordSuggestionsFor: '키워드 제안',
    on: '~에',
    recommendedKeywords: '추천 키워드',
    addedKeywords: '추가된 키워드',
    add: '추가하다',
    total: '총',
    cancel: '취소',
    apply: '대다',
    ok: '확인',
  },

  //AddnKeys
  addnKeys: {
    addNegKeywords: '제외 키워드 추가',
    add: '추가하다',
    total: '총',
    cancel: '취소',
    save: '저장',
  },

  //EditBidAmount
  editBidAmount: {
    bidAmount: '입찰 금액',
  },

  //SelectAdGroup
  selectAdGroup: {
    snackbar: '계속하려면 하나의 광고 그룹만 선택하십시오.',
    close: '닫기',
    chooseAdGroup: '광고그룹 선택',
    cancel: '취소',
    apply: '대다',
    campaigns : '캠페인',
    expandAll: '모두 확장',
    collapseAll: '모든 축소',
    adGroups : '광고그룹',
    adGroupSelected : '선택한 광고그룹',
    clearAll : '모두 지우기'
  },

  //negativeKeywords
  //AddNegativeKeywords
  addNegativeKeywords : {
    addNegKeywords : '제외 키워드 추가',
    selectTarget : '1. 대상 선택',
    addNegKeyword : '제외 키워드 추가',
    paragraph1 : '캠페인 또는 광고 그룹에 제외 키워드를 추가할 수 있습니다. 아래 상자에서 그 중 하나를 선택하십시오.',
    paragraph2 : '어떤 캠페인에 키워드를 업로드하시겠습니까?',
    selectCampaign : '캠페인 선택',
    paragraph3 : '어떤 광고그룹에 키워드를 업로드하시겠습니까?',
    selectAdgroup : '광고그룹 선택',
    cancel: '취소',
    easywebinar : 'EasyWebinar',
    softobiz : 'Softobiz Technologies Private Limited',
    campaigns : '캠페인:',
    selected : '선택된',
    change: '변화',
    paragraph4 : ' 광고그룹 제외 키워드는 이 광고그룹에만 적용됩니다. 제외 키워드를 모든 광고그룹에 적용하려면 캠페인 수준에서 설정하세요.',
    paragraph5 : ' 광고 실적을 분석하기 전에 캠페인을 실행할 시간을 주어 어떤 키워드가 귀하에게 적합하지 않을 수 있는지 알아보십시오. 제외 키워드를 너무 많이 추가하면 광고 노출이 제한될 수 있습니다.',
    addedKeywords : '추가된 키워드',
    add: '추가하다',
    total: '총',
    save: '저장',
  },

  //oAuth
  //SigninSlackBtn
  signinSlackBtn : {
    addSlackChannel : 'Slack 채널 추가',
    sno: 'S.아니요'
  },

  //DecisionAlert
   decisionAlert : {
cancel : '취소',
ok : '확인',
confirm:'확인하다'
  },

  //HelloWorld
  helloWorld : {
welcome : 'Vuetify에 오신 것을 환영합니다',
para1 : '다른 Vuetify 개발자와의 도움 및 협업을 위해,',
para2 : '우리의 온라인에 가입하십시오',
discord : '디스코드 커뮤니티',
h2 : "무엇 향후 계획?",
impLinks : '중요 링크',
ecosystem : '생태계',
  },

  //InfoAlert
  infoAlert : {
ok : '확인',
  },

  successMsg: {
    campaignUpdate: '캠페인이 업데이트되었습니다.',
    campaignMovedMsg: '상태가 변경되었습니다. 캠페인이 다른 탭으로 이동됨',
    campaignMoveFailMsg: "캠페인 상태를 변경할 수 없습니다.",
    adgroupUpdateFailMsg: "광고그룹 상태를 업데이트할 수 없습니다.",
    addgroupUpdatedMsg: '상태가 변경되었습니다. 광고그룹이 다른 탭으로 이동됨',
    keywordMovedMsg: '상태가 변경되었습니다. 키워드가 다른 탭으로 이동됨',
    keywordMoveFailMsg: "키워드 상태를 업데이트할 수 없습니다.",
    creativeAddedMsg: '크리에이티브 세트가 추가되었습니다.',
    campaignSavedMsg: '캠페인이 초안으로 저장되었습니다.',
    campaignSaveFailMsg: '현재 캠페인 초안을 저장할 수 없습니다. 다시 시도해 주세요!',
    keyAddedMsg: '키워드가 추가되었습니다.',
    signinFailMsg: '로그인할 수 없습니다. 다시 시도해 주세요!',
    deleteMsg: '성공적으로 삭제됨',
    deleteFailMsg: '삭제할 수 없습니다. 다시 시도해 주세요!',
    dataUpdateMsg : '데이터 업데이트됨',
    dataUpdateFailMsg: '데이터를 업데이트하는 동안 오류가 발생했습니다.',
    AutoDeleteMsg: '자동화 규칙이 성공적으로 삭제되었습니다.',
    requestFailMsg: '요청을 처리할 수 없습니다. 나중에 다시 시도 해주세요',
    AutoRuleDisSuccessMsg: '자동화 규칙이 비활성화되었습니다.',
    AutoRuleEnableMsg: '자동화 규칙이 성공적으로 활성화되었습니다.',
    SCAutoRuleDisSuccessMsg: '스크립팅된 규칙이 성공적으로 비활성화되었습니다.',
    SCAutoRuleEnableMsg: '스크립팅된 규칙이 성공적으로 활성화되었습니다.',
    bidChangeSuccessMsg: '입찰 금액이 성공적으로 변경되었습니다.',
    wentWrongMsg: '문제가 발생했습니다.',
    integrateSuccessMsg : '통합이 성공적으로 추가되었습니다',
    userCreateMsg: '사용자가 성공적으로 생성되었습니다.',
    emailExistMsg: '이메일이 이미 존재합니다',
    errorCreateUser: '사용자를 생성하는 동안 오류가 발생했습니다.',
    inviteFailMsg: '이메일로 초대 링크를 보내는 동안 오류가 발생했습니다.',
    failTryLaterMsg: '뭔가 잘못되었다. 나중에 다시 시도 해주세요',
    adgroupAddedMsg: '광고그룹이 추가되었습니다.',
    adgroupUpdatedMsg: '광고그룹이 업데이트되었습니다.',
    ruleUpdatedMsg: '규칙이 업데이트되었습니다.',
    ruleUpdateFailMsg: '규칙 업데이트에 실패했습니다. 나중에 다시 시도 해주세요',
    rCreatedMsg: '규칙이 생성되었습니다.',
    rCreationFailMsg: '규칙 생성에 실패했습니다. 나중에 다시 시도 해주세요',
    assetDataFailMsg: '자산 세부정보를 가져올 수 없습니다. 다시 시도해 주세요!',
    deleteCampFailMsg: '캠페인을 삭제하는 동안 오류가 발생했습니다.',
    CampDeletedMsg: '캠페인 삭제됨',
    creativeUpdated: '크리에이티브 세트가 업데이트되었습니다.',
    keyAdded: '키워드가 추가되었습니다.',
    negaKeyAddedMsg: '광고그룹에 제외 키워드가 추가되었습니다.',
    negKeyAddCamp: '캠페인에 제외 키워드가 추가되었습니다.',
    errorDeleteKey: '키워드를 삭제하는 동안 오류가 발생했습니다.',
    keyDeleteS: '키워드가 삭제되었습니다. 키워드가 삭제된 키워드로 이동됨',
    syncStarted: '동기화가 시작되었습니다.',
    firstSyncStarted:'Apple Search Ads 통합 완료!<br> <br> <p style="text-align: justify; color: inherit;">ASA 데이터가 처음으로 동기화되는 동안 잠시 기다려 주십시오. 이 첫 번째 동기화가 완료되는 즉시 앱과 ASA 데이터가 채워집니다. 페이지의 오른쪽 상단 가장자리에 있는 동기화 진행률을 따릅니다.<p>',
    integrationWarning:'이 MMP와 통합하기 전에 동기화된 Apple Search Ads 통합이 필요합니다. 위의 Apple Search Ads 통합을 추가하고 이 MMP와 통합하기 전에 첫 번째 동기화가 완료될 때까지 기다리십시오.',
    AdDeletedMsg: '광고 삭제됨',
    adUpdate: '광고 업데이트됨',
    deleteAdFailMsg: '광고를 삭제하는 동안 오류가 발생했습니다.',
    deleteStrategyFailMsg: '전략을 삭제하는 중 오류가 발생했습니다.',
    StrategyDeletedMsg: '전략이 삭제되었습니다.',

  },

  createCampaignRules: {
    selectCampGrp: '캠페인 그룹을 선택하세요!',
    selectApp: '앱을 선택해주세요!',
    selectCountry: '국가를 선택하세요!',
    campRequired: '캠페인 이름이 필요합니다!',
    campNameSize: '캠페인 이름은 200자 미만이어야 합니다',
    adGroupSize: '광고그룹 이름은 200자 미만이어야 합니다',
    keywordsize: '키워드는 80자 이하여야 합니다',
    budgetRequired: '예산 금액이 필요합니다!',
    validBudget: '올바른 예산을 입력하세요!',
    groupRequired: '광고그룹 이름이 필요합니다!',
    defMaxCptRequired: '기본 최대 CPT 입찰가가 필요합니다!',
    validCptBid: '유효한 CPT 입찰가를 입력하십시오!',
    validDailyCap: '유효한 일일 한도를 입력하십시오!',
    validCpaGoal: '유효한 CPA 목표를 입력하십시오!',
    selectDevices: '기기를 선택해주세요!',
    fNamereq: '이름은 필수 항목입니다.',
    fNameGrt: '이름은 12자 이하여야 합니다.',
    textMax: '50자 이하여야 합니다.',
    textMin: '2자 이상이어야 합니다.',
    fNameValid: '유효한 이름을 입력하세요!',
    lNameGrt: '성은 12자 이하여야 합니다.',
    lNameValid: '유효한 이름을 입력하세요!',
    phoneReq: '전화가 필요합니다',
    cmpniNameReq: '회사 이름은 필수 항목입니다.',
    jobTitleReq: '직위는 필수 항목입니다.',
    emailReq: '이메일이 필요합니다',
    emailValid: '이메일이 유효해야 합니다.',
    selectCamp: '캠페인 선택',
    cardNoReq: '카드 번호는 필수 항목입니다.',
    cardNo16Digit: '카드 번호는 숫자와 16자리 숫자여야 합니다.',
    expiryMonthReq: '만료 월과 연도는 필수 항목입니다.',
    expiryMMYY: '만료 날짜는 MM/YYYY여야 합니다. 예. 2023년 7월',
    invalidExpiry: '유효하지 않은 만료 월',
    invalidMonth: '유효하지 않은 만료 연도',
    cvvReq: '카드 CVV는 필수 항목입니다.',
    cvvNum3Digit: '카드 CVV는 숫자와 3자리 길이여야 합니다.',
    specialCharNotAllowed:'특수 문자는 허용되지 않습니다',
    onlySinglespaceAllowed:'단일 공백만 허용됩니다.',
    invalidPhoneFormat:'잘못된 전화 번호 형식'


  },

  settings: {
    profileInfo: '프로필 정보',
    updateProf: '프로필 업데이트',
    payMethod: '지불 방법',
    addCredit: '신용카드 추가',
    cardNo: '카드 번호',
    invoiceHistory: '송장 내역',
    invoiceId: '인보이스_ID',
    dueDate: '마감일',
    paidAt: '유료_시간',
    recure: '반복',
    total: '총',
    status: '상태',
    active: '동작',
    cardId: '카드_ID',
    type: '유형',
    expiryMonth: '만료 월',
    expiry: '만료 연도',
    action: '동작',
    addCard: '카드 추가',
    settings: '설정',
    accountSetting: '계정 설정',
    payment: '지불',
    primaryPayment: '이 카드를 기본 결제 수단으로 설정',
    successTitle: "성공!!!",
    successMsg:"결제 정보 변경이 성공적으로 업데이트되었습니다.",
    NoSuccessTitle: "변경사항이 감지되지 않았습니다!!!",
    NoSuccessMsg:"기존 결제 정보에 변경사항이 없습니다."

  },

  dataRequest: {
    yourDataRequest: '귀하의 데이터 요청',
    remain: '남은',
    complete: '완벽한',
    Fetching: '가져오기',
    Fetched: '가져온',
    appForKeyword: ' 키워드용 앱 - ',
    keywordsForApp: ' 앱용 키워드 - ',
    appsForCategory: ' 카테고리별 상위 앱 - ',
    competitorsForApp: ' 앱에 대한 제안된 경쟁자 - ',

  },

  users: {
    manageComp: '회사 관리',
    comp: '회사',
    addDisManageComp: '모든 회사 추가, 비활성화, 관리',
    createCompAdmin: '새 회사 관리자 만들기',
    enterEmail: '이메일 입력',
    allCompAdmin: '모든 회사 관리자',
    rowPerPages:'키워드'
  },

  manageSubscription: {
    trialPeriod: "당신은 평가판 기간에 있습니다",
    trialPeriodExpire: "평가판은 다음 날짜에 만료됩니다.",
    updateCard: "카드 업데이트",
    subscriptionDetail: "구독 세부정보",
    p1: "다음 결제일",
    p2: "여기에서 청구서 수신 주소, 지불 내역 및 기타 세부 정보를 관리합니다.",
    planListing: '계획 목록',
    furtherPlan: '계속 진행하려면 계획을 선택하세요.',
    generalPlan: '일반',
    month: '월',
    tryIt: '당신이 원하는만큼 그것을 시도',
    createCampaign: '캠페인 만들기',
    keyAnalysis: '키워드 분석',
    automationRule: '자동화 규칙',
    keyOpt: '키워드 최적화',
  },

  billingDetails: {
    billInfo: '결제 정보',
    compName: '회사 이름',
    addLine: '주소 입력란',
    city: '도시',
    zip: '지퍼',
    vat: '큰 통 숫자',
    updateBillAdd: '청구서 수신 주소 업데이트'
  },
  dateRangePresets: {
    today: '오늘',
    yesterday: '어제',
    weekToDate: '주간 누계',
    lastWeek:'지난주',
    lastSevenDays:'지난 7일',
    monthToDate:'월간 누계',
    lastMonth:'지난 달',
    lastThirtyDays:'지난 30일'
  },

  chartHeaders: {
    accounts: '계정',
    apps: '앱',
    campaign: '캠페인',
    adgroup: '광고그룹',
    keyword: '키워드',
    creativeset: '크리에이티브 세트',
    searchterm: '검색어',
  },

  goals: {
    selectGoal: '목표 선택',
    selectEventType: '이벤트 유형 선택',
    selectGoalRule: '목표 유형을 선택하세요.',
    selectEventRule: '이벤트 유형을 선택하세요.',
    goalNameRule: '목표 이름을 입력하세요',
    manageGoals: '목표 관리',
    goalDesc: '여기에서 목표를 관리할 수 있습니다.',
    createGoal: '목표 만들기',
    editGoal: '목표 수정',
    chooseGoal: '목표 유형 선택',
    chooseEvent: '이벤트 선택',
    goalName: '목표 이름',
    close: '닫다',
    update: '업데이트',
    create: '만들다',
    name: '이름',
    app: '앱',
    appId: '앱 ID',
    type: "유형",
    events: "이벤트",
    createdOn: "에 만든",
    createdBy: "만든 사람",
    action: "동작",
    revenue: "수익",
    purchase: "구입",
    subscribe: "구독하다",
    userEngagement: "사용자 참여",
    levelComplete: "레벨 완료",
    regComp: "등록 완료",
    tutComp: "튜토리얼 완료",
    startTrial: "튜토리얼 완료",
    appLaunch: "앱 실행",
    userAction: "사용자 작업",
    addToCart: "장바구니에 추가",
    addToWishlist: "위시리스트에 추가",
    checkoutStart: "결제 시작"
  },

  campaignGenerator: {
    decisionMessage: '템플릿을 삭제하시겠습니까?',
    addKeyPlaceholder: '쉼표로 구분된 텍스트를 입력하고 Enter 키를 누르거나 추가를 클릭합니다.',
    showDraft: '초안 표시',
    pageHeading : '캠페인 생성기',
    createCampaignHeading: '자동 캠페인 구성',
    Segmentation: '세그멘테이션',
    Categories: '카테고리',
    Brand: '브랜드',
    Category: '카테고리',
    Discovery: '디스커버리',
    Competitor: '경쟁자',
    Custom: '사용자 정의',
    Criteria: '기준',
    pleaseSelectCriteria: '기준을 선택하십시오',
    pleaseEnterValue: '값을 입력하십시오',
    Value: '값',
    To: '받는 사람',
    From: '에서',
    FromTo10: '첫 번째 값과 10보다 큰 차이가 있는 값을 입력하십시오.',
    customSlugHeading: '맞춤 캠페인 슬러그:',
    customSlugPH: '슬러그 이름 입력',
    customSlugAdd: '추가',
    customSlugDup: '중복된 슬러그 이름',
    SegmentationRules: '세그먼트 규칙',
    SegmentationDesc: '캠페인에는 앱 홍보를 위한 공통 주제 또는 전략에 중점을 둔 예산과 광고 그룹이 있습니다.',
    age: '나이',
    geo: '위치',
    selectCustomerType: '고객 유형을 선택하십시오',
    selectGender: '성별을 선택하세요',
    selectAge: '나이를 선택하세요',
    keyManually: '키워드 수동 추가',
    Summary: '요약',
    SummaryText: '캠페인 및 광고그룹 요약 확인',
    TotalCampaigns: '총 캠페인',
    TotalAdgroups: '총 광고그룹',
    CampaignTitle: '캠페인 제목',
    keysReady: '키워드가 준비되었습니다',
    custom: '사용자 정의',
    keysFetch: '다음에 대한 키워드 가져오기',
    Campaignsarecreated: '캠페인이 생성됨',
    Creatingcampaigns: '캠페인 만들기',
    Viewallitems: '모든 항목 보기',
    Adgroupsarecreated: '광고그룹이 생성됨',
    Creatingadgroups: '광고그룹 만들기',
    Keywordsarecreated: '키워드가 생성되었습니다',
    Creatingkeywords: '키워드 생성',
    ready: '준비',
    CreatedBy: '작성자',
    CreatedOn: '만든 날짜',
    Campaigngenerationdetails: '캠페인 생성 세부 정보',
    footerProps: {
      itemsPerPageText: '페이지당 행 수',
      itemsPerPageAllText: '전체',
      showFirstLastPage: true,
    },
    generateCampaigns: '캠페인 생성',
    setDefaultValues: '기본값 설정',
    setDefaultValuesSub: '대상 위치에 대한 기본 예산 설정을 제공하십시오.',
    nokeyPara: '카테고리, 검색 또는 경쟁자 캠페인에는 키워드를 입력할 필요가 없습니다. 키워드 분석 기술을 사용하여 키워드가 자동으로 생성되기 때문입니다.',
    waitingToSync: '동기화 대기 중',
    campSyncHour:'캠페인은 1시간 이내에 Apple에 동기화됩니다.',
    //syncInProgress: '동기화 진행 중',
    syncInProgress: '영어로부터',
    syncInProgressFewMin: '동기화가 진행 중이며 몇 분 안에 완료되어야 합니다.',
    syncComplete: '동기화 완료',
    allSyncCompleted: '모든 캠페인이 성공적으로 동기화되었습니다.',
    campNameUniq: '고유한 캠페인 이름을 입력하십시오',
    campNamExists: '같은 이름의 캠페인이 이미 존재합니다',
    adNameUniq: '고유한 광고 그룹 이름을 입력하십시오',
    saveAsDraft: '임시 보관함에 저장',
    draftHeading: '캠페인 템플릿 초안',
    draftSearch: '검색',
    headerOrgName: '조직 이름',
    headerAppName: '앱 이름',
    headerResume: '이력서'
  },

  appSetting: {
    manage: '관리하다',
    setDefaultValues: '기본값 설정',
    edit: '편집하다',
    update:'업데이트',
    noRecords: '일치하는 레코드를 찾을 수 없습니다',
    searchByCountry:'국가별 검색'
  },

  sr: {
    pageHeading: '예약 보고서',
    createScheduleReport: '예약 보고서 만들기',
    srDesc: '예약된 보고서를 사용하면 사용자가 정의한 시간과 요일에 보고서를 메일로 보내거나 느슨하게 할 수 있습니다',
    createSR: '새 보고서 예약',
    for: '보고 대상',
    type: '반복',
    dataDays: '마지막 데이터',
    reportNameExists: '이름이 이미 존재합니다!',
    enterReportName: '이름을 입력하세요',
    repeatMode: '반복 유형',
    pleaseSelectFor: '보고서 수준을 선택하십시오',
    pleaseSelectType: '반복 유형을 선택하십시오',
    pleaseSelectDay: '요일을 선택하세요',
    pleaseSelectTime: '시간을 선택하세요',
    noRepeat: '반복하지 않음',
    custom: '사용자 정의',
    daily: '매일',
    weekly: '매주',
    monthly: '월간',
    selectOrg: '조직을 선택하십시오',
    selectGoal: '목표를 선택하세요',
    goalsSelected: '선택된 목표',
    orgsSelected: '선택된 조직',
    scheduleReport: '예약된 보고서?',
    scheduleReports:'일정 보고서',
    reportOf:'보고',
    selectTime:'시간 선택',
    downloadReport:'보고서 다운로드',
    addMore:'더 추가',
    scheduleReport1:'일정 보고서',
    selectSlackChannel:'Slack 채널 선택',
    emailNoSpaces:'이메일에는 공백이 포함될 수 없습니다.',
    emailWarning:'이메일을 하나 이상 선택하거나 아래에 외부 이메일 ID를 하나 이상 입력하십시오.',
    errorDuringSave:'예약 보고서를 저장하는 동안 오류가 발생했습니다.',
    scheduleSuccess:'성공적으로 예약됨',
    scheduleSaveWarning:'이 보고서를 예약하시겠습니까?'
  },
   //Delete Ad
   deleteAds: {
    deleteAd: '광고 삭제',
    para: '광고를 삭제하시겠습니까?',
    cancel: '취소',
    delete: '삭제',
    ok:'확인'
  },
   //EditAds
   editAd: {
    editAdWizard: '광고 수정 마법사',
    adName: '광고 이름',
    cancel: '취소',
    save: '구하다'
  },
  createAdRules: {
    adRequired: '광고 이름이 필요합니다!',
    adNameSize: '광고 이름은 200자 미만이어야 합니다.'
},
  common : {
    save: '저장',
    cancel: '취소',
    noDataText: '표시할 데이터가 없습니다',
    days: '일',
    time: '시간',
    na: '나',
    goal: '목표',
    deleteConfirmText: '이 항목을 삭제하시겠습니까 ',
    createConfirmText: '이 새로운 생성을 진행합니다 ',
    updateConfirmText: '이 항목을 업데이트하시겠습니까 ',
    dataTableFooterProps: {
      itemsPerPageText: '페이지당 행 수',
      itemsPerPageAllText: '전체',
      showFirstLastPage: true,
    },
  },


  welcomeOnboard: {
    yes: '예',
    no: '아니요',
    continue: '계속하다',
    welcome: 'Search Ads Maven에 오신 것을 환영합니다..',
    description: '검색 Ads Maven에 오신 것을 환영합니다! 통합 설정부터 시작하겠습니다. Apple ID가 필요하고 ASA가 이중 요소 인증을 사용하는 경우 연결된 장치가 필요합니다.',
    getStart: '시작하기',
    setupLater: '나중에 애플리케이션 및 설정으로 이동',
    setup: '설정',
    createIntegration: '통합 생성',
    createIntDescription: 'Apple ID를 입력하여 Search Ads Maven과 Apple Search Ads 간에 양방향 동기화를 생성합니다. MMP 파트너가 있는 경우 해당 통합도 여기에서 설정할 수 있습니다. Apple Search Ads 데이터를 가져와서 시작하겠습니다."',
    enterAppleId: 'Apple ID 통합',
    success: '성공',
    stepper: {
      title: '동기화 진행 중!',
      descreption1: 'Apple Search Ads에서 이전 데이터를 가져오고 있습니다. 데이터 양에 따라 완료하는 데 몇 시간이 걸릴 수 있습니다. 이 동기화 중에 언제든지 오른쪽 상단의 진행률 표시기를 확인할 수 있으며 동기화가 완료되면 통합 페이지를 방문하십시오.',   
      descreption2: '그동안 플랫폼에 익숙해지자.',
    },
    stepperTwo: {      
      title: 'MMP 통합',
      descreption:'MMP는 전체 유입경로 앱 참여 데이터에 대해 다양한 마케팅 채널 및 파트너의 광고 지출 효과를 측정하고 비교할 수 있는 타사 측정 도구입니다. 여기에서 우리에게 도움이 될 가장 큰 MMP 기능은 고객이 앱 스토어를 떠나 앱 사용을 시작한 후에 발생하는 이벤트에 대해 ASA 성능을 분석하고 최적화하는 것입니다..',
      successfullIntegrations:'성공적인 통합',
      receivedTypes: '수신된 이벤트 유형'
      },
    startGuide: '시작 가이드',
    syncMsg: {
      your: '당신의',
      syncWasLess: '동기화가 다음보다 작았습니다.',
      moreTime: '전에. 다시 동기화하기 전에 더 많은 시간을 허용하십시오.'
    },
    goToApplication: '신청하러 가기',

    overview: {
      title: '개요에 오신 것을 환영합니다',
      description: '여기에서 현재 캠페인 실적, 관련 측정항목 등에 대한 요약을 찾을 수 있습니다. 필요에 맞게 이 보기를 사용자 정의할 수 있습니다.',
      popupSecond: {
        title: 'Search Ads Maven을 사용해 주셔서 감사합니다.',
        description: '통합에 대한 질문이나 문제가 있는 경우 언제든지 지원 팀에 문의하십시오.' 
      }
    },
    adsManager: {
      title: '광고 관리자에 오신 것을 환영합니다',
      description: '여기에서 애플리케이션, 캠페인, 광고그룹, 키워드 등에 대한 세분화된 분석 및 보고를 볼 수 있습니다. 또한 프로세스에서 추측을 없애기 위해 수동으로 캠페인을 만듭니다.  광고 관리자 섹션에서는 캠페인, 광고 그룹, 키워드 및 제외 키워드를 만들 수도 있습니다.'
    },
    keywordAnalysis: {
      title: '키워드 분석에 오신 것을 환영합니다 ',
      description: '여기에서 캠페인 키워드의 전반적인 효율성을 측정하고 앱에 대해 제안된 경쟁자를 확인하고 경쟁자의 키워드를 정복할 수 있습니다. 또한 캠페인에 보조 키워드를 빠르게 추가할 수 있습니다.'
    },
    keywordOptimization:{
      title: '키워드 최적화에 오신 것을 환영합니다',
      description: '여기에서 ASA에서 제안한 최소 및 최대 입찰가 범위에 속하지 않는 키워드에 대한 입찰가를 빠르고 효과적으로 최적화할 수 있습니다. ASA 권장 사항과 함께 포함된 앱 이벤트를 기반으로 목표 메트릭을 찾으십시오.'
    },
    integrations: {
      title: '통합에 오신 것을 환영합니다',
      description: '여기에서 ASA 통합을 추가하고, 타사 MMP 통합을 정의하고, 통신 채널을 설정할 수 있습니다.'
    },
    automation: {
      title: '자동화에 오신 것을 환영합니다',
      description: '여기에서 조직, 애플리케이션, 캠페인, 광고 그룹 및 키워드에 대한 자동화 규칙을 만들 수 있습니다.'
    },
    goals: {
      title: '목표에 오신 것을 환영합니다',
      description: ' 여기에서 수익, 참여 및 사용자 활동을 기반으로 캠페인 목표를 생성하기 위해 타사 MMP 이벤트를 사용하는 방법을 정의할 수 있습니다.'
    },
    campaignGenerator: {
      title: '캠페인 생성기에 오신 것을 환영합니다',
      description: '여기에서 수동으로 캠페인을 빠르게 만들거나 자동 캠페인 생성기를 사용하여 프로세스에서 추측을 제거할 수 있습니다.'
    },
    strategicView: {
      title: '전략적 관점에 오신 것을 환영합니다',
      description: 'Search Ads Maven 전략으로 Apple Search Ads의 복잡성을 극복하세요. 자동화와 명확한 마일스톤을 통해 캠페인, 광고그룹, 키워드를 간소화된 항목으로 그룹화합니다. 성공을 구축하고 실행하고 측정하는 모든 작업을 손쉽게 수행할 수 있습니다. 단순화하고, 최적화하고, 승리하세요.'
    },
    thirdParty: {
      popupOne: {
        title: '타사 이벤트 데이터를 통합하시겠습니까? ',
        description: ''
      },
      popupTwo: {
        title: '',
        description: 'ASA 동기화가 완료될 때까지 타사 이벤트 데이터를 가져올 수 없습니다. 동기화가 100%에 도달하면 이 페이지로 돌아가십시오.'
      },
      popupThree: {
        title: '',
        description: 'Search Ads Maven 내에서 ASA 정보를 채우는 데 시간이 걸리므로 그동안 플랫폼에 익숙해지도록 하겠습니다.'
      },    
    }
  }
}

