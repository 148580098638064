import { apolloClient } from '../apollo/client';
import { SAVE_SAM_ACTIVITY } from './apollo-queries';

export async function logActivity(logType: string, message: string, featureName: string, featurePath: string, data: Record<any, any>) {
  try {
    const result = await apolloClient.query({
      query: SAVE_SAM_ACTIVITY,
      variables: {
        activity: {
          logType,
          message,
          featureName,
          featurePath,
          data,
        },
      },
    });

    return result.data.saveSamActivity.successMessage;
  } catch (error) {
    console.log(error);
  }
}
