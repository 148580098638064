var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "grey lighten-4" },
    [
      !_vm.isPlanExpired && _vm.sidebar
        ? _c("Navbar", { class: { active: _vm.miniSidebar } })
        : _vm._e(),
      _vm.isPlanExpired || !_vm.sidebar
        ? _c("Nav", { class: { active: _vm.miniSidebar } })
        : _vm._e(),
      !_vm.isPlanExpired && _vm.sidebar
        ? _c("v-main", { class: { active: _vm.miniSidebar } }, [
            _c(
              "div",
              { staticClass: "d-flex flex-column w-100" },
              [
                _c("router-view"),
                _vm.version()
                  ? [
                      _c(
                        "p",
                        {
                          staticClass:
                            "text-right pr-6 text-body-1 primary--text ",
                        },
                        [_vm._v(_vm._s("Version " + _vm.version()) + " ")]
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm.isPlanExpired || !_vm.sidebar
        ? _c(
            "div",
            { staticClass: "main-outer h-100 d-flex" },
            [
              _c(
                "v-main",
                { staticClass: "spacing-left", staticStyle: { padding: "0" } },
                [_c("router-view")],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }